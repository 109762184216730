import { createMuiTheme } from '@material-ui/core/styles';
import { css, SerializedStyles } from '@emotion/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { drawerWidth } from './constants';

export const donutChartBreakdownColors = [
  '#0D2D3D',
  '#23DD9C',
  '#FD923D',
  '#EDCC38',
  '#8968C7',
  '#9D0606',
  '#13A5D5',
  '#EAE7E7',
];

export const donutChartSelectedColorMap: { [color: string]: string } = {
  '#0D2D3D': '#B5E0ED',
  '#23DD9C': '#D0FFEE',
  '#FD923D': '#FFE9D8',
  '#EDCC38': '#FFF6CE',
  '#8968C7': '#D5C2FC',
  '#9D0606': '#F0A8A8',
  '#13A5D5': '#C1F0FF',
  '#EAE7E7': '#F2F2F2',
};

export const themeColors: { [colorName: string]: string } = {
  cyprus: '#0d2d3d',
  sherpaBlue: '#004a5f',
  cerulean: '#007498',
  hippieBlue: '#498ea2',
  allports: 'rgba(255, 255, 255, 0.1)',
  casablanca: '#eeb354',
  silver: '#c1c1c1',
  concrete: '#dbdbd7',
  gainsboro: '#eae7e7',
  blackSqueeze: '#f2f2f0',
  neonCarrot: '#fd923d',
  tahitiGold: '#e07d22',
  papayaWhip: '#ffe9d8',
  white: '#ffffff',
  successGreen: '#027915',
  errorRed: '#d80000',
  goldenrod: '#eccb1d',
};

export const themeGradient: (angle: number) => string = angle => `transparent
linear-gradient(
  ${angle}deg,
  ${themeColors.cerulean} 0%,
  ${themeColors.sherpaBlue} 100%
)
0% 0% no-repeat padding-box;`;

const themeTypography: TypographyOptions = {
  fontFamily: ['Montserrat', 'Helvetica', 'sans-serif'].join(','),
  h1: {
    fontSize: '14px',
    textTransform: 'uppercase',
    letterSpacing: '3.92px',
    color: themeColors.cyprus,
  },
};

const themePalette: PaletteOptions = {
  primary: {
    main: themeColors.cyprus,
  },
  secondary: {
    main: themeColors.white,
  },
};

export const materialTheme = createMuiTheme({
  typography: {
    ...themeTypography,
  },
  palette: {
    ...themePalette,
  },
  overrides: {
    MuiButton: {
      outlined: {
        '&.outlined-button': {
          border: '1px solid #043746 !important',
          color: '#043746 !important',
        },
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: '8px !important',
      },
    },
  },
});

export const withGlobalAppStyles: (
  sidebarOpen: boolean,
  scrolled: boolean,
) => SerializedStyles = (sidebarOpen, scrolled) => css`
  main {
    background: ${window.location.pathname === '/sales' ||
    window.location.pathname === '/account' ||
    window.location.pathname === '/'
      ? '#f0f0f0'
      : themeColors.white};
    color: ${themeColors.cyprus};
    width: ${sidebarOpen ? `calc(100vw  - 304px)` : '100vw'};
    margin-left: ${sidebarOpen ? `${drawerWidth}px` : '0'};
    height: 100vh;
    padding-top: ${scrolled ? '82' : '152'}px;
    font-family: 'Montserrat', Helvetica, sans-serif;
    transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      padding-top 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    .body {
      transition: width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      width: ${!sidebarOpen ? '100vw' : 'calc(100vw - 304px)'};
      height: 100%;
    }
    section.body {
      padding: ${!sidebarOpen ? ' 28px 60px 28px 72px' : '28px 40px'};
    }
    ul {
      list-style: none;
    }
    .asin-section {
      padding: 0;
      height: 100%;
    }
  }
`;
