import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  cacheExchange,
  createClient,
  fetchExchange,
  Provider as GraphQLProvider,
} from 'urql';
import apiRoot from '../api/apiRoot';
import { Factory, Runnable } from '../common/types';
import { apiRoutes } from '../common/routes';
import { refreshTokenExchange } from '../auth/refreshTokenExchange';
import { authTokenKey } from '../auth/useAuth';

interface GraphQLContext {
  clearCache: Runnable;
}

const GraphQlClientContext = createContext<GraphQLContext>({
  clearCache: () => null,
});

const makeClient = () =>
  createClient({
    url: `${apiRoot}${apiRoutes.graphQl}`,
    exchanges: [cacheExchange, refreshTokenExchange, fetchExchange],
    fetchOptions: () => {
      const token = window.localStorage[authTokenKey];
      return {
        headers: { Authorization: token ? `Bearer ${token}` : '' },
      };
    },
  });

const CustomGraphQLProvider: React.FC = ({ children }) => {
  const [client, setClient] = useState(makeClient());
  useEffect(() => {
    setClient(makeClient());
  }, []);

  const clearCache = () => {
    setClient(makeClient());
  };
  return (
    <GraphQlClientContext.Provider value={{ clearCache }}>
      <GraphQLProvider value={client}>{children}</GraphQLProvider>
    </GraphQlClientContext.Provider>
  );
};

export const useGraphQLClient: Factory<GraphQLContext> = () =>
  useContext(GraphQlClientContext);

export default CustomGraphQLProvider;
