import { UseQueryState } from 'urql';
import moment, { unitOfTime } from 'moment';
import {
  DataPointAggregate,
  GetAsinsResponse,
  GetDataPointsAggregateResponse,
} from '../../api/data';
import {
  FieldConfiguration,
  GetFieldConfigurationsResponse,
} from '../../api/fieldConfiguration';
import { Props as FilterListProps } from '../breakdowns/FilterList';
import { PrimaryBreakdownData } from '../breakdowns/PrimaryBreakdown';
import { SecondaryBreakdownData } from '../breakdowns/SecondaryBreakdown';
import { prettyPrintNumber } from '../../common/utils';
import {
  BuyBoxSalesItem,
  Change,
  CombinedDonutGraphData,
  DataPoint,
  DataType,
  FilterType,
  LabeledDataPoint,
} from '../../common/types';
import { DateRange } from '../graphs/types';
import {
  Category,
  GetCategoriesByClientId,
  GetSubCategoriesByClientId,
  SubCategory,
} from '../../api/categories';
import { extractUuidFromFullPath } from '../../common/utils';
import { DataColumn } from '../graphs/BreakdownColumn';
import { XAxisResolutionType } from '../graphs/DualYAxisGraph';

const getChangeData: (
  previous: number,
  current: number,
) => { percentage: string; change: Change; rawValue?: number } = (
  previous,
  current,
) => {
  if (previous === current) {
    return {
      percentage: prettyPrintNumber(0, 'percent'),
      change: 'none',
      rawValue: 0,
    };
  }
  if (previous === 0 || !isFinite(previous)) {
    return {
      percentage: '--',
      change: 'positive',
      rawValue: Infinity,
    };
  }
  if (current === 0) {
    return {
      percentage: prettyPrintNumber(-100, 'percent'),
      change: 'negative',
      rawValue: -100,
    };
  }
  const raw = (current - previous) / previous;
  const converted = Math.round(raw * 10000) / 100;
  let percentVal: number;
  if (converted === 0) {
    percentVal = raw < 0 ? -0.01 : 0.01;
  } else {
    percentVal = raw < 0 ? converted * -1 : converted;
  }

  const percentage = `${converted === 0 ? '<' : ''} ${prettyPrintNumber(
    percentVal,
    'percent',
  )}`;
  return {
    percentage,
    change: raw < 0 ? 'negative' : 'positive',
    rawValue: raw < 0 ? percentVal * -1 : percentVal,
  };
};

const initialOrderedRevenueData: PrimaryBreakdownData = {
  delta: {
    change: 'none',
    percentage: '',
    value: '',
  },
  error: false,
  processing: true,
  value: '',
};

export const transformDataPointAggregatePrimary: (
  response: UseQueryState<GetDataPointsAggregateResponse>,
  responsePreviousPeriod: UseQueryState<GetDataPointsAggregateResponse>,
  deltaValueType: DataType,
) => PrimaryBreakdownData = (
  response,
  responsePreviousPeriod,
  deltaValueType,
) => {
  if (
    response.data &&
    response.data.dataPointAggregates &&
    responsePreviousPeriod.data &&
    responsePreviousPeriod.data.dataPointAggregates
  ) {
    const dataPoints = response.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const dataPointsPreviousPeriod = responsePreviousPeriod.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const current = dataPoints.length > 0 ? parseFloat(dataPoints[0].value) : 0;
    const previous =
      dataPointsPreviousPeriod.length > 0
        ? parseFloat(dataPointsPreviousPeriod[0].value)
        : 0;

    if (isNaN(current) || isNaN(previous)) {
      return {
        ...initialOrderedRevenueData,
        error: true,
        processing: false,
      };
    }

    return {
      delta: {
        ...getChangeData(previous, current),
        value: prettyPrintNumber(previous, deltaValueType),
      },
      processing: false,
      value: prettyPrintNumber(current, deltaValueType),
    };
  }
  if (response.fetching) {
    return initialOrderedRevenueData;
  }
  if (response.error) {
    return {
      ...initialOrderedRevenueData,
      error: true,
      processing: false,
    };
  }
  return { ...initialOrderedRevenueData, processing: false, error: true };
};

const initialFilterListProps: FilterListProps = {
  data: [],
  error: false,
  loading: true,
  deltaValueType: 'dollar amount',
};

export const transformOrderedRevenueTopFiveFilterData: (
  response: UseQueryState<GetDataPointsAggregateResponse>,
  previousResponse: UseQueryState<GetDataPointsAggregateResponse>,
  filterType: FilterType,
  deltaValueType: DataType,
  asins?: UseQueryState<GetAsinsResponse>,
) => FilterListProps = (
  currentDataResponse,
  previousDataResponse,
  filterType,
  deltaValueType,
  asins,
) => {
  let response = { ...initialFilterListProps, deltaValueType };
  if (
    currentDataResponse.fetching ||
    previousDataResponse.fetching ||
    (asins !== undefined && asins.fetching)
  ) {
    response = { ...initialFilterListProps, deltaValueType };
  } else if (
    currentDataResponse.error ||
    previousDataResponse.error ||
    (asins !== undefined && asins.error)
  ) {
    response = { data: [], error: true, loading: false, deltaValueType };
  } else if (currentDataResponse.data && previousDataResponse.data) {
    const dataPoints = currentDataResponse.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const dataPointsPreviousPeriod = previousDataResponse.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    switch (filterType) {
      case 'asin':
        if (asins !== undefined && asins.data) {
          return {
            data: asins.data.asins.edges.map(asin => {
              const previousDataPoint = dataPointsPreviousPeriod.find(
                datapoint =>
                  datapoint.asinId === extractUuidFromFullPath(asin.node.id),
              );
              const currentDataPoint = dataPoints.find(
                datapoint =>
                  datapoint.asinId === extractUuidFromFullPath(asin.node.id),
              );

              const current = currentDataPoint
                ? parseFloat(currentDataPoint.value)
                : 0;
              const previous = previousDataPoint
                ? parseFloat(previousDataPoint.value)
                : 0;
              const delta = getChangeData(previous, current);
              return {
                filter: `${asin.node.productName || ''} (${asin.node.asin})`,
                delta: delta,
                total: currentDataPoint ? currentDataPoint.value : '0',
              };
            }),
            error: false,
            loading: false,
            deltaValueType,
          };
        } else {
          return { data: [], error: true, loading: false, deltaValueType };
        }
      case 'category':
        return {
          data: dataPoints.map(currentDataPoint => {
            const previousDataPoint = dataPointsPreviousPeriod.find(
              previousDataPoint =>
                currentDataPoint.categoryId === previousDataPoint.categoryId,
            );
            const current = currentDataPoint
              ? parseFloat(currentDataPoint.value)
              : 0;
            const previous = previousDataPoint
              ? parseFloat(previousDataPoint.value)
              : 0;
            const delta = getChangeData(previous, current);
            return {
              filter: `${currentDataPoint.category ?? 'Uncategorized'}`,
              delta: delta,
              total: currentDataPoint ? currentDataPoint.value : '0',
            };
          }),
          error: false,
          loading: false,
          deltaValueType,
        };
      case 'subcategory':
        return {
          data: dataPoints.map(currentDataPoint => {
            const previousDataPoint = dataPointsPreviousPeriod.find(
              previousDataPoint =>
                currentDataPoint.subcategoryId ===
                previousDataPoint.subcategoryId,
            );
            const current = currentDataPoint
              ? parseFloat(currentDataPoint.value)
              : 0;
            const previous = previousDataPoint
              ? parseFloat(previousDataPoint.value)
              : 0;
            const delta = getChangeData(previous, current);
            return {
              filter: `${currentDataPoint.subcategory ?? 'Uncategorized'}`,
              delta: delta,
              total: currentDataPoint ? currentDataPoint.value : '0',
            };
          }),
          error: false,
          loading: false,
          deltaValueType,
        };
    }
  }
  return response;
};

export const transformTopFiveDataPointAggregateSecondaryASP: (
  revenueResponse: UseQueryState<GetDataPointsAggregateResponse>,
  unitsResponse: UseQueryState<GetDataPointsAggregateResponse>,
  previousRevenueResponse: UseQueryState<GetDataPointsAggregateResponse>,
  previousUnitsResponse: UseQueryState<GetDataPointsAggregateResponse>,
  filterType: FilterType,
  deltaValueType: DataType,
  asins?: UseQueryState<GetAsinsResponse>,
  isLostBb?: boolean,
) => FilterListProps = (
  revenueResponse,
  unitsResponse,
  previousRevenueResponse,
  previousUnitsResponse,
  filterType,
  deltaValueType,
  asins,
  isLostBb,
) => {
  let response = { ...initialFilterListProps, deltaValueType };
  if (
    revenueResponse.fetching ||
    unitsResponse.fetching ||
    previousRevenueResponse.fetching ||
    previousUnitsResponse.fetching ||
    (asins !== undefined && asins.fetching)
  ) {
    response = { ...initialFilterListProps, deltaValueType };
  } else if (
    revenueResponse.error ||
    unitsResponse.error ||
    previousRevenueResponse.error ||
    previousUnitsResponse.error ||
    (asins !== undefined && asins.error)
  ) {
    response = { data: [], error: true, loading: false, deltaValueType };
  } else if (
    revenueResponse.data &&
    unitsResponse.data &&
    previousRevenueResponse.data &&
    previousUnitsResponse.data
  ) {
    const dataPointsRevenue = revenueResponse.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const dataPointsUnits = unitsResponse.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const dataPointsPreviousPeriodRevenue = previousRevenueResponse.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const dataPointsPreviousPeriodUnits = previousUnitsResponse.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    if (asins?.data?.asins.edges.length === 0) {
      response = { data: [], error: true, loading: false, deltaValueType };
    } else {
      switch (filterType) {
        case 'asin':
          if (asins !== undefined && asins.data) {
            return {
              data: asins.data.asins.edges.map(asin => {
                const previousDataPointRevenue = dataPointsPreviousPeriodRevenue.find(
                  datapoint =>
                    datapoint.asinId === extractUuidFromFullPath(asin.node.id),
                );
                const previousDataPointUnits = dataPointsPreviousPeriodUnits.find(
                  datapoint =>
                    datapoint.asinId === extractUuidFromFullPath(asin.node.id),
                );
                const currentDataPointRevenue = dataPointsRevenue.find(
                  datapoint =>
                    datapoint.asinId === extractUuidFromFullPath(asin.node.id),
                );
                const currentDataPointUnits = dataPointsUnits.find(
                  datapoint =>
                    datapoint.asinId === extractUuidFromFullPath(asin.node.id),
                );

                const currentRevenue = currentDataPointRevenue
                  ? parseFloat(currentDataPointRevenue.value)
                  : 0;
                const currentUnits = currentDataPointUnits
                  ? parseFloat(currentDataPointUnits.value)
                  : 0;
                const previousRevenue = previousDataPointRevenue
                  ? parseFloat(previousDataPointRevenue.value)
                  : 0;
                const previousUnits = previousDataPointUnits
                  ? parseFloat(previousDataPointUnits.value)
                  : 0;
                let aspCurrent = currentRevenue / currentUnits;
                const aspPrevious = previousRevenue / previousUnits;

                const delta = getChangeData(aspPrevious, aspCurrent);

                if (isLostBb !== undefined) {
                  if (isLostBb && delta.change === 'positive') {
                    aspCurrent = aspCurrent * -1;
                  }
                }
                return {
                  filter: `${asin.node.productName || ''} (${asin.node.asin})`,
                  delta: delta,
                  total: aspCurrent
                    ? deltaValueType === 'percent'
                      ? Math.abs(aspCurrent * 100).toString()
                      : Math.abs(aspCurrent).toString()
                    : '0',
                };
              }),
              error: false,
              loading: false,
              deltaValueType,
            };
          } else {
            return { data: [], error: true, loading: false, deltaValueType };
          }
        case 'category':
          return {
            data: dataPointsRevenue.map(currentDataPointRevenue => {
              const currentDataPointUnits = dataPointsUnits.find(
                previousDataPoint =>
                  currentDataPointRevenue.categoryId ===
                  previousDataPoint.categoryId,
              );
              const previousDataPointRevenue = dataPointsPreviousPeriodRevenue.find(
                previousDataPoint =>
                  currentDataPointRevenue.categoryId ===
                  previousDataPoint.categoryId,
              );
              const previousDataPointUnits = dataPointsPreviousPeriodUnits.find(
                previousDataPoint =>
                  currentDataPointRevenue.categoryId ===
                  previousDataPoint.categoryId,
              );
              const currentRevenue = currentDataPointRevenue
                ? parseFloat(currentDataPointRevenue.value)
                : 0;
              const currentUnits = currentDataPointUnits
                ? parseFloat(currentDataPointUnits.value)
                : 0;
              const previousRevenue = previousDataPointRevenue
                ? parseFloat(previousDataPointRevenue.value)
                : 0;
              const previousUnits = previousDataPointUnits
                ? parseFloat(previousDataPointUnits.value)
                : 0;
              const aspCurrent = currentRevenue / currentUnits;
              const aspPrevious = previousRevenue / previousUnits;
              const delta = getChangeData(aspPrevious, aspCurrent);
              return {
                filter: `${
                  currentDataPointRevenue.category ?? 'Uncategorized'
                }`,
                delta: delta,
                total: aspCurrent
                  ? deltaValueType === 'percent'
                    ? Math.abs(aspCurrent * 100).toString()
                    : Math.abs(aspCurrent).toString()
                  : '0',
              };
            }),
            error: false,
            loading: false,
            deltaValueType,
          };
        case 'subcategory':
          return {
            data: dataPointsRevenue.map(currentDataPointRevenue => {
              const currentDataPointUnits = dataPointsUnits.find(
                previousDataPoint =>
                  currentDataPointRevenue.subcategoryId ===
                  previousDataPoint.subcategoryId,
              );
              const previousDataPointRevenue = dataPointsPreviousPeriodRevenue.find(
                previousDataPoint =>
                  currentDataPointRevenue.subcategoryId ===
                  previousDataPoint.subcategoryId,
              );
              const previousDataPointUnits = dataPointsPreviousPeriodUnits.find(
                previousDataPoint =>
                  currentDataPointRevenue.subcategoryId ===
                  previousDataPoint.subcategoryId,
              );
              const currentRevenue = currentDataPointRevenue
                ? parseFloat(currentDataPointRevenue.value)
                : 0;
              const currentUnits = currentDataPointUnits
                ? parseFloat(currentDataPointUnits.value)
                : 0;
              const previousRevenue = previousDataPointRevenue
                ? parseFloat(previousDataPointRevenue.value)
                : 0;
              const previousUnits = previousDataPointUnits
                ? parseFloat(previousDataPointUnits.value)
                : 0;
              const aspCurrent = currentRevenue / currentUnits;
              const aspPrevious = previousRevenue / previousUnits;
              const delta = getChangeData(aspPrevious, aspCurrent);
              return {
                filter: `${
                  currentDataPointRevenue.subcategory ?? 'Uncategorized'
                }`,
                delta: delta,
                total: aspCurrent
                  ? deltaValueType === 'percent'
                    ? Math.abs(aspCurrent * 100).toString()
                    : Math.abs(aspCurrent).toString()
                  : '0',
              };
            }),
            error: false,
            loading: false,
            deltaValueType,
          };
      }
    }
  }
  return response;
};

const initialBuyboxPercentData: SecondaryBreakdownData = {
  delta: {
    change: 'none',
    percentage: '',
  },
  error: false,
  processing: true,
  value: '',
};

export const transformDataPointAggregateSecondary: (
  response: UseQueryState<GetDataPointsAggregateResponse>,
  responsePreviousPeriod: UseQueryState<GetDataPointsAggregateResponse>,
  valueType: DataType,
) => SecondaryBreakdownData = (response, responsePreviousPeriod, valueType) => {
  if (
    response.data &&
    response.data.dataPointAggregates &&
    responsePreviousPeriod.data &&
    responsePreviousPeriod.data.dataPointAggregates
  ) {
    const dataPoints = response.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const dataPointsPreviousPeriod = responsePreviousPeriod.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const current = dataPoints.length > 0 ? parseFloat(dataPoints[0].value) : 0;
    const previous =
      dataPointsPreviousPeriod.length > 0
        ? parseFloat(dataPointsPreviousPeriod[0].value)
        : 0;
    if (isNaN(current) || isNaN(previous)) {
      return {
        ...initialBuyboxPercentData,
        error: true,
        processing: false,
      };
    }

    const delta = getChangeData(previous, current);
    return {
      delta: delta,
      processing: false,
      value: prettyPrintNumber(current, valueType),
    };
  }
  if (response.fetching) {
    return initialBuyboxPercentData;
  }
  if (response.error) {
    return {
      ...initialBuyboxPercentData,
      error: true,
      processing: false,
    };
  }
  return { ...initialBuyboxPercentData, processing: false, error: true };
};

export const transformDataPointAggregateSecondaryASP: (
  responseRevenue: UseQueryState<GetDataPointsAggregateResponse>,
  responseUnits: UseQueryState<GetDataPointsAggregateResponse>,
  responseRevenuePreviousPeriod: UseQueryState<GetDataPointsAggregateResponse>,
  responseUnitsPreviousPeriod: UseQueryState<GetDataPointsAggregateResponse>,
  valueType: DataType,
) => SecondaryBreakdownData = (
  responseRevenue,
  responseUnits,
  responseRevenuePreviousPeriod,
  responseUnitsPreviousPeriod,
  valueType,
) => {
  if (
    responseRevenue.data &&
    responseUnits.data &&
    responseRevenue.data.dataPointAggregates &&
    responseUnits.data.dataPointAggregates &&
    responseRevenuePreviousPeriod.data &&
    responseUnitsPreviousPeriod.data &&
    responseRevenuePreviousPeriod.data.dataPointAggregates &&
    responseUnitsPreviousPeriod.data.dataPointAggregates
  ) {
    const dataPointsRevenue = responseRevenue.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const dataPointsUnits = responseUnits.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );

    const dataPointsPreviousPeriodRevenue = responseRevenuePreviousPeriod.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const dataPointsPreviousPeriodUnits = responseUnitsPreviousPeriod.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const currentRevenue =
      dataPointsRevenue.length > 0 ? parseFloat(dataPointsRevenue[0].value) : 0;
    const currentUnits =
      dataPointsUnits.length > 0 ? parseFloat(dataPointsUnits[0].value) : 0;
    const previousRevenue =
      dataPointsPreviousPeriodRevenue.length > 0
        ? parseFloat(dataPointsPreviousPeriodRevenue[0].value)
        : 0;
    const previousUnits =
      dataPointsPreviousPeriodUnits.length > 0
        ? parseFloat(dataPointsPreviousPeriodUnits[0].value)
        : 0;
    if (
      isNaN(currentRevenue) ||
      isNaN(currentUnits) ||
      isNaN(previousRevenue) ||
      isNaN(previousUnits)
    ) {
      return {
        ...initialBuyboxPercentData,
        error: true,
        processing: false,
      };
    }
    const aspCurrent = currentRevenue / currentUnits;
    const aspPrevious = previousRevenue / previousUnits;
    const delta = getChangeData(aspPrevious, aspCurrent);
    return {
      delta: delta,
      processing: false,
      value: prettyPrintNumber(
        valueType === 'percent' ? 100 * aspCurrent : aspCurrent,
        valueType,
      ),
    };
  }
  if (responseRevenue.fetching) {
    return initialBuyboxPercentData;
  }
  if (responseRevenue.error) {
    return {
      ...initialBuyboxPercentData,
      error: true,
      processing: false,
    };
  }
  return { ...initialBuyboxPercentData, processing: false, error: true };
};

export const transformFieldConfigurationsByClientData: (
  response: UseQueryState<GetFieldConfigurationsResponse>,
) => FieldConfiguration[] = response => {
  if (response.fetching || response.error) {
    return [];
  }
  if (response.data) {
    return response.data.fieldConfigurations.edges
      .map(edge => ({
        displayName: edge.node.displayName,
        fieldKey: edge.node.fieldKey,
        id: edge.node.id,
        previous: false,
        axisLabel: edge.node.axisLabel,
        axisName: edge.node.axisName,
        fieldType: edge.node.fieldType,
        importerType: edge.node.importer?.type,
      }))
      .concat(
        response.data.fieldConfigurations.edges.map(edge => ({
          displayName: `${edge.node.displayName} - Previous`,
          fieldKey: edge.node.fieldKey,
          id: edge.node.id,
          previous: true,
          axisLabel: edge.node.axisLabel,
          axisName: edge.node.axisName,
          fieldType: edge.node.fieldType,
          importerType: edge.node.importer?.type,
        })),
      );
  }
  return [];
};

export const transformTwoPercentAsins: (
  yearOrderedRevenueResponse: UseQueryState<GetDataPointsAggregateResponse>,
  yearAsinsOrderedRevenueResponse: UseQueryState<
    GetDataPointsAggregateResponse
  >,
  triMonthOrderedRevenueResponse: UseQueryState<GetDataPointsAggregateResponse>,
  triMonthAsinsOrderedRevenueResponse: UseQueryState<
    GetDataPointsAggregateResponse
  >,
) => (string | undefined)[] = (
  yearOrderedRevenueResponse,
  yearAsinsOrderedRevenueResponse,
  triMonthOrderedRevenueResponse,
  triMonthAsinsOrderedRevenueResponse,
) => {
  if (
    yearOrderedRevenueResponse.data &&
    yearOrderedRevenueResponse.data.dataPointAggregates &&
    yearAsinsOrderedRevenueResponse.data &&
    yearAsinsOrderedRevenueResponse.data.dataPointAggregates &&
    yearOrderedRevenueResponse.data.dataPointAggregates.edges.length > 0 &&
    triMonthOrderedRevenueResponse.data &&
    triMonthOrderedRevenueResponse.data.dataPointAggregates &&
    triMonthAsinsOrderedRevenueResponse.data &&
    triMonthAsinsOrderedRevenueResponse.data.dataPointAggregates
  ) {
    const yearTwoPercent =
      Number(
        yearOrderedRevenueResponse.data.dataPointAggregates.edges[0]?.node
          .value,
      ) * 0.02;
    const triMonthTwoPercent =
      Number(
        triMonthOrderedRevenueResponse.data.dataPointAggregates.edges[0]?.node
          .value,
      ) * 0.02;
    const asinsPastYear = yearAsinsOrderedRevenueResponse.data.dataPointAggregates.edges
      .filter(
        _node =>
          Number(_node.node.value) > yearTwoPercent &&
          _node.node.asinId !== undefined,
      )
      .map(_node => _node.node.asinId);
    const asinsPastTriMonths = triMonthAsinsOrderedRevenueResponse.data.dataPointAggregates.edges
      .filter(
        _node =>
          Number(_node.node.value) > triMonthTwoPercent &&
          _node.node.asinId !== undefined,
      )
      .map(_node => _node.node.asinId);
    asinsPastTriMonths.forEach(asin => {
      if (!asinsPastYear.includes(asin)) {
        asinsPastYear.push(asin);
      }
    });
    return asinsPastYear;
  }
  return [];
};
const graphLabel: (
  timestamp: string,
  xAxisResolution: XAxisResolutionType,
) => string = (timestamp, xAxisResolution) => {
  switch (xAxisResolution) {
    case 'Daily':
      return `${timestamp.substring(4, 6)}/${timestamp.substring(
        6,
        8,
      )}/${timestamp.substring(0, 4)}`;
    case 'Weekly':
      return `W${timestamp.substring(4, 6)}-${timestamp.substring(2, 4)}`;
    case 'Monthly':
      return `${moment(timestamp, 'YYYYMM')
        .format('MMMM')
        .substring(0, 3)}-${timestamp.substring(2, 4)}`;
    case 'Yearly':
      return moment(timestamp, 'YYYY').format('YYYY');
  }
  return '';
};

const xValue: (
  timestamp: string,
  xAxisResolution: XAxisResolutionType,
) => string = (timestamp, xAxisResolution) => {
  switch (xAxisResolution) {
    case 'Daily':
      return moment(timestamp, 'YYYYMMDD').format('YYYYMMDD');
    case 'Weekly':
      return moment(timestamp, 'YYYYWW').format('YYYYMMDD');
    case 'Monthly':
      return moment(timestamp, 'YYYYMM').format('YYYYMMDD');
    case 'Yearly':
      return moment(timestamp, 'YYYY').format('YYYYMMDD');
  }
  return '';
};

const isPartial: (
  timestamp: string,
  xAxisResolution: XAxisResolutionType,
  selectedDates?: DateRange,
) => boolean = (timestamp, xAxisResolution, selectedDates) => {
  switch (xAxisResolution) {
    case 'Daily':
      return false;
    case 'Weekly':
      return (
        moment(timestamp, 'yyyyWW').format('YYYYMMDD') !==
          selectedDates?.startDate?.format('YYYYMMDD') ||
        moment(timestamp, 'yyyyWW').format('YYYYMMDD') !==
          selectedDates?.endDate?.format('YYYYMMDD')
      );
    case 'Monthly':
      return (
        moment(timestamp, 'YYYYMM').format('YYYYMMDD') !==
          selectedDates?.startDate?.format('YYYYMMDD') ||
        moment(timestamp, 'YYYYMM').format('YYYYMMDD') !==
          selectedDates?.endDate?.format('YYYYMMDD')
      );
    case 'Yearly':
      return (
        moment(timestamp, 'YYYY').format('YYYYMMDD') !==
          selectedDates?.startDate?.format('YYYYMMDD') ||
        moment(timestamp, 'YYYY').format('YYYYMMDD') !==
          selectedDates?.endDate?.format('YYYYMMDD')
      );
  }
  return false;
};
export const transformDataPointAggregateToGraphData: (
  type: DataType,
  xAxisResolution: XAxisResolutionType,
  currentResponse: UseQueryState<GetDataPointsAggregateResponse>,
  previousResponse?: UseQueryState<GetDataPointsAggregateResponse>,
  selectedDates?: DateRange,
  previousDates?: DateRange,
  dataSetName?: string,
) => DataPoint[] = (
  type,
  xAxisResolution,
  currentResponse,
  previousResponse,
  selectedDates,
  previousDates,
  dataSetName,
) => {
  if (currentResponse.data && currentResponse.data.dataPointAggregates) {
    const currentDataPoints = currentResponse.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    if (previousResponse === undefined) {
      const evenedDataPoints = evenDataPoints(
        currentDataPoints,
        selectedDates,
        xAxisResolution,
      );
      const dpCount = evenedDataPoints.length;
      return evenedDataPoints.map((datapoint, index) => {
        const partial =
          index === 0 || index === dpCount - 1
            ? isPartial(
                datapoint.timestamp ? datapoint.timestamp : '',
                xAxisResolution,
                selectedDates,
              )
            : false;
        return {
          dataSetName,
          x: xValue(
            datapoint.timestamp ? datapoint.timestamp : '',
            xAxisResolution,
          ),
          y: parseFloat(datapoint.value),
          label: `${partial ? 'Partial ' : ''}${graphLabel(
            datapoint.timestamp ? datapoint.timestamp : '',
            xAxisResolution,
          )}: ${prettyPrintNumber(Number(datapoint.value), type)}\n`,
        };
      });
    } else {
      const previousDataPoints = previousResponse.data?.dataPointAggregates.edges.map(
        edge => edge.node,
      );

      const finalDataPoints: DataPoint[] = [];
      if (
        previousDataPoints !== undefined &&
        previousDataPoints.length > 0 &&
        selectedDates !== undefined &&
        previousDates !== undefined &&
        selectedDates.startDate !== null &&
        selectedDates.endDate !== null &&
        previousDates.startDate !== null &&
        previousDates.endDate !== null
      ) {
        const previous = evenDataPoints(
          previousDataPoints,
          previousDates,
          xAxisResolution,
        );
        const current = evenDataPoints(
          currentDataPoints,
          selectedDates,
          xAxisResolution,
        );
        if (current.length < previous.length) {
          return [];
        }
        previous.forEach((previousDataPoint, index) => {
          const currentX =
            current[index].timestamp !== undefined
              ? current[index].timestamp
              : '';
          return finalDataPoints.push({
            dataSetName,
            x: xValue(currentX ? currentX : '', xAxisResolution),
            y: parseFloat(previousDataPoint.value),
            label: `${graphLabel(
              previousDataPoint.timestamp ? previousDataPoint.timestamp : '',
              xAxisResolution,
            )}: ${prettyPrintNumber(Number(previousDataPoint.value), type)}\n`,
          });
        });
      }
      return finalDataPoints;
    }
  }
  return [];
};
export const transformPointsToGraphablePoints: (
  primaryPoints: DataPoint[],
  secondaryPoints: DataPoint[],
  type: DataType,
) => DataPoint[] = (primaryPoints, secondaryPoints, type) => {
  if (secondaryPoints.length === 0) {
    return primaryPoints;
  }
  return primaryPoints.map((_dataPoint, index) => {
    const rawValue =
      type === 'percent'
        ? Number(_dataPoint.y / secondaryPoints[index].y) * 100
        : Number(_dataPoint.y / secondaryPoints[index].y);
    const value = prettyPrintNumber(isNaN(rawValue) ? 0 : rawValue, type);
    return {
      x: _dataPoint.x,
      y: Number(rawValue.toFixed(2)),
      label: `${_dataPoint.label?.split(':')[0]}: ${value}\n`,
      dataSetName: _dataPoint.dataSetName,
    };
  });
};
export const combineDonutGraphData: (
  revenueData: Array<LabeledDataPoint>,
  revenueTotal: number,
  productsData: Array<LabeledDataPoint>,
  productsTotal: number,
  colors: Array<string>,
) => Array<CombinedDonutGraphData> = (
  revenueData,
  revenueTotal,
  productsData,
  productsTotal,
  colors,
) => {
  const calculatePercentage: (value: number, total: number) => number = (
    value,
    total,
  ) => (total === 0 ? 0 : (value / total) * 100);

  const response = [] as Array<CombinedDonutGraphData>;
  revenueData.forEach((item, i) => {
    const pData = productsData.find(pItem => pItem.id === item.id);
    response.push({
      id: item.id,
      color: colors[i],
      label: item.secondLabel
        ? `${item.label} ${item.secondLabel}`
        : item.label,
      productsValue: pData ? pData.y : null,
      productsPercentage: pData
        ? calculatePercentage(pData.y, productsTotal).toFixed(2)
        : null,
      revenueValue: item.y,
      revenuePercentage: item
        ? calculatePercentage(item.y, revenueTotal).toFixed(2)
        : null,
      ...(item.secondLabel && { secondLabel: item.secondLabel }),
    } as CombinedDonutGraphData);
  });

  return response;
};

export const transformDataToBuyBoxSales: (
  inputBuyBoxData: FilterListProps,
  inputSalesData?: FilterListProps,
) => Array<BuyBoxSalesItem> = (inputBuyBoxData, inputSalesData) => {
  const buyBoxData = inputBuyBoxData.data
    .filter(_item => _item.delta.percentage)
    .sort((a, b) => parseInt(b.total) - parseInt(a.total))
    .map(
      (datum, i) =>
        ({
          index: i + 1,
          label: datum.filter,
          delta: datum.delta,
          primaryValue: parseFloat(datum.total),
        } as BuyBoxSalesItem),
    );

  if (inputSalesData) {
    return inputSalesData.data
      .sort((a, b) => parseInt(b.total) - parseInt(a.total))
      .map((datum, i) => {
        const bbItem = buyBoxData.find(item => item.label === datum.filter);

        return {
          index: i + 1,
          label: datum.filter,
          delta: datum.delta,
          primaryValue: parseFloat(datum.total),
          secondaryValue: bbItem ? bbItem.primaryValue : NaN,
        } as BuyBoxSalesItem;
      });
  }
  return buyBoxData;
};

const evenDataPoints: (
  dataPoints: DataPointAggregate[],
  selectedDates: DateRange | undefined,
  xAxisResolution: XAxisResolutionType,
) => DataPointAggregate[] = (dataPoints, selectedDates, xAxisResolution) => {
  const newDataPoints: DataPointAggregate[] = [];
  if (selectedDates !== undefined) {
    const startDate = selectedDates.startDate;
    const endDate = selectedDates.endDate;
    let step: string;
    let format = 'YYYYMMDD';
    switch (xAxisResolution) {
      case 'Daily':
        step = 'day';
        break;
      case 'Weekly':
        step = 'week';
        format = 'YYYYWW';
        break;
      case 'Monthly':
        step = 'month';
        format = 'YYYYMM';
        break;
      case 'Yearly':
        step = 'year';
        format = 'YYYY';
        break;
      default:
        step = 'day';
    }
    if (startDate !== null && endDate !== null) {
      for (
        let i = startDate.clone();
        i <= endDate;
        i = i.add(1, step as unitOfTime.DurationConstructor)
      ) {
        const foundDataPoint = dataPoints.find(
          dataPoint => dataPoint.timestamp === i.format(format),
        );
        if (foundDataPoint === undefined) {
          newDataPoints.push({
            timestamp: i.format(format),
            value: '0',
          });
        } else {
          newDataPoints.push({
            ...foundDataPoint,
            timestamp: i.format(format),
          });
        }
      }
    }
  }
  const compare = (a: DataPointAggregate, b: DataPointAggregate) => {
    return moment(a.timestamp, 'YYYYMMDD').isBefore(
      moment(b.timestamp, 'YYYYMMDD'),
    )
      ? -1
      : 1;
  };
  return newDataPoints.sort(compare);
};

export const transformCategoriesByClientData: (
  response: UseQueryState<GetCategoriesByClientId>,
) => Category[] = response => {
  if (response.fetching || response.error) {
    return [];
  }
  if (response.data) {
    const cats = response.data.categories.edges
      .filter(
        _edge =>
          _edge.node.asins !== undefined &&
          _edge.node.asins.totalCount !== undefined &&
          _edge.node.asins.totalCount > 0,
      )
      .map(edge => {
        return {
          id: extractUuidFromFullPath(edge.node.id),
          name: edge.node.name,
        };
      });
    cats.push({
      id: 'null',
      name: 'Uncategorized',
    });
    return cats;
  }
  return [];
};

export const transformSubCategoriesByClientData: (
  response: UseQueryState<GetSubCategoriesByClientId>,
) => SubCategory[] = response => {
  if (response.fetching || response.error) {
    return [];
  }
  if (response.data) {
    return response.data.subcategories.edges
      .filter(
        _edge =>
          _edge.node.asins !== undefined &&
          _edge.node.asins.totalCount !== undefined &&
          _edge.node.asins.totalCount > 0,
      )
      .map(edge => ({
        id: extractUuidFromFullPath(edge.node.id),
        name: edge.node.name,
        category: {
          id: edge.node.category.id,
          name: edge.node.category.name,
        },
      }));
  }
  return [];
};

const initialBreakDownColumn: DataColumn = {
  data: [],
  filterTitle: '',
  variableField: '',
  error: false,
  loading: false,
  type: 'dollar amount',
};

export const transformPerformanceBreakdownData: (
  responsePrimary: UseQueryState<GetDataPointsAggregateResponse>,
  responseSecondary: UseQueryState<GetDataPointsAggregateResponse>,
  previousResponsePriamry: UseQueryState<GetDataPointsAggregateResponse>,
  previousResponseSecondary: UseQueryState<GetDataPointsAggregateResponse>,
  filterType: FilterType,
  deltaValueType: DataType,
  asins?: UseQueryState<GetAsinsResponse>,
  hasSecondaries?: boolean,
  fieldConf?: string,
) => DataColumn = (
  responsePrimary,
  responseSecondary,
  previousResponsePrimary,
  previousResponseSecondary,
  filterType,
  deltaValueType,
  asins,
  hasSecondaries,
  fieldConf?: string,
) => {
  if (
    responsePrimary.fetching ||
    previousResponsePrimary.fetching ||
    responseSecondary?.fetching ||
    previousResponseSecondary?.fetching ||
    (asins !== undefined && asins.fetching)
  ) {
    return { ...initialBreakDownColumn, loading: true };
  }
  if (
    responsePrimary.error ||
    previousResponsePrimary.error ||
    responseSecondary.error ||
    previousResponseSecondary.error ||
    (asins !== undefined && asins.error)
  ) {
    return { ...initialBreakDownColumn, error: true };
  }
  if (responsePrimary.data && previousResponsePrimary.data) {
    const dataPointsPrimary = responsePrimary.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const dataPointsPreviousPeriodPrimary = previousResponsePrimary.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const dataPointsSecondary = responseSecondary?.data?.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const dataPointsPreviousPeriodSecondary = previousResponseSecondary?.data?.dataPointAggregates.edges.map(
      edge => edge.node,
    );

    switch (filterType) {
      case 'asin':
        if (asins !== undefined && asins.data) {
          return {
            data: asins.data.asins.edges.map(asin => {
              const previousDataPointPrimary = dataPointsPreviousPeriodPrimary.find(
                datapointPrimary =>
                  datapointPrimary.asinId === asin.node.id.slice(7),
              );
              const currentDataPointPrimary = dataPointsPrimary.find(
                datapoint => datapoint.asinId === asin.node.id.slice(7),
              );
              const previousDataPointSecondary = dataPointsPreviousPeriodSecondary?.find(
                datapointSecondary =>
                  datapointSecondary.asinId === asin.node.id.slice(7),
              );
              const currentDataPointSecondary = dataPointsSecondary?.find(
                datapoint => datapoint.asinId === asin.node.id.slice(7),
              );

              const currentPrimary = currentDataPointPrimary
                ? parseFloat(currentDataPointPrimary.value)
                : 0;
              const currentSecondary = currentDataPointSecondary
                ? parseFloat(currentDataPointSecondary.value)
                : 0;
              const previousPrimary = previousDataPointPrimary
                ? parseFloat(previousDataPointPrimary.value)
                : 0;
              const previousSecondary = previousDataPointSecondary
                ? parseFloat(previousDataPointSecondary.value)
                : 0;
              let totals = currentDataPointPrimary
                ? currentDataPointPrimary.value
                : '0';
              let delta =
                currentDataPointPrimary && previousDataPointPrimary
                  ? (100 / parseFloat(previousDataPointPrimary.value)) *
                    (parseFloat(currentDataPointPrimary.value) -
                      parseFloat(previousDataPointPrimary.value))
                  : previousDataPointPrimary
                  ? '-0'
                  : '0';
              let prevVal = previousDataPointPrimary
                ? previousDataPointPrimary.value
                : 0;
              let currVal = currentDataPointPrimary
                ? currentDataPointPrimary.value
                : 0;
              if (hasSecondaries) {
                const aspCurrent = currentPrimary / currentSecondary;
                const aspPrevious = previousPrimary / previousSecondary;
                prevVal = aspPrevious;
                currVal = aspCurrent;
                delta = getChangeData(
                  aspPrevious,
                  aspCurrent,
                ).rawValue!.toString();
                totals =
                  deltaValueType === 'percent'
                    ? (aspCurrent * 100).toString()
                    : aspCurrent.toString();
              }
              return {
                filter: `${asin.node.productName || ''} (${asin.node.asin})`,
                filterId: asin.node.id,
                delta: {
                  percentage: prettyPrintNumber(Number(delta), 'percent'),
                  change:
                    typeof delta === 'number'
                      ? delta === 0
                        ? 'none'
                        : delta > 0
                        ? 'positive'
                        : 'negative'
                      : delta.startsWith('-')
                      ? 'negative'
                      : 'positive',
                },
                total: totals,
                prev: prevVal.toString(),
                curr: currVal.toString(),
              };
            }),
            filterTitle: 'ASINs',
            variableField: fieldConf as string,
            error: false,
            loading: false,
            type: 'count',
          };
        } else {
          return { ...initialBreakDownColumn, error: true };
        }
      case 'category':
        if (dataPointsPreviousPeriodPrimary.length > dataPointsPrimary.length) {
          return {
            data: dataPointsPreviousPeriodPrimary.map(
              previousDataPointPrimary => {
                const currentDataPointPrimary = dataPointsPrimary.find(
                  datapoint =>
                    datapoint.categoryId ===
                    previousDataPointPrimary.categoryId,
                );
                let delta =
                  currentDataPointPrimary && previousDataPointPrimary
                    ? (100 / parseFloat(previousDataPointPrimary.value)) *
                      (parseFloat(currentDataPointPrimary.value) -
                        parseFloat(previousDataPointPrimary.value))
                    : previousDataPointPrimary
                    ? '-0'
                    : '0';
                let totals = currentDataPointPrimary
                  ? currentDataPointPrimary.value
                  : '0';
                let prevVal = previousDataPointPrimary
                  ? previousDataPointPrimary.value
                  : 0;
                let currVal = currentDataPointPrimary
                  ? currentDataPointPrimary.value
                  : 0;
                if (hasSecondaries) {
                  const currentDataPointSecondary = dataPointsSecondary?.find(
                    datapoint =>
                      datapoint.categoryId ===
                      previousDataPointPrimary.categoryId,
                  );
                  const previousDataPointSecondary = dataPointsPreviousPeriodSecondary?.find(
                    datapoint =>
                      datapoint.categoryId ===
                      previousDataPointPrimary?.categoryId,
                  );
                  const aspCurrent =
                    Number(currentDataPointPrimary?.value) /
                    Number(currentDataPointSecondary?.value);
                  const aspPrevious =
                    Number(previousDataPointPrimary?.value) /
                    Number(previousDataPointSecondary?.value);
                  prevVal = aspPrevious;
                  currVal = aspCurrent;
                  delta = getChangeData(
                    aspPrevious,
                    aspCurrent,
                  ).rawValue!.toString();
                  totals =
                    deltaValueType === 'percent'
                      ? (aspCurrent * 100).toString()
                      : aspCurrent.toString();
                }

                return {
                  filter: `${previousDataPointPrimary.category}`,
                  filterId: `${previousDataPointPrimary.categoryId}`,
                  delta: {
                    percentage: prettyPrintNumber(Number(delta), 'percent'),
                    change:
                      typeof delta === 'number'
                        ? delta === 0
                          ? 'none'
                          : delta > 0
                          ? 'positive'
                          : 'negative'
                        : delta.startsWith('-')
                        ? 'negative'
                        : 'positive',
                  },
                  total: totals,
                  prev: prevVal.toString(),
                  curr: currVal.toString(),
                };
              },
            ),
            filterTitle: 'Categories',
            variableField: fieldConf as string,
            error: false,
            loading: false,
            type: 'count',
          };
        } else {
          return {
            data: dataPointsPrimary.map(dataPointPrimary => {
              const previousDataPointPrimary = dataPointsPreviousPeriodPrimary.find(
                datapoint =>
                  datapoint.categoryId === dataPointPrimary.categoryId,
              );
              let delta =
                previousDataPointPrimary && dataPointPrimary
                  ? (100 / parseFloat(previousDataPointPrimary.value)) *
                    (parseFloat(dataPointPrimary.value) -
                      parseFloat(previousDataPointPrimary.value))
                  : dataPointPrimary
                  ? '-0'
                  : '0';
              let totals = previousDataPointPrimary
                ? previousDataPointPrimary.value
                : '0';
              let prevVal = previousDataPointPrimary
                ? previousDataPointPrimary.value
                : 0;
              let currVal = dataPointPrimary ? dataPointPrimary.value : 0;
              if (hasSecondaries) {
                const previousDataPointSecondary = dataPointsPreviousPeriodSecondary?.find(
                  datapoint =>
                    datapoint.categoryId === dataPointPrimary.categoryId,
                );
                const dataPointSecondary = dataPointsSecondary?.find(
                  datapoint =>
                    datapoint.categoryId === dataPointPrimary?.categoryId,
                );
                const aspPrevious =
                  Number(previousDataPointPrimary?.value) /
                  Number(previousDataPointSecondary?.value);
                const aspCurrent =
                  Number(dataPointPrimary?.value) /
                  Number(dataPointSecondary?.value);
                prevVal = aspPrevious;
                currVal = aspCurrent;
                delta = getChangeData(
                  aspPrevious,
                  aspCurrent,
                ).rawValue!.toString();
                totals =
                  deltaValueType === 'percent'
                    ? (aspCurrent * 100).toString()
                    : aspCurrent.toString();
              }
              return {
                filter: `${previousDataPointPrimary?.category}`,
                filterId: `${previousDataPointPrimary?.categoryId}`,
                delta: {
                  percentage: prettyPrintNumber(Number(delta), 'percent'),
                  change:
                    typeof delta === 'number'
                      ? delta === 0
                        ? 'none'
                        : delta > 0
                        ? 'positive'
                        : 'negative'
                      : delta.startsWith('-')
                      ? 'negative'
                      : 'positive',
                },
                total: totals,
                prev: prevVal.toString(),
                curr: currVal.toString(),
              };
            }),
            filterTitle: 'Categories',
            variableField: fieldConf as string,
            error: false,
            loading: false,
            type: 'count',
          };
        }
      case 'subcategory':
        if (dataPointsPreviousPeriodPrimary.length > dataPointsPrimary.length) {
          return {
            data: dataPointsPreviousPeriodPrimary.map(
              previousDataPointPrimary => {
                const currentDataPointPrimary = dataPointsPrimary.find(
                  datapoint =>
                    datapoint.subcategoryId ===
                    previousDataPointPrimary.subcategoryId,
                );
                let delta =
                  currentDataPointPrimary && previousDataPointPrimary
                    ? (100 / parseFloat(previousDataPointPrimary.value)) *
                      (parseFloat(currentDataPointPrimary.value) -
                        parseFloat(previousDataPointPrimary.value))
                    : previousDataPointPrimary
                    ? '-0'
                    : '0';
                let totals = currentDataPointPrimary
                  ? currentDataPointPrimary.value
                  : '0';
                let prevVal = previousDataPointPrimary
                  ? previousDataPointPrimary.value
                  : 0;
                let currVal = currentDataPointPrimary
                  ? currentDataPointPrimary.value
                  : 0;
                if (hasSecondaries) {
                  const currentDataPointSecondary = dataPointsSecondary?.find(
                    datapoint =>
                      datapoint.subcategoryId ===
                      previousDataPointPrimary.subcategoryId,
                  );
                  const previousDataPointSecondary = dataPointsPreviousPeriodSecondary?.find(
                    datapoint =>
                      datapoint.subcategoryId ===
                      previousDataPointPrimary?.subcategoryId,
                  );
                  const aspCurrent =
                    Number(currentDataPointPrimary?.value) /
                    Number(currentDataPointSecondary?.value);
                  const aspPrevious =
                    Number(previousDataPointPrimary?.value) /
                    Number(previousDataPointSecondary?.value);
                  prevVal = aspPrevious;
                  currVal = aspCurrent;
                  delta = getChangeData(
                    aspPrevious,
                    aspCurrent,
                  ).rawValue!.toString();
                  totals =
                    deltaValueType === 'percent'
                      ? (aspCurrent * 100).toString()
                      : aspCurrent.toString();
                }

                return {
                  filter: `${previousDataPointPrimary?.subcategory}`,
                  filterId: `${previousDataPointPrimary?.subcategoryId}`,
                  delta: {
                    percentage: prettyPrintNumber(Number(delta), 'percent'),
                    change:
                      typeof delta === 'number'
                        ? delta === 0
                          ? 'none'
                          : delta > 0
                          ? 'positive'
                          : 'negative'
                        : delta.startsWith('-')
                        ? 'negative'
                        : 'positive',
                  },
                  total: totals,
                  prev: prevVal.toString(),
                  curr: currVal.toString(),
                };
              },
            ),
            filterTitle: 'Subcategories',
            variableField: fieldConf as string,
            error: false,
            loading: false,
            type: 'count',
          };
        } else {
          return {
            data: dataPointsPrimary.map(dataPointPrimary => {
              const previousDataPointPrimary = dataPointsPreviousPeriodPrimary.find(
                datapoint =>
                  datapoint.subcategoryId === dataPointPrimary.subcategoryId,
              );
              let delta =
                previousDataPointPrimary && dataPointPrimary
                  ? (100 / parseFloat(previousDataPointPrimary.value)) *
                    (parseFloat(dataPointPrimary.value) -
                      parseFloat(previousDataPointPrimary.value))
                  : dataPointPrimary
                  ? '-0'
                  : '0';
              let totals = previousDataPointPrimary
                ? previousDataPointPrimary.value
                : '0';
              let prevVal = previousDataPointPrimary
                ? previousDataPointPrimary.value
                : 0;
              let currVal = dataPointPrimary ? dataPointPrimary.value : 0;
              if (hasSecondaries) {
                const previousDataPointSecondary = dataPointsPreviousPeriodSecondary?.find(
                  datapoint =>
                    datapoint.subcategoryId === dataPointPrimary.subcategoryId,
                );
                const dataPointSecondary = dataPointsSecondary?.find(
                  datapoint =>
                    datapoint.subcategoryId === dataPointPrimary?.subcategoryId,
                );
                const aspCurrent =
                  Number(previousDataPointPrimary?.value) /
                  Number(previousDataPointSecondary?.value);
                const aspPrevious =
                  Number(dataPointPrimary?.value) /
                  Number(dataPointSecondary?.value);
                prevVal = aspPrevious;
                currVal = aspCurrent;
                delta = getChangeData(
                  aspPrevious,
                  aspCurrent,
                ).rawValue!.toString();
                totals =
                  deltaValueType === 'percent'
                    ? (aspCurrent * 100).toString()
                    : aspCurrent.toString();
              }
              return {
                filter: `${dataPointPrimary?.subcategory}`,
                filterId: `${dataPointPrimary?.subcategoryId}`,
                delta: {
                  percentage: prettyPrintNumber(Number(delta), 'percent'),
                  change:
                    typeof delta === 'number'
                      ? delta === 0
                        ? 'none'
                        : delta > 0
                        ? 'positive'
                        : 'negative'
                      : delta.startsWith('-')
                      ? 'negative'
                      : 'positive',
                },
                total: totals,
                prev: prevVal.toString(),
                curr: currVal.toString(),
              };
            }),
            filterTitle: 'Subcategories',
            variableField: fieldConf as string,
            error: false,
            loading: false,
            type: 'count',
          };
        }
    }
  }
  return initialBreakDownColumn;
};
