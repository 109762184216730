import { useEffect, useRef } from 'react';
import { DataType, Transformer } from './types';
const addComma = (value: number | string) => {
  const preFormatted = value.toString();
  const split = preFormatted.split('');
  split.splice(1, 0, ',');
  const formatted = split.join('');
  return formatted;
};
export const extractUuidFromFullPath: Transformer<string> = fullPath => {
  const regex = /.{8}-.{4}-.{4}-.{4}-.{12}/;
  const match = regex.exec(fullPath);
  if (!match) {
    return '';
  }
  return match[0];
};
const prettyPrintDollar: (
  rawValue: number,
  decimalPlaces?: number,
) => string = (rawValue, decimalPlaces) => {
  if (rawValue < 1000) {
    return `$${rawValue.toFixed(2)}`;
  }
  if (rawValue < 1000000) {
    return `$${(rawValue / 1000).toFixed(decimalPlaces || 1)}K`;
  }
  return `$${(rawValue / 1000000).toFixed(2)}M`;
};

const prettyPrintPercent: (rawValue: number) => string = rawValue => {
  if (rawValue < 1) return `${rawValue.toFixed(2)}%`;
  if (rawValue < 1000) {
    return `${rawValue.toFixed(0)}%`;
  }
  if (rawValue < 10000) {
    return `${addComma(rawValue.toFixed(0))}%`;
  }
  if (rawValue < 100000) {
    return `${rawValue.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })}%`;
  }
  if (rawValue < 1000000) {
    return `${(rawValue / 1000).toFixed(0)}K%`;
  }
  return `${(rawValue / 1000000).toFixed(0)}M%`;
};

const prettyPrintCount: (rawValue: number) => string = rawValue => {
  if (rawValue < 1000) {
    return `${rawValue.toFixed(0)}`;
  }
  if (rawValue < 10000) {
    return addComma(rawValue.toFixed(0));
  }
  if (rawValue < 1000000) {
    return `${(rawValue / 1000).toFixed(1)}K`;
  }
  if (rawValue < 1000000) {
    return `${(rawValue / 1000).toFixed(2)}K`;
  }
  return `${(rawValue / 1000000).toFixed(2)}M`;
};

export const prettyPrintNumber: (
  value: number,
  type: DataType,
  decimalPlaces?: number,
) => string = (value, type, decimalPlaces) => {
  const isNegative = value < 0;
  const rawValue = Math.abs(value);
  if (!isFinite(rawValue)) return '--';
  let formatted = '';
  if (type === 'dollar amount')
    formatted = prettyPrintDollar(rawValue, decimalPlaces);
  if (type === 'percent') formatted = prettyPrintPercent(rawValue);
  if (type === 'count') formatted = prettyPrintCount(rawValue);
  return `${isNegative ? '-' : ''}${formatted}`;
};
export const prefixPositiveOrNegative: (
  changeType: string,
) => string = changeType => {
  switch (changeType) {
    case 'positive':
      return '+';
    case 'negative':
      return '-';
    default:
      return '';
  }
};
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
