import { Asin } from '../../../api/asin';
import { DataPointAggregate } from '../../../api/data';

export const prettyPrintLongformCurrency: (arg: string) => string = num =>
  `$${parseFloat(num).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;

export const getAsinValueByDataPointAsinId: (arg: {
  asins: Asin[];
  dataPoint: DataPointAggregate;
}) => { asin: string; productName: string } = ({ asins, dataPoint }) => {
  const asin = asins.find(asin => asin.id === `/asins/${dataPoint.asinId}`);
  if (asin) {
    return { asin: asin.asin, productName: asin.productName as string };
  }
  return { asin: '', productName: '' };
};

export const getMatchedDataPointValueByAsinId: (arg: {
  dataPoints: DataPointAggregate[];
  dataPoint: DataPointAggregate;
  isCurrency?: boolean;
}) => string = ({ dataPoints, dataPoint, isCurrency = true }) => {
  const match = dataPoints.find(
    dp =>
      dp.subcategoryId === dataPoint.subcategoryId &&
      dp.categoryId === dataPoint.categoryId &&
      dp.asinId === dataPoint.asinId,
  );
  if (match) {
    if (isCurrency) {
      return prettyPrintLongformCurrency(match.value);
    } else {
      return match.value.toLocaleString();
    }
  }
  return 'Data Not Available';
};

export const getMatchedDataPointValueByAsin: (arg: {
  dataPoints: DataPointAggregate[];
  asin: string;
  isCurrency?: boolean;
}) => string = ({ dataPoints, asin, isCurrency = true }) => {
  const match = dataPoints.find(dp => dp.asinId === asin);
  if (match) {
    if (isCurrency) {
      return prettyPrintLongformCurrency(match.value);
    } else {
      return match.value.toLocaleString();
    }
  }
  return 'Data Not Available';
};
