import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import DefaultPage from '../Page';
import { withDashboardStyles } from './styles';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { TableContainer } from '@material-ui/core';
import { useQuery, UseQueryState } from 'urql';
import { getLastImport, GetLastImportsResponse } from '../../api/lastImport';
import { useClients } from '../../clients/useClients';
import moment from 'moment';

const Page = styled(DefaultPage)`
  ${withDashboardStyles()}
`;

export interface LastImportRowItem {
  fieldConfiguration: string;
  state: string;
  notes: string;
  lastImportStartTime: string;
}
const HistoryDashboard: React.FC = () => {
  const { currentClient } = useClients();
  const [getLastImportResponse] = useQuery<GetLastImportsResponse>({
    query: getLastImport,
    variables: { client_id: currentClient.id },
    pause: currentClient.id === '',
  });
  const columns = [
    { title: 'Field', field: 'fieldConfiguration' },
    { title: 'State', field: 'state' },
    { title: 'Error Message', field: 'notes' },
    {
      title: 'Last Updated',
      field: 'lastImportStartTime',
    },
  ];
  const transformDataTable: (
    response: UseQueryState<GetLastImportsResponse>,
  ) => LastImportRowItem[] = response => {
    if (response.data && response.data.importStatuses) {
      const imports = response.data.importStatuses.edges.map(edge => edge.node);
      return imports.map(lastImport => {
        return {
          fieldConfiguration: lastImport.fieldConfiguration.displayName,
          state: `${lastImport.status[0].toUpperCase()}${lastImport.status.substring(
            1,
          )}`,
          notes: lastImport.notes,
          lastImportStartTime: moment(lastImport.lastImportStartTime).format(
            'DD-MMM-YYYY HH:mm:SS',
          ),
        };
      });
    }
    return [];
  };
  return (
    <Page title="Data History">
      <TableContainer className="history-table">
        <MaterialTable
          columns={columns}
          data={transformDataTable(getLastImportResponse)}
          icons={{
            // eslint-disable-next-line react/display-name
            SortArrow: forwardRef((props, ref) => (
              <ArrowDownward {...props} ref={ref} />
            )),
          }}
          options={{
            draggable: false,
            toolbar: false,
            paging: false,
            sorting: true,
          }}
          style={{}}
        />
      </TableContainer>
    </Page>
  );
};

export default HistoryDashboard;
