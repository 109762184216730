import gql from 'graphql-tag';
import { FieldConfiguration } from './fieldConfiguration';

export const getLastImport = gql`
  query GetLastImport($client_id: String, $first: Int) {
    importStatuses(
      client: $client_id
      order: { lastImportStartTime: "desc" }
      first: $first
    ) {
      edges {
        node {
          id
          fieldConfiguration {
            id
            fieldKey
            displayName
          }
          lastImportStartTime
          status
          notes
        }
      }
    }
  }
`;

export interface LastImport {
  id: string;
  fieldConfiguration: FieldConfiguration;
  lastImportStartTime: string;
  status: string;
  notes: string;
}

export interface GetLastImportsResponse {
  importStatuses: {
    edges: {
      node: LastImport;
    }[];
  };
}
