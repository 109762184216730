import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import styled from '@emotion/styled';

import { BottomButtonsWrapper, Wrapper } from '../dataSelect/styles';

interface Props {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

const StyledWrapper = styled(Wrapper)`
  .accept-button {
    color: #043746;
    width: 97px;
    border: 1px solid #043746;
    height: 42px;
    padding: 5px 15px;
    font-size: 13px;
    font-weight: 700;
    border-radius: 23px;
    text-transform: none;
  }
  .accept-button:hover {
    background-color: #c1f0ff;
  }
  .close-modal-button {
    top: 50px;
    color: #043746;
    right: 60px;
    position: absolute;
    background: transparent;
    margin-left: -12px;
    font-size: 12px;

    @media screen and (max-width: 425px) {
      right: 20px;
      top: 20px;
    }
  }

  h1 {
    color: #06313e;
    font-size: 14px;
    font-family: Montserrat, Helvetica, sans-serif;
    font-weight: 900;
    line-height: 1.167;
    letter-spacing: 3.92px;
    text-transform: uppercase;
  }

  p,
  .chart-description-data-items {
    font-size: 0.875rem;
    font-family: Montserrat, Helvetica, sans-serif;
    font-weight: 400;
    line-height: 1.43;
  }
  .modal-icon {
    height: 20.59px;
    width: 20.59px;
  }
  padding-top: 50px !important;
  padding-bottom: 20px !important;
`;

const FullScreenModal: React.FC<Props> = ({ open, onClose, children }) => {
  const [openModal, setOpenModal] = useState(open);

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  return (
    <Drawer
      disableEscapeKeyDown
      anchor="bottom"
      disableBackdropClick
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <StyledWrapper className="definition-modal">
        <IconButton
          className="close-modal-button"
          onClick={() => onClose()}
          edge="start"
        >
          <CloseIcon fontSize="inherit" className="modal-icon" />
        </IconButton>
        {children}
        <BottomButtonsWrapper>
          <Button
            className="accept-button"
            variant="outlined"
            onClick={() => onClose()}
          >
            Accept
          </Button>
        </BottomButtonsWrapper>
      </StyledWrapper>
    </Drawer>
  );
};

export default FullScreenModal;
