import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';

const AdminPrivateRoute: React.FC<RouteProps> = props => {
  const { validating, validToken, roles } = useAuth();
  return validating ? (
    <></>
  ) : (
    <>
      {validToken && roles.find(role => role !== 'ROLE_CLIENT_USER') ? (
        <Route {...props} />
      ) : (
        <Redirect to={'/'} />
      )}
    </>
  );
};

export default AdminPrivateRoute;
