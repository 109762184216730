import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import EmptyBox from '../icons/EmptyBox';
import DefaultPage from './Page';
import { GAEvent, PageView } from './Tracking';
const Container = styled.div`
  background: #f2f2f2;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    margin: 35px auto 25px;
    text-align: center;
  }
  h1 span {
    letter-spacing: 0;
    text-align: center;
    font: normal normal bold 32px/24px Montserrat;
    letter-spacing: 0px;
    color: #0d2d3d;
    opacity: 1;
  }
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
  .message {
    top: 350px;
    left: 486px;
    width: 650px;
    max-width: 90%;
    letter-spacing: 0;
    text-align: center;
    font: normal normal normal 13px/20px Montserrat;
    letter-spacing: 0px;
    color: #0d2d3d;
    opacity: 1;
  }
`;
interface Props {
  page?: string;
  clientName?: string;
}
const NoDataPage = styled(DefaultPage)`
  main {
    background-color: white;
    padding-top: 69px !important;
  }
  .body {
    padding-top: 0 !important;
    padding-bottom: 40px !important;
  }
`;

const NoData: React.FC<Props> = ({ page = 'Sales', clientName }) => {
  useEffect(() => {
    GAEvent({
      category: 'Error',
      action: 'No Import Data Available',
      label: `Client: ${clientName}`,
    });
    PageView();
  }, [clientName]);
  return (
    <NoDataPage className="no-data-page" title={page} page="Sales">
      <Container>
        <section>
          <EmptyBox />
          <h1>
            <span>No Data Available</span>
          </h1>
          <span className="message">
            {`We're sorry. It looks like the data from Amazon hasn't been updated
          yet. Please check back later or contact your account representative if
          you believe this is in error.`}
          </span>
        </section>
      </Container>
    </NoDataPage>
  );
};

export default NoData;
