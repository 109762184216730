import React from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { themeColors } from '../common/theme';

const StyledTooltip = withStyles(theme => ({
  arrow: {
    color: themeColors.cyprus,
  },
  tooltip: {
    backgroundColor: themeColors.cyprus,
    color: themeColors.white,
    boxShadow: theme.shadows[1],
    fontSize: 13,
    padding: '16px 24px',
    width: 'fit-content',
    minWidth: '184px',
    fontWeight: 400,
  },
}))(Tooltip);

const CustomTooltip: React.FC<TooltipProps> = props => (
  <StyledTooltip {...props}>{props.children}</StyledTooltip>
);

export default CustomTooltip;
