import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/core';
import { createMuiTheme, Theme } from '@material-ui/core';
import { themeColors } from '../../common/theme';
import { maxContentWidth } from '../../common/constants';

export const headerTheme: (theme: Theme) => Theme = theme =>
  createMuiTheme({
    ...theme,
    overrides: {
      MuiButton: {
        root: {
          borderRadius: '15px',
          height: '29px',
          textTransform: 'none',
          fontSize: '12px',
          fontWeight: 'normal',
          '&:not(:last-child)': {
            marginRight: '8px',
          },
        },
        outlinedPrimary: {
          border: 'none',
          background: themeColors.blackSqueeze,
        },
        containedSecondary: {
          padding: '0',
          backgroundColor: 'transparent',
          color: themeColors.sherpaBlue,
          border: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        startIcon: {
          color: themeColors.silver,
        },
      },
      MuiIconButton: {
        root: {
          position: 'absolute',
          bottom: '-16px',
          left: '16px',
          color: themeColors.white,
          background: themeColors.cyprus,
          padding: '4px',
          '&:hover': {
            color: themeColors.cyprus,
          },
        },
      },
    },
  });
export const withAccountHeaderStyles: () => SerializedStyles = () => css`
  padding: 26px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    margin: 0 !important;
  }
  &.no-data {
    color: #043746 !important;
    justify-content: center !important;
    display: flex;
    flex-direction: column !important;
  }
`;
export const withHeaderStyles: (
  compact: boolean,
  sidebarOpen: boolean,
) => SerializedStyles = (compact, sidebarOpen) => css`
  background: ${themeColors.white};
  height: ${compact ? '82px' : '152px'};
  transition: height 150ms linear, width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  padding: ${!sidebarOpen ? '0 60px 0 72px' : '0 40px'};
  position: fixed;
  top: 0;
  width: ${sidebarOpen ? 'calc(100vw - 304px)' : '100vw'};
  z-index: 1201;
  border-bottom: 1px solid ${themeColors.gainsboro};
  .header-content-wrapper {
    width: 100%;
    height: 100%;
    overflow-x: overlay;
    ${compact ? 'overflow-y: hidden;' : ''}
  }
  ${
    !sidebarOpen
      ? `@media only screen and (max-width: 1025px) {
      .header-content-wrapper {
        padding-right: 72px;
        margin-right: 10px;
      }
    padding-right: 5px !important;
  }`
      : `@media only screen and (max-width: 1280px) {
        .header-content-wrapper {
          padding-right: 40px;
          margin-right: 10px;
        }
    overflow-x: auto;
    padding-right: 5px !important;
  }`
  }
  h1 span {
    font-weight: 900;
  }

  .top-row,
  .clock-row,
  .bottom-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    100%
    max-width: 1148px;
  }
  .clock-row {
    justify-content: flex-end;
  }

  .top-row {
    justify-content: space-between;
    margin-top: 26px;
    min-width: 895px;
    max-width: 1148px;
    width: 100%;

    transition: opacity 150ms ease-in-out, margin 150ms ease-in-out;
    ${
      compact &&
      css`
        height: 0;
        opacity: 0;
        visibility: visible;
        margin-top: 0;
      `
    }

    .buttons-wrapper {
      width: 175px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .fake-button {
        height: 29px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: ${themeColors.silver};
        span {
          color: #043746 !important;
          margin-left: 8px;
          font-size: 12px;
        }
      }
      .header-button {
        color: #043746 !important;
        &:hover {
          font-weight: bold;
          span {
            color: #043746;
          }
        }
      }
    }
    .header-title {
      color: #043746 !important;
    }
    .account-manager {
      label {
        white-space: nowrap;
      }
      min-width: 80px !important;
    }
  }

  .bottom-row {
    transition: margin 150ms ease-in-out;
    margin-bottom: ${compact ? '16px' : '23px'};
    min-width: 895px;
    max-width: 1148px;
    width: 100%;
    .calendar-button {
      background: transparent;
      color: ${themeColors.cyprus};
      left: unset;
      bottom: unset;
      right: 10px;
      top: 10px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .input-wrapper {
    position: relative;
    &:not(:last-child) {
      margin-right: 16px;
    }
    &.header-input {
      width: 372px;
      min-width: 266px;
    }
    &.header-button {
      color: #043746 !important;
      &.import-date-wrapper {
        width: 100px;
      }
      align-content: 'center';
      label {
        left: 22px !important;
        color: #043746 !important;
        &.import-date-label {
          left: 17px !important;
          white-space: nowrap;
          width: auto !important;
          padding-right: 0px !important;
          &:hover + a button span {
            font-weight: bold;
          }
        }
        margin: auto;
        right: 25px;
        width: 124px;
        background: transparent !important;
        &:hover + button span {
          font-weight: bold;
        }
      }
    }
    .fake-input {
      width: 100%;
      height: 44px;
      background: transparent;
      outline: none;
      &:focus,
      &:active,
      &:hover,
      &:focus-within {
        outline: none;
      }
      border: 1px solid ${themeColors.concrete};
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 0px 0px 10px 15px;
      font-size: 13px;
      color: inherit;
      font-family: inherit;
    }

    label {
      position: absolute;
      font-size: 10px;
      background: ${themeColors.white};
      color: ${themeColors.cyprus};
      z-index: 2;
      top: -6px;
      left: 8px;
      padding: 0 10px 0 6px;
    }

    .asin_select {
      width: 100%;
    }

    &.data-set-wrapper {
      position: relative;
      min-width: 200px;

      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        right: 15px;
      }
    }

    &.compare-to-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      height: 44px;
      min-width: 150px;

      label {
        white-space: nowrap;
        left: unset;
        right: 25px;
        width: 124px;
      }

      .button-group {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
  }
  &.no-data {
    border-bottom: none !important;
    height: 69px !important;

  }

  .DateRangePicker_picker {
    left: auto !important;
    top: ${compact ? 'auto' : '150px'} !important;
    z-index: ${compact ? '12' : '1'};
    background-color: #fff;
    position: fixed !important;
    box-shadow: 0px 2px 5px #00000029;
    transition: all 0..75s;
  }
  .DateRangePicker {
    font-family: inherit;
    color: inherit;
    width: 100%;
    .DateRangePickerInput {
      width: 100%;
      border: 1px solid ${themeColors.concrete};
      border-radius: 4px;
      .DateInput {
        border-radius: 4px;
      }
      .DateInput_input {
        color: inherit;
        font-size: 13px;
        border-radius: 4px;
        padding: 12px 11px 4px;
      }
      .DateInput_input__focused {
        border-bottom: 2px solid ${themeColors.cyprus};
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        &:focus,
        &:active,
        &:hover,
        &:focus-within {
          outline: none;
        }
      }
      .DateRangePickerInput_arrow {
        color: inherit;
        position: absolute;
        z-index: 2;
        top: 18px;
        left: 102px;
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
    .CalendarDay__blocked_calendar {
      background: #F2F2F2;
    }
    .CalendarMonth_caption,
    .CalendarDay__default {
      color: inherit;
      border: none;
      font-size: 13px;
    }
    .CalendarMonth_caption {
      font-size: 14px;
      padding-bottom: 44px;
    }
    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDary__selected:hover {
      background: #E06C10;
      border: none;
      color: ${themeColors.white};
    }
    .CalendarDay__selected_span {
      background: ${themeColors.papayaWhip};
      border: none;
      color: ${themeColors.tahitiGold};
    }
    .DayPicker_weekHeader {
      color: ${themeColors.cyprus};
      small {
        font-size: 12px;
      }
    }
    .DayPickerNavigation_button__horizontalDefault {
      top: 16px;
      width: 29px;
      height: 29px;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      color: #E06C10;
      border: 1px solid #E06C10;
      border-radius: 50%;
      margin: 0 5px 0 4px;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  .date-picker-input-hover {
    cursor: pointer;
  }

  .header-title {
    color: #0b2735;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${maxContentWidth}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
