import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryScatter,
  VictoryPortal,
  Point,
  Curve,
} from 'victory';
import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { DataType, Factory } from '../../common/types';
import { useHeader } from '../header/useHeader';
import Legends from './DualYAxisLegend';
import { getMemoizedYAxes } from './utils';
import { LineWithClickHandler, YAxis, YAxisProps } from './types';
import { graphWidth, noDataMessage, badDateRangeMessage } from './constants';
import { lineColors, withGraphStyles } from './styles';
import {
  FieldConfiguration,
  getFieldConfigurationsByClientId,
  GetFieldConfigurationsResponse,
} from '../../api/fieldConfiguration';
import GraphModal from './GraphVariableModal';
import GraphLine from './GraphLine';
import { themeColors } from '../../common/theme';
import { waterfallGraphColors } from './waterfall/styles';
import { getTickValuesFromMaxValue, prettyPrintDate } from './waterfall/utils';
import { prettyPrintNumber } from '../../common/utils';
import { useQuery } from 'urql';
import { useClients } from '../../clients/useClients';
import { useSidebar } from '../sidebar/useSidebar';
import { transformFieldConfigurationsByClientData } from '../dashboard/transformers';
import LoadingIndicator from '../LoadingIndicator';
import PerformanceBreakdownTable from './PerformanceBreakdownTable';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import { Heading, ResultsList } from '../dataSelect/styles';
import FullScreenModal from '../fullScreenModal/FullScreenModal';

const Container = styled.section`
  ${withGraphStyles()}
  .no_data_message {
    height: 90px !important;
    margin: 0 auto !important;
    font-size: 13px;
    color: #0d2d3d;
  }
`;
const Wrapper = styled.div`
  &.hide {
    display: none !important;
    height: 100px;
  }
`;
interface Props {
  title: string;
}

const IconBtn = styled(IconButton)`
  float: right;
  top: -8px !important;
  right: -4px !important;
  color: #000 !important;
  &::hover {
    color: #000 !important;
  }
`;

const StyledUl = styled('ul')`
  padding: 10px 20px;
`;
const StyledMenu = styled(Menu)`
  ul {
    min-width: 207px !important;
    min-height: 66px !important;
    padding: 0 !important;
  }
  li {
    text-align: left !important;
    font: normal normal normal 13px/20px Montserrat !important;
    letter-spacing: 0px !important;
    color: #0d2d3d !important;
    opacity: 1 !important;
    height: 66px !important;
    padding-left: 25px !important;
  }
`;
const GraphDefinition: React.FC = () => (
  <>
    <Heading>
      <Typography variant="h1" className="modal-title">
        Performance Chart
      </Typography>
    </Heading>
    <ResultsList>
      <p>
        The Performance Chart allows you to visualize your data in line chart
        format. You can compare selected metrics against their previous period
        or year&apos;s values to gauge how your products are doing. You can also
        compare similar data on the same axis but dissimilar data will display
        on the opposite. Only two different types of data can be displayed at a
        single time. The labels on each side of the chart will tell you if
        you&apos;re looking at percentages, counts, or dollar figures.
      </p>
      <br />
      <p>
        You can select multiple variables by clicking the Plus icon in the top
        left. The following data is available to view in the chart:
      </p>
      <StyledUl className="chart-description-data-items">
        <li>Average Sale Price (ASP)</li>
        <li>Lost Buy Box %</li>
        <li>Ordered Revenue</li>
        <li>Replenishable Out-of-Stock (ROOS)</li>
        <li>Shipped Cost of Goods Sold (COGS)</li>
        <li>Units Sold</li>
      </StyledUl>
    </ResultsList>
  </>
);

export type XAxisResolutionType = 'Daily' | 'Weekly' | 'Monthly' | 'Yearly';

const DualYAxisGraph: React.FC<Props> = ({ title }) => {
  const [openModal, setOpenModal] = useState(false);
  const [lines, setLines] = useState<LineWithClickHandler[]>([]);
  const [primaryYAxis, setPrimaryYAxis] = useState<YAxisProps>({
    type: undefined,
    label: '',
  });
  const [secondaryYAxis, setSecondaryYAxis] = useState<YAxisProps>({
    type: undefined,
    label: '',
  });
  const [lastTick, setLastTick] = useState('');
  const [selectedVariables, setSelectedVariables] = useState<
    FieldConfiguration[]
  >([]);
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [
    shouldPreSelectDefaultVariables,
    setShouldPreSelectDefaultVariables,
  ] = useState(true);
  const [canUpdateVariables, setCanUpdateVariables] = useState(false);
  const [changingClient, setChangingClient] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [showDefinitionModal, setShowDefinitionModal] = useState(false);

  const { selectedDates, lastValidDate, isReady } = useHeader();
  const { currentClient } = useClients();

  const { fieldKeys, dataType } = useSidebar();

  const dateRangeInDays =
    selectedDates.endDate?.diff(selectedDates.startDate, 'days') || 0;
  const calculateXAxisResolutionByRange = useCallback<
    () => XAxisResolutionType
  >(() => {
    if (dateRangeInDays <= 27) {
      return 'Daily' as XAxisResolutionType;
    } else if (dateRangeInDays < 180) {
      return 'Weekly' as XAxisResolutionType;
    } else {
      return 'Monthly' as XAxisResolutionType;
    }
  }, [dateRangeInDays]);

  const defaultXAxisResolution = calculateXAxisResolutionByRange();

  const [xAxisResolution, setXAxisResolution] = useState<XAxisResolutionType>(
    defaultXAxisResolution,
  );

  useEffect(() => setXAxisResolution(calculateXAxisResolutionByRange()), [
    selectedDates.startDate,
    selectedDates.endDate,
    calculateXAxisResolutionByRange,
  ]);

  const [getFieldConfigurationsByClientIdGraphingEnabled] = useQuery<
    GetFieldConfigurationsResponse
  >({
    query: getFieldConfigurationsByClientId,
    variables: {
      importer_clients: currentClient.id,
      graphEnabled: true,
    },
    pause: currentClient.id === '',
  });

  const variableList: FieldConfiguration[] = transformFieldConfigurationsByClientData(
    getFieldConfigurationsByClientIdGraphingEnabled,
  ).sort((a, b) => (a.displayName > b.displayName ? 1 : -1));

  const yAxes = useMemo<YAxis[]>(
    () => getMemoizedYAxes(lines, primaryYAxis, secondaryYAxis),
    [lines, primaryYAxis, secondaryYAxis],
  );

  const updateSelectedVariables = useCallback(
    async (variables: FieldConfiguration[]) => {
      if (variables) {
        await setSelectedVariables(variables);
        const leftVar = variables.filter(_var => _var.axisName === 'left');
        const rightVar = variables.filter(_var => _var.axisName === 'right');
        if (leftVar.length > 0) {
          await setPrimaryYAxis({
            type: leftVar[0].fieldType as DataType,
            label: leftVar[0].axisLabel as string,
          });
        }
        if (rightVar.length > 0) {
          await setSecondaryYAxis({
            type: rightVar[0].fieldType as DataType,
            label: rightVar[0].axisLabel as string,
          });
        }
      } else {
        await setSelectedVariables([]);
      }
      await setLines([]);
      setOpenModal(false);
    },
    [
      setSelectedVariables,
      setSecondaryYAxis,
      setPrimaryYAxis,
      setLines,
      setOpenModal,
    ],
  );
  useEffect(() => {
    if (selectedVariables.length && lines.length) setInitializing(false);
  }, [lines, selectedVariables]);
  useEffect(() => {
    if (
      shouldPreSelectDefaultVariables &&
      !getFieldConfigurationsByClientIdGraphingEnabled.fetching &&
      !variableList.length
    ) {
      setShouldPreSelectDefaultVariables(false);
      updateSelectedVariables([]);
      if (changingClient) setChangingClient(false);
    }
    if (shouldPreSelectDefaultVariables && variableList.length > 0) {
      const defaultVariablesFieldKeys = [
        fieldKeys.orderedRevenue,
        fieldKeys.unitsSold,
      ];
      const defaultVariables = variableList.filter(_variable =>
        defaultVariablesFieldKeys.includes(_variable.fieldKey),
      );
      updateSelectedVariables(defaultVariables);
      setShouldPreSelectDefaultVariables(false);
      setChangingClient(false);
    }
  }, [
    fieldKeys,
    variableList,
    shouldPreSelectDefaultVariables,
    updateSelectedVariables,
    changingClient,
    setChangingClient,
    getFieldConfigurationsByClientIdGraphingEnabled.fetching,
  ]);
  useEffect(() => {
    setChangingClient(true);
    setInitializing(true);
    updateSelectedVariables([]);
    setShouldPreSelectDefaultVariables(true);
  }, [currentClient.id, updateSelectedVariables]);

  // Update selected variables after Vendor/Seller central switch
  useEffect(() => {
    const calculateNewSelectedVariables = (importerDataType: string) => {
      const newSelectedVariables = selectedVariables.filter(
        _variable =>
          _variable.importerType?.includes(importerDataType) === true ||
          _variable.fieldKey === 'ordered_revenue' ||
          _variable.fieldKey === 'ordered_revenue_sc' ||
          _variable.fieldKey === 'ordered_units' ||
          _variable.fieldKey === 'ordered_units_sc',
      );
      updateSelectedVariables(newSelectedVariables);
    };

    if (canUpdateVariables) {
      const importerDataType = dataType === 0 ? 'vendor' : 'seller';
      calculateNewSelectedVariables(importerDataType);
      setCanUpdateVariables(false);
    }
  }, [
    dataType,
    canUpdateVariables,
    selectedVariables,
    updateSelectedVariables,
  ]);

  useEffect(() => {
    setCanUpdateVariables(true);
  }, [dataType]);

  const isLoading = () =>
    !isReady ||
    initializing ||
    changingClient ||
    shouldPreSelectDefaultVariables ||
    getFieldConfigurationsByClientIdGraphingEnabled.fetching;

  const removeVariable = (displayName: string) => {
    const newSelectedVariables = selectedVariables.filter(
      _variable => _variable.displayName !== displayName,
    );
    updateSelectedVariables(newSelectedVariables);
  };

  const updateData = useCallback(
    (line: LineWithClickHandler) => {
      const exists = lines.find(
        _line => _line.dataSetName === line.dataSetName,
      );
      if (!exists) {
        setLines(_lines => [..._lines, line]);
      } else {
        const localLines = lines.filter(
          _line => _line.dataSetName !== line.dataSetName,
        );
        setLines([...localLines, line]);
      }
      if (line.data.length > 0) {
        const tick = line.data[line.data.length - 1].x as string;
        setLastTick(tick);
      }
    },
    [lines],
  );
  const handleAddButtonClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    setOpenModal(true);
  };
  const getMax = (type: string) => {
    const axisFound = yAxes.find(axis => axis.type === type);
    if (axisFound !== undefined) {
      return axisFound.max;
    }
    return 0;
  };
  const noData: Factory<boolean> = () => {
    if (isLoading()) return false;
    if (!lines.length && selectedVariables.length) return false;
    const linesWithData = lines.filter(line => line.data.length > 0).length;
    return !lines.length || !linesWithData || lastValidDate === null;
  };
  const handleMoreVertButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleViewDefinitionClick = () => {
    setShowDefinitionModal(true);
    handleMenuClose();
  };
  const handleNoData = () => {
    if (dateRangeInDays < 1) {
      return <div className="no_data_message">{badDateRangeMessage}</div>;
    }
    return <div className="no_data_message">{noDataMessage}</div>;
  };
  return (
    <Container className="performance-breakdown graph-breakdown">
      <div className="title performance-title">
        {title}
        <IconButton onClick={handleAddButtonClick} color="primary">
          <AddCircleOutlineIcon className="add-variable-icon" color="inherit" />
        </IconButton>

        <IconBtn
          className="more-vert-icon"
          aria-controls="more-vert-menu"
          aria-haspopup="true"
          onClick={handleMoreVertButtonClick}
        >
          <MoreVertIcon color="inherit" />
        </IconBtn>
        <StyledMenu
          id="more-vert-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleViewDefinitionClick()}>
            Show Definition
          </MenuItem>
        </StyledMenu>
        <div className="actions">
          <FormControl variant="outlined" className="select">
            <InputLabel htmlFor="select">X Axis</InputLabel>
            <Select
              label="X Axis"
              value={xAxisResolution}
              onChange={event =>
                setXAxisResolution(event.target.value as XAxisResolutionType)
              }
              displayEmpty
              disabled={dateRangeInDays === 0}
            >
              <MenuItem value={'Daily'}>Daily</MenuItem>
              {dateRangeInDays > 6 && (
                <MenuItem value={'Weekly'}>Weekly</MenuItem>
              )}
              {dateRangeInDays > 30 && (
                <MenuItem value={'Monthly'}>Monthly</MenuItem>
              )}
              {dateRangeInDays > 365 && (
                <MenuItem value={'Yearly'}>Yearly</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      </div>
      {isLoading() ? <LoadingIndicator /> : null}
      {!isLoading() && (!dateRangeInDays || noData()) && (
        <div className="no-data-wrapper">{handleNoData()}</div>
      )}
      <Wrapper
        className={`${
          isLoading() || !dateRangeInDays || noData() ? ' hide' : ''
        }`}
      >
        <div
          className={`graph_container${
            !isLoading() && (!dateRangeInDays || noData()) ? ' hide' : ''
          }`}
        >
          {!noData() && (
            <div className="legends">
              <Legends
                lines={lines}
                primaryYAxis={primaryYAxis}
                secondaryYAxis={secondaryYAxis}
                yAxes={yAxes}
              />
            </div>
          )}

          <VictoryChart
            containerComponent={
              <VictoryVoronoiContainer
                responsive
                voronoiDimension="x"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                labels={({ datum }) => {
                  if (datum.hideLabel) return;
                  return `${' '} ${' '} ${datum.dataSetName}:${
                    datum?.dataSetName?.length > 24 ? `\n` : ' '
                  }${datum.label} ${' '} ${' '}`;
                }}
                labelComponent={
                  <CustomTooltip
                    constrainToVisibleArea
                    renderInPortal={true}
                    center={{ y: 0 }}
                    orientation="center"
                    flyoutStyle={{
                      fill: '#0d2d3c',
                      padding: '20px',
                    }}
                    flyoutPadding={{
                      top: lines.length > 3 ? 5 : 15,
                      bottom: lines.length >= 2 ? 12 : 0,
                      left: 20,
                      right: 10,
                    }}
                  />
                }
                activateLabels={true}
              />
            }
            domain={{ y: [0, 1.5] }}
            domainPadding={{ x: [33, 20], y: [25, 25] }}
            height={425}
            padding={{
              top: 0,
              bottom: 50,
              left: 40,
              right: 46,
            }}
            style={{
              parent: {
                fontFamily: 'Montserrat, Helvetica, sans-serif',
                fontSize: '12px',
                fill: themeColors.cyprus,
                paddingTop: '5px',
              },
            }}
            theme={VictoryTheme.grayscale}
            width={graphWidth}
          >
            <VictoryAxis
              style={{
                axis: {
                  stroke: waterfallGraphColors.axis,
                },
                tickLabels: {
                  fill: themeColors.cyprus,
                  fontSize: '12px',
                  fontFamily: 'Montserrat, Helvetica, sans-serif',
                },
              }}
              tickFormat={(tick, index) => {
                if (noData()) {
                  return '';
                }
                if (index === 0 || tick === lastTick) {
                  return prettyPrintDate(tick);
                }
                return '';
              }}
              tickLabelComponent={<VictoryLabel dy={5} />}
            />
            {noData()
              ? []
              : yAxes.map(axis => (
                  <VictoryAxis
                    dependentAxis
                    style={{
                      axisLabel: {
                        fontSize: '13px',
                        fontFamily: 'Montserrat, Helvetica, sans-serif',
                      },
                      axis: {
                        stroke: 'transparent',
                      },
                      tickLabels: {
                        fontSize: '13px',
                        fontFamily: 'Montserrat, Helvetica, sans-serif',
                        fill: ({ tick }) =>
                          tick === 0 || tick === 1 || tick === 1.5
                            ? themeColors.cyprus
                            : themeColors.concrete,
                      },
                    }}
                    key={axis.label}
                    label={axis.label}
                    tickValues={getTickValuesFromMaxValue(1)}
                    orientation={axis.orientation}
                    tickFormat={tick => {
                      if (tick === 0) {
                        return tick;
                      }
                      if (tick === 1) {
                        return prettyPrintNumber(axis.max, axis.type);
                      }
                      if (tick === 1.5) {
                        return prettyPrintNumber(
                          axis.max + axis.max * 1.5,
                          axis.type,
                        );
                      }
                      return '—';
                    }}
                    tickLabelComponent={
                      <VictoryLabel
                        textAnchor={
                          axis.orientation === 'left' ? 'start' : 'end'
                        }
                        dx={axis.orientation === 'left' ? -3 : 3}
                      />
                    }
                  />
                ))}
            {selectedVariables.map(variable => (
              <GraphLine
                key={`${variable.fieldKey}-${variable.previous}`}
                fieldKey={variable.fieldKey}
                variable={variable}
                callBack={updateData}
                lines={lines}
                removeVariable={removeVariable}
                xAxisResolution={xAxisResolution}
              />
            ))}
            {noData()
              ? []
              : lines.map(line => {
                  const foundColor = lineColors.find(
                    color => color.fieldKey === line.fieldKey,
                  );
                  return (
                    <VictoryLine
                      data={line.data}
                      interpolation="linear"
                      key={`line-${line.dataSetName}`}
                      labelComponent={<></>}
                      dataComponent={
                        <VictoryPortal>
                          <Curve />
                        </VictoryPortal>
                      }
                      style={{
                        data: {
                          stroke:
                            foundColor === undefined
                              ? '#f00'
                              : foundColor.color,
                          strokeDasharray: line.previous ? '2.5' : undefined,
                        },
                        labels: {
                          fontSize: 12,
                          fill: '#fff',
                          textAnchor: 'left',
                          padding: '5px 0',
                        },
                      }}
                      y={datum =>
                        getMax(line.dataType ? line.dataType : '') > 0
                          ? datum.y / getMax(line.dataType ? line.dataType : '')
                          : 0
                      }
                    />
                  );
                })}
            {noData()
              ? []
              : lines.map((line, i) => {
                  const foundColor = lineColors.find(
                    color => color.fieldKey === line.fieldKey,
                  );
                  return (
                    <VictoryScatter
                      size={3.5}
                      data={line.data.map(lineData => {
                        return { ...lineData, hideLabel: true };
                      })}
                      key={`scatter-${line.dataSetName}-dot-${i}`}
                      labelComponent={<></>}
                      dataComponent={
                        <VictoryPortal>
                          <Point />
                        </VictoryPortal>
                      }
                      style={{
                        data: {
                          display: ({ active }) => {
                            if (active) return 'block';
                            return 'none';
                          },

                          fill: '#fefefe',
                          stroke:
                            foundColor === undefined
                              ? '#f00'
                              : foundColor.color,
                          strokeWidth: '1px',
                        },
                      }}
                      y={datum =>
                        getMax(line.dataType ? line.dataType : '') > 0
                          ? datum.y / getMax(line.dataType ? line.dataType : '')
                          : 0
                      }
                    />
                  );
                })}
          </VictoryChart>
        </div>
      </Wrapper>

      {!dateRangeInDays || noData() ? null : (
        <div className="performance-breakdown">
          <PerformanceBreakdownTable
            selectedVariables={selectedVariables}
            expand={showBreakdown}
            onShowAllClick={() => setShowBreakdown(!showBreakdown)}
          />
        </div>
      )}
      {openModal && (
        <GraphModal
          open={true}
          callBack={updateSelectedVariables}
          closeCallBack={() => setOpenModal(false)}
          variableList={variableList}
          previouslySelectedVariables={selectedVariables}
        />
      )}
      <FullScreenModal
        open={showDefinitionModal}
        onClose={() => setShowDefinitionModal(false)}
      >
        <GraphDefinition />
      </FullScreenModal>
    </Container>
  );
};

export default DualYAxisGraph;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const CustomTooltip = props => {
  return (
    <g>
      <line
        transform={`translate(${props.x})`}
        x1={0}
        y1={375}
        stroke="#0d2d3c"
        strokeWidth={1}
      />
      <VictoryPortal>
        <VictoryTooltip
          {...props}
          labelComponent={<VictoryLabel lineHeight={1.15} />}
        />
      </VictoryPortal>
    </g>
  );
};
