import ReactGA from 'react-ga';

export const InitGA = trackingID => {
  ReactGA.initialize(trackingID);
};

export const PageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const GAEvent = ({ category, action, label, value = null }) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};
