import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { materialTheme as theme } from '../common/theme';
import GraphQLProvider from './CustomGraphQLProvider';
import { AuthProvider } from '../auth/useAuth';
import { ClientsProvider } from '../clients/useClients';
import { FieldConfigurationsProvider } from '../fieldConfigurations/useFieldConfigurations';
import { HeaderProvider } from './header/useHeader';
import { SidebarProvider } from './sidebar/useSidebar';
import { ToastProvider } from './toast/useToast';
import { CategoryTitleProvider } from './breakdowns/useCategoryTitle';

const Providers: React.FC = ({ children }) => {
  return (
    <GraphQLProvider>
      <AuthProvider>
        <ClientsProvider>
          <FieldConfigurationsProvider>
            <HeaderProvider>
              <SidebarProvider>
                <ToastProvider>
                  <CategoryTitleProvider>
                    <ThemeProvider theme={theme}>{children}</ThemeProvider>
                  </CategoryTitleProvider>
                </ToastProvider>
              </SidebarProvider>
            </HeaderProvider>
          </FieldConfigurationsProvider>
        </ClientsProvider>
      </AuthProvider>
    </GraphQLProvider>
  );
};

export default Providers;
