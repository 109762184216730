import styled from '@emotion/styled';
import { createMuiTheme, Theme } from '@material-ui/core';
import { themeColors } from '../../common/theme';

import { AlertClassKey } from '@material-ui/lab/Alert';

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
  }
}

export const toastTheme: (theme: Theme) => Theme = theme =>
  createMuiTheme({
    ...theme,
    overrides: {
      MuiPaper: {
        elevation6: {
          boxShadow: `0px 3px 16px ${themeColors.cyprus}29`,
        },
      },
      MuiSnackbar: {
        anchorOriginBottomLeft: {
          '&.main-toast': {
            position: 'sticky',
            marginLeft: '20px',
            bottom: '20px',
          },
        },
      },
      MuiAlert: {
        filledInfo: {
          backgroundColor: themeColors.white,
          borderRadius: '8px',
          color: '#0d2d3dcc',
          fontWeight: 400,
          fontSize: '13px',
          lineHeight: '20px',
          padding: '20px 16px',
        },
        action: {
          alignItems: 'flex-start',
          marginTop: '-3px',
          color: themeColors.cyprus,
        },
        message: {
          padding: '0px',
        },
        icon: {
          padding: '0',
          marginRight: '16px',
        },
      },
    },
  });

export const AlertTitle = styled.p`
  margin-bottom: 8px;
`;
