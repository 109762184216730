import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'urql';
import { useHistory, useLocation } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { getDataPoints, GetDataPointsResponse } from '../../api/data';
import { useClients } from '../../clients/useClients';
import {
  Consumer,
  Factory,
  FilterItem,
  NullableMoment,
  Runnable,
} from '../../common/types';
import { DateRange } from '../graphs/types';
import useUrlSync from './useUrlSync';
import { removeDatesFromUrl } from './utils';
import { DataSetType } from '../sidebar/useSidebar';

export enum CompareToPreviousTypeOptions {
  notSet,
  period,
  year,
}

export interface Context {
  allFilters: boolean;
  compareToPreviousType: CompareToPreviousTypeOptions;
  lastValidDate: NullableMoment | boolean;
  periodLength: number;
  previousPeriodDates: DateRange;
  resetFilters: Runnable;
  selectedDates: DateRange;
  selectedFilters: FilterItem[];
  setAllFilters: Consumer<boolean>;
  setCompareToPreviousType: Consumer<CompareToPreviousTypeOptions>;
  setSelectedFilters: Consumer<FilterItem[]>;
  updatePreviousPeriodDates: Consumer<React.SetStateAction<DateRange>>;
  updateSelectedDates: Consumer<React.SetStateAction<DateRange>>;
  formatAndUpdateSelectedDates: Consumer<DateRange>;
  headerUpdate: boolean;
  setHeaderUpdate: Consumer<boolean>;
  resetDates: Runnable;
  checkedFilters: FilterItem[];
  setCheckedFilters: Consumer<FilterItem[]>;
  rowFilters: FilterItem[];
  setRowFilters: Consumer<FilterItem[]>;
  yearEnabled: boolean;
  headerFilters: FilterItem[];
  lastValidDateIsLoading: () => boolean;
  isReady: boolean;
  setSidebarDataType: Consumer<React.SetStateAction<DataSetType | undefined>>;
  setSidebarDataTypeChanged: Consumer<boolean>;
}

export const HeaderContext = createContext<Context>({
  allFilters: false,
  compareToPreviousType: CompareToPreviousTypeOptions.period,
  lastValidDate: null,
  periodLength: 0,
  previousPeriodDates: { startDate: null, endDate: null },
  resetFilters: () => null,
  selectedDates: { startDate: null, endDate: null },
  selectedFilters: [],
  setAllFilters: () => null,
  setCompareToPreviousType: () => null,
  setSelectedFilters: () => null,
  updatePreviousPeriodDates: () => null,
  updateSelectedDates: () => null,
  formatAndUpdateSelectedDates: () => null,
  headerUpdate: false,
  setHeaderUpdate: () => null,
  resetDates: () => null,
  checkedFilters: [],
  setCheckedFilters: () => null,
  rowFilters: [],
  setRowFilters: () => null,
  yearEnabled: false,
  headerFilters: [],
  lastValidDateIsLoading: () => true,
  isReady: false,
  setSidebarDataType: () => null,
  setSidebarDataTypeChanged: () => false,
});

export const HeaderProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const location = useLocation();

  const params = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ]);

  const {
    clients,
    currentClient,
    setCurrentClient,
    changingClient,
    setChangingClient,
  } = useClients();

  const [selectedDates, updateSelectedDates] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  const [sidebarDataType, setSidebarDataType] = useState<
    DataSetType | undefined
  >();
  const [sidebarDataTypeChanged, setSidebarDataTypeChanged] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState<FilterItem[]>([]);
  const [checkedFilters, setCheckedFilters] = useState<FilterItem[]>([]);
  const [headerFilters, setHeaderFilters] = useState<FilterItem[]>([]);
  const [rowFilters, setRowFilters] = useState<FilterItem[]>([]);
  const [yearEnabled, setYearEnabled] = useState(false);

  const [allFilters, setAllFilters] = useState(false);
  const [headerUpdate, setHeaderUpdate] = useState(false);

  const [clearFilters, setClearFilters] = useState(false);

  const [previousPeriodDates, updatePreviousPeriodDates] = useState<DateRange>({
    startDate: null,
    endDate: null,
  });

  const [lastValidDate, setLastValidDate] = useState<NullableMoment | null>(
    null,
  );
  const [compareToPreviousType, setCompareToPreviousType] = useState<
    CompareToPreviousTypeOptions
  >(CompareToPreviousTypeOptions.notSet);

  const calculatePeriodLength = useCallback(() => {
    if (!selectedDates.startDate && !selectedDates.endDate) return 0;
    const startDate = selectedDates.startDate || moment();
    const endDate = selectedDates.endDate || moment();
    return endDate.diff(startDate, 'days') + 1;
  }, [selectedDates]);

  const [periodLength, setPeriodLength] = useState<number>(
    calculatePeriodLength(),
  );
  const calculatePreviousPeriodDates = useCallback(() => {
    switch (compareToPreviousType) {
      case CompareToPreviousTypeOptions.period:
        return {
          startDate: moment(selectedDates.startDate).subtract(
            periodLength,
            'days',
          ),
          endDate: moment(selectedDates.endDate).subtract(periodLength, 'days'),
        };
      case CompareToPreviousTypeOptions.year:
      default:
        return {
          startDate: moment(selectedDates.startDate).subtract(1, 'year'),
          endDate: moment(selectedDates.endDate).subtract(1, 'year'),
        };
    }
  }, [periodLength, compareToPreviousType, selectedDates]);

  useEffect(() => {
    setPeriodLength(calculatePeriodLength());
    updatePreviousPeriodDates(calculatePreviousPeriodDates());
  }, [
    calculatePeriodLength,
    calculatePreviousPeriodDates,
    updatePreviousPeriodDates,
    compareToPreviousType,
  ]);

  const updateSelectedFilters = useCallback(selected => {
    setCheckedFilters([]);
    setHeaderFilters(selected);
    setClearFilters(selected.length === 0);
  }, []);

  const resetFilters = useCallback(() => {
    setCheckedFilters([]);
    setRowFilters([]);
    setHeaderFilters([]);
    updateSelectedFilters([]);
    setAllFilters(false);
    setCompareToPreviousType(CompareToPreviousTypeOptions.period);
    updateSelectedDates({ startDate: null, endDate: null });
  }, [updateSelectedFilters, setAllFilters, setCompareToPreviousType]);

  useUrlSync({
    clearFilters,
    clients,
    compareToPreviousType,
    currentClient,
    history,
    lastValidDate,
    params,
    selectedDates,
    selectedFilters,
    setCompareToPreviousType,
    setCurrentClient,
    setSelectedFilters,
    updateSelectedDates,
  });
  useEffect(() => {
    if (checkedFilters.length || rowFilters.length || headerFilters.length) {
      setSelectedFilters([...checkedFilters, ...rowFilters, ...headerFilters]);
    } else {
      setSelectedFilters([]);
    }
  }, [checkedFilters, rowFilters, headerFilters]);

  const [getLastImportResponse] = useQuery<GetDataPointsResponse>({
    query: getDataPoints,
    variables: {
      client_id: currentClient.id,
      first: 1,
      sidebarDataType: sidebarDataType,
      orderTimestamp: 'desc',
    },
    pause: currentClient.id === '',
  });

  const [getFirstImportDate] = useQuery<GetDataPointsResponse>({
    query: getDataPoints,
    variables: {
      client_id: currentClient.id,
      first: 1,
      sidebarDataType: sidebarDataType,
      orderTimestamp: 'asc',
    },
    pause: currentClient.id === '',
  });

  const lastValidDateIsLoading = () => getLastImportResponse.fetching;
  useEffect(() => {
    const handleImport = async () => {
      if (
        getLastImportResponse.data &&
        getLastImportResponse.data.dataPoints &&
        getLastImportResponse.data.dataPoints.edges &&
        getLastImportResponse.data.dataPoints.edges.length > 0
      ) {
        if (changingClient) setChangingClient(false);
        await setLastValidDate(
          moment(getLastImportResponse.data.dataPoints.edges[0].node.timestamp),
        );
      } else {
        if (changingClient && !getLastImportResponse.fetching)
          setChangingClient(false);
        if (currentClient.id && !getLastImportResponse.fetching)
          setLastValidDate(null);
      }
    };
    handleImport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLastImportResponse, getLastImportResponse.fetching]);

  useEffect(() => {
    if (
      getFirstImportDate.data &&
      getFirstImportDate.data.dataPoints &&
      getFirstImportDate.data.dataPoints.edges &&
      getFirstImportDate.data.dataPoints.edges.length > 0
    ) {
      const oldestDate = moment(
        getFirstImportDate.data.dataPoints.edges[0].node.timestamp,
      );
      if (
        selectedDates.startDate &&
        selectedDates.startDate?.diff(oldestDate, 'years') >= 1
      ) {
        setYearEnabled(true);
      } else {
        setYearEnabled(false);
      }
    }
    if (getLastImportResponse.fetching) {
      setYearEnabled(false);
    }
  }, [
    getFirstImportDate.data,
    getLastImportResponse,
    lastValidDate,
    selectedDates,
  ]);

  const formatAndUpdateSelectedDates = (dates: DateRange) => {
    let startDate = dates.startDate;
    let endDate = dates.endDate;
    if (!endDate) {
      endDate = startDate;
    }
    if (startDate !== null) {
      startDate = startDate.startOf('day');
    }

    if (endDate !== null) {
      endDate = endDate.endOf('day');
    }

    updateSelectedDates({ startDate, endDate });
  };
  const resetDates = () => removeDatesFromUrl({ history, params });
  const isReady =
    currentClient.id &&
    selectedDates.startDate &&
    selectedDates.endDate &&
    lastValidDate &&
    periodLength > 0
      ? true
      : false;
  useEffect(() => {
    if (
      ((selectedDates.endDate || lastValidDate) && changingClient) ||
      (getLastImportResponse.fetching && changingClient)
    ) {
      resetFilters();
      setLastValidDate(null);
      updateSelectedDates({ startDate: null, endDate: null });
    }
  }, [
    currentClient.id,
    changingClient,
    lastValidDate,
    resetFilters,
    getLastImportResponse.fetching,
    selectedDates.endDate,
  ]);

  useEffect(() => {
    if (sidebarDataTypeChanged) {
      updateSelectedDates({
        startDate: moment(lastValidDate as Moment)
          .subtract(7, 'days')
          .startOf('day'),
        endDate: lastValidDate,
      });
      setSidebarDataTypeChanged(false);
    }
  }, [sidebarDataTypeChanged, lastValidDate]);

  return (
    <HeaderContext.Provider
      value={{
        allFilters,
        compareToPreviousType,
        lastValidDate,
        periodLength,
        previousPeriodDates,
        resetFilters,
        selectedDates,
        selectedFilters,
        setAllFilters,
        setCompareToPreviousType,
        setSelectedFilters: updateSelectedFilters,
        updatePreviousPeriodDates,
        updateSelectedDates,
        formatAndUpdateSelectedDates,
        headerUpdate,
        setHeaderUpdate,
        resetDates,
        checkedFilters,
        setCheckedFilters,
        rowFilters,
        setRowFilters,
        yearEnabled,
        headerFilters,
        lastValidDateIsLoading,
        isReady,
        setSidebarDataType,
        setSidebarDataTypeChanged,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader: Factory<Context> = () => useContext(HeaderContext);
