import React, { useEffect, useState } from 'react';
import SearchIcon from '../../icons/SearchIcon';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import {
  Button,
  InputAdornment,
  List,
  ThemeProvider,
  Drawer,
  TextField,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
} from '@material-ui/core';
import { FieldConfiguration } from '../../api/fieldConfiguration';
import {
  BottomButtonsWrapper,
  CheckmarkWrapper,
  dataSelectTheme,
  Heading,
  InputsWrapper,
  DataDiv,
  Wrapper,
} from '../dataSelect/styles';
import { GAEvent } from '../Tracking';
import { useClients } from '../../clients/useClients';
import { useSidebar } from '../sidebar/useSidebar';
interface Props {
  open: boolean;
  variableList: FieldConfiguration[];
  previouslySelectedVariables: FieldConfiguration[];
  callBack: (variables: FieldConfiguration[]) => void;
  closeCallBack: () => void;
}

const GraphModal: React.FC<Props> = ({
  open,
  callBack,
  closeCallBack,
  variableList,
  previouslySelectedVariables,
}) => {
  const [openModal, setOpenModal] = useState(open);
  const [message, setMessage] = useState('');
  const [searchText, setSearchText] = useState('');
  const [primaryDataType, setPrimaryDataType] = useState('');
  const [primaryDataCount, setPrimaryDataCount] = useState(0);
  const [secondaryDataType, setSecondaryDataType] = useState('');
  const [secondaryDataCount, setSecondaryDataCount] = useState(0);
  const [selectedVariables, setSelectedVariables] = useState<
    FieldConfiguration[]
  >(previouslySelectedVariables);
  const { currentClient } = useClients();
  const { dataType } = useSidebar();

  useEffect(() => {
    const primaryAxisVariables = selectedVariables.filter(
      variable => variable.axisName === 'left',
    );
    setPrimaryDataCount(primaryAxisVariables.length);
    setPrimaryDataType(primaryAxisVariables[0]?.fieldType || '');
    const secondaryAxisVariables = selectedVariables.filter(
      variable => variable.axisName === 'right',
    );
    setSecondaryDataCount(secondaryAxisVariables.length);
    setSecondaryDataType(secondaryAxisVariables[0]?.fieldType || '');

    if (primaryAxisVariables.length > 1 && secondaryAxisVariables.length > 1) {
      setMessage(
        '2 of 2 max variables selected for the primary and secondary axis',
      );
      return;
    }
    if (primaryAxisVariables.length > 1) {
      setMessage('2 of 2 max variables selected for the primary axis');
      return;
    }
    if (secondaryAxisVariables.length > 1) {
      setMessage('2 of 2 max variables selected for the secondary axis');
      return;
    }
    setMessage('');
  }, [selectedVariables]);
  const handleClick = (fieldConfiguration: FieldConfiguration) => {
    const exists = selectedVariables.find(
      variable =>
        variable.id === fieldConfiguration.id &&
        variable.previous === fieldConfiguration.previous,
    );
    if (exists) {
      setSelectedVariables(variables =>
        variables.filter(
          variable => variable.displayName !== fieldConfiguration.displayName,
        ),
      );
      return;
    }
    setSelectedVariables(variables => [...variables, fieldConfiguration]);
  };
  const checkIfDisabled = (fieldConfiguration: FieldConfiguration) => {
    if (
      (fieldConfiguration.importerType?.includes('seller') && dataType === 0) ||
      (fieldConfiguration.importerType?.includes('vendor') && dataType === 1)
    ) {
      return true;
    }

    if (
      selectedVariables.find(
        variable => variable.displayName === fieldConfiguration.displayName,
      )
    ) {
      return false;
    }
    switch (fieldConfiguration.axisName) {
      case 'left':
        if (
          !primaryDataType ||
          fieldConfiguration.fieldType === primaryDataType
        ) {
          if (primaryDataCount < 2) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      case 'right':
        if (
          !secondaryDataType ||
          secondaryDataType === fieldConfiguration.fieldType
        ) {
          if (secondaryDataCount < 2) {
            return false;
          } else {
            return true;
          }
        }
        return true;
      default:
        return false;
    }
  };
  const type = (fieldType?: string) => {
    switch (fieldType) {
      case 'dollar amount':
        return '$';
      case 'count':
        return '#';
      case 'percent':
        return '%';
      default:
        return '';
    }
  };
  const filteredVariables = variableList.filter(
    variable =>
      variable.displayName.toLowerCase().indexOf(searchText.toLowerCase()) !==
        -1 ||
      variable.axisLabel?.toLowerCase().indexOf(searchText.toLowerCase()) !==
        -1,
  );
  return (
    <ThemeProvider theme={dataSelectTheme}>
      <Drawer
        anchor="bottom"
        disableBackdropClick
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Wrapper>
          <IconButton onClick={() => closeCallBack()} edge="start">
            <CloseIcon className="modal-icon" />
          </IconButton>
          <Heading>
            <Typography variant="h1">Select Your Variable(s)</Typography>
            <Typography variant="body1">Max of 4</Typography>
          </Heading>
          <InputsWrapper>
            <TextField
              id="search"
              fullWidth
              label="Search Variables"
              value={searchText}
              onChange={event => setSearchText(event.target.value)}
              InputProps={{
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      <IconButton
                        className="clear-button"
                        onClick={() => setSearchText('')}
                        disabled={!searchText}
                      >
                        {searchText ? (
                          <CloseIcon className="modal-icon" />
                        ) : (
                          <SearchIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </>
                ),
              }}
            />
          </InputsWrapper>
          <DataDiv>
            <List dense>
              {variableList.length === 0 || !filteredVariables.length ? (
                <Typography className="no-data" variant="body1">
                  There are no results matching your search. Please try again.
                </Typography>
              ) : (
                filteredVariables.map((fieldConfiguration, index) => (
                  <ListItem
                    key={`${fieldConfiguration.id}${index}`}
                    onClick={() => handleClick(fieldConfiguration)}
                    className="listItem"
                    button
                    disabled={checkIfDisabled(fieldConfiguration)}
                    selected={
                      undefined !==
                      selectedVariables.find(
                        variable =>
                          variable.fieldKey === fieldConfiguration.fieldKey &&
                          variable.previous === fieldConfiguration.previous,
                      )
                    }
                  >
                    <ListItemText
                      primary={`${fieldConfiguration.displayName} | ${
                        fieldConfiguration.axisLabel
                      } | ${type(fieldConfiguration.fieldType)}`}
                    />
                    {undefined !==
                      selectedVariables.find(
                        variable =>
                          variable.fieldKey === fieldConfiguration.fieldKey &&
                          variable.previous === fieldConfiguration.previous,
                      ) && (
                      <CheckmarkWrapper>
                        <CheckIcon color="inherit" />
                      </CheckmarkWrapper>
                    )}
                  </ListItem>
                ))
              )}
            </List>
          </DataDiv>
          <BottomButtonsWrapper>
            <span className="footer-message">{message}</span>
            <Button
              variant="outlined"
              className="apply-button"
              onClick={() => {
                callBack(selectedVariables);
                GAEvent({
                  category: 'Graphs',
                  action: 'Updated Variables',
                  label: `Client: ${currentClient.name}, Performance Chart`,
                });
              }}
            >
              Apply
            </Button>
          </BottomButtonsWrapper>
        </Wrapper>
      </Drawer>
    </ThemeProvider>
  );
};

export default GraphModal;
