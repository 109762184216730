import React from 'react';
import styled from '@emotion/styled';
import { VictoryPie } from 'victory';
import { donutChartSelectedColorMap } from '../../common/theme';
import { LabeledDataPoint, CombinedDonutGraphData } from '../../common/types';
import { withDonutGraphStyles } from './styles';
import { prettyPrintNumber } from '../../common/utils';
import CustomTooltip from '../Tooltip';
import { GAEvent } from '../Tracking';
import { useClients } from '../../clients/useClients';

const Container = styled.section`
  ${withDonutGraphStyles()}
`;
const EmptyContainer = styled.section`
  ${withDonutGraphStyles()}
  width: 100%;
  height: 310px;
  padding: 14px 16px 16px;
  div {
    background: #f2f2f2;
    width: 100%;
    height: 100%;
    margin: auto !important;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 15px;
  }
`;

interface Props {
  data: Array<LabeledDataPoint>;
  colorScale: Array<string>;
  onDataSliceSelect: (id: string) => void;
  selectedDataSlice: CombinedDonutGraphData;
  graphType: string;
  total: number;
}

const DonutGraph: React.FC<Props> = ({
  data,
  colorScale,
  onDataSliceSelect,
  selectedDataSlice,
  graphType,
  total,
}) => {
  const { currentClient } = useClients();
  if (data.length === 0) {
    return (
      <EmptyContainer>
        <div className="empty_response">
          There is no data for your selected date range or filters. Please try
          again.
        </div>
      </EmptyContainer>
    );
  }
  const getSelectedPercentage = () => {
    const percentage =
      'products' === graphType
        ? selectedDataSlice.productsPercentage
        : selectedDataSlice.revenuePercentage;
    return prettyPrintNumber(percentage || 0, 'percent');
  };
  const getSelectedValue = () => {
    const value =
      'products' === graphType
        ? selectedDataSlice.productsValue
        : selectedDataSlice.revenueValue;
    const type = 'products' === graphType ? 'count' : 'dollar amount';
    return prettyPrintNumber(value || 0, type);
  };
  const getTotal = () => {
    const type = graphType === 'products' ? 'count' : 'dollar amount';
    return prettyPrintNumber(total || 0, type);
  };
  const ToolTipData = (
    <div style={{ textAlign: 'center' }}>
      {selectedDataSlice.label}
      <br />
      {selectedDataSlice.secondLabel ? selectedDataSlice.secondLabel : null}
    </div>
  );

  return (
    <Container>
      <div className="graph-container">
        <svg viewBox="0 0 400 400">
          <VictoryPie
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onClick: () => {
                    GAEvent({
                      category: 'Graphs',
                      action: 'Selected Slice',
                      label: `Client: ${currentClient.name}, Donut Chart`,
                    });
                    return [
                      {
                        target: 'data',
                        mutation: ({ datum }) => {
                          const nextSliceId =
                            datum.id === selectedDataSlice.id ? '' : datum.id;
                          onDataSliceSelect(nextSliceId);
                        },
                      },
                    ];
                  },
                },
              },
            ]}
            colorScale={colorScale}
            data={data}
            innerRadius={110}
            style={{
              data: {
                cursor: 'pointer',
              },
              labels: { display: 'none' },
            }}
            standalone={false}
          />
          <VictoryPie
            standalone={false}
            colorScale={colorScale}
            data={data}
            innerRadius={170}
            style={{
              data: {
                cursor: 'pointer',
                fill: props => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  const colorIndex: number = props.index;
                  const sliceColor = colorScale[colorIndex];
                  const highlightColor = donutChartSelectedColorMap[sliceColor];
                  return props.datum.id === selectedDataSlice.id
                    ? highlightColor
                    : 'transparent';
                },
              },
              labels: { display: 'none' },
            }}
          />
        </svg>
        {selectedDataSlice.id && (
          <div className="selected-slice-details">
            <CustomTooltip title={ToolTipData} arrow placement="top">
              <div>
                <div className="selected-slice-label">
                  {selectedDataSlice.secondLabel ? (
                    <>
                      <p className="truncate">{selectedDataSlice.label}</p>
                      <p>{selectedDataSlice.secondLabel}</p>
                    </>
                  ) : (
                    selectedDataSlice.label
                  )}
                </div>
                <div className="selected-slice-value">
                  {getSelectedValue()} / {getTotal()}
                </div>
                <div className="selected-slice-percentage">
                  {getSelectedPercentage()}
                </div>
              </div>
            </CustomTooltip>
          </div>
        )}
      </div>
    </Container>
  );
};

export default DonutGraph;
