import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Grid from '@material-ui/core/Grid';
import { CombinedDonutGraphData } from '../../../common/types';
import { donutChartSelectedColorMap } from '../../../common/theme';
import { prettyPrintNumber } from '../../../common/utils';

const DataSliceColor = styled.div<{ color: string; selected?: boolean }>`
  background-color: ${p => p.color};
  ${p =>
    p.selected &&
    css`
      box-shadow: 0px 0px 0px 5px ${donutChartSelectedColorMap[p.color]};
    `}
`;

interface SliceBreakdownBlockProps {
  data: Array<CombinedDonutGraphData>;
  selectedDataSlice: CombinedDonutGraphData;
}

const SliceBreakdownBlock: React.FC<SliceBreakdownBlockProps> = ({
  data,
  selectedDataSlice,
}) => (
  <>
    {data.map(item => (
      <Grid item xs={3} key={`sl-${item.id}`}>
        <div className="slice-breakdown-item ">
          <DataSliceColor
            className={
              item.label === 'All Others'
                ? 'data-slice-color data-slice-border'
                : 'data-slice-color'
            }
            color={item.color}
            selected={item.id === selectedDataSlice.id}
          />
          <div className="data-slice-label">{item.label}</div>
          <div className="data-slice-details">
            <div>Ordered Revenue</div>
            {prettyPrintNumber(
              item.revenueValue || 0,
              'dollar amount',
              2,
            )} | {item.revenuePercentage}%
          </div>
          <div className="data-slice-details">
            <div>Ordered Products</div>
            {prettyPrintNumber(item.productsValue || 0, 'count')} |{' '}
            {item.productsPercentage}%
          </div>
        </div>
      </Grid>
    ))}
  </>
);

export default SliceBreakdownBlock;
