import React, { useMemo, useEffect, useState } from 'react';

import { DataType } from '../../common/types';
import { transformPerformanceBreakdownData } from '../dashboard/transformers';
import { useQuery } from 'urql';
import {
  getAsins,
  GetAsinsResponse,
  GetDataPointsAggregateResponse,
  getDataPointsAsinGrouped,
  getDataPointsSubcategoryGrouped,
  getFilteredDataPointsWaterfallGraph,
} from '../../api/data';
import { FieldConfiguration } from '../../api/fieldConfiguration';
import { useHeader } from '../header/useHeader';
import { useClients } from '../../clients/useClients';
import { FilterListItem } from '../breakdowns/FilterList';
import { dateFormat } from '../header/utils';
import { fieldConfigurationFormula } from '../sidebar/useSidebar';

export interface DataColumn {
  filterTitle: string;
  data: FilterListItem[];
  variableField: string;
  variableKey?: string;
  error: boolean;
  loading: boolean;
  type: DataType;
}

interface Props {
  variable: FieldConfiguration;
  callBack: (column: DataColumn) => void;
  columns: DataColumn[];
  pause: boolean;
}

const BreakdownColumn: React.FC<Props> = ({
  variable,
  callBack,
  columns,
  pause,
}) => {
  const [column, setColumn] = useState<DataColumn | null>(null);
  const usableFC = fieldConfigurationFormula(variable.fieldKey);

  const {
    selectedDates,
    previousPeriodDates,
    selectedFilters,
    allFilters,
  } = useHeader();
  const { currentClient } = useClients();

  const pauseQueries: boolean = useMemo(
    () =>
      pause ||
      currentClient.id === '' ||
      selectedDates.startDate === null ||
      selectedDates.endDate === null,
    [currentClient.id, pause, selectedDates.endDate, selectedDates.startDate],
  );
  const [getTopFiveCategoryDataResponsePrimary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsWaterfallGraph,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: usableFC.firstKey,
      order: 'desc',
      asin_id_list: selectedFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id),
      asin_category_list: selectedFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
      asin_subcategory_list: selectedFilters
        .filter(filter => filter.type === 'subcategory')
        .map(filter => filter.id),
    },
    pause: pauseQueries || (selectedFilters.length !== 0 && !allFilters),
  });
  const [getTopFiveCategoryDataResponseSecondary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsWaterfallGraph,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: usableFC.secondKey,
      order: 'desc',
      asin_id_list: selectedFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id),
      asin_category_list: selectedFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
      asin_subcategory_list: selectedFilters
        .filter(filter => filter.type === 'subcategory')
        .map(filter => filter.id),
    },
    pause: pauseQueries || (selectedFilters.length !== 0 && !allFilters),
  });
  const [getTopFiveSubCategoryDataResponsePrimary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsSubcategoryGrouped,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: usableFC.firstKey,
      order: 'desc',
      asin_category_list: selectedFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
    },
    pause:
      pauseQueries ||
      allFilters ||
      selectedFilters.length === 0 ||
      selectedFilters[0].type !== 'category',
  });
  const [getTopFiveSubCategoryDataResponseSecondary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsSubcategoryGrouped,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: usableFC.secondKey,
      order: 'desc',
      asin_category_list: selectedFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
    },
    pause:
      pauseQueries ||
      allFilters ||
      selectedFilters.length === 0 ||
      selectedFilters[0].type !== 'category',
  });

  const [getTopFiveAsinsDataResponsePrimary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: usableFC.firstKey,
      order: 'desc',
      asin_id_list: selectedFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id),
      asin_category_list: selectedFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
      asin_subcategory_list: selectedFilters
        .filter(filter => filter.type === 'subcategory')
        .map(filter => filter.id),
    },
    pause:
      pauseQueries ||
      selectedFilters.length === 0 ||
      selectedFilters[0].type === 'category',
  });
  const [getTopFiveAsinsDataResponseSecondary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: usableFC.secondKey,
      order: 'desc',
      asin_id_list: selectedFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id),
      asin_category_list: selectedFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
      asin_subcategory_list: selectedFilters
        .filter(filter => filter.type === 'subcategory')
        .map(filter => filter.id),
    },
    pause:
      pauseQueries ||
      selectedFilters.length === 0 ||
      selectedFilters[0].type === 'category',
  });

  const [getTopFivePreviousCategoryDataResponseSecondary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsWaterfallGraph,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: usableFC.secondKey,
      order: 'desc',
      asin_category_list:
        getTopFiveCategoryDataResponseSecondary.data &&
        getTopFiveCategoryDataResponseSecondary.data.dataPointAggregates &&
        getTopFiveCategoryDataResponseSecondary.data.dataPointAggregates.edges
          ? getTopFiveCategoryDataResponseSecondary.data.dataPointAggregates.edges.map(
              edge => edge.node.categoryId,
            )
          : [],
    },
    pause:
      pauseQueries ||
      (selectedFilters.length !== 0 && !allFilters) ||
      getTopFiveCategoryDataResponseSecondary.fetching,
  });
  const [getTopFivePreviousCategoryDataResponsePrimary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsWaterfallGraph,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: usableFC.firstKey,
      order: 'desc',
      asin_category_list:
        getTopFiveCategoryDataResponsePrimary.data &&
        getTopFiveCategoryDataResponsePrimary.data.dataPointAggregates &&
        getTopFiveCategoryDataResponsePrimary.data.dataPointAggregates.edges
          ? getTopFiveCategoryDataResponsePrimary.data.dataPointAggregates.edges.map(
              edge => edge.node.categoryId,
            )
          : [],
    },
    pause:
      pauseQueries ||
      (selectedFilters.length !== 0 && !allFilters) ||
      getTopFiveCategoryDataResponsePrimary.fetching,
  });
  const [getTopFivePreviousSubCategoryDataResponsePrimary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsSubcategoryGrouped,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: usableFC.firstKey,
      order: 'desc',
      asin_subcategory_list:
        getTopFiveSubCategoryDataResponsePrimary.data &&
        getTopFiveSubCategoryDataResponsePrimary.data.dataPointAggregates &&
        getTopFiveSubCategoryDataResponsePrimary.data.dataPointAggregates.edges
          ? getTopFiveSubCategoryDataResponsePrimary.data.dataPointAggregates.edges.map(
              edge => edge.node.subcategoryId,
            )
          : [],
    },
    pause:
      pauseQueries ||
      allFilters ||
      selectedFilters.length === 0 ||
      selectedFilters[0].type !== 'category' ||
      getTopFiveSubCategoryDataResponsePrimary.fetching ||
      (getTopFiveSubCategoryDataResponsePrimary.data &&
        getTopFiveSubCategoryDataResponsePrimary.data.dataPointAggregates &&
        getTopFiveSubCategoryDataResponsePrimary.data.dataPointAggregates
          .edges &&
        getTopFiveSubCategoryDataResponsePrimary.data.dataPointAggregates.edges
          .length === 0),
  });
  const [getTopFivePreviousSubCategoryDataResponseSecondary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsSubcategoryGrouped,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: usableFC.secondKey,
      order: 'desc',
      asin_subcategory_list:
        getTopFiveSubCategoryDataResponseSecondary.data &&
        getTopFiveSubCategoryDataResponseSecondary.data.dataPointAggregates &&
        getTopFiveSubCategoryDataResponseSecondary.data.dataPointAggregates
          .edges
          ? getTopFiveSubCategoryDataResponseSecondary.data.dataPointAggregates.edges.map(
              edge => edge.node.subcategoryId,
            )
          : [],
    },
    pause:
      pauseQueries ||
      allFilters ||
      selectedFilters.length === 0 ||
      selectedFilters[0].type !== 'category' ||
      getTopFiveSubCategoryDataResponseSecondary.fetching ||
      (getTopFiveSubCategoryDataResponseSecondary.data &&
        getTopFiveSubCategoryDataResponseSecondary.data.dataPointAggregates &&
        getTopFiveSubCategoryDataResponseSecondary.data.dataPointAggregates
          .edges &&
        getTopFiveSubCategoryDataResponseSecondary.data.dataPointAggregates
          .edges.length === 0),
  });
  const [getTopFiveAsinsPreviousDataResponsePrimary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: usableFC.firstKey,
      asin_id_list:
        getTopFiveAsinsDataResponsePrimary.data &&
        getTopFiveAsinsDataResponsePrimary.data.dataPointAggregates &&
        getTopFiveAsinsDataResponsePrimary.data.dataPointAggregates.edges
          ? getTopFiveAsinsDataResponsePrimary.data.dataPointAggregates.edges.map(
              edge => edge.node.asinId,
            )
          : [],
    },
    pause: pauseQueries || getTopFiveAsinsDataResponsePrimary.fetching,
  });
  const [getTopFiveAsinsPreviousDataResponseSecondary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: usableFC.secondKey,
      asin_id_list:
        getTopFiveAsinsDataResponseSecondary.data &&
        getTopFiveAsinsDataResponseSecondary.data.dataPointAggregates &&
        getTopFiveAsinsDataResponseSecondary.data.dataPointAggregates.edges
          ? getTopFiveAsinsDataResponseSecondary.data.dataPointAggregates.edges.map(
              edge => edge.node.asinId,
            )
          : [],
    },
    pause: pauseQueries || getTopFiveAsinsDataResponseSecondary.fetching,
  });
  const asinList =
    selectedFilters
      .filter(filter => filter.type === 'asin')
      .map(filter => filter.id).length > 0
      ? selectedFilters
          .filter(filter => filter.type === 'asin')
          .map(filter => filter.id)
      : getTopFiveAsinsDataResponsePrimary.data &&
        getTopFiveAsinsDataResponsePrimary.data.dataPointAggregates &&
        getTopFiveAsinsDataResponsePrimary.data.dataPointAggregates.edges
      ? getTopFiveAsinsDataResponsePrimary.data.dataPointAggregates.edges.map(
          edge => edge.node.asinId,
        )
      : [];
  const [getAsinsResponse] = useQuery<GetAsinsResponse>({
    query: getAsins,
    variables: {
      asin_id_list: asinList,
    },
    pause:
      pauseQueries ||
      asinList.length === 0 ||
      selectedFilters.length === 0 ||
      selectedFilters[0].type === 'category' ||
      getTopFiveAsinsDataResponsePrimary.fetching ||
      (getTopFiveAsinsDataResponsePrimary.data &&
        getTopFiveAsinsDataResponsePrimary.data.dataPointAggregates &&
        getTopFiveAsinsDataResponsePrimary.data.dataPointAggregates.edges &&
        getTopFiveAsinsDataResponsePrimary.data.dataPointAggregates.edges
          .length === 0),
  });

  useEffect(() => {
    const col = transformPerformanceBreakdownData(
      selectedFilters.length === 0 || allFilters
        ? getTopFiveCategoryDataResponsePrimary
        : selectedFilters[0].type === 'category'
        ? getTopFiveSubCategoryDataResponsePrimary
        : getTopFiveAsinsDataResponsePrimary,
      selectedFilters.length === 0 || allFilters
        ? getTopFiveCategoryDataResponseSecondary
        : selectedFilters[0].type === 'category'
        ? getTopFiveSubCategoryDataResponseSecondary
        : getTopFiveAsinsDataResponseSecondary,
      selectedFilters.length === 0 || allFilters
        ? getTopFivePreviousCategoryDataResponsePrimary
        : selectedFilters[0].type === 'category'
        ? getTopFivePreviousSubCategoryDataResponsePrimary
        : getTopFiveAsinsPreviousDataResponsePrimary,
      selectedFilters.length === 0 || allFilters
        ? getTopFivePreviousCategoryDataResponseSecondary
        : selectedFilters[0].type === 'category'
        ? getTopFivePreviousSubCategoryDataResponseSecondary
        : getTopFiveAsinsPreviousDataResponseSecondary,
      selectedFilters.length === 0 || allFilters
        ? 'category'
        : selectedFilters[0].type === 'category'
        ? 'subcategory'
        : 'asin',
      variable.fieldType as DataType,
      getAsinsResponse,
      usableFC.secondKey !== '',
      variable.fieldKey,
    );
    setColumn({
      ...col,
      variableField: variable.displayName,
      variableKey: variable.fieldKey,
      type: variable.fieldType as DataType,
    });
  }, [
    allFilters,
    getAsinsResponse,
    getTopFiveAsinsDataResponsePrimary,
    getTopFiveAsinsPreviousDataResponsePrimary,
    getTopFiveCategoryDataResponsePrimary,
    getTopFivePreviousCategoryDataResponsePrimary,
    getTopFivePreviousSubCategoryDataResponsePrimary,
    getTopFiveSubCategoryDataResponsePrimary,
    getTopFiveAsinsDataResponseSecondary,
    getTopFiveAsinsPreviousDataResponseSecondary,
    getTopFiveCategoryDataResponseSecondary,
    getTopFivePreviousCategoryDataResponseSecondary,
    getTopFivePreviousSubCategoryDataResponseSecondary,
    getTopFiveSubCategoryDataResponseSecondary,
    selectedFilters,
    variable.displayName,
    variable.fieldType,
    usableFC.secondKey,
    variable.fieldKey,
  ]);

  useEffect(() => {
    if (!pause && column !== null) {
      const exists = columns.find(
        _column => _column.variableField === column.variableField,
      );
      if (!exists) {
        callBack(column);
      } else {
        let counter = 0;

        if (exists.data.length === column.data.length) {
          exists.data.forEach((dp, i) => {
            if (dp.filter !== column.data[i].filter) {
              counter++;
            }
          });
        } else {
          counter = 1;
        }
        if (counter > 0) {
          callBack(column);
        }
      }
    }
  }, [callBack, column, columns, pause]);
  return <></>;
};

export default BreakdownColumn;
