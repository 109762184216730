import React, { useCallback, useEffect, useState, Fragment } from 'react';
import styled from '@emotion/styled';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DefaultAccordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withAccordionOverrides } from '../breakdowns/styles';
import { FieldConfiguration } from '../../api/fieldConfiguration';
import BreakdownColumn, { DataColumn } from './BreakdownColumn';
import {
  prettyPrintNumber,
  prefixPositiveOrNegative,
} from '../../common/utils';
import { useHeader } from '../header/useHeader';
import { IconButton, Checkbox, Button } from '@material-ui/core';
import { FilterType } from '../../common/types';
import { useClients } from '../../clients/useClients';
import { useCategoryTitle } from '../breakdowns/useCategoryTitle';

const Accordion = styled(DefaultAccordion)`
  ${withAccordionOverrides(false)}
  .perfomance-breakdown-table {
    max-height: 480px
    overflow: auto;
  }
`;

interface Props {
  selectedVariables: FieldConfiguration[];
  expand: boolean;
  onShowAllClick: () => void;
}
interface CheckRow {
  filterRow: string;
  text: string;
  checked: boolean;
}

interface FilterRow {
  filter: string;
  id?: string;
  type?: string;
}
interface BreadCrumb {
  type: FilterType;
  id: string;
  label: string;
}
const PerformanceBreakdownTable: React.FC<Props> = ({
  selectedVariables,
  expand,
  onShowAllClick,
}) => {
  const [columns, setColumns] = useState<DataColumn[]>([]);
  const [checkedRows, setCheckedRows] = useState<CheckRow[]>([]);
  const {
    rowFilters,
    setRowFilters,
    headerUpdate,
    setHeaderUpdate,
    checkedFilters,
    setCheckedFilters,
  } = useHeader();
  const { currentClient } = useClients();
  const { categoryTitle } = useCategoryTitle();
  const [filterRows, setFilterRows] = useState<FilterRow[]>([]);
  const [categoryBreadcrumb, setCategoryBreadcrumb] = useState<
    BreadCrumb | undefined
  >(undefined);
  const [subcategoryBreadcrumb, setSubcategoryBreadcrumb] = useState<
    BreadCrumb | undefined
  >(undefined);
  const usefulVariables = selectedVariables.filter(
    (variable, index, self) =>
      self.findIndex(
        val => val.fieldKey.split('-')[0] === variable.fieldKey.split('-')[0],
      ) === index,
  );
  const handleColumnReset = useCallback(() => {
    setColumns(oldColumns =>
      oldColumns.filter(col => {
        const exists = selectedVariables.find(
          val => col.variableField === val.displayName,
        );
        if (exists) return col;
        return false;
      }),
    );
  }, [selectedVariables, setColumns]);
  const resetData = useCallback(async () => {
    await setCategoryBreadcrumb(undefined);
    await setSubcategoryBreadcrumb(undefined);
    await setCheckedFilters([]);
    await setCheckedRows([]);
    await setRowFilters([]);
    await setFilterRows([]);
    handleColumnReset();
  }, [setCheckedFilters, setCheckedRows, setRowFilters, handleColumnReset]);

  const updateData = useCallback(
    (column: DataColumn) => {
      const currentColumns = columns.filter(col => {
        const exists = usefulVariables.find(
          val => col.variableField === val.displayName,
        );
        if (exists) return col;
        return false;
      });
      const sanitizedColumns = currentColumns.filter(_column => {
        return _column.variableField !== column.variableField;
      });

      setColumns([...sanitizedColumns, column]);
    },
    [columns, usefulVariables],
  );
  useEffect(() => {
    resetData();
  }, [currentClient, resetData]);
  useEffect(() => {
    setCheckedFilters([]);
    setCheckedRows([]);
    handleColumnReset();
  }, [selectedVariables, setCheckedFilters, setColumns, handleColumnReset]);
  useEffect(() => {
    const rows = columns.map(column =>
      column.data.map(datum => {
        return {
          filter: datum.filter,
          id: datum.filterId,
          type: column.filterTitle,
        };
      }),
    );
    if (rows.length > 0) {
      const preFinal = rows.reduce((strA, strB) => strA.concat(strB));
      const finalArray = preFinal.filter(
        (_filter, _idx, _self) =>
          _idx ===
          _self.findIndex(
            t => t.id === _filter.id && t.filter === _filter.filter,
          ),
      );
      setFilterRows(finalArray);
    }
  }, [columns]);

  const updateCheckedRows = async (
    rowFilter?: string,
    text?: string,
    type?: string,
  ) => {
    if (!rowFilter || !text) {
      return;
    }
    const foundRow = checkedRows.find(_row => _row.filterRow === rowFilter);
    const chooseType = () => {
      if (type === 'Categories') return 'category';
      if (type === 'Subcategories') return 'subcategory';
      return 'asin';
    };
    if (!foundRow) {
      setCheckedRows(_checkedRows => [
        ..._checkedRows,
        { filterRow: rowFilter, checked: true, text: text },
      ]);
      setCheckedFilters([
        ...checkedFilters.filter(_filter => _filter.type === chooseType()),
        {
          type: chooseType(),
          id: rowFilter,
          text: text,
        },
      ]);
    } else {
      foundRow.checked = !foundRow.checked;
      if (!foundRow.checked) {
        await setCheckedFilters([
          ...checkedFilters.filter(
            _selectedFilter => _selectedFilter.id !== rowFilter,
          ),
        ]);
        await setCheckedRows([
          ...checkedRows.filter(
            _selectedRow => _selectedRow.filterRow !== rowFilter,
          ),
        ]);
      } else {
        setCheckedFilters([
          ...checkedFilters,
          {
            type: chooseType(),
            id: rowFilter,
            text: text,
          },
        ]);
      }
    }
    setHeaderUpdate(false);
  };
  const isChecked = (filter?: string) => {
    if (!filter) {
      return false;
    }
    const found = checkedRows.find(_row => _row.filterRow === filter);
    if (found) {
      return found.checked;
    } else {
      return false;
    }
  };
  const loadChildren = async (
    filterId?: string,
    text?: string,
    type?: string,
  ) => {
    if (type === 'ASINs') return;
    setCheckedFilters([]);
    if (!filterId || !text) {
      return;
    }
    if (!categoryBreadcrumb && type === 'Categories') {
      if (rowFilters.length > 0 && rowFilters[0].type === 'category') {
        setCategoryBreadcrumb({
          id: rowFilters[0].id,
          label: rowFilters[0].text,
          type: 'category',
        });
        setRowFilters([
          {
            id: filterId,
            type: 'subcategory',
            text: text,
          },
        ]);
        setSubcategoryBreadcrumb({
          id: filterId,
          label: text,
          type: 'subcategory',
        });
        setCheckedRows([]);
      } else {
        setRowFilters([
          {
            id: filterId,
            type: 'category',
            text: text,
          },
        ]);
        setCategoryBreadcrumb({ id: filterId, label: text, type: 'category' });
        setCheckedRows([]);
      }
    } else {
      if (!subcategoryBreadcrumb) {
        setRowFilters([
          {
            id: filterId,
            type: 'subcategory',
            text: text,
          },
        ]);
        setSubcategoryBreadcrumb({
          id: filterId,
          label: text,
          type: 'subcategory',
        });
        setCheckedRows([]);
      }
    }
    setHeaderUpdate(false);
  };

  const handleBreadcrumbRemoval = async (
    breadcrumb?: BreadCrumb,
    type?: FilterType,
  ) => {
    if (!type || !breadcrumb) {
      await setRowFilters([]);
      await setCheckedRows([]);
      await setCheckedFilters([]);
      await setCategoryBreadcrumb(undefined);
      await setSubcategoryBreadcrumb(undefined);
      return;
    }
    switch (type) {
      case 'category':
        if (rowFilters.length && rowFilters[0].type === type) return;
        await setCheckedRows([]);
        await setCheckedFilters([]);
        await setSubcategoryBreadcrumb(undefined);
        await setRowFilters([
          {
            id: breadcrumb.id,
            type,
            text: breadcrumb.label,
          },
        ]);
        return;
      default:
        return;
    }
  };
  const handleBreadcrumbs = () => {
    return (
      <span>
        {((subcategoryBreadcrumb || categoryBreadcrumb) && (
          <Button
            style={{ width: '15px' }}
            className="breadcrumb-button clear-button"
            onClick={() => handleBreadcrumbRemoval()}
          >
            ✕
          </Button>
        )) ||
          null}
        {(categoryBreadcrumb?.label && (
          <Button
            className="breadcrumb-button"
            size="small"
            onClick={() =>
              handleBreadcrumbRemoval(categoryBreadcrumb, 'category')
            }
          >
            {categoryBreadcrumb.label}
          </Button>
        )) ||
          null}
        {categoryBreadcrumb && subcategoryBreadcrumb ? '>' : null}
        {(subcategoryBreadcrumb?.label && (
          <Button
            className="breadcrumb-button"
            size="small"
            onClick={() =>
              handleBreadcrumbRemoval(subcategoryBreadcrumb, 'subcategory')
            }
          >
            {subcategoryBreadcrumb.label}
          </Button>
        )) ||
          null}
      </span>
    );
  };
  const renderCategoryTitle = () => {
    if (checkedFilters.length) {
      if (checkedFilters[0].type === 'category') return 'Categories';
      if (checkedFilters[0].type === 'subcategory') return 'Subcategories';
      return 'ASINs';
    }
    return categoryTitle;
  };
  useEffect(() => {
    if (headerUpdate) {
      setCheckedRows([]);
      setCategoryBreadcrumb(undefined);
      setSubcategoryBreadcrumb(undefined);
    }
  }, [headerUpdate]);
  return selectedVariables.length === 0 ? null : (
    <Accordion elevation={0} expanded={expand} onChange={onShowAllClick}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <span className="performance-breakdown-title">BREAKDOWN</span>
        <span className="accordian-button">
          {expand ? 'Collapse' : 'Expand'}
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <div className="breadcrumbs">{handleBreadcrumbs()}</div>
        <TableContainer className="performance-breakdown-table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="checkbox-column" />
                <TableCell className="category-column">
                  {renderCategoryTitle()}
                </TableCell>
                {columns.map(variable => (
                  <TableCell
                    className="border-left"
                    align="right"
                    key={`head-${variable.variableField}`}
                    colSpan={2}
                    style={{ width: columns?.length > 3 ? '17.5%' : '22%' }}
                  >
                    {variable.variableField.indexOf(' - ') > -1
                      ? variable.variableField.substring(
                          0,
                          variable.variableField.indexOf(' - '),
                        )
                      : variable.variableField}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {usefulVariables.map(variable => (
                <BreakdownColumn
                  key={`bdColumn${variable.fieldKey}`}
                  variable={variable}
                  callBack={updateData}
                  columns={columns}
                  pause={
                    (checkedRows.length > 0 &&
                      checkedRows.filter(_row => _row.checked).length > 0) ||
                    checkedFilters.length > 0
                  }
                />
              ))}
              {filterRows
                .filter(_row => _row.id !== 'undefined')
                .map(filterRow => (
                  <TableRow
                    className={`${
                      (filterRow.type !== 'ASINs' && 'breakdown-row') || ''
                    }`}
                    key={filterRow.id}
                  >
                    <TableCell className="checkbox-column">
                      <Checkbox
                        color="primary"
                        onClick={e => {
                          e.stopPropagation();
                          updateCheckedRows(
                            filterRow.id,
                            filterRow.filter,
                            filterRow.type,
                          );
                        }}
                        checked={isChecked(filterRow.id)}
                      />
                    </TableCell>
                    <TableCell
                      onClick={e => {
                        e.stopPropagation();
                        if (filterRow.type === 'ASINs') return;

                        loadChildren(
                          filterRow.id,
                          filterRow.filter,
                          filterRow.type,
                        );
                      }}
                    >
                      <div className="clickable-table-cell">
                        {filterRow.filter}
                        <IconButton
                          color="default"
                          aria-label="filter-button"
                          component="span"
                          disabled={filterRow.type === 'ASINs'}
                        >
                          <ChevronRight />
                        </IconButton>
                      </div>
                    </TableCell>
                    {columns.map((column, index) => {
                      const rowFound = column.data.find(
                        value => value.filter === filterRow.filter,
                      );
                      if (
                        usefulVariables.find(
                          _variable =>
                            _variable.displayName === column.variableField,
                        )
                      ) {
                        return (
                          <Fragment key={`performace-column${index}`}>
                            <TableCell
                              align="left"
                              onClick={e => {
                                e.stopPropagation();
                                if (filterRow.type === 'ASINs') return;
                                loadChildren(
                                  filterRow.id,
                                  filterRow.filter,
                                  filterRow.type,
                                );
                              }}
                              key={`${column.filterTitle} - ${index}`}
                              className={`data-column border-left ${
                                rowFound
                                  ? rowFound.delta.change === 'positive'
                                    ? 'positive_delta'
                                    : 'negative_delta'
                                  : ''
                              }`}
                            >
                              {rowFound
                                ? `${
                                    (!rowFound.delta.percentage.includes('-') &&
                                      !rowFound.delta.percentage.includes(
                                        '+',
                                      ) &&
                                      prefixPositiveOrNegative(
                                        rowFound.delta.change,
                                      )) ||
                                    ''
                                  }${rowFound.delta.percentage}`
                                : ''}
                            </TableCell>
                            <TableCell
                              className="data-column"
                              onClick={e => {
                                e.stopPropagation();
                                if (filterRow.type === 'ASINs') return;
                                loadChildren(
                                  filterRow.id,
                                  filterRow.filter,
                                  filterRow.type,
                                );
                              }}
                              align="center"
                            >
                              {rowFound
                                ? selectedVariables.filter(
                                    _var =>
                                      _var.fieldKey === column.variableKey,
                                  ).length === 1
                                  ? prettyPrintNumber(
                                      Number(
                                        selectedVariables
                                          .filter(
                                            _var =>
                                              _var.fieldKey ===
                                              column.variableKey,
                                          )[0]
                                          .displayName.includes('Previous')
                                          ? rowFound.prev
                                          : rowFound.curr,
                                      ),
                                      column.type,
                                      column.type === 'dollar amount'
                                        ? 2
                                        : undefined,
                                    )
                                  : `${prettyPrintNumber(
                                      Number(rowFound.prev),
                                      column.type,
                                      column.type === 'dollar amount'
                                        ? 2
                                        : undefined,
                                    )} / ${prettyPrintNumber(
                                      Number(rowFound.curr),
                                      column.type,
                                      column.type === 'dollar amount'
                                        ? 2
                                        : undefined,
                                    )}`
                                : ''}
                            </TableCell>
                          </Fragment>
                        );
                      }
                      return null;
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default PerformanceBreakdownTable;
