import React, { useEffect, useState } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment, { Moment } from 'moment';
import styled from '@emotion/styled';
import { ThemeProvider, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from '@material-ui/core/Button';
import ChevronRight from '@material-ui/icons/ChevronRight';
import DateRangeIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { usePrevious } from '../../common/utils';
import { useClients } from '../../clients/useClients';
import FeatherBox from '../../icons/FeatherBox';
import { useSidebar } from '../sidebar/useSidebar';
import { useToast } from '../toast/useToast';
import { CompareToPreviousTypeOptions, useHeader } from './useHeader';
import {
  Content,
  headerTheme,
  withHeaderStyles,
  withAccountHeaderStyles,
} from './styles';
import { Link } from 'react-router-dom';
import CategorySelectModal from '../dataSelect/dataSelectDrawer';
import { FilterItem } from '../../common/types';
import { GAEvent } from '../Tracking';
// import CustomTooltip from '../Tooltip';
const HeaderNode = styled.header<{ compact: boolean; sidebarOpen: boolean }>`
  ${p => withHeaderStyles(p.compact, p.sidebarOpen)}
  overflow: visible !important;
`;
const HeaderContent = styled.div`
  ${withAccountHeaderStyles()}
  .header-title-no-data {
    color: #043746 !important;
    justify-content: center !important;
    display: flex;
    flex-direction: column;
  }
`;
interface Props {
  compact: boolean;
  title: string;
  page?: string;
}

const Header: React.FC<Props> = ({ compact, title, page }) => {
  const [openSelectCategoryModal, setOpenSelectCategoryModal] = useState(false);

  const [
    focusedDateInput,
    setFocusedDateInput,
  ] = useState<FocusedInputShape | null>(null);

  const {
    compareToPreviousType,
    lastValidDate,
    lastValidDateIsLoading,
    periodLength,
    resetFilters,
    selectedDates,
    selectedFilters,
    setAllFilters,
    setCompareToPreviousType,
    setSelectedFilters,
    formatAndUpdateSelectedDates,
    setHeaderUpdate,
    resetDates,
    yearEnabled,
    checkedFilters,
    rowFilters,
    headerFilters,
  } = useHeader();

  const { currentClient } = useClients();

  const { open: sidebarOpen, setOpen: setSidebarOpen } = useSidebar();

  const {
    setContents: setToastContents,
    setOpen: setToastOpen,
    setTitle: setToastTitle,
  } = useToast();

  const updateSelectedFilters = (
    filters: FilterItem[],
    allSelected: boolean,
  ) => {
    if (filters !== null) {
      resetFilters();
      setSelectedFilters(filters);
    }
    setAllFilters(allSelected);

    setOpenSelectCategoryModal(false);
    setHeaderUpdate(true);
  };

  useEffect(() => {
    if (
      window.location.pathname === '/sales' &&
      selectedFilters.length === 1 &&
      selectedFilters[0].type === 'asin'
    ) {
      setToastOpen(true);
      setToastContents(
        <p>
          Select multiple ASINs in the &quot;viewing&quot; selector to
          <br />
          compare data for Percentage Breakdown, Buy Box,
          <br />
          Sales, and Waterfall data.
        </p>,
      );

      setToastTitle('A single ASIN is applied.');
    } else {
      setToastOpen(false);
      setToastTitle('');
      setToastContents(<></>);
    }
  }, [selectedFilters, setToastContents, setToastOpen, setToastTitle]);

  const previousPeriodLength = usePrevious(periodLength);

  useEffect(() => {
    if (
      previousPeriodLength !== undefined &&
      previousPeriodLength <= 31 &&
      periodLength >= 31
    ) {
      setToastOpen(true);
      setToastContents(
        <p>
          Select a date range less than 31 days long
          <br />
          to enable a weekly breakdown on the
          <br />
          ASIN breakdown page.
        </p>,
      );
      setToastTitle('A long date range has been applied');
    }
  }, [
    periodLength,
    previousPeriodLength,
    setToastContents,
    setToastOpen,
    setToastTitle,
  ]);

  const getSelectionText = () => {
    if (selectedFilters.length === 0) {
      return 'No Selection Applied';
    }

    const chooseVariable = () => {
      if (checkedFilters.length) return checkedFilters;
      if (rowFilters.length) return rowFilters;
      return headerFilters.length ? headerFilters : selectedFilters;
    };
    const filterVariable = chooseVariable();
    switch (filterVariable[0]?.type || '') {
      case 'asin':
        return `${filterVariable.length} ${
          filterVariable.length === 1 ? 'ASIN' : 'ASINs'
        } Selected`;
      case 'category':
        return `${filterVariable.length} ${
          filterVariable.length === 1 ? 'Category' : 'Categories'
        } Selected`;
      case 'subcategory':
        return `${filterVariable.length} ${
          filterVariable.length === 1 ? 'Subcategory' : 'Subcategories'
        } Selected`;
    }
  };
  const AlternateHeader = () => {
    if (!lastValidDate)
      return (
        <HeaderContent className="no-data">
          <Typography className="header-title" variant="h1">
            {currentClient.name} <span>{title}</span>
          </Typography>
        </HeaderContent>
      );
    return (
      <HeaderContent>
        <Typography className="header-title" variant="h1">
          ACCOUNT
        </Typography>
        <Typography className="header-title">
          <h1>Hello {title}</h1>
        </Typography>
      </HeaderContent>
    );
  };
  const noData = () =>
    currentClient.id && !lastValidDateIsLoading() && !lastValidDate;
  return (
    <ThemeProvider theme={headerTheme}>
      <HeaderNode
        className={`header-node${
          noData() && page === 'Sales' ? ' no-data' : ''
        }`}
        compact={(noData() && title === 'Sales') || compact}
        sidebarOpen={sidebarOpen}
      >
        {page === 'account' || (noData() && page === 'Sales') ? (
          <AlternateHeader />
        ) : (
          <Content className="header-content-wrapper">
            <div className="top-row">
              <Typography className="header-title" variant="h1">
                {currentClient.name} <span>{title}</span>
              </Typography>
              <div className="buttons-wrapper">
                <div className="input-wrapper header-button">
                  <label>Page</label>
                  <Button
                    className="header-button"
                    color="secondary"
                    disableElevation
                    onClick={() => {
                      resetDates();
                      updateSelectedFilters([], false);
                      resetFilters();
                    }}
                    startIcon={<RefreshIcon />}
                    variant="contained"
                    disableRipple
                  >
                    Reset
                  </Button>
                </div>
                {/* {(currentClient.accountManagers.edges.length && (
                  <CustomTooltip
                    title={currentClient.accountManagers.edges
                      .map(_edge => _edge.node.email)
                      .join(' ')}
                    placement="top-start"
                  >
                    <div className="input-wrapper header-button">
                      <label>Account Manager</label>
                      <span className="fake-button">
                        <AccountCircleIcon fontSize="small" color="inherit" />
                        <span className="fake-button-span account-manager">
                          {currentClient.accountManagers.edges.length > 0
                            ? currentClient.accountManagers.edges[0].node.email
                            : ''}
                        </span>
                      </span>
                    </div>
                  </CustomTooltip>
                )) ||
                  null} */}
                <div className="buttons-wrapper">
                  <div className="input-wrapper header-button import-date-wrapper">
                    <label className="import-date-label header-button">
                      Import Date
                    </label>
                    <Link
                      className="history-link"
                      style={{ textDecoration: 'none' }}
                      to="/history"
                    >
                      <Button
                        color="secondary"
                        className="header-button"
                        disableElevation
                        startIcon={<AccessTimeIcon />}
                        variant="contained"
                        disableRipple
                      >
                        {lastValidDate !== null && Boolean(lastValidDate)
                          ? moment(lastValidDate as Moment).format('MM/DD/YYYY')
                          : '-'}
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="bottom-row">
              <div className="input-wrapper header-input">
                <label>Time Period</label>
                <div
                  className={`date-picker-input ${
                    lastValidDate && 'date-picker-input-hover'
                  }`}
                  role="button"
                  onClick={() => {
                    return (
                      lastValidDate &&
                      !focusedDateInput &&
                      setFocusedDateInput('startDate')
                    );
                  }}
                >
                  <DateRangePicker
                    customArrowIcon={<ArrowForwardIcon />}
                    disabled={lastValidDate === null}
                    displayFormat="MM / DD / YY"
                    endDate={selectedDates.endDate}
                    endDateId="sales_data_end_date"
                    focusedInput={focusedDateInput}
                    hideKeyboardShortcutsPanel
                    isDayBlocked={day => day.isAfter(lastValidDate)}
                    isOutsideRange={() => false}
                    minimumNights={0}
                    onDatesChange={({ startDate, endDate }) => {
                      formatAndUpdateSelectedDates({ startDate, endDate });
                      if (startDate && endDate) {
                        GAEvent({
                          category: 'Filters',
                          action: 'Date Range Selected',
                          label: `Client: ${currentClient.name}, ${new Date(
                            startDate.toString(),
                          ).toLocaleDateString()} - ${new Date(
                            endDate.toString(),
                          ).toLocaleDateString()}`,
                        });
                      }
                    }}
                    onFocusChange={setFocusedDateInput}
                    startDate={selectedDates.startDate}
                    startDateId="sales_data_start_date"
                    navNext={
                      <div className="DayPickerNavigation_button__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault">
                        <ArrowForwardIcon />
                      </div>
                    }
                    navPrev={
                      <div className="DayPickerNavigation_button__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault">
                        <ArrowBackIcon />
                      </div>
                    }
                  />
                </div>
                <IconButton
                  className="calendar-button"
                  onClick={() => setFocusedDateInput('startDate')}
                >
                  <DateRangeIcon color="inherit" />
                </IconButton>
              </div>
              <div className="input-wrapper header-input data-set-wrapper">
                <label>Viewing</label>
                <button
                  className="fake-input"
                  onClick={() => setOpenSelectCategoryModal(true)}
                >
                  {getSelectionText()} <FeatherBox />
                </button>
              </div>
              {page !== 'asinBreakdown' && (
                <div className="input-wrapper header-input compare-to-wrapper">
                  <label>Compare to previous</label>
                  <div className="button-group">
                    <Button
                      color="primary"
                      disableElevation
                      variant={
                        compareToPreviousType ===
                        CompareToPreviousTypeOptions.period
                          ? 'contained'
                          : 'outlined'
                      }
                      onClick={() =>
                        setCompareToPreviousType(
                          CompareToPreviousTypeOptions.period,
                        )
                      }
                    >
                      Period
                    </Button>
                    <Button
                      disableElevation
                      color="primary"
                      variant={
                        compareToPreviousType ===
                        CompareToPreviousTypeOptions.year
                          ? 'contained'
                          : 'outlined'
                      }
                      onClick={() =>
                        setCompareToPreviousType(
                          CompareToPreviousTypeOptions.year,
                        )
                      }
                      disabled={!yearEnabled}
                    >
                      Year
                    </Button>
                  </div>
                </div>
              )}
              {openSelectCategoryModal && (
                <CategorySelectModal
                  closeCallBack={() => setOpenSelectCategoryModal(false)}
                  open={openSelectCategoryModal}
                  previouslySelectedFilters={selectedFilters}
                  callBack={updateSelectedFilters}
                />
              )}
            </div>
          </Content>
        )}
        {!sidebarOpen && (
          <IconButton disableRipple onClick={() => setSidebarOpen(true)}>
            <ChevronRight color="inherit" />
          </IconButton>
        )}
      </HeaderNode>
    </ThemeProvider>
  );
};

export default Header;
