import React from 'react';
import { Link, LinkProps, useLocation, NavLink } from 'react-router-dom';

interface Props extends LinkProps {
  isNavLink?: boolean;
}

const LinkWithSearchParams: React.FC<Props> = ({
  children,
  isNavLink = false,
  to,
  ...otherProps
}) => {
  const search = useLocation().search;
  return isNavLink ? (
    <NavLink to={`${to}${search}`} {...otherProps}>
      {children}
    </NavLink>
  ) : (
    <Link to={`${to}${search}`} {...otherProps}>
      {children}
    </Link>
  );
};

export default LinkWithSearchParams;
