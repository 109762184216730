import React, { createContext, useContext, useState } from 'react';
import { Consumer, Factory } from '../../common/types';

export interface Context {
  contents: JSX.Element;
  open: boolean;
  setContents: Consumer<JSX.Element>;
  setOpen: Consumer<boolean>;
  setTitle: Consumer<string>;
  title: string;
}

export const ToastContext = createContext<Context>({
  contents: <></>,
  open: false,
  setContents: () => null,
  setOpen: () => null,
  setTitle: () => null,
  title: '',
});

export const ToastProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [contents, setContents] = useState<JSX.Element>(<></>);
  const [title, setTitle] = useState('');

  return (
    <ToastContext.Provider
      value={{
        contents,
        open,
        setContents,
        setOpen,
        setTitle,
        title,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export const useToast: Factory<Context> = () => useContext(ToastContext);
