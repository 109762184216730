export const dashboardRoutes = {
  asins: '/asins',
  finance: '/finance',
  inventory: '/inventory',
  marketing: '/marketing',
  reporting: '/reporting',
  sales: '/sales',
};

export const routes = {
  login: '/login',
  join: '/join/:token?',
  resetPassword: '/password_reset/:token?',
  account: '/account',
  history: '/history',
  dashboard: dashboardRoutes,
};

export const apiRoutes = {
  forgotPassword: '/forgot-password/{token}',
  graphQl: '/graphql',
  login: '/login_check',
  refreshToken: '/token/refresh',
};
