import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {
  CombinedDonutGraphData,
  LabeledDataPoint,
} from '../../../common/types';
import DonutGraph from '../../graphs/DonutGraph';
import LoadingIndicator from '../../LoadingIndicator';
import { Heading, ResultsList } from '../../dataSelect/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FullScreenModal from '../../fullScreenModal/FullScreenModal';

interface ChartBlockProps {
  colors: Array<string>;
  data: Array<LabeledDataPoint>;
  graphType: string;
  loading: boolean;
  onDataSliceSelect: (id: string) => void;
  selectedDataSlice: CombinedDonutGraphData;
  title: string;
  total: number;
}

const IconBtn = styled(IconButton)`
  position: absolute !important;
  top: 8px !important;
  right: 4px !important;
  color: #000 !important;
  &::hover {
    color: #000 !important;
  }
`;
const StyledMenu = styled(Menu)`
  ul {
    min-width: 207px !important;
    min-height: 66px !important;
    padding: 0 !important;
  }
  li {
    text-align: left !important;
    font: normal normal normal 13px/20px Montserrat !important;
    letter-spacing: 0px !important;
    color: #0d2d3d !important;
    opacity: 1 !important;
    height: 66px !important;
    padding-left: 25px !important;
  }
`;
const GraphDefinition: React.FC = () => (
  <>
    <Heading>
      <Typography variant="h1" className="modal-title">
        Division of sales revenue and units
      </Typography>
    </Heading>
    <ResultsList>
      <p>
        Division of sales revenue and units for all products selected during the
        time period. Drill down by selecting a category, or sub-category in the
        page header.
      </p>
    </ResultsList>
  </>
);

const ChartBlock: React.FC<ChartBlockProps> = ({
  colors,
  data,
  graphType,
  loading,
  onDataSliceSelect,
  selectedDataSlice,
  title,
  total,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [showDefinitionModal, setShowDefinitionModal] = useState(false);

  const handleMoreVertButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleViewDefinitionClick = () => {
    setShowDefinitionModal(true);
    handleMenuClose();
  };
  useEffect(() => {
    if (!loading && !selectedDataSlice?.id)
      data.length && onDataSliceSelect(data[0].id || '');
  });
  return (
    <Paper className="donut-chart donut-chart-section" elevation={0}>
      <h3 className="chart-title">{title}</h3>
      <IconBtn
        className="more-vert-icon"
        aria-controls="more-vert-menu"
        aria-haspopup="true"
        onClick={handleMoreVertButtonClick}
      >
        <MoreVertIcon color="inherit" />
      </IconBtn>
      <StyledMenu
        id="more-vert-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleViewDefinitionClick()}>
          Show Definition
        </MenuItem>
      </StyledMenu>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <DonutGraph
            colorScale={colors}
            data={data}
            graphType={graphType}
            onDataSliceSelect={onDataSliceSelect}
            selectedDataSlice={selectedDataSlice}
            total={total}
          />
          {data.length > 0 && title === 'Ordered Revenue' && (
            <h5>Click pie slice for details</h5>
          )}
        </>
      )}
      <FullScreenModal
        open={showDefinitionModal}
        onClose={() => setShowDefinitionModal(false)}
      >
        <GraphDefinition />
      </FullScreenModal>
    </Paper>
  );
};
export default ChartBlock;
