import { ENTRYPOINT } from '../config/entrypoint';

declare global {
  interface Window {
    _env_: {
      API_URL: string;
      GA_TRACKING_ID: string;
    };
  }
}

let apiRoot = ENTRYPOINT;
if (window._env_ && window._env_.API_URL) {
  apiRoot = window._env_.API_URL;
}
export default apiRoot;
