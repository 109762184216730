import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Heading, ResultsList } from '../dataSelect/styles';
import { fieldKeyMap } from '../sidebar/useSidebar';

const GlanceViewsDefinition: React.FC = () => (
  <>
    <Heading>
      <Typography variant="h1">Glance Views</Typography>
    </Heading>
    <ResultsList>
      <p>
        Glance views show how many times a product detail page has been viewed
        by shoppers.
      </p>
    </ResultsList>
  </>
);

const ConversionsDefinition: React.FC = () => (
  <>
    <Heading>
      <Typography variant="h1">Conversions</Typography>
    </Heading>
    <ResultsList>
      <p>Conversions</p>
    </ResultsList>
  </>
);

const ASPDefinition: React.FC = () => (
  <>
    <Heading>
      <Typography variant="h1">Average Sales Price</Typography>
    </Heading>
    <ResultsList>
      <p>
        Average Sales Price (ASP)- Average price per unit sold in selected time
        period.
      </p>
    </ResultsList>
  </>
);

const ConversionRateDefinition: React.FC = () => (
  <>
    <Heading>
      <Typography variant="h1">Conversion Rate</Typography>
    </Heading>
    <ResultsList>
      <p>
        Conversion Rate (CV) - % of sessions that resulted in a sale. Calculated
        by dividing orders by sessions.
      </p>
    </ResultsList>
  </>
);

const AOVDefinition: React.FC = () => (
  <>
    <Heading>
      <Typography variant="h1">Average Order Value</Typography>
    </Heading>
    <ResultsList>
      <p>
        Average Order Value (AOV) – revenue per transaction in the selected time
        period.
      </p>
    </ResultsList>
  </>
);

const BuyBoxDefinition: React.FC = () => (
  <>
    <Heading>
      <Typography variant="h1">Buy Box</Typography>
    </Heading>
    <ResultsList>
      <p>Buy Box % - The % of page views where the buy box was won.</p>
    </ResultsList>
  </>
);

export const SecondaryBreakdownDefinition: React.FC<{ fieldKey: string }> = ({
  fieldKey,
}) => {
  switch (fieldKey) {
    case fieldKeyMap.vendor.secondaryBreakdown1.key:
      return <GlanceViewsDefinition />;
    case fieldKeyMap.vendor.secondaryBreakdown2.key:
      return <ConversionsDefinition />;
    case fieldKeyMap.vendor.secondaryBreakdown3.key:
      return <ASPDefinition />;
    case fieldKeyMap.seller.secondaryBreakdown1.key:
      return <BuyBoxDefinition />;
    case fieldKeyMap.seller.secondaryBreakdown2.key:
      return <ConversionRateDefinition />;
    case fieldKeyMap.seller.secondaryBreakdown3.key:
      return <AOVDefinition />;
    default:
      return null;
  }
};
