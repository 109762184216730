import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Heading, ResultsList } from '../dataSelect/styles';
import { fieldKeyMap } from '../sidebar/useSidebar';

const OrderedRevenueDefinition: React.FC<{ fieldKey: string }> = ({
  fieldKey,
}) => (
  <>
    <Heading>
      <Typography variant="h1">Ordered Revenue</Typography>
    </Heading>
    <ResultsList>
      {fieldKey === fieldKeyMap.vendor.orderedRevenue && (
        <p>
          Gross revenue of products ordered by the consumer from Amazon.com.
          Calculated at the retail price.
        </p>
      )}
      {fieldKey === fieldKeyMap.seller.orderedRevenue && (
        <p>
          Gross revenue ordered by consumers during the selected time period.
          Returns are not subtracted from ordered revenue.
        </p>
      )}
    </ResultsList>
  </>
);

const ShippedCOGSDefinition: React.FC = () => (
  <>
    <Heading>
      <Typography variant="h1">Shipped Cost of Goods Sold</Typography>
    </Heading>
    <ResultsList>
      <p>
        Shipped Cost of Goods Sold (COGS) – Total products shipped to the
        consumer from Amazon, calculated at Amazon’s acquisition cost from the
        manufacturer.
      </p>
    </ResultsList>
  </>
);

const UnitsSoldDefinition: React.FC = () => (
  <>
    <Heading>
      <Typography variant="h1">Units Sold</Typography>
    </Heading>
    <ResultsList>
      <p>
        Units Sold – Total number of individual items sold to the consumer
        during the selected time period.
      </p>
    </ResultsList>
  </>
);

const SessionsDefinition: React.FC = () => (
  <>
    <Heading>
      <Typography variant="h1">Sessions</Typography>
    </Heading>
    <ResultsList>
      <p>
        Sessions – qty of unique customers who visited a product page during the
        selected time period. Multiple visits by the same customer in any 24
        hour period count as 1 session.
      </p>
    </ResultsList>
  </>
);

export const PrimaryBreakdownDefinition: React.FC<{ fieldKey: string }> = ({
  fieldKey,
}) => {
  switch (fieldKey) {
    case fieldKeyMap.vendor.orderedRevenue:
    case fieldKeyMap.seller.orderedRevenue:
      return <OrderedRevenueDefinition fieldKey={fieldKey} />;
    case fieldKeyMap.vendor.primaryBreakdown2.key:
      return <ShippedCOGSDefinition />;
    case fieldKeyMap.vendor.unitsSold:
    case fieldKeyMap.seller.unitsSold:
      return <UnitsSoldDefinition />;
    case fieldKeyMap.seller.primaryBreakdown2.key:
      return <SessionsDefinition />;
    default:
      return null;
  }
};
