import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
import { authTokenKey } from '../auth/useAuth';
import apiRoot from './apiRoot';

axios.defaults.headers.common[
  'Authorization'
] = `Bearer ${window.localStorage.getItem(authTokenKey)}`;

axios.defaults.baseURL = apiRoot;

const itemsPerPage = 25;

/* 
 This is not extensive but includes the only values used
 */
interface ResponseData {
  'hydra:member': { asinId: string }[];
  'hydra:totalItems': number;
}

export const getAsinsByFieldKeyDataAggregate: (arg: {
  client: string;
  endDate: string;
  fieldKey: string;
  page: number;
  selectedCategories: string[];
  selectedSubcategories: string[];
  selectedAsins: string[];
  sort: string;
  orderField: string;
  startDate: string;
}) => Promise<AxiosResponse<ResponseData>> = async ({
  client,
  endDate,
  fieldKey,
  page,
  selectedCategories,
  selectedSubcategories,
  selectedAsins,
  sort,
  orderField,
  startDate,
}) => {
  try {
    let order = {};
    switch (orderField) {
      case 'asin':
        order = { 'asin.asin': sort };
        break;
      case 'productName':
        order = { 'asin.productName': sort };
        break;
      case 'category':
        order = { category: sort };
        break;
      case 'subcategory':
        order = { subcategory: sort };
        break;
      default:
        order = { value: sort };
    }
    const response = await axios.get<ResponseData>(`/data_point_aggregates`, {
      params: {
        'fieldConfiguration.fieldKey': fieldKey,
        client,
        groupBy: 'asinCategorySubcategoryCombined',
        itemsPerPage: itemsPerPage,
        order: order,
        page,
        pagination: true,
        partial: false,
        resolution: { period: 'single', operation: 'sum' },
        timestamp: { after: startDate, before: endDate },
        ...(selectedCategories.length > 0 && {
          'asin.category[]': selectedCategories,
        }),
        ...(selectedSubcategories.length > 0 && {
          'asin.subcategory[]': selectedSubcategories,
        }),
        ...(selectedAsins.length > 0 && {
          'asin[]': selectedAsins,
        }),
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    });
    return response;
  } catch (e) {
    console.error(e);
    return Promise.resolve(e);
  }
};
