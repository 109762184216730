import React, { useMemo, useState } from 'react';
import { useQuery } from 'urql';
import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import InfoIcon from '@material-ui/icons/Info';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import LoadingIndicator from '../LoadingIndicator';
import CustomTooltip from '../Tooltip';
import { useHeader } from '../header/useHeader';
import { useClients } from '../../clients/useClients';
import { useSidebar } from '../sidebar/useSidebar';
import {
  getAsins,
  GetAsinsResponse,
  GetDataPointsAggregateResponse,
  getDataPointsAsinGrouped,
  getFilteredDataPoints,
} from '../../api/data';
import { BuyBoxSalesItem, DataType } from '../../common/types';
import { prettyPrintDate } from '../graphs/waterfall/utils';
import {
  transformOrderedRevenueTopFiveFilterData,
  transformDataToBuyBoxSales,
  transformTopFiveDataPointAggregateSecondaryASP,
  transformTwoPercentAsins,
} from '../dashboard/transformers';

import { withBuyBoxSalesStyles } from './styles';
import {
  prettyPrintNumber,
  prefixPositiveOrNegative,
} from '../../common/utils';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import FullScreenModal from '../fullScreenModal/FullScreenModal';
import { Heading, ResultsList } from '../dataSelect/styles';
import { SecondaryBreakdownDefinition } from './SecondaryBreakdownDefinitions';
import { dateFormat } from '../header/utils';

const Container = styled.section`
  ${withBuyBoxSalesStyles()}
  .change-right {
    padding: 8px !important;
  }
  .change-left {
    padding: 18px !important;
  }
  .change-info-left {
    justify-content: center !important;
    width: 89px !important;
  }
`;

const StyledMenu = styled(Menu)`
  ul {
    min-width: 207px !important;
    min-height: 66px !important;
    padding: 0 !important;
  }
  li {
    text-align: left !important;
    font: normal normal normal 13px/20px Montserrat !important;
    letter-spacing: 0px !important;
    color: #0d2d3d !important;
    opacity: 1 !important;
    height: 66px !important;
    padding-left: 25px !important;
  }
`;
interface BuyBoxSalesProps {
  title: string;
  items: Array<BuyBoxSalesItem>;
  isLoading: boolean;
  primaryValueType: DataType;
  primaryValueTitle: string;
  secondaryValueType?: DataType;
  secondaryValueTitle?: string;
  sortable?: boolean;
  modalDefinitionContent: React.ReactNode;
}

type SortDirection = 'ASC' | 'DESC';

const BuyBox: React.FC<BuyBoxSalesProps> = ({
  title,
  items,
  isLoading,
  primaryValueType,
  primaryValueTitle,
  secondaryValueType,
  secondaryValueTitle,
  sortable = false,
  modalDefinitionContent,
}) => {
  const boxId = title.replace(' ', '-');
  const [sortDirection, setSortDirection] = useState<SortDirection>('DESC');
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [showDefinitionModal, setShowDefinitionModal] = useState(false);
  const { previousPeriodDates, lastValidDate, isReady } = useHeader();
  let sortedItems = items;

  if (sortable) {
    sortedItems = items.sort((a, b) =>
      sortDirection !== 'DESC'
        ? (a.delta.rawValue ? a.delta.rawValue : 0) -
          (b.delta.rawValue ? b.delta.rawValue : 0)
        : (b.delta.rawValue ? b.delta.rawValue : 0) -
          (a.delta.rawValue ? a.delta.rawValue : 0),
    );
  }

  const deltaTitleTooltipText = (
    <div style={{ textAlign: 'center' }}>
      Change from <br />
      {prettyPrintDate(previousPeriodDates.startDate)} to{' '}
      {prettyPrintDate(previousPeriodDates.endDate)}
    </div>
  );

  const NoDataPlaceholder = () =>
    !isReady || isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="empty_response">
        There is no data for your selected date range or filters. Please try
        again.
      </div>
    );

  const handleMoreVertButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleViewDefinitionClick = () => {
    setShowDefinitionModal(true);
    handleMenuClose();
  };

  const filteredItems = () => {
    return sortedItems.filter(_item => {
      if (sortDirection === 'ASC' && _item.delta.rawValue !== undefined) {
        return _item.delta.rawValue < 0;
      } else {
        return _item.delta.rawValue !== undefined && _item.delta.rawValue >= 0;
      }
    }).length;
  };

  return (
    <Paper className="buy-box" elevation={0}>
      {isLoading || sortedItems.length === 0 || lastValidDate === null ? (
        <>
          <h3 className="box-title">{title}</h3>
          <NoDataPlaceholder />
        </>
      ) : (
        <div className="data-container">
          <h3 className="box-title">{title}</h3>
          <div className="action-buttons">
            {sortable && (
              <div className="sort-buttons-container">
                <IconButton
                  aria-label="sort descending"
                  component="span"
                  className={`sort-button desc ${
                    sortDirection === 'DESC' ? 'selected' : ''
                  }`}
                  onClick={() => setSortDirection('DESC')}
                >
                  <ArrowDownwardIcon />
                </IconButton>
                <IconButton
                  aria-label="sort ascending"
                  component="span"
                  className={`sort-button asc ${
                    sortDirection === 'ASC' ? 'selected' : ''
                  }`}
                  onClick={() => setSortDirection('ASC')}
                >
                  <ArrowUpwardIcon />
                </IconButton>
              </div>
            )}
            <IconButton
              className="context-menu-button"
              color="primary"
              aria-label="context menu"
              aria-controls="more-vert-menu"
              aria-haspopup="true"
              onClick={handleMoreVertButtonClick}
              component="span"
            >
              <MoreVertIcon />
            </IconButton>
            <StyledMenu
              id="more-vert-menu"
              anchorEl={menuAnchorEl}
              keepMounted
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleViewDefinitionClick()}>
                Show Definition
              </MenuItem>
            </StyledMenu>
          </div>
          <TableContainer className="box-items">
            {filteredItems() === 0 ? (
              <div className="empty_response">
                There is no data for your selected date range or filters. Please
                try again.
              </div>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell
                      className={`change-info${
                        !secondaryValueTitle ? ' change-info-left' : ''
                      }`}
                    >
                      Change&nbsp;
                      <CustomTooltip title={deltaTitleTooltipText} arrow>
                        <InfoIcon className="info-icon" />
                      </CustomTooltip>
                    </TableCell>
                    <TableCell className="bold">{primaryValueTitle}</TableCell>
                    {secondaryValueTitle && (
                      <TableCell className="bold">
                        {secondaryValueTitle}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedItems
                    .filter(_item => {
                      if (
                        sortDirection === 'ASC' &&
                        _item.delta.rawValue !== undefined
                      ) {
                        return _item.delta.rawValue < 0;
                      } else {
                        return (
                          _item.delta.rawValue !== undefined &&
                          _item.delta.rawValue >= 0
                        );
                      }
                    })
                    .slice(0, 5)
                    .map((item, index) => (
                      <TableRow key={`${boxId}-${item.index}`}>
                        <TableCell className="index-badge-column">
                          <div className="index-badge">
                            {sortable ? index + 1 : item.index}
                          </div>
                        </TableCell>
                        <TableCell>{item.label}</TableCell>
                        <TableCell
                          className={`bold right ${
                            secondaryValueTitle ? 'change-right' : 'change-left'
                          } ${
                            primaryValueTitle === 'LBB%'
                              ? item.delta.change === 'positive'
                                ? 'negative'
                                : 'positive'
                              : item.delta.change
                          }_delta`}
                        >
                          {item.delta.percentage !== '--' &&
                            prefixPositiveOrNegative(item.delta.change)}
                          {item.delta.percentage.trim().replace('-', '')}
                        </TableCell>
                        <TableCell className="bold right">
                          {prettyPrintNumber(
                            item.primaryValue,
                            primaryValueType,
                          )}
                        </TableCell>
                        {secondaryValueTitle && (
                          <TableCell className="bold right">
                            {typeof item.secondaryValue === 'number'
                              ? isNaN(item.secondaryValue)
                                ? '--'
                                : prettyPrintNumber(
                                    item.secondaryValue,
                                    secondaryValueType,
                                  )
                              : null}
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </div>
      )}
      <FullScreenModal
        open={showDefinitionModal}
        onClose={() => setShowDefinitionModal(false)}
      >
        {modalDefinitionContent}
      </FullScreenModal>
    </Paper>
  );
};

const SalesDefinition: React.FC = () => (
  <>
    <Heading>
      <Typography variant="h1">Top Performing Sales</Typography>
    </Heading>
    <ResultsList>
      <p>
        Top Performing Sales / Bottom Performing Sales – Shows best or worst
        performers during the selected time period. Click the up or down arrow
        to change view between best and worst.
      </p>
    </ResultsList>
  </>
);

const BuyBoxSales: React.FC = () => {
  const { selectedFilters, selectedDates, previousPeriodDates } = useHeader();
  const { currentClient } = useClients();
  const { fieldKeys } = useSidebar();
  const pauseQueries: boolean = useMemo(
    () =>
      currentClient.id === '' ||
      selectedDates.startDate === null ||
      selectedDates.endDate === null,
    [currentClient, selectedDates],
  );
  const commonQueryVariables = {
    client_id: currentClient.id,
    asin_id_list: selectedFilters
      .filter(filter => filter.type === 'asin')
      .map(filter => filter.id),
    asin_category_list: selectedFilters
      .filter(filter => filter.type === 'category')
      .map(filter => filter.id),
    asin_subcategory_list: selectedFilters
      .filter(filter => filter.type === 'subcategory')
      .map(filter => filter.id),
    aggregateOperation: 'sum',
  };

  const shouldNotRenderSection = commonQueryVariables.asin_id_list.length === 1;

  const [getLastYearOrderedRevenue] = useQuery<GetDataPointsAggregateResponse>({
    query: getFilteredDataPoints,
    variables: {
      ...commonQueryVariables,
      fieldConfiguration_fieldKey: fieldKeys.buyBoxSales.key,
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate
              .clone()
              .subtract(1, 'years')
              .format(dateFormat),
      endDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate
              .clone()
              .subtract(1, 'days')
              .format(dateFormat),
      aggregateOperation: 'sum',
      client_id: currentClient.id,
    },
    pause: pauseQueries,
  });
  const [getLastThreeMonthsOrderedRevenue] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPoints,
    variables: {
      ...commonQueryVariables,
      fieldConfiguration_fieldKey: fieldKeys.buyBoxSales.key,
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate
              .clone()
              .subtract(3, 'months')
              .format(dateFormat),
      endDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate
              .clone()
              .subtract(1, 'days')
              .format(dateFormat),
      aggregateOperation: 'sum',
      client_id: currentClient.id,
    },
    pause: pauseQueries,
  });

  const [getLastYearOrderedRevenueAsins] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      ...commonQueryVariables,
      fieldConfiguration_fieldKey: fieldKeys.buyBoxSales.key,
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate
              .clone()
              .subtract(1, 'years')
              .format(dateFormat),
      endDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate
              .clone()
              .subtract(1, 'days')
              .format(dateFormat),
      aggregateOperation: 'sum',
      client_id: currentClient.id,
      order: 'desc',
    },
    pause: pauseQueries,
  });
  const [getLastThreeMonthsOrderedRevenueAsins] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      ...commonQueryVariables,
      fieldConfiguration_fieldKey: fieldKeys.buyBoxSales.key,
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate
              .clone()
              .subtract(3, 'months')
              .format(dateFormat),
      endDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate
              .clone()
              .subtract(1, 'days')
              .format(dateFormat),
      aggregateOperation: 'sum',
      client_id: currentClient.id,
      order: 'desc',
    },
    pause: pauseQueries,
  });

  const asinList = transformTwoPercentAsins(
    getLastYearOrderedRevenue,
    getLastYearOrderedRevenueAsins,
    getLastThreeMonthsOrderedRevenue,
    getLastThreeMonthsOrderedRevenueAsins,
  );
  const [getTopFiveAsinsDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      fieldConfiguration_fieldKey: fieldKeys.buyBox.key,
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      order: 'desc',
      aggregateOperation: null,
      asin_id_list: asinList,
    },
    pause: pauseQueries || asinList.length === 0,
  });

  const [getTopFiveAsinsPreviousDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      fieldConfiguration_fieldKey: fieldKeys.buyBox.key,
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      asin_id_list: asinList,
      asin_category_list: null,
      asin_subcategory_list: null,
      aggregateOperation: null,
    },
    pause:
      pauseQueries ||
      getTopFiveAsinsDataResponse.fetching ||
      asinList.length === 0,
  });

  const [getAsinsResponse] = useQuery<GetAsinsResponse>({
    query: getAsins,
    variables: {
      asin_id_list: asinList,
    },
    pause:
      pauseQueries ||
      getTopFiveAsinsDataResponse.fetching ||
      asinList.length === 0 ||
      (getTopFiveAsinsDataResponse.data &&
        getTopFiveAsinsDataResponse.data.dataPointAggregates &&
        getTopFiveAsinsDataResponse.data.dataPointAggregates.edges &&
        getTopFiveAsinsDataResponse.data.dataPointAggregates.edges.length ===
          0),
  });

  const [getSalesTopFiveAsinsDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      fieldConfiguration_fieldKey: fieldKeys.buyBoxSales?.key,
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      order: 'desc',
      aggregateOperation: null,
      asin_id_list: asinList,
    },
    pause: pauseQueries,
  });

  const [getSalesTopFiveAsinsPreviousDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      fieldConfiguration_fieldKey: fieldKeys.buyBoxSales?.key,
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      asin_id_list: asinList,
      asin_category_list: null,
      asin_subcategory_list: null,
      aggregateOperation: null,
    },
    pause: pauseQueries || getSalesTopFiveAsinsDataResponse.fetching,
  });

  const [getSalesAsinsResponse] = useQuery<GetAsinsResponse>({
    query: getAsins,
    variables: {
      asin_id_list: asinList,
    },
    pause:
      pauseQueries ||
      getSalesTopFiveAsinsDataResponse.fetching ||
      asinList.length === 0 ||
      (getSalesTopFiveAsinsDataResponse.data &&
        getSalesTopFiveAsinsDataResponse.data.dataPointAggregates &&
        getSalesTopFiveAsinsDataResponse.data.dataPointAggregates.edges &&
        getSalesTopFiveAsinsDataResponse.data.dataPointAggregates.edges
          .length === 0),
  });

  const [getTopFiveAsinsPrimaryDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      order: 'desc',
      aggregateOperation: 'sum',
      fieldConfiguration_fieldKey: fieldKeys.buyBox.key,
      asin_id_list: asinList,
    },
    pause: pauseQueries || asinList.length === 0,
  });

  const [getTopFiveAsinsSecondaryDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      order: 'desc',
      aggregateOperation: 'sum',
      asin_id_list: asinList,
      fieldConfiguration_fieldKey: fieldKeys.buyBox.secondKey,
    },
    pause: pauseQueries || asinList.length === 0,
  });

  const [getTopFiveAsinsPreviousPrimaryDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      asin_id_list: asinList,
      asin_category_list: null,
      asin_subcategory_list: null,
      aggregateOperation: 'sum',
      fieldConfiguration_fieldKey: fieldKeys.buyBox.key,
    },
    pause:
      pauseQueries ||
      getTopFiveAsinsPrimaryDataResponse.fetching ||
      asinList.length === 0,
  });

  const [getTopFiveAsinsPreviousSecondaryDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      asin_id_list: asinList,
      asin_category_list: null,
      asin_subcategory_list: null,
      aggregateOperation: 'sum',
      fieldConfiguration_fieldKey: fieldKeys.buyBox.secondKey,
    },
    pause:
      pauseQueries ||
      getTopFiveAsinsSecondaryDataResponse.fetching ||
      asinList.length === 0,
  });

  const topFiveBuyBoxFilterData = transformTopFiveDataPointAggregateSecondaryASP(
    getTopFiveAsinsPrimaryDataResponse,
    getTopFiveAsinsSecondaryDataResponse,
    getTopFiveAsinsPreviousPrimaryDataResponse,
    getTopFiveAsinsPreviousSecondaryDataResponse,
    'asin',
    fieldKeys.buyBox?.type,
    getAsinsResponse,
    fieldKeys.buyBox?.key === 'lost_buy_box',
  );
  const topFiveSalesFilterData = transformOrderedRevenueTopFiveFilterData(
    getSalesTopFiveAsinsDataResponse,
    getSalesTopFiveAsinsPreviousDataResponse,
    'asin',
    fieldKeys.buyBoxSales?.type,
    getSalesAsinsResponse,
  );
  const wipBuyBoxItems =
    asinList.length === 0
      ? []
      : transformDataToBuyBoxSales(topFiveBuyBoxFilterData)
          .filter(_item => Math.abs(_item.delta.rawValue as number) > 5)
          .filter(_item => _item.primaryValue > 0.009);

  let wipBuyBoxSalesItems: BuyBoxSalesItem[] = [];
  if (asinList.length !== 0) {
    wipBuyBoxSalesItems = transformDataToBuyBoxSales(
      topFiveBuyBoxFilterData,
      topFiveSalesFilterData,
    )
      .filter(_item => Math.abs(_item.delta.rawValue as number) > 5)
      .filter(_item => _item.primaryValue > 0.009);
  }

  const isBuyBoxLoading =
    getTopFiveAsinsDataResponse.fetching ||
    getTopFiveAsinsPreviousDataResponse.fetching ||
    getAsinsResponse.fetching ||
    getLastYearOrderedRevenue.fetching ||
    getLastThreeMonthsOrderedRevenue.fetching ||
    getLastYearOrderedRevenueAsins.fetching ||
    getLastThreeMonthsOrderedRevenueAsins.fetching ||
    getTopFiveAsinsPrimaryDataResponse.fetching ||
    getTopFiveAsinsSecondaryDataResponse.fetching ||
    getTopFiveAsinsPreviousPrimaryDataResponse.fetching ||
    getTopFiveAsinsPreviousSecondaryDataResponse.fetching;
  const isSalesLoading =
    getSalesTopFiveAsinsDataResponse.fetching ||
    getSalesTopFiveAsinsPreviousDataResponse.fetching ||
    getSalesAsinsResponse.fetching ||
    getLastYearOrderedRevenue.fetching ||
    getLastThreeMonthsOrderedRevenue.fetching ||
    getLastYearOrderedRevenueAsins.fetching ||
    getLastThreeMonthsOrderedRevenueAsins.fetching ||
    getTopFiveAsinsPrimaryDataResponse.fetching ||
    getTopFiveAsinsSecondaryDataResponse.fetching ||
    getTopFiveAsinsPreviousPrimaryDataResponse.fetching ||
    getTopFiveAsinsPreviousSecondaryDataResponse.fetching;
  return (
    <Container>
      {!shouldNotRenderSection && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <BuyBox
              sortable
              title={fieldKeys.buyBox.title}
              items={wipBuyBoxItems}
              isLoading={isBuyBoxLoading}
              primaryValueType={fieldKeys.buyBox.type}
              primaryValueTitle={
                fieldKeys.buyBox.key === 'lost_buy_box' ? 'LBB%' : 'BB%'
              }
              key={fieldKeys.buyBox.key}
              modalDefinitionContent={
                <SecondaryBreakdownDefinition fieldKey={fieldKeys.buyBox.key} />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <BuyBox
              sortable
              key={fieldKeys.buyBoxSales.key}
              title={fieldKeys.buyBoxSales?.title || ''}
              items={wipBuyBoxSalesItems}
              isLoading={isSalesLoading}
              primaryValueType="dollar amount"
              primaryValueTitle="Revenue"
              secondaryValueType={fieldKeys.buyBox.type}
              secondaryValueTitle={
                fieldKeys.buyBox.key === 'lost_buy_box' ? 'LBB%' : 'BB%'
              }
              modalDefinitionContent={<SalesDefinition />}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default BuyBoxSales;
