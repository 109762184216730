import React, { useState } from 'react';
import styled from '@emotion/styled';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import Toast from './toast/Toast';
import { withGlobalAppStyles } from '../common/theme';
import { useSidebar } from './sidebar/useSidebar';

const Container = styled.div<{ scrolled: boolean; sidebarOpen: boolean }>`
  ${p => withGlobalAppStyles(p.sidebarOpen, p.scrolled)};
`;

const Page: React.FC<{ className?: string; title: string; page?: string }> = ({
  children,
  className,
  title,
  page,
}) => {
  const { open: sidebarOpen } = useSidebar();
  const [mainScrollTop, setMainScrollTop] = useState(0);
  return (
    <Container
      className={className}
      scrolled={mainScrollTop > 0}
      sidebarOpen={sidebarOpen}
    >
      <Sidebar />
      <main>
        <Header title={title} compact={mainScrollTop > 0} page={page} />
        <section
          className="body"
          onScroll={e => {
            const target = e.target as Element;
            setMainScrollTop(target.scrollTop);
          }}
        >
          {children}
        </section>
        <Toast />
      </main>
    </Container>
  );
};

export default Page;
