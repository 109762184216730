import React from 'react';
import { themeColors } from '../common/theme';

interface Props {
  color?: string;
}
const FlagWithCircle: React.FC<Props> = ({ color = themeColors.cyprus }) => (
  <svg
    id="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g
      id="Ellipse_272"
      data-name="Ellipse 272"
      fill="none"
      stroke={color}
      strokeWidth="1"
    >
      <circle cx="16" cy="16" r="16" stroke="none" />
      <circle cx="16" cy="16" r="15.5" fill="none" />
    </g>
    <g
      id="Icon_feather-flag"
      data-name="Icon feather-flag"
      transform="translate(10.4 9)"
    >
      <path
        id="Path_390"
        data-name="Path 390"
        d="M6,12.75S6.75,12,9,12s3.75,1.5,6,1.5,3-.75,3-.75v-9s-.75.75-3,.75S11.25,3,9,3s-3,.75-3,.75Z"
        transform="translate(-6 -3)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Path_391"
        data-name="Path 391"
        d="M6,27.75V22.5"
        transform="translate(-6 -12.75)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </g>
  </svg>
);

export default FlagWithCircle;
