import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { createMuiTheme, Theme } from '@material-ui/core';
import DefaultSelect from '@material-ui/core/Select';
import DefaultListItem from '@material-ui/core/ListItem';
import { themeColors, themeGradient } from '../../common/theme';
import { drawerWidth } from '../../common/constants';

export const sidebarTheme: (theme: Theme) => Theme = theme =>
  createMuiTheme({
    ...theme,
    overrides: {
      MuiCssBaseline: {
        '@global': {
          html: {
            background: themeGradient(5),
          },
        },
      },
      MuiDrawer: {
        paper: {
          padding: '40px 16px 0px 16px',
          width: `${drawerWidth}px`,
          background: themeGradient(5),
          border: 'none',
          overflowX: 'hidden',
          zIndex: 1299,
        },
      },
      MuiIconButton: {
        root: {
          color: themeColors.white,
          background: themeColors.cyprus,
          padding: '4px',
        },
      },
      MuiInputBase: {
        root: {
          color: themeColors.white,
          fontSize: '13px',
          fontWeight: 'bold',
          marginBottom: '13px',
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: `${themeColors.hippieBlue}!important`,
          borderWidth: '1px !important',
        },
        input: {
          padding: '16px 16px 13px',
        },
      },
      MuiSelect: {
        icon: {
          color: themeColors.white,
        },
      },
      MuiPaper: {
        root: {
          border: `1px solid ${themeColors.hippieBlue}`,
          borderTop: 'none',
          backgroundColor: '#005770',
          color: themeColors.white,
          boxShadow: 'none',
        },
        rounded: {
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0',
        },
        elevation1: {
          boxShadow: 'none',
        },
      },
      MuiMenu: {
        list: {
          maxHeight: '398px',
          overflow: 'auto',
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '13px',
          '&:hover': {
            backgroundColor: themeColors.sherpaBlue,
          },
        },
      },
      MuiAccordion: {
        root: {
          width: '100%',
          backgroundColor: 'transparent',
          border: 'none',
          margin: '0 !important',
          '&:before': {
            height: '0',
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          minHeight: '45px !important',
          '&:hover p': {
            fontWeight: 'bold !important',
          },
        },
        expandIcon: {
          backgroundColor: 'transparent',
        },
        content: {
          margin: '0 !important',
        },
      },
      MuiAccordionDetails: {
        root: {
          padding: '0',
        },
      },
      MuiTypography: {
        body1: {
          fontSize: '13px',
          '&:hover': {
            fontWeight: 'bold',
          },
        },
        body2: {
          cursor: 'pointer',
          '&:hover': {
            fontWeight: 'bold',
          },
        },
        root: {
          '&.version_number': {
            background: '#054d62 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            color: '#dde7e6',
            marginLeft: '-14px',
            padding: '8px 14px',
            '&:hover': {
              fontWeight: 'normal',
            },
          },
        },
      },
      MuiList: {
        root: {
          padding: '0',
          width: '100%',
        },
        padding: {
          paddingTop: '0',
          paddingBottom: '0',
        },
      },
      MuiListItem: {
        root: {
          color: themeColors.white,
          marginTop: '4px',
          marginBottom: '10px',
          paddingLeft: '16px !important',
        },
        gutters: {
          margin: '0',
          padding: '0 16px',
          height: '44px',
        },
      },
      MuiButton: {
        root: {
          '&:not(:last-child)': {
            marginRight: '8px',
          },
        },
        contained: {
          borderRadius: '13px',
          height: '25px',
          textTransform: 'none',
          fontSize: '12px',
          fontWeight: 'normal',
          backgroundColor: themeColors.blackSqueeze,
          color: themeColors.cyprus,
          '&:hover': {
            color: themeColors.blackSqueeze,
            backgroundColor: themeColors.cyprus,
          },
        },
        outlined: {
          borderRadius: '13px',
          height: '25px',
          textTransform: 'none',
          fontSize: '12px',
          fontWeight: 'normal',
          backgroundColor: 'transparent',
          color: themeColors.white,
          border: 'none',
          padding: '6px 0',
          minWidth: '25px',
          '&:hover': {
            color: themeColors.cyprus,
            backgroundColor: themeColors.blackSqueeze,
          },
        },
      },
      MuiChip: {
        root: {
          height: '25px !important',
          padding: '6px 16px',
        },
        outlined: {
          height: '25px  !important',
          width: '25px',
          marginRight: '6px',
          padding: '0 !important',
          color: '#7b9aa6',
          border: 'none',
        },
        label: {
          display: 'flex',
          paddingLeft: '0px !important',
          paddingRight: '0px !important',
          justifyContent: 'center',
          fontSize: '12px',
        },
      },
    },
  });

export const Top = styled.div`
  width: calc(100% + 32px);
  margin-left: -16px;
  padding: 0 16px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid ${themeColors.hippieBlue};
`;

export const WordmarkRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  padding-left: 16px;
`;

export const Select = styled(DefaultSelect)<{ menuOpen: boolean }>`
  fieldset {
    transition: border 100ms ease-in-out;
  }
  ${p =>
    p.menuOpen &&
    css`
      border-width: 1px !important;
      fieldset {
        border-bottom: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    `}
`;

export const StyledListItem = styled(DefaultListItem)`
  :hover {
    background-color: transparent !important;
    a {
      p {
        font-weight: bold !important;
      }
    }
  }
  a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    &:hover {
      &:not(.active) {
        p {
          font-weight: bold;
        }
      }
    }
    &.active:hover {
      p {
        font-weight: 400 !important;
      }
    }
    &.active {
      p {
        position: relative;
        display: inline;
        &:after {
          position: absolute;
          display: block;
          content: '';
          width: 6px;
          height: 6px;
          background-color: ${themeColors.neonCarrot};
          right: -24px;
          top: 7px;
          border-radius: 50%;
        }
      }
    }
  }
`;

export const DataToggleWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 0;
`;
