import React from 'react';
import { VictoryLabel } from 'victory';
import { themeColors } from '../../../common/theme';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const StackLabel: React.Component = props => {
  const regex = /\n(.*)/;
  let secondLine = '';
  let secondLineColor = themeColors.successGreen;
  const match = regex.exec(props.text);
  if (match) {
    secondLine = match[1];
  }
  if (secondLine.length > 0 && secondLine[0] === '-') {
    secondLineColor = themeColors.errorRed;
  }
  return (
    <VictoryLabel
      {...props}
      backgroundPadding={[{ bottom: 5 }, { bottom: 10 }]}
      style={[
        {
          fill: themeColors.cyprus,
          fontSize: '12px',
          fontFamily: 'Montserrat, Helvetica, sans-serif',
          fontWeight: 'bold',
        },
        {
          fill: secondLineColor,
          fontSize: '12px',
          fontFamily: 'Montserrat, Helvetica, sans-serif',
        },
      ]}
    />
  );
};

export default StackLabel;
