import { DataType, Line } from '../../common/types';
import { YAxis, YAxisProps } from './types';

export const getNormalizedYValue: (
  axes: YAxis[],
  type: DataType,
  value: number,
) => number = (axes, type, value) => {
  const axis = axes.find(axis => axis.type === type);
  if (axis !== undefined && axis.max > 0) {
    return value / axis.max;
  }
  return 0;
};

export const getMemoizedYAxes: (
  lines: Line[],
  primaryYAxis: YAxisProps,
  secondaryYAxis: YAxisProps,
) => YAxis[] = (lines, primaryYAxis, secondaryYAxis) => {
  const axes: YAxis[] = [];
  const primaryYAxisData = lines.filter(
    line => line.dataType === primaryYAxis.type,
  );
  const secondaryYAxisData = lines.filter(
    line => line.dataType === secondaryYAxis.type,
  );
  if (primaryYAxisData.length > 0) {
    axes.push({
      label: primaryYAxis.label,
      max: Math.max(
        ...primaryYAxisData.flatMap(line =>
          line.data.map(datum => datum.y).filter(_y => isFinite(_y)),
        ),
      ),
      orientation: 'left',
      type: primaryYAxis.type,
    });
  }
  if (secondaryYAxisData.length > 0) {
    axes.push({
      label: secondaryYAxis.label,
      max: Math.max(
        ...secondaryYAxisData.flatMap(line =>
          line.data.map(datum => datum.y).filter(_y => isFinite(_y)),
        ),
      ),
      orientation: 'right',
      type: secondaryYAxis.type,
    });
  }
  return axes;
};
