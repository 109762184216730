import { useEffect } from 'react';
import { History } from 'history';
import { Consumer, FilterItem, NullableMoment } from '../../common/types';
import { Client } from '../../api/client';
import { DateRange } from '../graphs/types';
import { CompareToPreviousTypeOptions } from './useHeader';
import {
  syncCompareToPreviousTypeWithUrl,
  syncCurrentClientWithUrl,
  syncSelectedDatesWithUrl,
  syncSelectedFiltersWithUrl,
} from './utils';
import { useClients } from '../../clients/useClients';

export enum urlFilterParams {
  startDate = 'sd',
  endDate = 'ed',
  compareToPrevious = 'ctp',
  client = 'cl',
  asin = 'asin',
  filters = 'filters',
}

interface useUrlSyncArgumentObject {
  clearFilters: boolean;
  clients: Client[];
  compareToPreviousType: CompareToPreviousTypeOptions;
  currentClient: Client;
  history: History;
  lastValidDate: NullableMoment | boolean;
  params: URLSearchParams;
  selectedDates: DateRange;
  selectedFilters: FilterItem[];
  setCompareToPreviousType: Consumer<CompareToPreviousTypeOptions>;
  setCurrentClient: Consumer<string>;
  setSelectedFilters: Consumer<FilterItem[]>;
  updateSelectedDates: Consumer<DateRange>;
}

const useUrlSync: Consumer<useUrlSyncArgumentObject> = ({
  clearFilters,
  clients,
  compareToPreviousType,
  currentClient,
  history,
  lastValidDate,
  params,
  selectedDates,
  selectedFilters,
  setCompareToPreviousType,
  setCurrentClient,
  setSelectedFilters,
  updateSelectedDates,
}) => {
  const { changingClient } = useClients();
  useEffect(() => {
    syncCurrentClientWithUrl({
      clients,
      currentClient,
      history,
      params,
      setCurrentClient,
    });
  }, [clients, currentClient, history, params, setCurrentClient]);

  useEffect(() => {
    if (changingClient) return;
    syncSelectedDatesWithUrl({
      lastValidDate,
      history,
      params,
      selectedDates,
      updateSelectedDates,
    });
  }, [
    lastValidDate,
    history,
    params,
    selectedDates,
    updateSelectedDates,
    changingClient,
  ]);

  useEffect(() => {
    syncCompareToPreviousTypeWithUrl({
      compareToPreviousType,
      history,
      params,
      setCompareToPreviousType,
    });
  }, [compareToPreviousType, history, params, setCompareToPreviousType]);

  useEffect(() => {
    syncSelectedFiltersWithUrl({
      clearFilters,
      history,
      params,
      selectedFilters,
      setSelectedFilters,
    });
  }, [clearFilters, history, params, selectedFilters, setSelectedFilters]);
};

export default useUrlSync;
