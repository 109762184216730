import React from 'react';
import { themeColors } from '../common/theme';

interface Props {
  color?: string;
}
const FeatherBox: React.FC<Props> = ({ color = themeColors.cyprus }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.828"
    height="17.085"
    viewBox="0 0 15.828 17.085"
  >
    <g
      id="Icon_feather-box"
      data-name="Icon feather-box"
      transform="translate(-3.336 -1.977)"
    >
      <path
        id="Path_316"
        data-name="Path 316"
        d="M18,13.5v-6a1.5,1.5,0,0,0-.75-1.3L12,3.2a1.5,1.5,0,0,0-1.5,0l-5.25,3A1.5,1.5,0,0,0,4.5,7.5v6a1.5,1.5,0,0,0,.75,1.3l5.25,3a1.5,1.5,0,0,0,1.5,0l5.25-3A1.5,1.5,0,0,0,18,13.5Z"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_317"
        data-name="Path 317"
        d="M4.905,10.44l6.547,3.787L18,10.44"
        transform="translate(-0.203 -3.718)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_318"
        data-name="Path 318"
        d="M18,25.56V18"
        transform="translate(-6.75 -7.498)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default FeatherBox;
