import Sales from './Sales';
import AsinBreakdown from './asinBreakdown/AsinBreakdown';

import { routes } from '../../common/routes';

interface DashboardRoute {
  label: string;
  route: string;
  component: React.FC;
}

const dashboardRoutes: DashboardRoute[] = [
  {
    label: 'Sales',
    route: routes.dashboard.sales,
    component: Sales,
  },
  {
    label: 'ASIN Breakdown',
    route: routes.dashboard.asins,
    component: AsinBreakdown,
  },
];

export default dashboardRoutes;
