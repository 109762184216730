import React, { useMemo, useState } from 'react';
import { useQuery } from 'urql';
import styled from '@emotion/styled';
import DefaultAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Change, DataType } from '../../common/types';
import { useClients } from '../../clients/useClients';
import { useHeader } from '../header/useHeader';
import {
  getAsins,
  GetAsinsResponse,
  GetDataPointsAggregateResponse,
  getDataPointsAsinGrouped,
  getDataPointsSubcategoryGrouped,
  getFilteredDataPoints,
  getFilteredDataPointsWaterfallGraph,
} from '../../api/data';
import LoadingIndicator from '../LoadingIndicator';
import {
  transformDataPointAggregateSecondaryASP,
  transformTopFiveDataPointAggregateSecondaryASP,
} from '../dashboard/transformers';
import { withAccordionOverrides, withSecondaryBreakdownStyles } from './styles';
import CustomTooltip from '../Tooltip';
import { prettyPrintDate } from '../graphs/waterfall/utils';
import FilterList from './FilterList';
import FullScreenModal from '../fullScreenModal/FullScreenModal';
import { SecondaryBreakdownDefinition } from './SecondaryBreakdownDefinitions';
import { dateFormat } from '../header/utils';
import { prefixPositiveOrNegative } from '../../common/utils';

const Breakdown = styled.div`
  ${withSecondaryBreakdownStyles()}
  min-height: 85px !important;
`;

const Accordion = styled(DefaultAccordion)`
  ${withAccordionOverrides(true)}
`;

const IconBtn = styled(IconButton)`
  position: absolute !important;
  top: 8px !important;
  right: 4px !important;
  color: #000 !important;
  &::hover {
    color: #000 !important;
  }
`;
const StyledMenu = styled(Menu)`
  ul {
    min-width: 207px !important;
    min-height: 66px !important;
    padding: 0 !important;
  }
  li {
    text-align: left !important;
    font: normal normal normal 13px/20px Montserrat !important;
    letter-spacing: 0px !important;
    color: #0d2d3d !important;
    opacity: 1 !important;
    height: 66px !important;
    padding-left: 25px !important;
  }
`;

export interface SecondaryBreakdownData {
  delta: {
    change: Change;
    percentage: string;
  };
  error?: boolean;
  processing: boolean;
  value: string;
}

export interface Props {
  fieldKey: string;
  secondaryFieldKey: string;
  link: string;
  title: string;
  valueType: DataType;
}

const ASPSecondaryBreakdown: React.FC<Props> = ({
  fieldKey,
  secondaryFieldKey,
  title,
  valueType,
}) => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [showDefinitionModal, setShowDefinitionModal] = useState(false);

  const { currentClient } = useClients();

  const {
    allFilters,
    selectedFilters,
    selectedDates,
    previousPeriodDates,
    isReady,
  } = useHeader();

  const pauseQueries: boolean = useMemo(
    () =>
      currentClient.id === '' ||
      selectedDates.startDate === null ||
      selectedDates.endDate === null,
    [currentClient, selectedDates],
  );

  const commonQueryVariables = {
    client_id: currentClient.id,
    asin_id_list: selectedFilters
      .filter(filter => filter.type === 'asin')
      .map(filter => filter.id),
    asin_category_list: selectedFilters
      .filter(filter => filter.type === 'category')
      .map(filter => filter.id),
    asin_subcategory_list: selectedFilters
      .filter(filter => filter.type === 'subcategory')
      .map(filter => filter.id),
    aggregateOperation: 'sum',
  };

  const [getTopFiveCategoryRevenueDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsWaterfallGraph,
    variables: {
      ...commonQueryVariables,
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      order: 'desc',
      fieldConfiguration_fieldKey: fieldKey,
    },
    pause: pauseQueries || (selectedFilters.length !== 0 && !allFilters),
  });

  const [getTopFiveCategoryUnitsDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsWaterfallGraph,
    variables: {
      ...commonQueryVariables,
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      order: 'desc',
      fieldConfiguration_fieldKey: secondaryFieldKey,
      asin_category_list:
        getTopFiveCategoryRevenueDataResponse.data &&
        getTopFiveCategoryRevenueDataResponse.data.dataPointAggregates &&
        getTopFiveCategoryRevenueDataResponse.data.dataPointAggregates.edges
          ? getTopFiveCategoryRevenueDataResponse.data.dataPointAggregates.edges
              .slice(0, 5)
              .map(edge => edge.node.categoryId)
          : [],
    },
    pause:
      pauseQueries ||
      (selectedFilters.length !== 0 && !allFilters) ||
      getTopFiveCategoryRevenueDataResponse.fetching,
  });

  const [getTopFivePreviousCategoryRevenueDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsWaterfallGraph,
    variables: {
      ...commonQueryVariables,
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      order: 'desc',
      asin_id_list: null,
      fieldConfiguration_fieldKey: fieldKey,
      asin_subcategory_list: null,
      asin_category_list:
        getTopFiveCategoryRevenueDataResponse.data &&
        getTopFiveCategoryRevenueDataResponse.data.dataPointAggregates &&
        getTopFiveCategoryRevenueDataResponse.data.dataPointAggregates.edges
          ? getTopFiveCategoryRevenueDataResponse.data.dataPointAggregates.edges
              .slice(0, 5)
              .map(edge => edge.node.categoryId)
          : [],
    },
    pause:
      pauseQueries ||
      (selectedFilters.length !== 0 && !allFilters) ||
      getTopFiveCategoryRevenueDataResponse.fetching,
  });

  const [getTopFivePreviousCategoryUnitsDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsWaterfallGraph,
    variables: {
      ...commonQueryVariables,
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      order: 'desc',
      asin_id_list: null,
      asin_subcategory_list: null,
      fieldConfiguration_fieldKey: secondaryFieldKey,
      asin_category_list:
        getTopFiveCategoryUnitsDataResponse.data &&
        getTopFiveCategoryUnitsDataResponse.data.dataPointAggregates &&
        getTopFiveCategoryUnitsDataResponse.data.dataPointAggregates.edges
          ? getTopFiveCategoryUnitsDataResponse.data.dataPointAggregates.edges
              .slice(0, 5)
              .map(edge => edge.node.categoryId)
          : [],
    },
    pause:
      pauseQueries ||
      (selectedFilters.length !== 0 && !allFilters) ||
      getTopFiveCategoryUnitsDataResponse.fetching,
  });

  const [getTopFiveSubCategoryRevenueDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsSubcategoryGrouped,
    variables: {
      ...commonQueryVariables,
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      order: 'desc',
      asin_id_list: null,
      asin_subcategory_list: null,
      aggregateOperation: null,
      fieldConfiguration_fieldKey: fieldKey,
    },
    pause:
      pauseQueries ||
      allFilters ||
      selectedFilters.length === 0 ||
      selectedFilters[0].type !== 'category',
  });

  const [getTopFiveSubCategoryUnitsDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsSubcategoryGrouped,
    variables: {
      ...commonQueryVariables,
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      order: 'desc',
      asin_id_list: null,
      asin_subcategory_list:
        getTopFiveSubCategoryRevenueDataResponse.data &&
        getTopFiveSubCategoryRevenueDataResponse.data.dataPointAggregates &&
        getTopFiveSubCategoryRevenueDataResponse.data.dataPointAggregates.edges
          ? getTopFiveSubCategoryRevenueDataResponse.data.dataPointAggregates.edges
              .slice(0, 5)
              .map(edge => edge.node.subcategoryId)
          : [],
      aggregateOperation: null,
      fieldConfiguration_fieldKey: secondaryFieldKey,
    },
    pause:
      pauseQueries ||
      allFilters ||
      selectedFilters.length === 0 ||
      selectedFilters[0].type !== 'category' ||
      getTopFiveSubCategoryRevenueDataResponse.fetching ||
      (getTopFiveSubCategoryRevenueDataResponse.data &&
        getTopFiveSubCategoryRevenueDataResponse.data.dataPointAggregates &&
        getTopFiveSubCategoryRevenueDataResponse.data.dataPointAggregates
          .edges &&
        getTopFiveSubCategoryRevenueDataResponse.data.dataPointAggregates.edges
          .length === 0),
  });

  const [getTopFivePreviousSubCategoryRevenueDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsSubcategoryGrouped,
    variables: {
      ...commonQueryVariables,
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      order: 'desc',
      fieldConfiguration_fieldKey: fieldKey,
      asin_subcategory_list:
        getTopFiveSubCategoryRevenueDataResponse.data &&
        getTopFiveSubCategoryRevenueDataResponse.data.dataPointAggregates &&
        getTopFiveSubCategoryRevenueDataResponse.data.dataPointAggregates.edges
          ? getTopFiveSubCategoryRevenueDataResponse.data.dataPointAggregates.edges
              .slice(0, 5)
              .map(edge => edge.node.subcategoryId)
          : [],
      asin_id_list: null,
      asin_category_list: null,
      aggregateOperation: null,
    },
    pause:
      pauseQueries ||
      allFilters ||
      selectedFilters.length === 0 ||
      selectedFilters[0].type !== 'category' ||
      getTopFiveSubCategoryRevenueDataResponse.fetching ||
      (getTopFiveSubCategoryRevenueDataResponse.data &&
        getTopFiveSubCategoryRevenueDataResponse.data.dataPointAggregates &&
        getTopFiveSubCategoryRevenueDataResponse.data.dataPointAggregates
          .edges &&
        getTopFiveSubCategoryRevenueDataResponse.data.dataPointAggregates.edges
          .length === 0),
  });

  const [getTopFivePreviousSubCategoryUnitsDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsSubcategoryGrouped,
    variables: {
      ...commonQueryVariables,
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      order: 'desc',
      fieldConfiguration_fieldKey: secondaryFieldKey,
      asin_subcategory_list:
        getTopFiveSubCategoryUnitsDataResponse.data &&
        getTopFiveSubCategoryUnitsDataResponse.data.dataPointAggregates &&
        getTopFiveSubCategoryUnitsDataResponse.data.dataPointAggregates.edges
          ? getTopFiveSubCategoryUnitsDataResponse.data.dataPointAggregates.edges
              .slice(0, 5)
              .map(edge => edge.node.subcategoryId)
          : [],
      asin_id_list: null,
      asin_category_list: null,
      aggregateOperation: null,
    },
    pause:
      pauseQueries ||
      allFilters ||
      selectedFilters.length === 0 ||
      selectedFilters[0].type !== 'category' ||
      getTopFiveSubCategoryUnitsDataResponse.fetching ||
      (getTopFiveSubCategoryUnitsDataResponse.data &&
        getTopFiveSubCategoryUnitsDataResponse.data.dataPointAggregates &&
        getTopFiveSubCategoryUnitsDataResponse.data.dataPointAggregates.edges &&
        getTopFiveSubCategoryUnitsDataResponse.data.dataPointAggregates.edges
          .length === 0),
  });

  const [getTopFiveAsinsRevenueDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      ...commonQueryVariables,
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      order: 'desc',
      aggregateOperation: null,
      fieldConfiguration_fieldKey: fieldKey,
    },
    pause:
      pauseQueries ||
      selectedFilters.length === 0 ||
      !['subcategory', 'asin'].includes(selectedFilters[0].type),
  });

  const [getTopFiveAsinsUnitsDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      ...commonQueryVariables,
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      order: 'desc',
      aggregateOperation: null,
      asin_id_list:
        getTopFiveAsinsRevenueDataResponse.data &&
        getTopFiveAsinsRevenueDataResponse.data.dataPointAggregates &&
        getTopFiveAsinsRevenueDataResponse.data.dataPointAggregates.edges
          ? getTopFiveAsinsRevenueDataResponse.data.dataPointAggregates.edges
              .slice(0, 5)
              .map(edge => edge.node.asinId)
          : [],
      fieldConfiguration_fieldKey: secondaryFieldKey,
    },
    pause:
      pauseQueries ||
      selectedFilters.length === 0 ||
      !['subcategory', 'asin'].includes(selectedFilters[0].type),
  });

  const [getTopFiveAsinsPreviousRevenueDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      ...commonQueryVariables,
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      asin_id_list:
        getTopFiveAsinsRevenueDataResponse.data &&
        getTopFiveAsinsRevenueDataResponse.data.dataPointAggregates &&
        getTopFiveAsinsRevenueDataResponse.data.dataPointAggregates.edges
          ? getTopFiveAsinsRevenueDataResponse.data.dataPointAggregates.edges
              .slice(0, 5)
              .map(edge => edge.node.asinId)
          : [],
      asin_category_list: null,
      asin_subcategory_list: null,
      aggregateOperation: null,
      fieldConfiguration_fieldKey: fieldKey,
    },
    pause:
      pauseQueries ||
      selectedFilters.length === 0 ||
      !['subcategory', 'asin'].includes(selectedFilters[0].type) ||
      getTopFiveAsinsRevenueDataResponse.fetching,
  });

  const [getTopFiveAsinsPreviousUnitsDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      ...commonQueryVariables,
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      asin_id_list:
        getTopFiveAsinsUnitsDataResponse.data &&
        getTopFiveAsinsUnitsDataResponse.data.dataPointAggregates &&
        getTopFiveAsinsUnitsDataResponse.data.dataPointAggregates.edges
          ? getTopFiveAsinsUnitsDataResponse.data.dataPointAggregates.edges
              .slice(0, 5)
              .map(edge => edge.node.asinId)
          : [],
      asin_category_list: null,
      asin_subcategory_list: null,
      aggregateOperation: null,
      fieldConfiguration_fieldKey: secondaryFieldKey,
    },
    pause:
      pauseQueries ||
      selectedFilters.length === 0 ||
      !['subcategory', 'asin'].includes(selectedFilters[0].type) ||
      getTopFiveAsinsUnitsDataResponse.fetching,
  });

  const asinFilterList =
    selectedFilters
      .filter(filter => filter.type === 'asin')
      .map(filter => filter.id).length > 0
      ? selectedFilters
          .filter(filter => filter.type === 'asin')
          .map(filter => filter.id)
      : getTopFiveAsinsRevenueDataResponse.data &&
        getTopFiveAsinsRevenueDataResponse.data.dataPointAggregates &&
        getTopFiveAsinsRevenueDataResponse.data.dataPointAggregates.edges
      ? getTopFiveAsinsRevenueDataResponse.data.dataPointAggregates.edges
          .slice(0, 5)
          .map(edge => edge.node.asinId)
      : [];

  const [getAsinsResponse] = useQuery<GetAsinsResponse>({
    query: getAsins,
    variables: {
      asin_id_list: asinFilterList,
    },
    pause:
      pauseQueries ||
      getTopFiveAsinsRevenueDataResponse.fetching ||
      asinFilterList.length === 0 ||
      (selectedFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id).length > 0
        ? false
        : getTopFiveAsinsRevenueDataResponse.data &&
          getTopFiveAsinsRevenueDataResponse.data.dataPointAggregates &&
          getTopFiveAsinsRevenueDataResponse.data.dataPointAggregates.edges &&
          getTopFiveAsinsRevenueDataResponse.data.dataPointAggregates.edges
            .length === 0),
  });

  const [orderedRevenueResponse] = useQuery<GetDataPointsAggregateResponse>({
    query: getFilteredDataPoints,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      ...commonQueryVariables,
      fieldConfiguration_fieldKey: fieldKey,
    },
    pause: pauseQueries,
  });

  const [orderedUnitsResponse] = useQuery<GetDataPointsAggregateResponse>({
    query: getFilteredDataPoints,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      ...commonQueryVariables,
      fieldConfiguration_fieldKey: secondaryFieldKey,
    },
    pause: pauseQueries,
  });

  const [previousOrderedRevenueResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPoints,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      ...commonQueryVariables,
      fieldConfiguration_fieldKey: fieldKey,
    },
    pause: pauseQueries,
  });

  const [previousOrderedUnitsResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPoints,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      ...commonQueryVariables,
      fieldConfiguration_fieldKey: secondaryFieldKey,
    },
    pause: pauseQueries,
  });

  const data = transformDataPointAggregateSecondaryASP(
    orderedRevenueResponse,
    orderedUnitsResponse,
    previousOrderedRevenueResponse,
    previousOrderedUnitsResponse,
    valueType,
  );
  const loading =
    !isReady ||
    data.processing ||
    orderedRevenueResponse.fetching ||
    orderedUnitsResponse.fetching ||
    previousOrderedRevenueResponse.fetching ||
    previousOrderedUnitsResponse.fetching;

  const deltaTitleTooltipText = (
    <div style={{ textAlign: 'center' }}>
      Change from <br />
      {prettyPrintDate(previousPeriodDates.startDate)} to{' '}
      {prettyPrintDate(previousPeriodDates.endDate)}
    </div>
  );

  const handleMoreVertButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleViewDefinitionClick = () => {
    setShowDefinitionModal(true);
    handleMenuClose();
  };
  const singleAsinSelected = () =>
    selectedFilters?.length === 1 && selectedFilters[0]?.type === 'asin';

  const ASPTop5Data = transformTopFiveDataPointAggregateSecondaryASP(
    selectedFilters.length === 0 || allFilters
      ? getTopFiveCategoryRevenueDataResponse
      : selectedFilters[0].type === 'category'
      ? getTopFiveSubCategoryRevenueDataResponse
      : getTopFiveAsinsRevenueDataResponse,
    selectedFilters.length === 0 || allFilters
      ? getTopFiveCategoryUnitsDataResponse
      : selectedFilters[0].type === 'category'
      ? getTopFiveSubCategoryUnitsDataResponse
      : getTopFiveAsinsUnitsDataResponse,
    selectedFilters.length === 0 || allFilters
      ? getTopFivePreviousCategoryRevenueDataResponse
      : selectedFilters[0].type === 'category'
      ? getTopFivePreviousSubCategoryRevenueDataResponse
      : getTopFiveAsinsPreviousRevenueDataResponse,
    selectedFilters.length === 0 || allFilters
      ? getTopFivePreviousCategoryUnitsDataResponse
      : selectedFilters[0].type === 'category'
      ? getTopFivePreviousSubCategoryUnitsDataResponse
      : getTopFiveAsinsPreviousUnitsDataResponse,
    selectedFilters.length === 0 || allFilters
      ? 'category'
      : selectedFilters[0].type === 'category'
      ? 'subcategory'
      : 'asin',
    valueType,
    getAsinsResponse,
  );
  return (
    <Breakdown className={accordionExpanded ? 'expanded' : ''}>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="title">{title}</div>
          <div className="data">
            {data.error ? (
              'No data available'
            ) : (
              <>
                <IconBtn
                  className="more-vert-icon"
                  aria-controls="more-vert-menu"
                  aria-haspopup="true"
                  onClick={handleMoreVertButtonClick}
                >
                  <MoreVertIcon color="inherit" />
                </IconBtn>
                <StyledMenu
                  id="more-vert-menu"
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={() => handleViewDefinitionClick()}>
                    Show Definition
                  </MenuItem>
                </StyledMenu>
                <div className="delta_row">
                  <div className="total">{data.value}</div>
                  <div className="delta secondary">
                    <div className="delta_data">
                      <span className="delta_title">Change</span>
                      <CustomTooltip title={deltaTitleTooltipText} arrow>
                        <InfoIcon className="info-icon" />
                      </CustomTooltip>{' '}
                      <span
                        className={`change-percentage ${
                          fieldKey === 'lost_buy_box' || fieldKey === 'rep_oos'
                            ? data.delta.change === 'positive'
                              ? 'negative'
                              : 'positive'
                            : data.delta.change
                        }_delta`}
                      >
                        {data.delta.percentage !== '--' &&
                          prefixPositiveOrNegative(data.delta.change)}
                        {data.delta.percentage.trim().replace('-', '')}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {(!data.error &&
            !singleAsinSelected() &&
            data.value !== '--' &&
            ASPTop5Data.data.length && (
              <Accordion
                elevation={0}
                expanded={accordionExpanded}
                onChange={() => setAccordionExpanded(!accordionExpanded)}
              >
                <AccordionSummary
                  className="breakdown_summary_container"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <span className="breakdown_summary">
                    Top{' '}
                    {selectedFilters.length === 0 || allFilters
                      ? 'Categories'
                      : selectedFilters[0].type === 'category'
                      ? 'Subcategories'
                      : 'ASINs'}
                  </span>
                  <span className="breakdown_summary">
                    {accordionExpanded ? 'Collapse' : 'Expand'}
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <FilterList
                    {...ASPTop5Data}
                    reverse={
                      fieldKey === 'lost_buy_box' || fieldKey === 'rep_oos'
                    }
                  />
                </AccordionDetails>
              </Accordion>
            )) ||
            null}
          <FullScreenModal
            open={showDefinitionModal}
            onClose={() => setShowDefinitionModal(false)}
          >
            <SecondaryBreakdownDefinition fieldKey={fieldKey} />
          </FullScreenModal>
        </>
      )}
    </Breakdown>
  );
};

export default ASPSecondaryBreakdown;
