import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { routes } from '../common/routes';
import { Consumer, Factory, Runnable } from '../common/types';
import { useGraphQLClient } from '../components/CustomGraphQLProvider';
import { get } from '../api/restApi';

export interface Context {
  authToken: string;
  refreshToken: string;
  logout: Runnable;
  setToken: (token: string) => void;
  setRefreshAuthToken: (refreshToken: string) => void;
  username: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  roles: string[];
  userId: string;
  validating: boolean;
  validToken: boolean;
  versionNumber: string;
}

export const authTokenKey = 'token';
export const refreshTokenKey = 'refresh_token';

export const AuthContext = createContext<Context>({
  authToken: '',
  refreshToken: '',
  logout: () => null,
  setToken: () => {},
  setRefreshAuthToken: () => {},
  username: '',
  fullName: '',
  firstName: '',
  lastName: '',
  roles: [],
  userId: '',
  validating: true,
  validToken: false,
  versionNumber: '',
});

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useProvidedAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth: Factory<Context> = () => useContext(AuthContext);

const getDefaultAuthToken: Factory<string> = () => {
  const token = window.localStorage.getItem(authTokenKey);
  return token || '';
};

const getDefaultRefreshToken: Factory<string> = () => {
  const token = window.localStorage.getItem(refreshTokenKey);
  return token || '';
};

const defaultValidating: boolean = getDefaultAuthToken() !== '';

const useProvidedAuth: Factory<Context> = () => {
  const [authToken, setAuthToken] = useState(getDefaultAuthToken());
  const [refreshToken, setRefreshToken] = useState(getDefaultRefreshToken());
  const [validating, setValidating] = useState(defaultValidating);
  const [validToken, setTokenValidity] = useState(false);
  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [roles, setRoles] = useState<string[]>([]);
  const [userId, setUserId] = useState('');
  const [versionNumber, setVersionNumber] = useState('');

  const history = useHistory();

  const { clearCache } = useGraphQLClient();

  const setToken: Consumer<string> = token => {
    if (token === '') {
      window.localStorage.removeItem(authTokenKey);
    } else {
      window.localStorage.setItem(authTokenKey, token);
    }
    setAuthToken(token);
  };

  const setRefreshAuthToken: Consumer<string> = token => {
    if (token === '') {
      window.localStorage.removeItem(refreshTokenKey);
    } else {
      window.localStorage.setItem(refreshTokenKey, token);
    }
    setRefreshToken(token);
  };

  const logout: Runnable = () => {
    window.localStorage.clear();
    clearCache();
    window.location.reload(true);
    history.push(routes.login);
  };

  useEffect(() => {
    if (authToken === '') {
      setTokenValidity(false);
    } else {
      const decoded = jwt_decode<{
        exp: number;
        iat: number;
        username: string;
        roles: string[];
        uid: string;
        fullName: string;
        versionNumber: string;
      }>(authToken);
      const expiration = decoded.exp * 1000;
      const now = new Date();
      const current = now.getTime();
      const isValid = expiration > current;
      setUsername(decoded.username);
      setUserId(`/users/${decoded.uid}`);
      setVersionNumber(decoded.versionNumber);
      setTokenValidity(isValid);
      setRoles(decoded.roles);
      if (!isValid) {
        window.localStorage.removeItem(authTokenKey);
        window.localStorage.removeItem(refreshTokenKey);
      }
    }
    setValidating(false);
  }, [authToken]);

  const getUserDetails = useCallback(async () => {
    const result = await (await get(userId, authToken)).json();
    if (result) {
      await setFullName(result.fullName || '');
      await setFirstName(result.firstName || '');
      await setLastName(result.lastName || '');
    }
  }, [userId, authToken]);

  useEffect(() => {
    if (userId) getUserDetails();
  }, [userId, getUserDetails]);

  return {
    authToken,
    refreshToken,
    logout,
    setToken,
    setRefreshAuthToken,
    username,
    roles,
    userId,
    validating,
    validToken,
    fullName,
    firstName,
    lastName,
    versionNumber,
  };
};
