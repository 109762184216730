import React from 'react';

interface Props {
  color: string;
  left: boolean;
  dotted: boolean;
}
const Arrow: React.FC<Props> = ({ color, dotted, left }) => {
  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Path 3</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        {left && !dotted && (
          <polyline
            id="Path-3"
            stroke={color}
            points="6.21111305 0 1 5.27774477 11.0051372 5.27774477 11.0051372 5.79862543 1 5.79862543 6.21111305 11"
          />
        )}
        {left && dotted && (
          <g
            id="Group-3"
            transform="translate(5.500000, 5.500000) rotate(-180.000000) translate(-5.500000, -5.500000) "
            stroke={color}
          >
            <polyline
              id="Path-3"
              transform="translate(7.605557, 5.500000) rotate(-180.000000) translate(-7.605557, -5.500000) "
              points="10.2111131 0 5 5.27774477 5 5.79862543 10.2111131 11"
            />

            <g
              id="Group-2"
              transform="translate(0.000000, 4.000000)"
              strokeWidth="2"
              strokeDasharray="2"
            >
              <path d="M0.985306817,1.5 L9.5,1.5" id="Line-3" />
            </g>
          </g>
        )}
        {!left && !dotted && (
          <polyline
            id="Path-3"
            stroke={color}
            transform="translate(6.419492, 6.245414) rotate(-180.000000) translate(-6.419492, -6.245414) "
            points="6.6280369 0.745413934 1.41692384 6.02315871 11.422061 6.02315871 11.422061 6.54403936 1.41692384 6.54403936 6.6280369 11.7454139"
          />
        )}
        {!left && dotted && (
          <g
            id="Group-3"
            transform="translate(0.000000, 1.000000)"
            stroke={color}
          >
            <polyline
              id="Path-3"
              transform="translate(7.605557, 5.500000) rotate(-180.000000) translate(-7.605557, -5.500000) "
              points="10.2111131 0 5 5.27774477 5 5.79862543 10.2111131 11"
            />

            <g
              id="Group-2"
              transform="translate(0.000000, 4.000000)"
              strokeWidth="2"
              strokeDasharray="2"
            >
              <path d="M0.985306817,1.5 L9.5,1.5" id="Line-3" />
            </g>
          </g>
        )}
      </g>
    </svg>
  );
};

export default Arrow;
