import { css, SerializedStyles } from '@emotion/core';
import { Factory } from '../../common/types';
import { themeColors } from '../../common/theme';

export const withPrimaryBreakdownStyles: Factory<SerializedStyles> = () => css`
  min-height: 150px;
  padding: 17px 16px 5px;
  &.expanded {
    padding: 17px 16px 0 !important;
  }

  .title {
    margin-bottom: 12px;
  }
  .total {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 55px;
    letter-spacing: 3.29px;
    text-align: left;
    color: #0d2d3d;
    opacity: 1;
  }
  .menu-button {
    text-align: left !important;
    font: normal normal normal 13px/20px Montserrat !important;
    letter-spacing: 0px !important;
    color: #0d2d3d !important;
    opacity: 1 !important;
  }

  .data {
    height: 3.1em;
    .total {
      font-size: 45px;
      font-family: BebasNeue-Regular;
    }

    .delta {
      .delta_title {
        font-size: 13px;
        margin-bottom: 8px;

        .info-icon {
          font-size: 1.25em;
          vertical-align: bottom;
          color: ${themeColors.concrete};
        }
      }
    }
  }
  .accordian-button {
    color: #0d2d3d;
  }
`;

export const withSecondaryBreakdownStyles: Factory<SerializedStyles> = () => css`
  min-height: 115px;
  padding: 16px 16px 5px;
  &.expanded {
    padding: 16px 16px 0px !important;
  }
  .title {
    margin-bottom: 16px;
  }
  .total {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 16px;
    text-align: left;
    font: normal normal bold 13px/20px Montserrat;
    letter-spacing: 0.52px;
    color: #0d2d3d;
    text-transform: uppercase;
    opacity: 1;
  }
  .data {
    height: 1em;
    justify-content: left !important;
    .total {
      width: 50% !important;
      font-family: Montserrat;
      font-size: 13px;
      font-weight: bold;
    }
    .delta_row {
      width: 100%;
      height: fit-content;
      display: flex;
    }
    .delta {
      width: 50% !important;
      border-left: 1px solid ${themeColors.gainsboro};
      padding: 0 !important;
      .delta_data {
        width: 100% !important;
        margin: auto;
        padding-left: 5px;
        white-space: nowrap;
        // justify-content: center !important;
        .positive_delta {
          width: 140px;
        }
        .negative_delta {
          width: 140px;
        }
      }
      .delta_title {
        font-size: 13px;
        font-weight: normal;
        padding-left: 9%;
        margin-right: 3px;
      }
      .info-icon {
        font-size: 1.25em;
        vertical-align: bottom;
        color: ${themeColors.concrete};
      }
    }
  }
  .accordian-button {
    color: #0d2d3d;
  }
  .change-percentage {
    margin-right: auto;
    margin-left: 10px;
    width: fit-content !important;
  }
  .MuiAccordionSummary-root {
    padding: 0px 16px 0 12px;
  }
  .secondary {
    .delta_data {
      padding-right: 10px;
    }
  }
`;

export const withAccordionOverrides: (
  donut: boolean,
) => SerializedStyles = donut => css`
  width: calc(100% + 24px);
  ${donut
    ? 'margin-left: -12px;  &.MuiAccordion-root.Mui-expanded {margin: 0 0 0 -12px;}'
    : ''}

  &::before {
    display: none;
  }
  .MuiAccordionSummary-root {
    // margin-bottom: -12px;
    min-height: 45px !important;
    &.Mui-expanded {
      min-height: 45px;
    }
    svg {
      height: 0.75em;
      width: 0.75em;
    }
  }
  .MuiIconButton-edgeEnd {
    margin-right: -12px !important;
  }
  .MuiAccordionSummary-content {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: 12px;
    margin: 0 !important;

    &.Mui-expanded {
      min-height: 45px;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0 12px;
    display: block;
    .breadcrumbs {
      font-style: italic;
      margin-left: 20px;
      font-size: 12px;
      margin-bottom: 12px;
    }
    ul {
      &.top-categories-list {
        font-size: 13px;
      }

      width: 100%;
      li {
        align-items: center;
        background: #f0f0f0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 6px 0;
        padding: 12px;
        width: 100%;

        &.top-category-item {
          border-radius: 4px;
          padding: 15px;

          &:last-child {
            margin-bottom: 18px;
          }
        }

        .positive_delta {
          color: green;
        }

        .negative_delta {
          color: #d80000;
        }

        .category-name {
          width: 55%;
        }

        .category-delta {
          font-weight: bold;
          text-align: left;
          width: 25%;
          padding-left: 2.5%;
        }
        @media (max-width: 1024px) {
          .category-delta {
            padding-right: 5%;
          }
        }
        .category-total {
          font-weight: bold;
          text-align: right;
          width: 25%;
        }
      }
    }
  }
  .breakdown-row:hover {
    cursor: pointer;
    background: #f0f0f0;
  }
  .clickable-table-cell {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 3px 0;
  }
`;

export const withDonutChartBreakdownStyles: Factory<SerializedStyles> = () => css`
  width: 100%;
  background: #fff;
  border-radius: 16px;
  border: 1px solid ${themeColors.concrete};
  padding: 16px;
  min-height: 240px;

  .charts-wrapper {
    width: 100%;
    min-height: 325px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .MuiPaper-root {
      width: 49.5%;
      min-height: 350px;
      border: 1px solid ${themeColors.concrete};
      border-radius: 14px;
    }
  }

  .donut-chart-section {
    text-align: center;
    position: relative;

    h3 {
      text-align: left;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 0.52px;
      color: ${themeColors.cyprus};
      margin-top: 24px;
      margin-left: 40px;
    }

    &.donut-slice-breakdown {
      padding: 16px 32px 0;

      .slice-breakdown-title {
        font-size: 13px;
        color: ${themeColors.cyprus};
        font-weight: bold;
      }
      .accordian-button {
        color: #0d2d3d;
      }
      .slice-breakdown-table {
        border: 1px solid;
        border-radius: 8px;
        border-color: ${themeColors.concrete};
        margin-bottom: 1em;
        table {
          .MuiTableCell-head {
            font-weight: bold;
          }

          .MuiTableCell-body,
          .MuiTableCell-head {
            font-size: 13px;
          }

          .data-slice-color {
            margin-right: 8px;
          }

          .positive_delta {
            color: ${themeColors.successGreen};
          }

          .negative_delta {
            color: ${themeColors.errorRed};
          }

          .border-left {
            border-left: 1px solid;
            border-color: ${themeColors.concrete};
          }
        }
      }

      .slice-breakdown-item {
        font-size: 12px;
        line-height: 1.5em;
        text-align: left;
        padding: 0 0 1em;

        .data-slice-color {
          width: 0.75em;
          height: 0.75em;
          border-radius: 50%;
          float: left;
          margin-top: 0.35em;

          &.selected {
            -webkit-box-shadow: 0px 0px 0px 3px rgba(184, 184, 184, 1);
            -moz-box-shadow: 0px 0px 0px 3px rgba(184, 184, 184, 1);
            box-shadow: 0px 0px 0px 3px rgba(184, 184, 184, 1);
          }
        }

        .data-slice-label {
          font-weight: bold;
        }

        .data-slice-label,
        .data-slice-details,
        .show-all-slices {
          margin-left: 1.5em;
        }

        .show-all-slices {
          button {
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
            font-size: 1em;
          }
        }
      }
    }

    .graph-container {
      position: relative;

      .selected-slice-details {
        position: absolute;
        width: 100%;
        margin: 45% 35%;
        top: 0;
        line-height: 1.25em;
        text-align: left;
        font-size: 13px;
        font-weight: bold;
      }
    }

    .chart-titles-section {
      padding: 16px 32px 0;

      .chart-title,
      .values-total {
        text-align: left;
      }
    }
  }
`;

export const withBuyBoxSalesStyles: Factory<SerializedStyles> = () => css`
  width: 100%;
  .buy-box {
    display: flex;
    flex-direction: column;
    padding: 2px 16px 16px;
    border-radius: 16px;
    border: 1px solid ${themeColors.concrete};
    height: 100% !important;
    .MuiTableContainer-root {
      overflow-x: unset;
    }
    .action-buttons {
      float: right;

      .sort-buttons-container {
        float: left;

        .sort-button {
          border: solid 1px ${themeColors.cyprus};
          padding: 0;
          margin: 11px 6px;
          height: 22.5px !important;
          width: 22.5px !important;
          .MuiIconButton-label {
            height: 22.5px !important;
            width: 22.5px !important;
            justify-content: center !important;
          }
          color: ${themeColors.cyprus};
          svg {
            height: 13.5px !important;
            width: 13.5px !important;
          }

          &.selected {
            border-color: ${themeColors.errorRed};
            background: ${themeColors.errorRed}2b;
            color: ${themeColors.errorRed};

            &.asc {
              color: ${themeColors.successGreen};
              border-color: ${themeColors.successGreen};
              background: ${themeColors.successGreen}2b;
            }
          }
        }
      }
      .context-menu-button {
        &:hover {
          background-color: transparent;
        }

        float: right;
        padding: 11px 0;
        margin-right: -10px !important;
      }
    }
    .MuiTableCell-head {
      text-align: right !important;
      padding-right: 0px !important;
    }
    .box-items {
      .change-info {
        min-width: 75px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        text-anchor: end;
      }
      .MuiTable-root {
        .MuiTableHead-root {
          .MuiTableCell-head {
            font-size: 13px;
            color: ${themeColors.cyprus};
            padding: 5px;
            border: none;
            position: relative;
          }
        }

        .MuiTableBody-root {
          .MuiTableCell-body {
            font-size: 13px;
            padding: 15px 0 15px 15px;
            color: ${themeColors.cyprus};

            &.positive_delta {
              color: ${themeColors.successGreen};
            }

            &.negative_delta {
              color: ${themeColors.errorRed};
            }

            .index-badge {
              background: ${themeColors.blackSqueeze} 0% 0% no-repeat
                padding-box;
              border-radius: 50%;
              width: 19px;
              height: 19px;
              text-align: center;
              font-size: 12px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
      .MuiTableRow-root:last-child {
        .MuiTableCell-root {
          border-bottom: none !important;
        }
      }
      .index-badge-column {
        vertical-align: top !important;
        padding-top: 16px !important;
        padding-left: 0px !important;
      }
      .bold {
        font-weight: bold;
      }

      .right {
        text-align: right;
      }

      .info-icon {
        font-size: 1.25em;
        vertical-align: sub;
        color: ${themeColors.concrete};
        margin-top: -10px !important;
        position: absolute;
        right: 0;
      }
    }

    .box-title {
      float: left;
      margin: 15px 0;
      color: ${themeColors.cyprus};
      font-size: 13px;
      text-transform: uppercase;
      height: 16px;
      letter-spacing: 0.52px;
      display: flex;
      align-items: flex-end;
    }

    .empty_response {
      min-height: 75px;
      text-align: center;
      width: 100%;
      align-items: center;
      display: flex;
      padding: 15px;
      background-color: #f2f2f2;
      border-radius: 16px;
      flex-grow: 1;
      font-size: 13px;
      color: #0d2d3d;
      justify-content: center;
      align-items: center;
      height: calc(100% - 50px);
      display: flex;
    }
    .box-items,
    .data-container {
      height: 100%;
    }
  }
`;
