import React, { useState } from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryPortal,
  VictoryStack,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import styled from '@emotion/styled';
import { themeColors } from '../../../common/theme';
import { Factory } from '../../../common/types';
import { prettyPrintNumber } from '../../../common/utils';
import LoadingIndicator from '../../LoadingIndicator';
import {
  transformDataToWaterfallBreakdown,
  transformDataToWaterfallGraph,
} from '../transformers';
import { noDataMessage } from '../constants';
import useWaterfallGraphData from './useWaterfallGraphData';
import StackLabel from './StackLabel';
import XAxisLabel from './XAxisLabel';
import GradientDefinition from './GradientDefinition';
import { Container, waterfallGraphColors } from './styles';
import { getTickValuesFromMaxValue, prettyPrintDate } from './utils';
import { prettyPrintLongformCurrency } from '../../dashboard/asinBreakdown/utils';
import WaterfallBreakdownTable from './WaterfallBreakdownTable';
import FullScreenModal from '../../fullScreenModal/FullScreenModal';
import { Heading, ResultsList } from '../../dataSelect/styles';
import { useHeader } from '../../header/useHeader';

interface Props {
  title: string;
}

const IconBtn = styled(IconButton)`
  position: absolute !important;
  top: 8px !important;
  right: 4px !important;
  color: #000 !important;
  &::hover {
    color: #000 !important;
  }
`;
const StyledMenu = styled(Menu)`
  ul {
    min-width: 207px !important;
    min-height: 66px !important;
    padding: 0 !important;
  }
  li {
    text-align: left !important;
    font: normal normal normal 13px/20px Montserrat !important;
    letter-spacing: 0px !important;
    color: #0d2d3d !important;
    opacity: 1 !important;
    height: 66px !important;
    padding-left: 25px !important;
  }
`;
const GraphDefinition: React.FC = () => (
  <>
    <Heading>
      <Typography variant="h1" className="modal-title">
        Waterfall Chart
      </Typography>
    </Heading>
    <ResultsList>
      <p>
        The Waterfall Chart allows you to see the breakdown of your performers
        visualizing the most impactful categories, subcategories, or ASINs
        depending on your page selections. Selecting all data will display the
        most &quot;turbulent&quot; categories. Selecting categories from the
        header will display subcategories and selecting subcategories will
        display ASINs. If a sales unit is on the rise, it will show you the
        positive effect it is having on your revenue. Conversely, if your unit
        is going down, it will show you the negative effect it is having on your
        total revenue.
      </p>
    </ResultsList>
  </>
);

const WaterfallGraph: React.FC<Props> = ({ title }) => {
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [showDefinitionModal, setShowDefinitionModal] = useState(false);
  const {
    asinsResponse,
    asinsResponsePrevious,
    subcategoriesResponse,
    currentPeriodDates,
    currentPeriodRevenueResponse,
    currentRevenueBySubItemResponse,
    previousPeriodDates,
    previousPeriodRevenueResponse,
    previousRevenueBySubItemResponse,
    singleAsinSelected,
    subItemType,
  } = useWaterfallGraphData();
  const { isReady } = useHeader();
  const { dataset, labels, max, changes } = transformDataToWaterfallGraph(
    previousPeriodRevenueResponse,
    currentPeriodRevenueResponse,
    previousRevenueBySubItemResponse,
    currentRevenueBySubItemResponse,
    subItemType,
    asinsResponse,
    subcategoriesResponse,
    asinsResponsePrevious,
  );

  const breakdownDataPoints = transformDataToWaterfallBreakdown(
    previousPeriodRevenueResponse,
    currentPeriodRevenueResponse,
    previousRevenueBySubItemResponse,
    currentRevenueBySubItemResponse,
    subItemType,
    asinsResponse,
    subcategoriesResponse,
  );

  const fetching: Factory<boolean> = () =>
    !isReady ||
    previousPeriodRevenueResponse.fetching ||
    currentPeriodRevenueResponse.fetching ||
    previousRevenueBySubItemResponse.fetching ||
    currentRevenueBySubItemResponse.fetching;

  const handleMoreVertButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleViewDefinitionClick = () => {
    setShowDefinitionModal(true);
    handleMenuClose();
  };
  return (
    <Container className="waterfall-breakdown graph-breakdown">
      <div className="title">
        {title}
        <IconBtn
          className="more-vert-icon"
          aria-controls="more-vert-menu"
          aria-haspopup="true"
          onClick={handleMoreVertButtonClick}
        >
          <MoreVertIcon color="inherit" />
        </IconBtn>
        <StyledMenu
          id="more-vert-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handleViewDefinitionClick()}>
            Show Definition
          </MenuItem>
        </StyledMenu>
      </div>
      <div className="graph_container waterfall-graph-container">
        {fetching() && (
          <div>
            <LoadingIndicator />
          </div>
        )}
        {!fetching() &&
          (dataset.length === 0 || breakdownDataPoints.length === 0) && (
            <div className="no_data_message">{noDataMessage}</div>
          )}
        {!fetching() && singleAsinSelected && (
          <div className="no_data_message">
            Select multiple asins to view waterfall data.
          </div>
        )}
        <GradientDefinition
          id="orange"
          start={waterfallGraphColors.neonCarrot}
          stop={waterfallGraphColors.hitPink}
        />
        <GradientDefinition
          id="gray"
          start={waterfallGraphColors.whiteSmoke}
          stop={waterfallGraphColors.white}
        />
        {!fetching() &&
          dataset.length > 0 &&
          breakdownDataPoints.length > 0 &&
          !singleAsinSelected && (
            <VictoryChart
              containerComponent={
                <VictoryVoronoiContainer
                  voronoiDimension="x"
                  labels={props => {
                    // console.log(props);
                    if (props.datum.childName === 'negative-space') {
                      return '';
                    }
                    const index = dataset[1].findIndex(
                      _datum => _datum.x === props.datum.x,
                    );
                    const change =
                      index > 0
                        ? changes[index].startsWith('-')
                          ? changes[index]
                          : `+${changes[index]}`
                        : '';
                    const wordWrap = () => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      if (typeof props.datum.x === 'string') {
                        return props.datum.x.replace(
                          /(?![^\n]{1,32}$)([^\n]{1,32})\s/g,
                          '$1\n',
                        );
                      } else {
                        return '';
                      }
                    };
                    return `
                    ${wordWrap() || `\n`}:
                    \n
                    Ordered Rev:  ${
                      props.datum.isNegative ? '-' : ''
                    }${prettyPrintLongformCurrency(props.datum.y.toString())}
                    \n
                    \n
                    ${index > 0 ? 'Change: ' : ''} ${change}`;
                  }}
                  labelComponent={
                    <VictoryTooltip
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      centerOffset={{ x: 0, y: -62 }}
                      constrainToVisibleArea
                      renderInPortal
                      flyoutStyle={{
                        margin: '5px',
                        fill: '#0d2d3c',
                      }}
                      style={{
                        fill: '#fff',
                        fontWeight: 'normal',
                        fontSize: '12px',
                      }}
                      flyoutPadding={data => {
                        const handleTopPadding = () => {
                          if (data.datum.x === 'Current Period') return -15;
                          if (data.datum.x === 'Previous Period')
                            return undefined;
                          if (data.datum.x.length < 45) return -15;
                          return -25;
                        };
                        const handleBottomPadding = () => {
                          if (data.datum.x === 'Current Period') return -25;
                          if (data.datum.x === 'Previous Period') return -40;
                          return -25;
                        };
                        return {
                          left: 30,
                          right: -30,
                          top: handleTopPadding(),
                          bottom: handleBottomPadding(),
                        };
                      }}
                    />
                  }
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  mouseFollowTooltips
                />
              }
              domainPadding={{ x: -19, y: 0 }}
              height={350}
              padding={{
                top: 15,
                bottom: 5,
                left: 71,
                right: 46,
              }}
              style={{
                parent: {
                  fontFamily: 'Montserrat, Helvetica, sans-serif',
                  fontSize: '12px',
                  fill: themeColors.cyprus,
                },
              }}
              theme={VictoryTheme.grayscale}
              width={1140}
            >
              <VictoryPortal>
                <VictoryAxis
                  width={1125}
                  style={{
                    axis: {
                      stroke: waterfallGraphColors.axis,
                      alignContent: 'center',
                      marginRight: '200px',
                    },
                    tickLabels: {
                      fill: themeColors.cyprus,
                      fontSize: '12px',
                      fontFamily: 'Montserrat, Helvetica, sans-serif',
                      backgroundColor: 'red',
                    },
                  }}
                  tickFormat={tick => {
                    if (tick === 'Previous Period') {
                      return `Ordered\n Revenue`;
                    }
                    if (tick === 'Current Period') {
                      return `Ordered\n Revenue\n`;
                    }
                    if (tick.length) {
                      const tickArray = tick.split('(');
                      if (tickArray[0].length > 19) {
                        tickArray[0] = tickArray[0].slice(0, 19).trim() + '...';
                      }
                      tick = tickArray.join('\n(');
                    }

                    return tick;
                  }}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  tickLabelComponent={<XAxisLabel />}
                />
              </VictoryPortal>
              <VictoryAxis
                dependentAxis
                style={{
                  axis: {
                    stroke: 'transparent',
                  },
                  tickLabels: {
                    fontSize: '13px',
                    fontFamily: 'Montserrat, Helvetica, sans-serif',
                    fill: ({ tick }) =>
                      tick === 0 || tick === max
                        ? themeColors.cyprus
                        : themeColors.concrete,
                  },
                }}
                tickFormat={tick => {
                  if (tick === 0) {
                    return tick;
                  }
                  if (tick === max) {
                    return prettyPrintNumber(tick, 'count');
                  }
                  return '—';
                }}
                tickLabelComponent={
                  <VictoryLabel textAnchor="start" dx={-33} dy={-4} />
                }
                tickValues={getTickValuesFromMaxValue(max)}
              />
              <VictoryStack
                domainPadding={{ x: 8, y: 0 }}
                key="waterfallVictoryStack"
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                labelComponent={<StackLabel />}
                labels={labels}
              >
                {dataset.map((data, i) => {
                  // console.log(data);
                  return (
                    <VictoryBar
                      name={i === 0 ? 'negative-space' : 'data-bar'}
                      barWidth={65}
                      data={data}
                      key={`${i}-${data[i].y}`}
                      style={{
                        data: {
                          padding: '20px',
                          fill:
                            i === 0
                              ? 'url(#gray_gradient)'
                              : 'url(#orange_gradient)',
                        },
                        labels: {
                          textAnchor: 'left',
                          fill: '#fff',
                          fontWeight: 'light',
                          color: '#fff',
                        },
                      }}
                    />
                  );
                })}
              </VictoryStack>
            </VictoryChart>
          )}
      </div>
      {(!fetching() &&
        dataset.length > 0 &&
        breakdownDataPoints.length > 0 &&
        !singleAsinSelected && (
          <div className="date-range">
            <span>
              {prettyPrintDate(previousPeriodDates.startDate)} -{' '}
              {prettyPrintDate(previousPeriodDates.endDate)}
            </span>
            <span>
              {prettyPrintDate(currentPeriodDates.startDate)} -{' '}
              {prettyPrintDate(currentPeriodDates.endDate)}
            </span>
          </div>
        )) ||
        null}
      {!singleAsinSelected ? (
        <div className="performance-breakdown">
          <WaterfallBreakdownTable
            breakdownDataPoints={breakdownDataPoints}
            expand={showBreakdown}
            onShowAllClick={() => setShowBreakdown(!showBreakdown)}
          />
        </div>
      ) : (
        <div className="performance-breakdown"></div>
      )}
      <FullScreenModal
        open={showDefinitionModal}
        onClose={() => setShowDefinitionModal(false)}
      >
        <GraphDefinition />
      </FullScreenModal>
    </Container>
  );
};

export default WaterfallGraph;
