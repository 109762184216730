import gql from 'graphql-tag';
import { Asin } from './asin';

export const getCategoriesByClientId = gql`
  query GetCategoriesByClientId($dataPoints_client: String) {
    categories(
      client_id: $dataPoints_client
      first: 9999
      order: { name: "asc" }
    ) {
      edges {
        node {
          id
          name
          asins {
            totalCount
          }
        }
      }
    }
  }
`;

export const getSubCategoriesByClientId = gql`
  query GetSubCategoriesByClientId(
    $dataPoints_client: String
    $subcategories_id_list: [String] = []
  ) {
    subcategories(
      category_client: $dataPoints_client
      first: 9999
      id_list: $subcategories_id_list
      order: { name: "asc" }
    ) {
      edges {
        node {
          id
          name
          asins {
            totalCount
          }
          category {
            name
            id
          }
        }
      }
    }
  }
`;
export interface Category {
  name: string;
  id: string;
  asins?: {
    totalCount?: number;
    edges: {
      node: Asin;
    }[];
  };
}

export interface SubCategory {
  name: string;
  id: string;
  category: Category;
  asins?: {
    totalCount?: number;
    edges: {
      node: Asin;
    }[];
  };
}

export interface GetCategoriesByClientId {
  categories: {
    edges: {
      node: Category;
    }[];
  };
}

export interface GetSubCategoriesByClientId {
  subcategories: {
    edges: {
      node: SubCategory;
    }[];
  };
}
