import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '../../icons/SearchIcon';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useQuery } from 'urql';
import {
  Asin,
  getAsinsByClientId,
  GetAsinsByClientIdResponse,
} from '../../api/asin';
import { useClients } from '../../clients/useClients';
import {
  Category,
  getCategoriesByClientId,
  GetCategoriesByClientId,
  getSubCategoriesByClientId,
  GetSubCategoriesByClientId,
  SubCategory,
} from '../../api/categories';
import {
  transformCategoriesByClientData,
  transformSubCategoriesByClientData,
} from '../dashboard/transformers';
import LoadingIndicator from '../LoadingIndicator';
import { transformAsinsByClientData } from '../header/transformers';
import { FilterItem, FilterType, Runnable } from '../../common/types';
import {
  BottomButtonsWrapper,
  ButtonsWrapper,
  CheckmarkWrapper,
  DataDiv,
  dataSelectTheme,
  Heading,
  InputsWrapper,
  Select,
  Wrapper,
} from './styles';

interface SelectItem {
  text: string;
  value: string;
  parent?: Category | SubCategory;
}

interface Props {
  callBack: (filters: FilterItem[], allSelected: boolean) => void;
  closeCallBack: Runnable;
  open: boolean;
  previouslySelectedFilters: FilterItem[];
}

const DataSelectDrawer: React.FC<Props> = ({
  callBack,
  closeCallBack,
  open,
  previouslySelectedFilters,
}) => {
  const [loading, setLoading] = useState(true);
  const [selectOpen, setSelectOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(open);
  const [seeAll, setSeeAll] = useState(false);
  const [resetClicked, setResetClicked] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const [selector, setSelector] = useState(
    previouslySelectedFilters.length === 0
      ? 'category'
      : previouslySelectedFilters[0].type,
  );
  const [itemsList, setItemsList] = useState<SelectItem[]>([]);
  const [filterList, setFilterList] = useState<FilterItem[]>(
    previouslySelectedFilters,
  );
  const { currentClient } = useClients();
  const [getAsinsByClient] = useQuery<GetAsinsByClientIdResponse>({
    query: getAsinsByClientId,
    variables: {
      dataPoints_client: currentClient.id,
    },
    pause: currentClient.id === '',
  });
  const [getCategoriesByClient] = useQuery<GetCategoriesByClientId>({
    query: getCategoriesByClientId,
    variables: {
      dataPoints_client: currentClient.id,
    },
    pause: currentClient.id === '',
  });
  const [getSubCategoriesByClient] = useQuery<GetSubCategoriesByClientId>({
    query: getSubCategoriesByClientId,
    variables: {
      dataPoints_client: currentClient.id,
    },
    pause: currentClient.id === '',
  });
  useEffect(() => {
    const asinList: Asin[] = transformAsinsByClientData(getAsinsByClient);

    const categoryList: Category[] = transformCategoriesByClientData(
      getCategoriesByClient,
    );
    const subCategoryList: SubCategory[] = transformSubCategoriesByClientData(
      getSubCategoriesByClient,
    );
    switch (selector) {
      case 'asin':
        if (getAsinsByClient.fetching) {
          setLoading(true);
        } else {
          setLoading(false);
        }
        setItemsList(
          asinList.map(asin => {
            return {
              value: asin.id,
              text: `${asin.productName ? asin.productName : ''} (${
                asin.asin
              })`,
              parent: asin.subcategory,
            };
          }),
        );
        setSearchPlaceholder('Search Product ASIN or Product Name');
        break;
      case 'category':
        if (getCategoriesByClient.fetching) {
          setLoading(true);
        } else {
          setLoading(false);
        }
        setItemsList(
          categoryList.map(cat => {
            return {
              value: cat.id,
              text: cat.name,
            };
          }),
        );
        setSearchPlaceholder('Search Product Categories');
        break;
      case 'subcategory':
        if (getSubCategoriesByClient.fetching) {
          setLoading(true);
        } else {
          setLoading(false);
        }
        // eslint-disable-next-line no-case-declarations
        const subcats = subCategoryList.map(subCat => {
          return {
            value: subCat.id,
            text: subCat.name,
            parent: subCat.category,
          };
        });

        setItemsList([
          ...subcats,
          {
            value: 'null',
            text: 'Uncategorized',
            parent: {
              id: 'null',
              name: 'Uncategorized',
            },
          },
        ]);
        setSearchPlaceholder('Search Product Subcategories');
        break;
    }
  }, [
    getAsinsByClient,
    getCategoriesByClient,
    getSubCategoriesByClient,
    selector,
  ]);
  const handleToggle = (item: SelectItem) => {
    if (filterList.find(filter => filter.id === item.value)) {
      setFilterList(filterList.filter(filter => filter.id !== item.value));
    } else {
      setFilterList([
        ...filterList,
        {
          id: item.value,
          text: item.text,
          type: selector as FilterType,
          parent: item.parent,
        },
      ]);
    }
    if (
      selector === 'category' &&
      filterList.length === itemsList.length &&
      filterList.length > 0
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  };

  const selectAll: Runnable = () => {
    if (selector === 'category') {
      setAllSelected(true);
    }

    if (filterList.length !== itemsList.length) {
      setFilterList(
        itemsList
          .filter(
            item =>
              item.text.toLowerCase().indexOf(searchText.toLowerCase()) >= 0,
          )
          .map(item => {
            return {
              id: item.value,
              text: item.text,
              type: selector as FilterType,
              parent: item.parent,
            };
          }),
      );
    }
  };

  const clearAll: Runnable = () => {
    if (selector === 'category') {
      setAllSelected(false);
    }
    setFilterList([]);
  };

  useEffect(() => {
    if (filterList.length === 0 && resetClicked) {
      callBack([], false);
    }
  }, [callBack, filterList, resetClicked]);

  const reset: Runnable = () => {
    clearAll();
    setResetClicked(true);
  };
  const helperText: () => string | JSX.Element = () => {
    switch (selector) {
      case 'asin':
        return (
          <>
            Selecting one more ASINs will show the selected in the breakdowns.
            <br />
            If more than 5 ASINs are selected, only the top 5 will be shown in
            the breakdowns.
          </>
        );
      case 'category':
        if (filterList?.length) {
          return 'Selecting one or more categories (including selecting all categories) will display subcategories in breakdowns.';
        }
        return 'When nothing is selected, Categories will be shown in the breakdowns.';
      case 'subcategory':
        return 'Selecting Subcategories will display ASINs in the breakdowns.';
    }
  };
  const filteredItems = itemsList
    .filter(
      item => item.text.toLowerCase().indexOf(searchText.toLowerCase()) >= 0,
    )
    .sort((a, b) => {
      return `${a.text}${
        selector === 'subcategory' ? ` (${a.parent?.name})` : ''
      }`.localeCompare(
        `${b.text}${selector === 'subcategory' ? ` (${b.parent?.name})` : ''}`,
        'en',
        {
          numeric: true,
        },
      );
    });
  return (
    <ThemeProvider theme={dataSelectTheme}>
      <Drawer
        anchor="bottom"
        disableBackdropClick
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        disableEscapeKeyDown
      >
        <Wrapper>
          <IconButton onClick={() => closeCallBack()} edge="start">
            <CloseIcon className="modal-icon" />
          </IconButton>
          <Heading>
            <Typography variant="h1">Update Data Set</Typography>
            <Typography variant="body1">
              <div style={{ width: '100%', minHeight: '20px' }}>
                {filterList.length > 0 && `Selected: `}
                {filterList
                  .map(filter => filter.text)
                  .slice(0, seeAll ? filterList.length : 10)
                  .join(', ')}
                {filterList.length > 10 && (
                  <Button onClick={() => setSeeAll(!seeAll)}>
                    {seeAll ? '(See Less)' : '(See All)'}
                  </Button>
                )}
              </div>
            </Typography>
          </Heading>
          <InputsWrapper>
            <FormControl variant="outlined" className="select">
              <InputLabel htmlFor="select">Viewing</InputLabel>
              <Select
                menuOpen={selectOpen}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  elevation: 0,
                  getContentAnchorEl: el => {
                    if (el.parentElement) {
                      return el.parentElement;
                    }
                    return el;
                  },
                }}
                label="Viewing"
                inputProps={{
                  name: 'viewing',
                  id: 'viewing',
                }}
                value={selector}
                variant="outlined"
                onChange={event => {
                  const value = event.target.value as FilterType;
                  setSelector(value);
                  setAllSelected(false);
                  setSearchText('');
                  setFilterList([]);
                }}
                onClose={() => setSelectOpen(false)}
                onOpen={() => setSelectOpen(true)}
              >
                <MenuItem value="category">Categories</MenuItem>
                <MenuItem value="subcategory">Subcategories</MenuItem>
                <MenuItem value="asin">ASINs</MenuItem>
              </Select>
            </FormControl>
            <FormControl className="search">
              <InputLabel htmlFor="search">{searchPlaceholder}</InputLabel>
              <Input
                id="search"
                type="text"
                value={searchText}
                onChange={event => setSearchText(event.target.value)}
                endAdornment={
                  <>
                    <InputAdornment position="end">
                      <IconButton
                        className="clear-button"
                        onClick={() => setSearchText('')}
                        disabled={!searchText}
                      >
                        {searchText ? (
                          <CloseIcon className="modal-icon" />
                        ) : (
                          <SearchIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </>
                }
              />
            </FormControl>
          </InputsWrapper>
          {itemsList.length === 0 || !filteredItems.length ? (
            <ButtonsWrapper />
          ) : (
            <ButtonsWrapper>
              <Button onClick={() => selectAll()} variant="text">
                Select All
              </Button>

              <Button onClick={() => clearAll()} variant="text">
                Clear All
              </Button>
            </ButtonsWrapper>
          )}
          <DataDiv>
            <List dense>
              {itemsList.length === 0 || !filteredItems.length ? (
                loading ? (
                  <LoadingIndicator />
                ) : (
                  <Typography variant="body1" className="no-data">
                    There are no results matching your search. Please try again.
                  </Typography>
                )
              ) : (
                filteredItems.map((item, index) => (
                  <ListItem
                    key={`${item.value}${index}`}
                    className="listItem"
                    onClick={() => handleToggle(item)}
                    button
                    selected={
                      undefined !==
                      filterList.find(filter => filter.id === item.value)
                    }
                  >
                    <ListItemText
                      primary={`${item.text}${
                        (selector === 'subcategory' &&
                          ` (${item?.parent?.name})`) ||
                        ''
                      }`}
                    />
                    {undefined !==
                      filterList.find(filter => filter.id === item.value) && (
                      <CheckmarkWrapper>
                        <CheckIcon color="inherit" />
                      </CheckmarkWrapper>
                    )}
                  </ListItem>
                ))
              )}
            </List>
          </DataDiv>
          <BottomButtonsWrapper>
            <FormHelperText>{helperText()}</FormHelperText>
            <Button
              className="reset-button"
              onClick={() => reset()}
              variant="text"
            >
              Reset to All Data
            </Button>
            <Button
              variant="outlined"
              className="apply-button"
              onClick={() => callBack(filterList, allSelected)}
            >
              Apply
            </Button>
          </BottomButtonsWrapper>
        </Wrapper>
      </Drawer>
    </ThemeProvider>
  );
};

export default DataSelectDrawer;
