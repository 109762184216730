import React from 'react';

interface Props {
  color?: string;
}
const EmptyBox: React.FC<Props> = ({ color = '#498ea2' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="86"
    height="66"
    viewBox="0 0 86 66"
    fill={color}
  >
    <g transform="translate(-7 -969.362)">
      <path
        className="a"
        d="M65.27,969.389,92.712,978.8a.531.531,0,0,1,0,.945l-13.768,4.724,13.768,4.692a.531.531,0,0,1,0,.945l-14.75,5.07v30.231a.58.58,0,0,1-.346.472l-27.442,9.447a.464.464,0,0,1-.345,0l-27.442-9.447a.461.461,0,0,1-.348-.472V995.179l-14.75-5.07a.531.531,0,0,1,0-.945l13.737-4.692L7.288,979.749a.531.531,0,0,1,0-.945l27.442-9.416a.578.578,0,0,1,.412.031l14.876,5.1,14.845-5.1a.664.664,0,0,1,.412-.031Zm-.158,1.008-13.547,4.661,25.891,8.88L91,979.277Zm-30.227,0-25.859,8.88,13.515,4.661,25.923-8.88Zm15.129,5.2-25.891,8.88,25.891,8.88,25.891-8.88Zm27.41,9.416c-8.63,2.929-17.241,5.915-25.86,8.88l13.547,4.629L91,989.637Zm-54.852,0L9.025,989.637l25.891,8.88,13.547-4.629Zm26.936,9.573-14.433,4.944a.52.52,0,0,1-.348,0l-11.679-4v29.192l26.461-9.1Zm1.013.031v21.036l26.429,9.069V995.525l-11.679,4a.52.52,0,0,1-.348,0Zm0,22.106v17.414L75.9,1025.41Zm-1.013,0-25.385,8.691q12.69,4.369,25.385,8.723Z"
      />
    </g>
  </svg>
);

export default EmptyBox;
