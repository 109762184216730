import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import Providers from './components/Providers';
import PrivateRoute from './components/PrivateRoute';
import { routes } from './common/routes';
import dashboardRoutes from './components/dashboard/routes';
import * as serviceWorker from './serviceWorker';
import Login from './components/login/Login';
import ResetPassword from './components/ResetPassword';
import Join from './components/Join';
import Account from './components/account/Account';
import './index.css';
import HistoryDashboard from './components/dashboard/History';
import AdminPrivateRoute from './components/AdminPrivateRoute';
import { InitGA, PageView } from './components/Tracking';

let GA_ID = process.env.REACT_APP_GA_TRACKING_ID;
if (window._env_ && window._env_.GA_TRACKING_ID) {
  GA_ID = window._env_.GA_TRACKING_ID;
}
function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    if (
      location?.pathname !== '/' &&
      location.search.includes('cl=') &&
      location.search.includes('sd=')
    )
      PageView();
  }, [location]);
}
const App: React.FC = () => {
  useEffect(() => {
    InitGA(GA_ID);
  }, []);

  usePageViews();
  return (
    <Switch>
      <Route path={routes.login} component={Login} strict={true} />
      <Route
        path={routes.resetPassword}
        component={ResetPassword}
        strict={true}
      />
      <Route path={routes.join} component={Join} strict={true} />
      {dashboardRoutes.map(route => (
        <PrivateRoute
          key={route.label}
          path={route.route}
          component={route.component}
          strict={true}
          exact={true}
        />
      ))}
      <PrivateRoute path="/" strict={true} exact={true} />
      <PrivateRoute
        path={routes.account}
        component={Account}
        strict={true}
        exact={true}
      />
      <AdminPrivateRoute
        path={routes.history}
        component={HistoryDashboard}
        strict={true}
        exact={true}
      />
      <Route render={() => <h1>Not Found</h1>} />
    </Switch>
  );
};
ReactDOM.render(
  <BrowserRouter>
    <Providers>
      <App />
    </Providers>
  </BrowserRouter>,
  document.getElementById('root'),
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
