import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { createMuiTheme, Theme } from '@material-ui/core';
import DefaultSelect from '@material-ui/core/Select';
import { themeColors } from '../../common/theme';

export const dataSelectTheme: (theme: Theme) => Theme = theme =>
  createMuiTheme({
    ...theme,
    overrides: {
      MuiDrawer: {
        paper: {
          width: `100vw`,
          background: themeColors.white,
          border: 'none',
        },
      },
      MuiSvgIcon: {
        root: {
          '&.modal-icon': {
            height: '20.59px',
            width: '20.59px',
            fill: '#0D2D3D',
          },
          '&.search-icon': {
            width: '16.49px',
            height: '16.49px',
          },
        },
      },
      MuiIconButton: {
        root: {
          '&.clear-button': {
            padding: '5px',
            width: '30px',
            height: '30px',
          },
        },
        edgeStart: {
          background: 'transparent',
          position: 'absolute',
          color: themeColors.cyprus,
          top: '42px',
          right: '52px',
        },
      },
      MuiTypography: {
        h1: {
          fontWeight: 900,
          color: '#06313E',
        },
        body1: {
          fontSize: '13px',
          color: themeColors.cyprus,
          marginTop: '8px',
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: `${themeColors.concrete}!important`,
          borderWidth: '1px !important',
        },
        input: {
          padding: '16px 16px 13px',
        },
      },
      MuiInputBase: {
        root: {
          color: themeColors.cyprus,
          fontSize: '13px',
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: '13px',
        },
        outlined: {
          fontSize: '13px',
          color: themeColors.cyprus,
        },
        formControl: {
          transform: 'translate(0, 18px) scale(1)',
        },
      },
      MuiInputAdornment: {
        positionEnd: {
          marginBottom: '6px',
        },
      },
      MuiSelect: {
        icon: {
          color: themeColors.cyprus,
        },
      },
      MuiPaper: {
        root: {
          border: `1px solid ${themeColors.concrete}`,
          borderTop: 'none',
          backgroundColor: themeColors.white,
          color: themeColors.cyprus,
          boxShadow: 'none',
        },
        rounded: {
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0',
        },
        elevation1: {
          boxShadow: 'none',
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '13px',
          '&:hover': {
            backgroundColor: '#f2f2f2',
          },
        },
      },
      MuiList: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      MuiListItem: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: '#efefef !important',
          },
        },
        dense: {
          borderRadius: '4px',
          border: '1px solid transparent',
          paddingTop: '11px',
          paddingBottom: '11px',
          marginBottom: '3px',
          '&.Mui-selected, &.Mui-selected:hover': {
            border: '1px solid #24DD9C',
            backgroundColor: '#D0FFEE',
          },
        },

        button: {
          '&:hover': {
            backgroundColor: '#f2f2f2',
          },
        },
      },
      MuiListItemText: {
        root: {
          marginTop: '0',
          marginBottom: '0',
        },
        primary: {
          margin: '0',
        },
      },
      MuiButton: {
        text: {
          fontSize: '13px',
          color: '#043746 !important',
          textTransform: 'none',
          fontWeight: 400,
          '&.reset-button': {
            minWidth: '130px',
          },
        },
        outlined: {
          width: '97px',
          height: '42px',
          textTransform: 'none',
          fontSize: '13px',
          fontWeight: 700,
          color: '#043746 !important',
          border: '1px solid  #043746 !important',
          borderRadius: '23px',
          '&.apply-button:hover': {
            background: '#c1f0ff',
          },
        },
      },
      MuiFormHelperText: {
        root: {
          marginRight: 'auto !important',
        },
      },
    },
  });

export const Wrapper = styled.div`
  height: 100vh;
  min-height: -webkit-fill-available;
  width: 775px;
  margin: 0 auto;
  margin-top: 168px;
  &.definition-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
  }
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
`;

export const Heading = styled.div`
  text-align: center;
  margin-bottom: 31px;
`;

export const Select = styled(DefaultSelect)<{ menuOpen: boolean }>`
  fieldset {
    transition: border 100ms ease-in-out;
  }
  ${p =>
    p.menuOpen &&
    css`
      border-width: 1px !important;
      fieldset {
        border-bottom: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    `}
`;

export const InputsWrapper = styled.div`
  margin-bottom: 10px;

  .select {
    width: 275px;
    margin-right: 16px;
  }
  .search {
    width: 483px;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  button {
    &:first-child {
      margin-left: 10px;
    }
    &:last-child {
      margin-right: -8px;
    }
  }
`;

export const ResultsList = styled.div`
  background: transparent;
  border: 1px solid #7070704d;
  border-radius: 16px;
  max-height: 65vh;
  margin-bottom: 30px;
  opacity: 1;
  overflow: auto;
  padding-top: 38px;
  padding-bottom: 40px;
  padding-left: 63px;
  padding-right: 64px;

  @media screen and (max-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const DataDiv = styled.div`
  overflow: auto;
  background: transparent;
  margin-bottom: 20px;
  max-height: 235px;
  min-height: 235px;
  background: transparent;
  margin-bottom: 14px;
  .no-data {
    width: 100%;
    height: 235px;
    background: #f2f2f2;
    border-radius: 16px;
    text-align: center;
    align-items: center;
    display: flex;
    margin: 0;
    justify-content: center;
    font-size: 13px;
    color: #0d2d3d;
  }
`;

export const CheckmarkWrapper = styled.span`
  width: 18px;
  height: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #24dd9c;
  color: #24dd9c;
  border-radius: 50%;
  svg {
    width: 10px;
    height: 10px;
  }
`;

export const BottomButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  button {
    &:first-child {
      margin-right: 12px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .footer-message {
    padding-right: 20px !important;
    font: normal normal normal 13px/16px Montserrat;
    letter-spacing: 0px;
    color: #043746;
    opacity: 1;
  }
`;
