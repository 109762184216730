import gql from 'graphql-tag';

export const getFieldConfigurations = gql`
  query GetFieldConfigurations {
    fieldConfigurations {
      edges {
        node {
          displayName
          fieldKey
          id
        }
      }
    }
  }
`;

export interface Importer {
  type: string;
}

export interface FieldConfiguration {
  displayName: string;
  fieldKey: string;
  id: string;
  previous?: boolean;
  axisLabel?: string;
  axisName?: string;
  fieldType?: string;
  importerType?: string;
  importer?: Importer;
}

export interface GetFieldConfigurationsResponse {
  fieldConfigurations: {
    edges: {
      node: FieldConfiguration;
    }[];
  };
}

export const getFieldConfigurationsByClientId = gql`
  query GetFieldConfigurations(
    $importer_clients: String
    $graphEnabled: Boolean
  ) {
    fieldConfigurations(
      importer_clientImporters_client: $importer_clients
      graphEnabled: $graphEnabled
    ) {
      edges {
        node {
          displayName
          fieldKey
          id
          axisLabel
          axisName
          fieldType
          importer {
            type
          }
        }
      }
    }
  }
`;
