import moment from 'moment';

export const prettyPrintDate: (date: moment.Moment | null) => string = date =>
  moment(date).format('MM / DD / YY');

export const getTickValuesFromMaxValue: (max: number) => number[] = max => [
  0,
  max * 0.1,
  max * 0.2,
  max * 0.3,
  max * 0.4,
  max * 0.5,
  max * 0.6,
  max * 0.7,
  max * 0.8,
  max * 0.9,
  max,
  max * 1.1,
  max * 1.2,
  max * 1.3,
  max * 1.4,
  max * 1.5,
];
