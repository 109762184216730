import React, { useEffect, useState } from 'react';
import { Chip, ThemeProvider } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import { routes } from '../../common/routes';
import { useClients } from '../../clients/useClients';
import { useAuth } from '../../auth/useAuth';
import LinkWithSearchParams from '../header/LinkWithSearchFilters';
import Wordmark from '../Wordmark';
import { DataSetType, useSidebar } from './useSidebar';
import {
  DataToggleWrapper,
  Select,
  sidebarTheme,
  StyledListItem,
  Top,
  WordmarkRow,
} from './styles';
import dashboardRoutes from '../dashboard/routes';
import { useHeader } from '../header/useHeader';

const Sidebar: React.FC = () => {
  const [clientSelectOpen, setClientSelectOpen] = useState(false);

  const { logout, fullName, username, versionNumber } = useAuth();

  const { dataType, open, setDataType, setOpen } = useSidebar();

  const { clients, currentClient, setCurrentClient } = useClients();

  const { lastValidDate, lastValidDateIsLoading } = useHeader();

  useEffect(() => {
    if (
      currentClient.sellerCentralEnabled &&
      !currentClient.vendorCentralEnabled &&
      dataType !== DataSetType.seller
    ) {
      setDataType(DataSetType.seller);
    }
    if (
      currentClient.vendorCentralEnabled &&
      !currentClient.sellerCentralEnabled &&
      dataType !== DataSetType.vendor
    ) {
      setDataType(DataSetType.vendor);
    }
  }, [currentClient, dataType, setDataType]);

  return (
    <ThemeProvider theme={sidebarTheme}>
      <Drawer open={open} onClose={() => setOpen(false)} variant="persistent">
        <Top>
          <WordmarkRow>
            <Link
              color="inherit"
              component={props => <LinkWithSearchParams {...props} isNavLink />}
              to="/sales"
              underline="none"
            >
              <Wordmark />
            </Link>
            <IconButton disableRipple onClick={() => setOpen(false)}>
              <ChevronLeft color="inherit" />
            </IconButton>
          </WordmarkRow>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">{fullName || username}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <StyledListItem disableGutters button>
                  <Link
                    color="inherit"
                    component={props => (
                      <LinkWithSearchParams {...props} isNavLink />
                    )}
                    to={routes.account}
                    underline="none"
                  >
                    <Typography color="inherit" variant="body1">
                      Account
                    </Typography>
                  </Link>
                </StyledListItem>
                <StyledListItem disableGutters button onClick={() => logout()}>
                  <Link
                    color="inherit"
                    underline="none"
                    href="#"
                    onClick={(e: React.MouseEvent) => e.preventDefault()}
                  >
                    <Typography color="inherit" variant="body1">
                      Logout
                    </Typography>
                  </Link>
                </StyledListItem>
                <ListItem disableGutters>
                  <Typography className="version_number">
                    Scout v1.{versionNumber}
                  </Typography>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Top>
        {clients.length > 0 && (
          <Select
            menuOpen={clientSelectOpen}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              elevation: 0,
              getContentAnchorEl: el => {
                if (el.parentElement) {
                  return el.parentElement;
                }
                return el;
              },
            }}
            onChange={e => {
              setCurrentClient(e.target.value as string, true);
            }}
            onClose={() => setClientSelectOpen(false)}
            onOpen={() => setClientSelectOpen(true)}
            value={currentClient.id}
            variant="outlined"
          >
            {clients
              .filter(_c => _c.enabled)
              .map(client => (
                <MenuItem key={client.id} value={client.id}>
                  {client.name}
                </MenuItem>
              ))}
          </Select>
        )}
        <List>
          {dashboardRoutes.map(route => (
            <StyledListItem
              button
              disabled={
                Boolean(currentClient.id) &&
                !lastValidDateIsLoading() &&
                !lastValidDate
              }
              key={route.label}
            >
              <Link
                color="inherit"
                component={props => (
                  <LinkWithSearchParams {...props} isNavLink />
                )}
                to={route.route}
                underline="none"
              >
                <Typography variant="body1">{route.label}</Typography>
              </Link>
            </StyledListItem>
          ))}
          <DataToggleWrapper>
            {currentClient.sellerCentralEnabled &&
              currentClient.vendorCentralEnabled && (
                <>
                  <Button
                    disabled={!currentClient.vendorCentralEnabled}
                    disableElevation
                    onClick={() => setDataType(DataSetType.vendor)}
                    variant={
                      dataType === DataSetType.vendor ? 'contained' : 'outlined'
                    }
                  >
                    {dataType === DataSetType.vendor ? 'Vendor' : 'V'}
                  </Button>
                  <Button
                    style={{
                      marginRight: dataType === DataSetType.seller ? 0 : '5px',
                      marginLeft: '8px',
                    }}
                    disabled={!currentClient.sellerCentralEnabled}
                    disableElevation
                    onClick={() => setDataType(DataSetType.seller)}
                    variant={
                      dataType === DataSetType.seller ? 'contained' : 'outlined'
                    }
                  >
                    {dataType === DataSetType.seller ? 'Seller' : 'S'}
                  </Button>
                </>
              )}
            {(!currentClient.sellerCentralEnabled ||
              !currentClient.vendorCentralEnabled) && (
              <>
                <Chip
                  style={{
                    marginRight: '8px',
                  }}
                  label={dataType === DataSetType.vendor ? 'Vendor' : 'V'}
                  variant={
                    dataType === DataSetType.vendor ? 'default' : 'outlined'
                  }
                  disabled={!currentClient.vendorCentralEnabled}
                />
                <Chip
                  style={{
                    marginRight: dataType === DataSetType.seller ? 0 : '5px',
                    marginLeft: '8px',
                  }}
                  label={dataType === DataSetType.seller ? 'Seller' : 'S'}
                  variant={
                    dataType === DataSetType.seller ? 'default' : 'outlined'
                  }
                  disabled={!currentClient.sellerCentralEnabled}
                />
              </>
            )}
          </DataToggleWrapper>
        </List>
      </Drawer>
    </ThemeProvider>
  );
};

export default Sidebar;
