import { useEffect, useMemo, useState } from 'react';
import { Consumer, Factory } from '../../../common/types';
import { getAsinsByFieldKeyDataAggregate } from '../../../api/asinListPagination';
import { useHeader } from '../../header/useHeader';
import { useSidebar } from '../../sidebar/useSidebar';
import { useClients } from '../../../clients/useClients';
import { dateFormat } from '../../header/utils';
import moment from 'moment';

export type ColumnFieldName =
  | 'asin'
  | 'productName'
  | 'category'
  | 'orderedRevenueCurrent'
  | 'orderedRevenuePrevious'
  | 'orderedUnits'
  | 'subcategory';

type SortType = 'asc' | 'desc';

export interface SortOption {
  column: ColumnFieldName;
  type: SortType;
}

const useAsinPagination: Factory<{
  currentList: string[];
  currentPage: number;
  loading: boolean;
  setCurrentPage: Consumer<number>;
  setSortOption: Consumer<SortOption>;
  sortOption: SortOption;
  totalCount: number;
}> = () => {
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [currentList, setCurrentList] = useState<string[]>([]);
  /* pagination is zero-indexed */
  const [currentPage, setCurrentPage] = useState(0);
  const [sortOption, setSortOption] = useState<SortOption>({
    column: 'orderedRevenueCurrent',
    type: 'desc',
  });

  const { currentClient } = useClients();

  const { fieldKeys } = useSidebar();

  const { selectedFilters, selectedDates, previousPeriodDates } = useHeader();

  const selectedCategories = useMemo(
    () =>
      selectedFilters
        .filter(filter => filter.type === 'category')
        .map(category => category.id),
    [selectedFilters],
  );

  const selectedSubcategories = useMemo(
    () =>
      selectedFilters
        .filter(filter => filter.type === 'subcategory')
        .map(subcategory => subcategory.id),
    [selectedFilters],
  );

  const selectedAsins = useMemo(
    () =>
      selectedFilters
        .filter(filter => filter.type === 'asin')
        .map(asin => asin.id),
    [selectedFilters],
  );

  useEffect(() => {
    setLoading(true);
    const fetchByFieldKeyDataAggregate = async (
      fieldKey: string,
      orderField: string,
      previous: boolean,
    ) => {
      const startDateSel = previous
        ? moment().startOf('year').subtract(1, 'year')
        : selectedDates.startDate;
      const endDateSel = previous
        ? moment().startOf('year').subtract(1, 'year').endOf('year')
        : selectedDates.endDate;
      const response = await getAsinsByFieldKeyDataAggregate({
        client: currentClient.id,
        endDate: endDateSel ? endDateSel.format(dateFormat) : '',
        fieldKey,
        page: currentPage,
        selectedCategories,
        selectedSubcategories,
        selectedAsins,
        sort: sortOption.type,
        orderField: orderField,
        startDate: startDateSel ? startDateSel.format(dateFormat) : '',
      });
      if (response.status === 200) {
        setCurrentList(
          response.data['hydra:member'].map(entry => entry.asinId),
        );
        setTotalCount(response.data['hydra:totalItems']);
      }
      setLoading(false);
    };
    if (
      currentClient.id !== '' &&
      selectedDates.startDate !== null &&
      selectedDates.endDate !== null
    ) {
      switch (sortOption.column) {
        case 'orderedRevenueCurrent':
          fetchByFieldKeyDataAggregate(
            fieldKeys.orderedRevenue,
            'revenue',
            false,
          );
          break;
        case 'orderedUnits':
          fetchByFieldKeyDataAggregate(fieldKeys.unitsSold, 'units', false);
          break;
        case 'asin':
          fetchByFieldKeyDataAggregate(fieldKeys.orderedRevenue, 'asin', false);
          break;
        case 'productName':
          fetchByFieldKeyDataAggregate(
            fieldKeys.orderedRevenue,
            'productName',
            false,
          );
          break;
        case 'category':
          fetchByFieldKeyDataAggregate(
            fieldKeys.orderedRevenue,
            'category',
            false,
          );
          break;
        case 'subcategory':
          fetchByFieldKeyDataAggregate(
            fieldKeys.orderedRevenue,
            'subcategory',
            false,
          );
          break;
        case 'orderedRevenuePrevious':
          fetchByFieldKeyDataAggregate(
            fieldKeys.orderedRevenue,
            'revenue',
            true,
          );
          break;
      }
    }
  }, [
    currentClient,
    currentPage,
    fieldKeys,
    previousPeriodDates.endDate,
    previousPeriodDates.startDate,
    selectedAsins,
    selectedCategories,
    selectedDates.endDate,
    selectedDates.startDate,
    selectedSubcategories,
    sortOption,
  ]);

  return {
    currentList,
    currentPage,
    loading,
    setCurrentPage,
    setSortOption,
    sortOption,
    totalCount,
  };
};

export default useAsinPagination;
