import { css, SerializedStyles } from '@emotion/core';
import { Factory } from '../../common/types';

export const withAccountPageStyles: Factory<SerializedStyles> = () => css`
  min-width: 516px;
  width: fit-content;
  min-height: 312px;
  padding: 16px;
  background: white;
  border-radius: 20px;
  border: 1px solid #dbdbd7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 76px;
}
  .password-button {
    border: 1px solid #0d2d3d;
    border-radius: 23px;
    color: #043746;
    font: normal normal bold 13px/16px Montserrat;
    letter-spacing: 0px;
    margin-top: 18px !important;
    margin-bottom: 0 !important;
    padding: 13px 16px;
    text-transform: capitalize;
  }
  .password-button:hover {
    background-color: #c1f0ff;
  }
  .account-form {
    width: 364px;
    height: 100%;
    .form-control {
      margin: 10px 0 12px;
    }
  }
  .input {
    text-align: left;
    font: normal normal normal 13px/20px Montserrat;
    letter-spacing: 0px;
    color: #0d2d3d;
    font-size: 16px !important;
  }
  .MuiInputBase-root {
    height: 44px;
    font: normal normal normal 13px/20px Montserrat;
    letter-spacing: 0px;
  }
  label {
  color: #0D2D3D;

  }
  svg {
    fill: #0D2D3D
  }
`;
