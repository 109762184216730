import { css, SerializedStyles } from '@emotion/core';
import { Factory } from '../../common/types';
import { themeColors } from '../../common/theme';

export const lineColors = [
  { color: '#8968c7E6', fieldKey: 'average_sales_price' },
  { color: '#0D2D3DE6', fieldKey: 'ordered_revenue_avp' },
  { color: '#EDCC38E6', fieldKey: 'ordered_revenue_rep_oos' },
  { color: '#1c6489E6', fieldKey: 'buy_box_percentage' },
  { color: '#1c6489E6', fieldKey: 'lost_buy_box' },
  { color: '#00a269E6', fieldKey: 'sessions' },
  { color: '#FDAF72E6', fieldKey: 'pageviews' },
  { color: '#90E8C9E6', fieldKey: 'units_ordered' },
  { color: '#134B69E6', fieldKey: 'total_order_items' },
  { color: '#e06c10E6', fieldKey: 'ordered_revenue' },
  { color: '#e06c10E6', fieldKey: 'ordered_revenue_sc' },
  { color: '#8052D5E6', fieldKey: 'ordered_product_sales' },
  { color: '#2286b9E6', fieldKey: 'ordered_units' },
  { color: '#2286b9E6', fieldKey: 'ordered_units_sc' },
  { color: '#00a269E6', fieldKey: 'shipped_cogs' },
  { color: '#00a269E6', fieldKey: 'shipped_cogs_avp' },
  { color: '#af2525E6', fieldKey: 'rep_oos' },
  { color: '#af2525E6', fieldKey: 'conversion_rate_old' },
  { color: '#af2525E6', fieldKey: 'conversion_rate' },
];

export const withDonutGraphStyles: Factory<SerializedStyles> = () => css`
  padding: 0 15% 15px;

  .empty_response {
    margin: 24px;
    font-size: 13px;
    color: #0d2d3d;
    justify-content: center;
  }
`;

export const withGraphStyles: Factory<SerializedStyles> = () => css`
  width: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 4px 16px;
  .no-data-wrapper {
    display: flex;
    margin-bottom: 36px;
    width: 100%;
    .no_data_message {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
      width: calc(100% - 84px);
      margin: auto;
      padding: 0px !important;
      background: #f2f2f2;
      border-radius: 16px;
      font-size: 13px;
      color: #0d2d3d;
    }
  }
  .title {
    padding-left: 42px !important;
    padding-right: 24px !important;
    .actions {
      float: right;

      .MuiFormLabel-root {
        font-size: 10px;
        color: ${themeColors.cyprus};
        transform: translate(14px, -6px);
        text-transform: none;
      }

      .MuiInputBase-formControl {
        font-size: 13px;

        .MuiSelect-select.MuiSelect-selectMenu {
          padding-top: 8px;
          padding-bottom: 8px;
          text-transform: capitalize;
        }
      }
    }
  }

  .graph_container {
    &.hide {
      display: none !important;
    }
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    padding-right: 16px;
    padding-left: 16px;
    .legends {
      max-width: 1114px;
      min-width: 860px;
      padding: 0 26px;
      height: 48px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      > ul {
        width: 50%;
        display: flex;
        padding: 0;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        z-index: 1;

        > li {
          &:not(:last-of-type) {
            margin-right: 9px;
          }
        }

        &:last-child {
          justify-content: flex-end;
        }
      }
    }

    .no_data_message {
      position: absolute;
      top: 12px;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-bottom: 50px;
      font-size: 13px;
      color: #0d2d3d;
    }
    .no_data_message {
      height: 90px;
      width: calc(100% - 84px);
      margin: auto;
      padding: 0 !important;
      background: #f2f2f2;
      border-radius: 16px;
      font-size: 13px;
      color: #0d2d3d;
    }
    .VictoryContainer {
      margin: 0 auto;
      z-index: 1;
    }
  }

  .performance-breakdown {
    padding-bottom: 18px;
    .MuiAccordionDetails-root {
      padding-left: 16px;
      padding-right: 16px;
    }
    .MuiAccordion-rounded:last-child {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    .MuiAccordion-root {
      width: 100%;
    }
    .MuiGrid-container {
      padding: 16px 20px 0;
    }

    .MuiAccordionSummary-root {
      padding: 0px 42px;
    }
    .breadcrumb-button {
      font-style: italic;
      font-size: 12px;
    }
    .clear-button {
      width: 30px !important;
      min-width: 30px !important;
    }
    .clear-button > span {
      width: 30px;
    }
    .performance-breakdown-title {
      font-size: 13px;
      color: ${themeColors.cyprus};
      font-weight: bold;
    }
    .accordian-button {
      color: #0d2d3d;
    }
    .performance-breakdown-table {
      border: 1px solid;
      max-height: 480px;
      overflow: auto;
      border-radius: 8px;
      border-color: ${themeColors.concrete};
      margin-bottom: 1em;
      table {
        .MuiTableCell-root {
          padding: 2px 10px;
          max-width: 400px;
          .MuiSvgIcon-root {
            height: 18px;
            width: 18px;
          }
        }
        .MuiTableCell-head {
          padding: 16px;
          text-align: center;
          font-weight: bold;
          white-space: nowrap;
        }

        .MuiTableCell-body,
        .MuiTableCell-head {
          font-size: 13px;
        }

        .data-slice-color {
          margin-right: 8px;
        }

        .positive_delta {
          color: ${themeColors.successGreen};
        }

        .negative_delta {
          color: ${themeColors.errorRed};
        }

        .border-left {
          border-left: 1px solid;
          border-color: ${themeColors.concrete};
        }
      }
    }
  }
  .add-variable-icon {
    height: 17px !important;
    width: 17px !important;
  }
  .checkbox-column {
    width: 1px !important;
    padding-left: 25px !important;
    padding-right: 7px !important;
    .MuiSvgIcon-root {
      width: 0.8em;
      height: 0.8em;
    }
  }
  .MuiCheckbox-root {
    padding: 0 !important;
  }
  .category-column {
    text-align: left !important;
  }
  .end-column {
    width: 1px;
    padding: 0 12.5px !important;
  }
`;

export const withLegendItemStyles: () => SerializedStyles = () => css`
  border: 1px solid ${themeColors.concrete};
  height: 32px;
  border-radius: 16px;
  font-size: 10px;
  padding: 2px 4px;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-left: 10px;
    margin-right: 10px;
  }

  span {
    margin-right: 10px;
  }

  button {
    width: 8px;
    height: 8px;
    padding: 4px;
    margin-right: 5px;
    svg {
      width: 0.6em;
      height: 0.6em;
    }
  }
`;
