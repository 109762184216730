import React from 'react';
import { VictoryLabel } from 'victory';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const XAxisLabel: React.Component = props => {
  return <VictoryLabel {...props} angle={-45} textAnchor={'end'} />;
};

export default XAxisLabel;
