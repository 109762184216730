import { useMemo } from 'react';
import { useQuery, UseQueryState } from 'urql';
import { Factory, FilterType } from '../../../common/types';
import {
  getAsins,
  GetAsinsResponse,
  getDataByAsinForWaterfall,
  getDataBySubcategoryForWaterfall,
  GetDataPointsAggregateResponse,
  getFilteredDataPoints,
  getFilteredDataPointsWaterfallGraph,
} from '../../../api/data';
import { useClients } from '../../../clients/useClients';
import { useSidebar } from '../../sidebar/useSidebar';
import { useHeader } from '../../header/useHeader';
import { DateRange } from '../types';
import { dateFormat } from '../../header/utils';
import {
  GetSubCategoriesByClientId,
  getSubCategoriesByClientId,
} from '../../../api/categories';

type AggregateResponseState = UseQueryState<GetDataPointsAggregateResponse>;

interface WaterfallGraphData {
  asinsResponse: UseQueryState<GetAsinsResponse>;
  asinsResponsePrevious: UseQueryState<GetAsinsResponse>;
  subcategoriesResponse: UseQueryState<GetSubCategoriesByClientId>;
  currentPeriodDates: DateRange;
  currentPeriodRevenueResponse: AggregateResponseState;
  currentRevenueBySubItemResponse: AggregateResponseState;
  previousPeriodDates: DateRange;
  previousPeriodRevenueResponse: AggregateResponseState;
  previousRevenueBySubItemResponse: AggregateResponseState;
  singleAsinSelected: boolean;
  subItemType: FilterType;
}

const useWaterfallGraphData: Factory<WaterfallGraphData> = () => {
  const { previousPeriodDates, selectedDates, selectedFilters } = useHeader();

  const { currentClient } = useClients();

  const { fieldKeys } = useSidebar();

  const selectedCategories = useMemo(
    () =>
      selectedFilters
        .filter(filter => filter.type === 'category')
        .map(category => category.id),
    [selectedFilters],
  );

  const selectedSubcategories = useMemo(
    () =>
      selectedFilters
        .filter(filter => filter.type === 'subcategory')
        .map(subcategory => subcategory.id),
    [selectedFilters],
  );

  const selectedAsins = useMemo(
    () =>
      selectedFilters
        .filter(filter => filter.type === 'asin')
        .map(asin => asin.id),
    [selectedFilters],
  );

  const sharedVariables = {
    client_id: currentClient.id,
    fieldConfiguration_fieldKey: fieldKeys.orderedRevenue,
    ...(selectedCategories.length > 0 && {
      asin_category_list: selectedCategories,
    }),
    ...(selectedSubcategories.length > 0 && {
      asin_subcategory_list: selectedSubcategories,
    }),
    ...(selectedAsins.length > 1 && {
      asin_id_list: selectedAsins,
    }),
  };

  const currentPeriodVariables = {
    startDate:
      selectedDates.startDate === null
        ? null
        : selectedDates.startDate.format(dateFormat),
    endDate:
      selectedDates.endDate === null
        ? null
        : selectedDates.endDate.format(dateFormat),
    ...sharedVariables,
  };

  const previousPeriodVariables = {
    startDate:
      previousPeriodDates.startDate === null
        ? null
        : previousPeriodDates.startDate.format(dateFormat),
    endDate:
      previousPeriodDates.endDate === null
        ? null
        : previousPeriodDates.endDate.format(dateFormat),
    ...sharedVariables,
  };

  const pauseQueries: boolean = useMemo(
    () =>
      currentClient.id === '' ||
      selectedDates.startDate === null ||
      selectedDates.endDate === null,
    [currentClient, selectedDates],
  );

  const [previousPeriodRevenueResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPoints,
    variables: {
      ...previousPeriodVariables,
    },
    pause: pauseQueries,
  });

  const [currentPeriodRevenueResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPoints,
    variables: {
      ...currentPeriodVariables,
    },
    pause: pauseQueries,
  });

  const [previousRevenueByCategoryResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsWaterfallGraph,
    variables: {
      ...previousPeriodVariables,
    },
    pause:
      pauseQueries ||
      selectedCategories.length > 0 ||
      selectedSubcategories.length > 0 ||
      selectedAsins.length > 1,
  });

  const [currentRevenueByCategoryResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsWaterfallGraph,
    variables: {
      ...currentPeriodVariables,
    },
    pause:
      pauseQueries ||
      selectedCategories.length > 0 ||
      selectedSubcategories.length > 0 ||
      selectedAsins.length > 1,
  });

  const [previousRevenueBySubcategoryResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataBySubcategoryForWaterfall,
    variables: {
      ...previousPeriodVariables,
    },
    pause: pauseQueries || selectedCategories.length === 0,
  });

  const [currentRevenueBySubcategoryResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataBySubcategoryForWaterfall,
    variables: {
      ...currentPeriodVariables,
    },
    pause: pauseQueries || selectedCategories.length === 0,
  });

  const [previousRevenueByAsinResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataByAsinForWaterfall,
    variables: {
      ...previousPeriodVariables,
    },
    pause:
      pauseQueries ||
      (selectedSubcategories.length === 0 && selectedAsins.length <= 1),
  });

  const [currentRevenueByAsinResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataByAsinForWaterfall,
    variables: {
      ...currentPeriodVariables,
    },
    pause:
      pauseQueries ||
      (selectedSubcategories.length === 0 && selectedAsins.length <= 1),
  });

  const currentRevenueBySubItemResponse = useMemo(() => {
    if (selectedCategories.length > 0) {
      return currentRevenueBySubcategoryResponse;
    }
    if (selectedSubcategories.length > 0 || selectedAsins.length > 1) {
      return currentRevenueByAsinResponse;
    }
    return currentRevenueByCategoryResponse;
  }, [
    currentRevenueByAsinResponse,
    currentRevenueByCategoryResponse,
    currentRevenueBySubcategoryResponse,
    selectedAsins,
    selectedCategories,
    selectedSubcategories,
  ]);

  const previousRevenueBySubItemResponse = useMemo(() => {
    if (selectedCategories.length > 0) {
      return previousRevenueBySubcategoryResponse;
    }
    if (selectedSubcategories.length > 0 || selectedAsins.length > 1) {
      return previousRevenueByAsinResponse;
    }
    return previousRevenueByCategoryResponse;
  }, [
    previousRevenueByAsinResponse,
    previousRevenueByCategoryResponse,
    previousRevenueBySubcategoryResponse,
    selectedAsins,
    selectedCategories,
    selectedSubcategories,
  ]);

  const asinIdsForSelectedSubCategories: string[] = useMemo(() => {
    if (
      !currentRevenueByAsinResponse.fetching &&
      !currentRevenueByAsinResponse.error &&
      currentRevenueByAsinResponse.data
    ) {
      return currentRevenueByAsinResponse.data?.dataPointAggregates.edges.map(
        edge => edge.node.asinId || '',
      );
    }
    return [];
  }, [currentRevenueByAsinResponse]);

  const asinIdsForSelectedSubCategoriesPrevious: string[] = useMemo(() => {
    if (
      !previousRevenueByAsinResponse.fetching &&
      !previousRevenueByAsinResponse.error &&
      previousRevenueByAsinResponse.data
    ) {
      return previousRevenueByAsinResponse.data?.dataPointAggregates.edges.map(
        edge => edge.node.asinId || '',
      );
    }
    return [];
  }, [previousRevenueByAsinResponse]);

  const subcategoriesForSelectedCategories: string[] = useMemo(() => {
    if (
      !currentRevenueBySubcategoryResponse.fetching &&
      !currentRevenueBySubcategoryResponse.error &&
      currentRevenueBySubcategoryResponse.data
    ) {
      const res = currentRevenueBySubcategoryResponse.data?.dataPointAggregates.edges.map(
        edge => edge.node.subcategoryId || '',
      );
      return res.filter(item => item !== '');
    }
    return [];
  }, [currentRevenueBySubcategoryResponse]);

  const [asinsResponse] = useQuery<GetAsinsResponse>({
    query: getAsins,
    variables: {
      asin_id_list: asinIdsForSelectedSubCategories,
    },
    pause: pauseQueries || asinIdsForSelectedSubCategories.length < 1,
  });

  const [asinsResponsePrevious] = useQuery<GetAsinsResponse>({
    query: getAsins,
    variables: {
      asin_id_list: asinIdsForSelectedSubCategoriesPrevious,
    },
    pause: pauseQueries || asinIdsForSelectedSubCategoriesPrevious.length < 1,
  });

  const [subcategoriesResponse] = useQuery<GetSubCategoriesByClientId>({
    query: getSubCategoriesByClientId,
    variables: {
      subcategories_id_list: subcategoriesForSelectedCategories,
    },
    pause: pauseQueries || subcategoriesForSelectedCategories.length < 1,
  });

  const subItemType = useMemo(() => {
    if (selectedCategories.length > 0) {
      return 'subcategory';
    }
    if (selectedSubcategories.length > 0 || selectedAsins.length > 1) {
      return 'asin';
    }
    return 'category';
  }, [selectedAsins, selectedSubcategories, selectedCategories]);

  return {
    asinsResponse,
    asinsResponsePrevious,
    subcategoriesResponse,
    currentPeriodDates: selectedDates,
    currentPeriodRevenueResponse,
    currentRevenueBySubItemResponse,
    previousPeriodDates,
    previousPeriodRevenueResponse,
    previousRevenueBySubItemResponse,
    singleAsinSelected: selectedAsins.length === 1,
    subItemType,
  };
};

export default useWaterfallGraphData;
