import { css, SerializedStyles } from '@emotion/core';
import { Factory } from '../../../common/types';
import { themeColors } from '../../../common/theme';

export const withDonutChartBreakdownStyles: Factory<SerializedStyles> = () => css`
  width: 100%;
  background: #fff;
  border-radius: 16px;
  border: 1px solid ${themeColors.concrete};
  padding: 16px;
  min-height: 240px;
  margin-bottom: 16px;

  .charts-wrapper {
    width: 100%;
    min-height: 325px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .MuiPaper-root {
      width: 49.5%;
      min-height: 350px;
      border: 1px solid ${themeColors.concrete};
      border-radius: 14px;
    }
  }

  .donut-chart-section {
    text-align: center;
    position: relative;

    h3 {
      text-align: left;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 0.52px;
      color: ${themeColors.cyprus};
      margin-top: 24px;
      margin-left: 30px;
    }
    h5 {
      font-size: 13px;
      font-weight: 700;
      -webkit-letter-spacing: 0.52px;
      -moz-letter-spacing: 0.52px;
      -ms-letter-spacing: 0.52px;
      letter-spacing: 0.52px;
      color: #0d2d3d;
      position: absolute;
      bottom: 24px;
      left: 30px;
    }

    &.donut-slice-breakdown {
      .MuiGrid-container {
        padding: 16px 20px 0;
      }

      .MuiAccordionSummary-root {
        padding: 0px 32px;
      }

      .slice-breakdown-title {
        font-size: 13px;
        color: ${themeColors.cyprus};
        font-weight: bold;
      }

      .accordian-button {
        color: #0d2d3d;
      }

      .slice-breakdown-table {
        border: 1px solid;
        border-radius: 8px;
        border-color: ${themeColors.concrete};
        margin-bottom: 1em;
        .color-swatch-column {
          display: inline-flex;
        }
        .data-column-header {
          width: 30%;
        }
        table {
          .MuiTableCell-head {
            font-weight: bold;
          }

          .MuiTableCell-body,
          .MuiTableCell-head {
            font-size: 13px;
          }

          .data-slice-color {
            margin-right: 8px;
          }

          .positive_delta {
            color: ${themeColors.successGreen};
          }

          .negative_delta {
            color: ${themeColors.errorRed};
          }

          .border-left {
            border-left: 1px solid;
            border-color: ${themeColors.concrete};
          }
          .column-data {
            padding: 16px 32px !important;
          }
        }
      }

      .slice-breakdown-item {
        font-size: 12px;
        line-height: 1.5em;
        text-align: left;
        padding: 0 0 38px;
        .data-slice-color {
          width: 9px;
          height: 9px;
          border-radius: 50%;
          float: left;
          margin-top: 0.35em;
        }
        .data-slice-color.data-slice-border {
          background: #fbfbfb 0% 0% no-repeat padding-box;
          border: 1px solid #0e3142;
        }

        .data-slice-label {
          font-weight: bold;
        }
        .data-slice-label:first-child {
          padding-bottom: 3px;
        }

        .data-slice-label,
        .data-slice-details,
        .show-all-slices {
          margin-left: 1.5em;
        }

        .data-slice-details {
          padding-bottom: 0;

          &:last-child {
            padding-top: 8px;
          }

          div:first-child {
            padding-bottom: 5px;
          }
          div {
            padding-bottom: 2px;
          }
        }

        .show-all-slices {
          button {
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
            font-size: 1em;
          }
        }
      }
    }

    .graph-container {
      position: relative;
      margin-bottom: 5% !important;
      .selected-slice-details {
        pointer-events: none;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div {
          pointer-events: auto !important;
        }
        height: 50%;
        width: 40%;
        top: 25%;
        left: 30%;
        line-height: 1.25em;
        text-align: center;
        font-size: 13px;
        font-weight: bold;
        color: #0d2d3d;

        .truncate {
          text-overflow: ellipsis;
          max-width: 100%;
          position: relative;
          max-height: 5em;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .chart-titles-section {
      padding: 16px 32px 0;

      .chart-title,
      .values-total {
        text-align: left;
      }
    }
  }
`;
