import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface PasswordFieldProps {
  fieldKey: string;
  id: string;
  label: string;
  onChange: (key: string, value: string) => void;
  value: string;
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  fieldKey,
  id,
  label,
  onChange,
  value,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl className="form-control" variant="outlined" fullWidth>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={event => onChange(fieldKey, event.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={label.length * 10}
      />
    </FormControl>
  );
};

export default PasswordField;
