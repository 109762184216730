import { UseQueryState } from 'urql';
import {
  GetAsinsResponse,
  GetDataPointsAggregateResponse,
} from '../../../api/data';
import { LabeledDataPoint } from '../../../common/types';
import { extractUuidFromFullPath } from '../../../common/utils';
import { donutChartBreakdownColors } from '../../../common/theme';
import { DataFilters } from './useDonutChartData';

interface TransformerInput {
  baseGroupIds?: string[];
  filters: DataFilters;
  loading: boolean;
  maxGroupsCount: number;
  responses: {
    asins: UseQueryState<GetAsinsResponse>;
    groupedByAsin: UseQueryState<GetDataPointsAggregateResponse>;
    groupedByCategory: UseQueryState<GetDataPointsAggregateResponse>;
    groupedBySubcategory: UseQueryState<GetDataPointsAggregateResponse>;
  };
}
type GroupedBy = 'category' | 'subcategory' | 'asinId';

interface TransformerOutput {
  allData: LabeledDataPoint[];
  colors: string[];
  data: LabeledDataPoint[];
  extraGroup: LabeledDataPoint[];
  total: number;
}

export const transformResponseDataToDonutGraphData: (
  arg: TransformerInput,
) => TransformerOutput = ({
  baseGroupIds,
  filters,
  loading,
  maxGroupsCount,
  responses,
}) => {
  if (loading) {
    return {
      allData: [],
      colors: [...donutChartBreakdownColors],
      data: [],
      extraGroup: [],
      total: 0,
    };
  }
  if (
    filters.asins.length === 0 &&
    filters.categories.length === 0 &&
    filters.subCategories.length === 0
  ) {
    return genericTransformer({
      data: responses.groupedByCategory,
      groupedBy: 'category',
      maxGroupsCount,
      baseGroupIds,
    });
  }

  if (filters.categories.length > 0) {
    return genericTransformer({
      data: responses.groupedBySubcategory,
      groupedBy: 'subcategory',
      maxGroupsCount,
      baseGroupIds,
    });
  }

  if (filters.subCategories.length > 0 || filters.asins.length > 0) {
    return genericTransformer({
      asins: responses.asins,
      data: responses.groupedByAsin,
      groupedBy: 'asinId',
      maxGroupsCount,
      baseGroupIds,
    });
  }

  return {
    allData: [],
    colors: [...donutChartBreakdownColors],
    data: [],
    extraGroup: [],
    total: 0,
  };
};

const genericTransformer: (arg: {
  asins?: UseQueryState<GetAsinsResponse>;
  baseGroupIds?: string[];
  data: UseQueryState<GetDataPointsAggregateResponse>;
  groupedBy: GroupedBy;
  maxGroupsCount: number;
}) => TransformerOutput = ({
  asins,
  baseGroupIds,
  data,
  groupedBy,
  maxGroupsCount,
}) => {
  const extraGroupLabel = 'All Others';
  const extraGroupId = 'all-others';
  const orderedColors = [...donutChartBreakdownColors];
  const mappedData =
    data.data?.dataPointAggregates.edges
      .map((edge, i) => {
        let label =
          (edge.node[groupedBy]
            ? data.data?.dataPointAggregates.edges.findIndex(
                _datum => _datum.node[groupedBy] === edge.node[groupedBy],
              ) !== i
              ? `${edge.node[groupedBy]} `
              : edge.node[groupedBy]
            : 'Uncategorized') || '';
        let secondLabel = '';
        if (groupedBy === 'asinId') {
          const asin = asins?.data?.asins.edges.find(
            _edge =>
              extractUuidFromFullPath(_edge.node.id) === edge.node.asinId,
          )?.node;
          label = `${asin?.productName ?? ''}`;
          secondLabel = `(${asin?.asin})`;
        }
        return {
          id: `${label.replace(' ', '-')}${secondLabel}`,
          x: 1,
          y: parseFloat(edge.node.value),
          label,
          ...(secondLabel !== '' && { secondLabel }),
        };
      })
      .sort((a, b) => b.y - a.y) || [];
  const dataGroups: LabeledDataPoint[] = [];
  const extraGroup: LabeledDataPoint[] = [];
  let valueSum = 0;
  if (!baseGroupIds) {
    mappedData.forEach(item => {
      if (dataGroups?.length < maxGroupsCount) {
        dataGroups.push(item);
      } else if (dataGroups?.length === maxGroupsCount) {
        dataGroups.push({
          id: extraGroupId,
          y: item.y,
          label: extraGroupLabel,
        });
        extraGroup.push(item);
      } else {
        dataGroups[maxGroupsCount].y += item.y;
        extraGroup.push(item);
      }
      valueSum += item.y;
    });
  } else {
    const extraGroupData: LabeledDataPoint = {
      id: extraGroupId,
      y: 0,
      label: extraGroupLabel,
    };
    let addExtraGroup = false;

    mappedData.forEach(item => {
      const itemId = item.id;
      if (baseGroupIds.includes(itemId) && item.id !== extraGroupId) {
        dataGroups.push(item);
        const itemIndex = dataGroups.length - 1;
        const colorIndex = baseGroupIds.indexOf(itemId);
        orderedColors[itemIndex] = donutChartBreakdownColors[colorIndex];
      } else {
        extraGroupData.y += item.y;
        addExtraGroup = true;
        extraGroup.push(item);
      }
      valueSum += item.y;
    });
    if (addExtraGroup) {
      dataGroups.push(extraGroupData);
    }
  }
  return {
    allData: mappedData,
    colors: orderedColors,
    data: dataGroups,
    extraGroup: extraGroup,
    total: valueSum,
  };
};
