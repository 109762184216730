import gql from 'graphql-tag';
import { SubCategory } from './categories';

export const getAsinsByClientId = gql`
  query GetAsinsByClientId($dataPoints_client: String) {
    asins(first: 10000, client_id: $dataPoints_client) {
      edges {
        node {
          id
          asin
          productName
          subcategory {
            id
            name
            category {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export interface Asin {
  asin: string;
  id: string;
  productName?: string;
  subcategory?: SubCategory;
}

export interface GetAsinsByClientIdResponse {
  asins: {
    edges: {
      node: Asin;
    }[];
  };
}
