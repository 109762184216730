import React, { useMemo, useState } from 'react';
import { useQuery } from 'urql';

import styled from '@emotion/styled';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import DefaultAccordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import CustomTooltip from '../Tooltip';
import FilterList from './FilterList';
import LoadingIndicator from '../LoadingIndicator';
import FullScreenModal from '../fullScreenModal/FullScreenModal';
import { Change, DataType } from '../../common/types';
import { useClients } from '../../clients/useClients';
import { useHeader } from '../header/useHeader';
import {
  getAsins,
  GetAsinsResponse,
  GetDataPointsAggregateResponse,
  getDataPointsAsinGrouped,
  getDataPointsSubcategoryGrouped,
  getFilteredDataPoints,
  getFilteredDataPointsWaterfallGraph,
} from '../../api/data';
import {
  transformDataPointAggregatePrimary,
  transformOrderedRevenueTopFiveFilterData,
} from '../dashboard/transformers';
import { withAccordionOverrides, withPrimaryBreakdownStyles } from './styles';
import { prettyPrintDate } from '../graphs/waterfall/utils';
import { PrimaryBreakdownDefinition } from './PrimaryBreakdownDefinitions';
import { dateFormat } from '../header/utils';
import { prefixPositiveOrNegative } from '../../common/utils';

const Breakdown = styled.div`
  ${withPrimaryBreakdownStyles()}
  min-height: 110px !important;
`;

const Accordion = styled(DefaultAccordion)`
  ${withAccordionOverrides(true)}
`;

const IconBtn = styled(IconButton)`
  position: absolute !important;
  top: 8px !important;
  right: 4px !important;
  color: #000 !important;
  &::hover {
    color: #000 !important;
  }
`;
const StyledMenu = styled(Menu)`
  ul {
    min-width: 207px !important;
    min-height: 66px !important;
    padding: 0 !important;
  }
  li {
    text-align: left !important;
    font: normal normal normal 13px/20px Montserrat !important;
    letter-spacing: 0px !important;
    color: #0d2d3d !important;
    opacity: 1 !important;
    height: 66px !important;
    padding-left: 25px !important;
  }
`;

export interface PrimaryBreakdownData {
  delta: {
    change: Change;
    percentage: string;
    value: string;
  };
  error?: boolean;
  processing: boolean;
  value: string;
}

export interface Props {
  fieldKey: string;
  link: string;
  title: string;
  deltaValueType: DataType;
}

const PrimaryBreakdown: React.FC<Props> = ({
  fieldKey,
  title,
  deltaValueType,
}) => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [showDefinitionModal, setShowDefinitionModal] = useState(false);

  const { currentClient } = useClients();

  const {
    allFilters,
    selectedFilters,
    selectedDates,
    previousPeriodDates,
    isReady,
  } = useHeader();

  const pauseQueries: boolean = useMemo(
    () =>
      currentClient.id === '' ||
      selectedDates.startDate === null ||
      selectedDates.endDate === null,
    [currentClient, selectedDates],
  );

  const [getDataPointsAggregateResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPoints,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: fieldKey,
      asin_id_list: selectedFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id),
      asin_category_list: selectedFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
      asin_subcategory_list: selectedFilters
        .filter(filter => filter.type === 'subcategory')
        .map(filter => filter.id),
    },
    pause: pauseQueries,
  });

  const [getDataPointsAggregatePreviousPeriodResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPoints,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: fieldKey,
      asin_id_list: selectedFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id),
      asin_category_list: selectedFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
      asin_subcategory_list: selectedFilters
        .filter(filter => filter.type === 'subcategory')
        .map(filter => filter.id),
    },
    pause: pauseQueries,
  });

  const data = transformDataPointAggregatePrimary(
    getDataPointsAggregateResponse,
    getDataPointsAggregatePreviousPeriodResponse,
    deltaValueType,
  );

  const [getTopFiveAsinsDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: fieldKey,
      order: 'desc',
      asin_id_list: selectedFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id),
      asin_category_list: selectedFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
      asin_subcategory_list: selectedFilters
        .filter(filter => filter.type === 'subcategory')
        .map(filter => filter.id),
    },
    pause:
      pauseQueries ||
      selectedFilters.length === 0 ||
      !['subcategory', 'asin'].includes(selectedFilters[0].type),
  });

  const [getTopFiveAsinsPreviousDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGrouped,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: fieldKey,
      asin_id_list:
        getTopFiveAsinsDataResponse.data &&
        getTopFiveAsinsDataResponse.data.dataPointAggregates &&
        getTopFiveAsinsDataResponse.data.dataPointAggregates.edges
          ? getTopFiveAsinsDataResponse.data.dataPointAggregates.edges
              .slice(0, 5)
              .map(edge => edge.node.asinId)
          : [],
    },
    pause:
      pauseQueries ||
      selectedFilters.length === 0 ||
      !['subcategory', 'asin'].includes(selectedFilters[0].type) ||
      getTopFiveAsinsDataResponse.fetching,
  });

  const asinFilterList =
    selectedFilters
      .filter(filter => filter.type === 'asin')
      .map(filter => filter.id).length > 0
      ? selectedFilters
          .filter(filter => filter.type === 'asin')
          .map(filter => filter.id)
      : getTopFiveAsinsDataResponse.data &&
        getTopFiveAsinsDataResponse.data.dataPointAggregates &&
        getTopFiveAsinsDataResponse.data.dataPointAggregates.edges
      ? getTopFiveAsinsDataResponse.data.dataPointAggregates.edges
          .slice(0, 5)
          .map(edge => edge.node.asinId)
      : [];

  const [getAsinsResponse] = useQuery<GetAsinsResponse>({
    query: getAsins,
    variables: {
      asin_id_list: asinFilterList,
    },
    pause:
      pauseQueries ||
      getTopFiveAsinsDataResponse.fetching ||
      asinFilterList.length === 0 ||
      (selectedFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id).length > 0
        ? false
        : getTopFiveAsinsDataResponse.data &&
          getTopFiveAsinsDataResponse.data.dataPointAggregates &&
          getTopFiveAsinsDataResponse.data.dataPointAggregates.edges &&
          getTopFiveAsinsDataResponse.data.dataPointAggregates.edges.length ===
            0),
  });
  const [getTopFiveCategoryDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsWaterfallGraph,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: fieldKey,
      order: 'desc',
      asin_id_list: selectedFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id),
      asin_category_list: selectedFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
      asin_subcategory_list: selectedFilters
        .filter(filter => filter.type === 'subcategory')
        .map(filter => filter.id),
    },
    pause: pauseQueries || (selectedFilters.length !== 0 && !allFilters),
  });

  const [getTopFivePreviousCategoryDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsWaterfallGraph,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: fieldKey,
      order: 'desc',
      asin_category_list:
        getTopFiveCategoryDataResponse.data &&
        getTopFiveCategoryDataResponse.data.dataPointAggregates &&
        getTopFiveCategoryDataResponse.data.dataPointAggregates.edges
          ? getTopFiveCategoryDataResponse.data.dataPointAggregates.edges
              .slice(0, 5)
              .map(edge => edge.node.categoryId)
          : [],
    },
    pause:
      pauseQueries ||
      (selectedFilters.length !== 0 && !allFilters) ||
      getTopFiveCategoryDataResponse.fetching,
  });

  const [getTopFiveSubCategoryDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsSubcategoryGrouped,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: fieldKey,
      order: 'desc',
      asin_category_list: selectedFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
    },
    pause:
      pauseQueries ||
      allFilters ||
      selectedFilters.length === 0 ||
      selectedFilters[0].type !== 'category',
  });

  const [getTopFivePreviousSubCategoryDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsSubcategoryGrouped,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: fieldKey,
      order: 'desc',
      asin_subCategory_list:
        getTopFiveSubCategoryDataResponse.data &&
        getTopFiveSubCategoryDataResponse.data.dataPointAggregates &&
        getTopFiveSubCategoryDataResponse.data.dataPointAggregates.edges
          ? getTopFiveSubCategoryDataResponse.data.dataPointAggregates.edges
              .slice(0, 5)
              .map(edge => edge.node.subcategoryId)
          : [],
    },
    pause:
      pauseQueries ||
      allFilters ||
      selectedFilters.length === 0 ||
      selectedFilters[0].type !== 'category' ||
      getTopFiveSubCategoryDataResponse.fetching ||
      (getTopFiveSubCategoryDataResponse.data &&
        getTopFiveSubCategoryDataResponse.data.dataPointAggregates &&
        getTopFiveSubCategoryDataResponse.data.dataPointAggregates.edges &&
        getTopFiveSubCategoryDataResponse.data.dataPointAggregates.edges
          .length === 0),
  });

  const deltaTitleTooltipText = (
    <div style={{ textAlign: 'center' }}>
      Change from <br />
      {prettyPrintDate(previousPeriodDates.startDate)} to{' '}
      {prettyPrintDate(previousPeriodDates.endDate)}
    </div>
  );

  const handleMoreVertButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleViewDefinitionClick = () => {
    setShowDefinitionModal(true);
    handleMenuClose();
  };
  const singleAsinSelected = () =>
    selectedFilters?.length === 1 && selectedFilters[0]?.type === 'asin';

  const ORTop5Data = transformOrderedRevenueTopFiveFilterData(
    selectedFilters.length === 0 || allFilters
      ? getTopFiveCategoryDataResponse
      : selectedFilters[0].type === 'category'
      ? getTopFiveSubCategoryDataResponse
      : getTopFiveAsinsDataResponse,
    selectedFilters.length === 0 || allFilters
      ? getTopFivePreviousCategoryDataResponse
      : selectedFilters[0].type === 'category'
      ? getTopFivePreviousSubCategoryDataResponse
      : getTopFiveAsinsPreviousDataResponse,
    selectedFilters.length === 0 || allFilters
      ? 'category'
      : selectedFilters[0].type === 'category'
      ? 'subcategory'
      : 'asin',
    deltaValueType,
    getAsinsResponse,
  );
  const showSpinner =
    !isReady ||
    getDataPointsAggregateResponse.fetching ||
    getDataPointsAggregatePreviousPeriodResponse.fetching ||
    data.processing;
  return (
    <Breakdown className={accordionExpanded ? 'expanded' : ''}>
      {showSpinner ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="title">{title}</div>
          <div className="data">
            {data.error ? (
              <p className="empty_response">No data available</p>
            ) : (
              <>
                <IconBtn
                  className="more-vert-icon"
                  aria-controls="more-vert-menu"
                  aria-haspopup="true"
                  onClick={handleMoreVertButtonClick}
                  disableFocusRipple={true}
                >
                  <MoreVertIcon color="inherit" />
                </IconBtn>
                <StyledMenu
                  id="more-vert-menu"
                  anchorEl={menuAnchorEl}
                  keepMounted
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    className="menu-button"
                    onClick={() => handleViewDefinitionClick()}
                  >
                    Show Definition
                  </MenuItem>
                </StyledMenu>
                <div className="total">{data.value}</div>
                <div className="delta">
                  <div className="delta_title">
                    Change&nbsp;
                    <CustomTooltip title={deltaTitleTooltipText} arrow>
                      <InfoIcon className="info-icon" />
                    </CustomTooltip>
                  </div>
                  <div className="delta_data">
                    <span className={`${data.delta.change}_delta`}>
                      {`${prefixPositiveOrNegative(
                        data.delta.change,
                      )}${data.delta.percentage.trim().replace('-', '')}`}
                    </span>
                    <span className="delta_value">{data.delta.value}</span>
                  </div>
                </div>
              </>
            )}
          </div>
          {(!data.error &&
            !singleAsinSelected() &&
            data.value !== '--' &&
            ORTop5Data.data.length && (
              <Accordion
                elevation={0}
                expanded={accordionExpanded}
                onChange={() => setAccordionExpanded(!accordionExpanded)}
              >
                <AccordionSummary
                  className="breakdown_summary_container"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <span className="breakdown_summary">
                    Top{' '}
                    {selectedFilters.length === 0 || allFilters
                      ? 'Categories'
                      : selectedFilters[0].type === 'category'
                      ? 'Subcategories'
                      : 'ASINs'}
                  </span>
                  <span className="breakdown_summary accordian-button">
                    {accordionExpanded ? 'Collapse' : 'Expand'}
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <FilterList {...ORTop5Data} />
                </AccordionDetails>
              </Accordion>
            )) ||
            null}
          <FullScreenModal
            open={showDefinitionModal}
            onClose={() => setShowDefinitionModal(false)}
          >
            <PrimaryBreakdownDefinition fieldKey={fieldKey} />
          </FullScreenModal>
        </>
      )}
    </Breakdown>
  );
};

export default PrimaryBreakdown;
