import styled from '@emotion/styled';
import { withGraphStyles } from '../styles';
import { themeColors } from '../../../common/theme';

export const waterfallGraphColors = {
  neonCarrot: themeColors.neonCarrot,
  hitPink: '#fdaf72',
  whiteSmoke: '#f2f2f2',
  white: themeColors.white,
  axis: themeColors.gainsboro,
};

export const Container = styled.section`
  position: relative;
  ${withGraphStyles()}
  .waterfall-column {
    padding: 13px 13px 13px 25px !important;
  }
  .spacer-column {
    width: 1px !important;
    padding: 4px;
  }
  .detail-columns {
    width: 16%;
    padding: 16px 42px 16px 16px !important;
  }
  .more-vert-icon {
    right: 20px !important;
    top: 20px !important;
  }
  .waterfall-graph-container {
    // height: 515px !important;
    padding-bottom: 11.5% !important;
  }
  .date-range {
    max-width: 1147px;
    min-width: 895px;
    display: flex;
    padding: 0 49px 0 42px;
    flex-direction: row;
    justify-content: space-between;
    font-family: Montserrat, Helvetica, sans-serif;
    font-size: 12px;
    fill: ${themeColors.cyprus};
    margin-top: 20px;
  }
`;
