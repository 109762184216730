import { createMuiTheme } from '@material-ui/core';
import { css, SerializedStyles } from '@emotion/core';
import { themeColors, themeGradient } from '../../common/theme';
import { Factory } from '../../common/types';

export const loginFormTheme = createMuiTheme({
  typography: {
    fontFamily: ['Montserrat', 'Helvetica', 'sans-serif'].join(','),
    h1: {
      display: 'none',
    },
    body1: {
      fontSize: '13px',
    },
  },
  palette: {
    primary: {
      main: themeColors.white,
    },
    text: {
      primary: themeColors.white,
    },
    background: {
      default: themeGradient(122),
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          background: themeGradient(122),
        },
      },
    },
    MuiTextField: {
      root: {
        width: '100%',
        marginBottom: '24px',
      },
    },
    MuiFormLabel: {
      root: {
        color: themeColors.white,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: themeColors.hippieBlue,
      },
      input: {
        padding: '14px',
        '&:-webkit-autofill': {
          '-webkit-transition-delay': '99999s',
        },
        '&:-webkit-autofill:hover': {
          '-webkit-transition-delay': '99999s',
        },
        '&:-webkit-autofill:focus': {
          '-webkit-transition-delay': '99999s',
        },
        '&:-webkit-autofill: active': {
          '-webkit-transition-delay': '99999s',
        },
      },
    },
    MuiButton: {
      outlined: {
        borderColor: themeColors.casablanca,
        borderRadius: '23px',
        textTransform: 'none',
        padding: '12px 24px',
        fontWeight: 'bold',
        lineHeight: '1.1',
        '&:hover:enabled': {
          backgroundColor: themeColors.casablanca,
          color: '#0f394e',
        },
        '&:disabled': {
          borderColor: themeColors.casablanca,
          color: '#0f394e',
          opacity: '0.5',
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(14px, 15px) scale(1)',
      },
    },
    MuiLink: {
      root: {
        display: 'block',
        marginTop: '32px',
      },
    },
    MuiCheckbox: {
      root: {
        color: themeColors.hippieBlue,
      },
    },
  },
});

export const withFormStyles: Factory<SerializedStyles> = () => css`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  overflow: auto;
  margin-left: 40px;
  margin-right: 40px;
  width: calc(100vw - 80px);

  form {
    width: 372px;
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 425px) {
      width: calc(100%);
    }

    .form-control {
      margin-bottom: 16px;
    }

    .info {
      width: 100%;
      margin-top: 52px;
      border-radius: 16px;
      background: ${themeColors.allports};
      color: ${themeColors.white};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 34px 80px;
      font-size: 13px;
      letter-spacing: 0;
      text-align: center;
      line-height: 1.53em;
      @media screen and (max-width: 425px) {
        padding: 34px;
      }
      span.emphasize {
        font-weight: 700;
        margin-bottom: 6px;
      }
      span {
        line-height: 20px;
        padding-top: 6px;
      }
    }
  }
`;
