import { UseQueryState } from 'urql';
import {
  GetAsinsResponse,
  GetDataPointsAggregateResponse,
} from '../../../api/data';
import { RowItem } from './AsinBreakdown';
import { SortOption } from './useAsinPagination';
import {
  getAsinValueByDataPointAsinId,
  getMatchedDataPointValueByAsin,
  getMatchedDataPointValueByAsinId,
  prettyPrintLongformCurrency,
} from './utils';
import { extractUuidFromFullPath } from '../../../common/utils';
import { Asin } from '../../../api/asin';

export const transformDataTable: (
  previousResponse: UseQueryState<GetDataPointsAggregateResponse>,
  currentResponse: UseQueryState<GetDataPointsAggregateResponse>,
  asinsResponse: UseQueryState<GetAsinsResponse>,
  getOrderedUnitsResponse: UseQueryState<GetDataPointsAggregateResponse>,
  sortType: SortOption,
) => RowItem[] = (
  previousResponse,
  currentResponse,
  asinsResponse,
  getOrderedUnitsResponse,
  sortType,
) => {
  if (
    previousResponse.data &&
    previousResponse.data.dataPointAggregates &&
    currentResponse.data &&
    currentResponse.data.dataPointAggregates &&
    asinsResponse.data &&
    asinsResponse.data.asins &&
    !currentResponse.error &&
    !previousResponse.error &&
    !asinsResponse.error &&
    getOrderedUnitsResponse.data &&
    getOrderedUnitsResponse.data.dataPointAggregates &&
    !getOrderedUnitsResponse.error
  ) {
    const asins = asinsResponse.data.asins.edges.map(edge => edge.node);
    const orderedRevenueDataPoints = currentResponse.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const orderedRevenuePageTotal =
      orderedRevenueDataPoints.length > 0
        ? prettyPrintLongformCurrency(
            orderedRevenueDataPoints
              .reduce((sum, value) => sum + parseFloat(value.value), 0)
              .toString(),
          )
        : 'N/A';

    const orderedRevenuePreviousDataPoints = previousResponse.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const orderedRevenuePreviousPageTotal =
      orderedRevenuePreviousDataPoints.length > 0
        ? prettyPrintLongformCurrency(
            orderedRevenuePreviousDataPoints
              .reduce((sum, value) => sum + parseFloat(value.value), 0)
              .toString(),
          )
        : 'N/A';

    const orderedUnitsDataPoints = getOrderedUnitsResponse.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const orderedUnitsPageTotal =
      orderedUnitsDataPoints.length > 0
        ? orderedUnitsDataPoints
            .reduce((sum, value) => sum + parseInt(value.value), 0)
            .toLocaleString()
        : 'N/A';
    let rowsWithoutPageTotal: RowItem[] = [];
    if (sortType.column === 'orderedRevenueCurrent') {
      rowsWithoutPageTotal = orderedRevenueDataPoints.map(dataPoint => {
        const { asin, productName } = getAsinValueByDataPointAsinId({
          asins,
          dataPoint,
        });
        const orderedRevenuePrevious = getMatchedDataPointValueByAsinId({
          dataPoints: orderedRevenuePreviousDataPoints,
          dataPoint,
        });
        const orderedUnits = getMatchedDataPointValueByAsinId({
          dataPoints: orderedUnitsDataPoints,
          dataPoint,
          isCurrency: false,
        });
        return {
          asin,
          productName,
          asinId: dataPoint.asinId as string,
          category: dataPoint.category ? dataPoint.category : 'Uncategorized',
          subcategory: dataPoint.subcategory
            ? dataPoint.subcategory
            : 'Uncategorized',
          orderedRevenueCurrent: prettyPrintLongformCurrency(dataPoint.value),
          orderedRevenuePrevious,
          orderedUnits,
        };
      });
    }
    if (sortType.column === 'orderedRevenuePrevious') {
      rowsWithoutPageTotal = orderedRevenuePreviousDataPoints.map(dataPoint => {
        const { asin, productName } = getAsinValueByDataPointAsinId({
          asins,
          dataPoint,
        });
        const orderedRevenue = getMatchedDataPointValueByAsinId({
          dataPoints: orderedRevenueDataPoints,
          dataPoint,
        });
        const orderedUnits = getMatchedDataPointValueByAsinId({
          dataPoints: orderedUnitsDataPoints,
          dataPoint,
          isCurrency: false,
        });
        return {
          asin,
          productName,
          asinId: dataPoint.asinId as string,
          category: dataPoint.category ? dataPoint.category : 'Uncategorized',
          subcategory: dataPoint.subcategory
            ? dataPoint.subcategory
            : 'Uncategorized',
          orderedRevenueCurrent: orderedRevenue,
          orderedRevenuePrevious: prettyPrintLongformCurrency(dataPoint.value),
          orderedUnits,
        };
      });
    }
    if (sortType.column === 'orderedUnits') {
      rowsWithoutPageTotal = orderedUnitsDataPoints.map(dataPoint => {
        const { asin, productName } = getAsinValueByDataPointAsinId({
          asins,
          dataPoint,
        });
        const orderedRevenueCurrent = getMatchedDataPointValueByAsinId({
          dataPoints: orderedRevenueDataPoints,
          dataPoint,
        });
        const orderedRevenuePrevious = getMatchedDataPointValueByAsinId({
          dataPoints: orderedRevenuePreviousDataPoints,
          dataPoint,
        });
        return {
          asin,
          productName,
          asinId: dataPoint.asinId as string,
          category: dataPoint.category ? dataPoint.category : 'Uncategorized',
          subcategory: dataPoint.subcategory
            ? dataPoint.subcategory
            : 'Uncategorized',
          orderedRevenueCurrent,
          orderedRevenuePrevious,
          orderedUnits: dataPoint.value.toLocaleString(),
        };
      });
    }
    if (
      sortType.column === 'asin' ||
      sortType.column === 'productName' ||
      sortType.column === 'category' ||
      sortType.column === 'subcategory'
    ) {
      let sortFunction = (a: Asin, b: Asin) => {
        console.log(a);
        console.log(b);
        return 1;
      };
      switch (sortType.column) {
        case 'asin':
          sortFunction = (a: Asin, b: Asin) => {
            if (sortType.type === 'asc') {
              return a.asin > b.asin ? 1 : -1;
            } else {
              return b.asin > a.asin ? 1 : -1;
            }
          };
          break;
        case 'category':
          sortFunction = (a: Asin, b: Asin) => {
            const aText = a.subcategory?.category.name
              ? a.subcategory?.category.name
              : '';
            const bText = b.subcategory?.category.name
              ? b.subcategory?.category.name
              : '';
            if (sortType.type === 'asc') {
              return aText > bText ? 1 : -1;
            } else {
              return bText > aText ? 1 : -1;
            }
          };
          break;
        case 'productName':
          sortFunction = (a: Asin, b: Asin) => {
            const aText = a.productName;
            const bText = b.productName;
            if (sortType.type === 'asc') {
              if (aText === null || aText === undefined) {
                return 1;
              }
              if (bText === null || bText === undefined) {
                return -1;
              }
              return aText > bText ? 1 : -1;
            } else {
              if (bText === null || bText === undefined) {
                return 1;
              }
              if (aText === null || aText === undefined) {
                return -1;
              }
              return bText > aText ? 1 : -1;
            }
          };
          break;
        case 'subcategory':
          sortFunction = (a: Asin, b: Asin) => {
            const aText = a.subcategory?.name ? a.subcategory?.name : '';
            const bText = b.subcategory?.name ? b.subcategory?.name : '';
            if (sortType.type === 'asc') {
              return aText > bText ? 1 : -1;
            } else {
              return bText > aText ? 1 : -1;
            }
          };
          break;
      }
      rowsWithoutPageTotal = asins.sort(sortFunction).map(_asin => {
        const orderedRevenueCurrent = getMatchedDataPointValueByAsin({
          dataPoints: orderedRevenueDataPoints,
          asin: extractUuidFromFullPath(_asin.id),
        });
        const orderedRevenuePrevious = getMatchedDataPointValueByAsin({
          dataPoints: orderedRevenuePreviousDataPoints,
          asin: extractUuidFromFullPath(_asin.id),
        });
        const orderedUnits = getMatchedDataPointValueByAsin({
          dataPoints: orderedUnitsDataPoints,
          asin: extractUuidFromFullPath(_asin.id),
          isCurrency: false,
        });
        return {
          asin: _asin.asin,
          productName: _asin.productName ? _asin.productName : '',
          asinId: extractUuidFromFullPath(_asin.id) as string,
          category: _asin.subcategory
            ? _asin.subcategory
              ? _asin.subcategory.category.name
              : 'Uncategorized'
            : 'Uncategorized',
          subcategory: _asin.subcategory
            ? _asin.subcategory.name
            : 'Uncategorized',
          orderedRevenueCurrent,
          orderedRevenuePrevious,
          orderedUnits,
        };
      });
    }
    return rowsWithoutPageTotal.concat({
      asin: `Page Total (${rowsWithoutPageTotal.length})`,
      productName: '',
      asinId: '',
      category: '-',
      subcategory: '',
      orderedRevenueCurrent: orderedRevenuePageTotal,
      orderedRevenuePrevious: orderedRevenuePreviousPageTotal,
      orderedUnits: orderedUnitsPageTotal,
    });
  }
  return [];
};
export interface WeekItem {
  week: string;
  value: string;
}
export interface WeeksRowItem {
  asinId: string;
  weeks: WeekItem[];
}
export const transformWeeksDataTable: (
  weeksResponse: UseQueryState<GetDataPointsAggregateResponse>,
) => WeeksRowItem[] = weeksResponse => {
  if (
    weeksResponse.data &&
    weeksResponse.data.dataPointAggregates &&
    !weeksResponse.error
  ) {
    const orderedRevenueDataPoints = weeksResponse.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const weeksRows: WeeksRowItem[] = [];
    orderedRevenueDataPoints.forEach(dataPoint => {
      const foundRow = weeksRows.find(
        _weekRow => _weekRow.asinId === dataPoint.asinId,
      );
      if (undefined === foundRow) {
        weeksRows.push({
          asinId: dataPoint.asinId,
          weeks: [
            { week: dataPoint.timestamp?.slice(-2), value: dataPoint.value },
          ],
        } as WeeksRowItem);
      } else {
        foundRow.weeks.push({
          week: dataPoint.timestamp?.slice(-2),
          value: dataPoint.value,
        } as WeekItem);
      }
    });
    return weeksRows;
  }
  return [];
};

export interface ColumnTotals {
  orderedRevenueCurrent: string;
  orderedUnits: string;
  orderedRevenuePrevious: string;
  [weekNumber: string]: string;
}

export const getColumnTotalsFromDataResponses: (arg: {
  orderedRevenueResponse: UseQueryState<GetDataPointsAggregateResponse>;
  orderedUnitsResponse: UseQueryState<GetDataPointsAggregateResponse>;
  orderedRevenuePreviousResponse: UseQueryState<GetDataPointsAggregateResponse>;
}) => ColumnTotals = ({
  orderedRevenueResponse,
  orderedUnitsResponse,
  orderedRevenuePreviousResponse,
}) => {
  if (
    !orderedRevenueResponse.fetching &&
    !orderedUnitsResponse.fetching &&
    !orderedRevenuePreviousResponse.fetching &&
    orderedRevenueResponse.data &&
    orderedUnitsResponse.data &&
    orderedRevenuePreviousResponse.data &&
    !orderedRevenueResponse.error &&
    !orderedUnitsResponse.error &&
    !orderedRevenuePreviousResponse.error
  ) {
    const orderedRevenueCurrent =
      orderedRevenueResponse.data.dataPointAggregates.edges.length > 0 &&
      orderedRevenueResponse.data.total.edges.length > 0
        ? prettyPrintLongformCurrency(
            orderedRevenueResponse.data.total.edges[0].node.value.toString(),
          )
        : 'N/A';

    const orderedRevenuePrevious =
      orderedRevenuePreviousResponse.data.dataPointAggregates.edges.length >
        0 && orderedRevenuePreviousResponse.data.total.edges.length > 0
        ? prettyPrintLongformCurrency(
            orderedRevenuePreviousResponse.data.total.edges[0].node.value.toString(),
          )
        : 'N/A';

    const orderedUnits =
      orderedUnitsResponse.data.dataPointAggregates.edges.length > 0 &&
      orderedUnitsResponse.data.total.edges.length > 0
        ? orderedUnitsResponse.data.total.edges[0].node.value.toLocaleString()
        : 'N/A';
    const totals: ColumnTotals = {
      orderedRevenueCurrent,
      orderedRevenuePrevious,
      orderedUnits,
    };

    orderedRevenueResponse.data.weeklyTotals.edges.forEach(edge => {
      totals[`w${edge.node.timestamp.slice(-2)}`] = edge.node.value.toString();
    });
    return totals;
  }
  return {
    orderedRevenueCurrent: '',
    orderedRevenuePrevious: '',
    orderedUnits: '',
  };
};
