import React from 'react';
import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { LineWithClickHandler, YAxis, YAxisProps } from './types';
import { lineColors, withLegendItemStyles } from './styles';
import { themeColors } from '../../common/theme';
import Arrow from './Arrow';

const LegendItem = styled.li`
  ${withLegendItemStyles()}
`;

interface Props {
  lines: LineWithClickHandler[];
  primaryYAxis: YAxisProps;
  secondaryYAxis: YAxisProps;
  yAxes: YAxis[];
}

const DualYAxisLegend: React.FC<Props> = ({
  lines,
  primaryYAxis,
  secondaryYAxis,
  yAxes,
}) => (
  <>
    <ul>
      {lines
        .filter(line => {
          const primaryYAxisData = yAxes.find(
            axis => axis.type === primaryYAxis.type,
          );
          return primaryYAxisData && line.dataType === primaryYAxisData.type;
        })
        .map(line => {
          const foundColor = lineColors.find(
            color => color.fieldKey === line.fieldKey,
          );
          return (
            <LegendItem key={line.dataSetName}>
              <Arrow
                color={foundColor ? foundColor.color : '#f00'}
                left={true}
                dotted={line.previous}
              />
              <span>{line.dataSetName}</span>
              <IconButton
                color="primary"
                disableRipple
                onClick={() => line.onRemoveClick()}
                size="small"
              >
                <CancelRoundedIcon
                  style={{ color: themeColors.concrete }}
                  fontSize="small"
                />
              </IconButton>
            </LegendItem>
          );
        })}
    </ul>
    <ul>
      {lines
        .filter(line => {
          const secondaryYAxisData = yAxes.find(
            axis => axis.type === secondaryYAxis.type,
          );
          return (
            secondaryYAxisData && line.dataType === secondaryYAxisData.type
          );
        })
        .map(line => {
          const foundColor = lineColors.find(
            color => color.fieldKey === line.fieldKey,
          );
          return (
            <LegendItem key={line.dataSetName}>
              <Arrow
                color={foundColor ? foundColor.color : '#f00'}
                left={false}
                dotted={line.previous}
              />
              <span>{line.dataSetName}</span>
              <IconButton
                color="primary"
                disableRipple
                onClick={() => line.onRemoveClick()}
                size="small"
              >
                <CancelRoundedIcon
                  style={{ color: themeColors.concrete }}
                  fontSize="small"
                />
              </IconButton>
            </LegendItem>
          );
        })}
    </ul>
  </>
);

export default DualYAxisLegend;
