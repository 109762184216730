import gql from 'graphql-tag';
import { post, get } from './restApi';
import { apiRoutes } from '../common/routes';

interface Response {
  token?: string;
  refresh_token?: string;
  code?: number;
  message?: string;
}

export const login: (
  email: string,
  password: string,
) => Promise<Response> = async (email, password) => {
  const response = await post(
    apiRoutes.login,
    JSON.stringify({ email, password }),
  );

  return response.json();
};

export const updateToken: (
  refreshToken: string,
) => Promise<Response> = async refreshToken => {
  const response = await post(
    apiRoutes.refreshToken,
    JSON.stringify({ refresh_token: refreshToken }),
  );

  return response.json();
};

export const requestPasswordResetEmail: (
  email: string,
) => Promise<number> = async email => {
  const response = await post(
    apiRoutes.forgotPassword.replace('{token}', ''),
    JSON.stringify({ email }),
  );

  return response.status;
};

export const requestPasswordReset: (
  password: string,
  token: string,
) => Promise<number> = async (password, token) => {
  const response = await post(
    apiRoutes.forgotPassword.replace('{token}', token),
    JSON.stringify({ password }),
  );

  return response.status;
};

export const validatePasswordResetToken: (
  token: string,
) => Promise<number> = async token => {
  const response = await get(
    apiRoutes.forgotPassword.replace('{token}', token),
  );

  return response.status;
};

export const updatePasswordMutation = gql`
  mutation($userId: ID!, $newPassword: String, $oldPassword: String) {
    updateUser(
      input: {
        id: $userId
        newPassword: $newPassword
        oldPassword: $oldPassword
      }
    ) {
      user {
        id
      }
    }
  }
`;
