import gql from 'graphql-tag';
import { Asin } from './asin';

export const getDataPoints = gql`
  query GetDataPoints(
    $startDate: String
    $endDate: String
    $asin: String
    $orderTimestamp: String
    $first: Int
    $client_id: String
    $sidebarDataType: Int
  ) {
    dataPoints(
      timestamp: { after: $startDate, before: $endDate }
      asin: $asin
      order: { timestamp: $orderTimestamp }
      first: $first
      client: $client_id
      sidebarDataType: $sidebarDataType
    ) {
      edges {
        node {
          id
          timestamp
          value
        }
      }
    }
  }
`;

export interface DataPoint {
  id: string;
  timestamp: string;
  value: string;
  sidebarDataType: number;
}

export interface DataPointAggregate {
  asinId?: string;
  category?: string;
  categoryId?: string;
  subcategory?: string;
  subcategoryId?: string;
  timestamp?: string;
  value: string;
}

export interface GetDataPointsResponse {
  dataPoints: {
    edges: {
      node: DataPoint;
    }[];
  };
}

export interface GetDataPointsAggregateResponse {
  dataPointAggregates: {
    edges: {
      node: DataPointAggregate;
    }[];
  };
  total: {
    edges: {
      node: {
        value: number;
      };
    }[];
  };
  weeklyTotals: {
    edges: {
      node: {
        value: number;
        timestamp: string;
      };
    }[];
  };
}

export interface GetAsinsResponse {
  asins: {
    totalCount?: number;
    edges: {
      node: Asin;
    }[];
  };
}

export const getFilteredDataPoints = gql`
  query GetFilteredDataPoints(
    $startDate: String
    $endDate: String
    $asin_id_list: [String]
    $asin_category_list: [String]
    $asin_subcategory_list: [String]
    $client_id: String
    $fieldConfiguration_fieldKey: String
    $aggregateOperation: String = "sum"
  ) {
    dataPointAggregates(
      timestamp: { after: $startDate, before: $endDate }
      asin_list: $asin_id_list
      client: $client_id
      fieldConfiguration_fieldKey: $fieldConfiguration_fieldKey
      resolution: { period: "single", operation: $aggregateOperation }
      asin_category_list: $asin_category_list
      asin_subcategory_list: $asin_subcategory_list
    ) {
      edges {
        node {
          value
        }
      }
    }
  }
`;

export const getFilteredDataPointsGraph = gql`
  query GetFilteredDataPointsGraph(
    $startDate: String
    $endDate: String
    $strictEndDate: String
    $asin_id_list: [String]
    $asin_category_list: [String]
    $asin_subcategory_list: [String]
    $client_id: String
    $fieldConfiguration_fieldKey: String
    $resolutionPeriod: String = "daily"
  ) {
    dataPointAggregates(
      timestamp: {
        after: $startDate
        strictly_before: $strictEndDate
        before: $endDate
      }
      asin_list: $asin_id_list
      asin_category_list: $asin_category_list
      asin_subcategory_list: $asin_subcategory_list
      client: $client_id
      fieldConfiguration_fieldKey: $fieldConfiguration_fieldKey
      resolution: { period: $resolutionPeriod, operation: "sum" }
      order: { timestamp: "asc" }
      first: 1000
    ) {
      edges {
        node {
          value
          timestamp
        }
      }
    }
  }
`;

export const getFilteredDataPointsWaterfallGraph = gql`
  query GetFilteredDataPointsWaterfallGraph(
    $startDate: String
    $endDate: String
    $asin_id_list: [String]
    $asin_category_list: [String]
    $asin_subcategory_list: [String]
    $client_id: String
    $fieldConfiguration_fieldKey: String
    $order: String
    $aggregateOperation: String = "sum"
  ) {
    dataPointAggregates(
      timestamp: { after: $startDate, before: $endDate }
      client: $client_id
      fieldConfiguration_fieldKey: $fieldConfiguration_fieldKey
      resolution: { period: "single", operation: $aggregateOperation }
      groupBy: "category"
      asin_list: $asin_id_list
      asin_category_list: $asin_category_list
      asin_subcategory_list: $asin_subcategory_list
      order: { value: $order }
      first: 1000
    ) {
      edges {
        node {
          value
          categoryId
          category
        }
      }
    }
  }
`;

export const getDataBySubcategoryForWaterfall = gql`
  query GetDataBySubcategoryForWaterfall(
    $startDate: String
    $endDate: String
    $asin_id_list: [String]
    $asin_category_list: [String]
    $asin_subcategory_list: [String]
    $client_id: String
    $fieldConfiguration_fieldKey: String
    $order: String
  ) {
    dataPointAggregates(
      timestamp: { after: $startDate, before: $endDate }
      client: $client_id
      fieldConfiguration_fieldKey: $fieldConfiguration_fieldKey
      resolution: { period: "single", operation: "sum" }
      groupBy: "asinCategorySubcategory"
      asin_list: $asin_id_list
      asin_category_list: $asin_category_list
      asin_subcategory_list: $asin_subcategory_list
      order: { value: $order }
      first: 1000
    ) {
      edges {
        node {
          value
          subcategoryId
          subcategory
        }
      }
    }
  }
`;

export const getDataByAsinForWaterfall = gql`
  query GetDataByAsinForWaterfall(
    $startDate: String
    $endDate: String
    $asin_id_list: [String]
    $asin_category_list: [String]
    $asin_subcategory_list: [String]
    $client_id: String
    $fieldConfiguration_fieldKey: String
    $order: String
  ) {
    dataPointAggregates(
      timestamp: { after: $startDate, before: $endDate }
      client: $client_id
      fieldConfiguration_fieldKey: $fieldConfiguration_fieldKey
      resolution: { period: "single", operation: "sum" }
      groupBy: "asin"
      asin_list: $asin_id_list
      asin_category_list: $asin_category_list
      asin_subcategory_list: $asin_subcategory_list
      order: { value: $order }
      first: 1000
    ) {
      edges {
        node {
          value
          asinId
        }
      }
    }
  }
`;

export const getDataPointsSubcategoryGrouped = gql`
  query GetDataPointsSubcategoryGrouped(
    $startDate: String
    $endDate: String
    $asin_id_list: [String]
    $asin_category_list: [String]
    $asin_subcategory_list: [String]
    $client_id: String
    $fieldConfiguration_fieldKey: String
    $order: String
  ) {
    dataPointAggregates(
      timestamp: { after: $startDate, before: $endDate }
      client: $client_id
      fieldConfiguration_fieldKey: $fieldConfiguration_fieldKey
      resolution: { period: "single", operation: "sum" }
      groupBy: "asinCategorySubcategory"
      asin_list: $asin_id_list
      asin_category_list: $asin_category_list
      asin_subcategory_list: $asin_subcategory_list
      order: { value: $order }
    ) {
      edges {
        node {
          value
          subcategoryId
          subcategory
        }
      }
    }
  }
`;

export const getDataPointsAsinGrouped = gql`
  query GetDataPointsAsinGrouped(
    $startDate: String
    $endDate: String
    $asin_id_list: [String]
    $asin_category_list: [String]
    $asin_subcategory_list: [String]
    $client_id: String
    $fieldConfiguration_fieldKey: String
    $order: String
    $limit: Int = 9999
  ) {
    dataPointAggregates(
      timestamp: { after: $startDate, before: $endDate }
      asin_list: $asin_id_list
      asin_category_list: $asin_category_list
      asin_subcategory_list: $asin_subcategory_list
      client: $client_id
      fieldConfiguration_fieldKey: $fieldConfiguration_fieldKey
      resolution: { period: "single", operation: "sum" }
      order: { value: $order }
      groupBy: "asin"
      first: $limit
    ) {
      edges {
        node {
          value
          asinId
        }
      }
    }
  }
`;

export const getAsins = gql`
  query GetAsins($asin_id_list: [String]) {
    asins(id_list: $asin_id_list, first: 9999) {
      edges {
        node {
          id
          asin
          productName
          subcategory {
            id
            name
            category {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export const getDataPointsAsinGroupedWithCategories = gql`
  query GetDataPointsAsinGroupedWithCategories(
    $startDate: String
    $endDate: String
    $asin_id_list: [String]
    $client_id: String
    $fieldConfiguration_fieldKey: String
    $order: String
    $weeklyStartDate: String
    $weeklyEndDate: String
  ) {
    dataPointAggregates(
      timestamp: { after: $startDate, before: $endDate }
      asin_list: $asin_id_list
      client: $client_id
      fieldConfiguration_fieldKey: $fieldConfiguration_fieldKey
      resolution: { period: "single", operation: "sum" }
      order: { value: $order }
      groupBy: "asinCategorySubcategoryCombined"
    ) {
      edges {
        node {
          value
          asinId
          category
          categoryId
          subcategory
          subcategoryId
        }
      }
    }
    total: dataPointAggregates(
      client: $client_id
      fieldConfiguration_fieldKey: $fieldConfiguration_fieldKey
      groupBy: "client"
      resolution: { period: "single", operation: "sum" }
      timestamp: { after: $startDate, before: $endDate }
    ) {
      edges {
        node {
          value
        }
      }
    }
    weeklyTotals: dataPointAggregates(
      client: $client_id
      fieldConfiguration_fieldKey: $fieldConfiguration_fieldKey
      groupBy: "client"
      resolution: { period: "weekly", operation: "sum" }
      timestamp: { after: $weeklyStartDate, before: $weeklyEndDate }
    ) {
      edges {
        node {
          value
          timestamp
        }
      }
    }
  }
`;

export const getDataPointsAsinGroupedWithCategoriesWeekly = gql`
  query GetDataPointsAsinGroupedWithCategoriesWeekly(
    $startDate: String
    $endDate: String
    $asin_id_list: [String]
    $client_id: String
    $fieldConfiguration_fieldKey: String
    $order: String
  ) {
    dataPointAggregates(
      timestamp: { after: $startDate, before: $endDate }
      asin_list: $asin_id_list
      client: $client_id
      fieldConfiguration_fieldKey: $fieldConfiguration_fieldKey
      resolution: { period: "weekly", operation: "sum" }
      order: { value: $order }
      groupBy: "asinCategorySubcategoryCombined"
      first: 100
    ) {
      edges {
        node {
          value
          asinId
          category
          categoryId
          subcategory
          subcategoryId
          timestamp
        }
      }
    }
  }
`;

export const getFilteredDataPointsForDonutChart = gql`
  query GetFilteredDataPointsForDonutChart(
    $clientId: String
    $endDate: String
    $fieldKey: String
    $startDate: String
  ) {
    dataPointAggregates(
      client: $clientId
      fieldConfiguration_fieldKey: $fieldKey
      groupBy: "category"
      resolution: { period: "single", operation: "sum" }
      timestamp: { after: $startDate, before: $endDate }
    ) {
      edges {
        node {
          value
          category
        }
      }
      totalCount
    }
  }
`;

export const getFilteredDataPointsGroupedBySubCategory = gql`
  query GetFilteredDataPointsGroupedBySubCategory(
    $categories: [String]
    $clientId: String
    $endDate: String
    $fieldKey: String
    $startDate: String
  ) {
    dataPointAggregates(
      asin_category_list: $categories
      client: $clientId
      fieldConfiguration_fieldKey: $fieldKey
      groupBy: "asinCategorySubcategory"
      resolution: { period: "single", operation: "sum" }
      timestamp: { after: $startDate, before: $endDate }
    ) {
      edges {
        node {
          value
          subcategory
        }
      }
      totalCount
    }
  }
`;

export const getFilteredDataPointsGroupedByAsin = gql`
  query GetFilteredDataPointsGroupedBySubCategory(
    $asins: [String]
    $clientId: String
    $endDate: String
    $fieldKey: String
    $startDate: String
    $subcategories: [String]
  ) {
    dataPointAggregates(
      asin_list: $asins
      asin_subcategory_list: $subcategories
      client: $clientId
      fieldConfiguration_fieldKey: $fieldKey
      groupBy: "asin"
      resolution: { period: "single", operation: "sum" }
      timestamp: { after: $startDate, before: $endDate }
      first: 9999
    ) {
      edges {
        node {
          value
          asinId
        }
      }
      totalCount
    }
  }
`;
