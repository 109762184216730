import styled from '@emotion/styled';
import DefaultPopover from '@material-ui/core/Popover';
import DefaultTableCell from '@material-ui/core/TableCell';
import { themeColors } from '../../../common/theme';

export const TableContainer = styled.section`
  height: 100%;
  max-width: 1148px;
  min-width: 600px;
  margin: auto;
  padding-right: 10px;
  .MuiPaper-root {
    padding: 0;
    font-size: 13px;
    color: ${themeColors.cyprus};
    box-shadow: none;
    border-radius: 0;
    min-height: calc(100% - 88px);
    padding-bottom: 76px;
    > div {
      &:first-of-type {
        border: 1px solid ${themeColors.concrete};
        border-radius: 8px;
      }
    }
    margin: auto;
  }
  .MuiTableBody-root {
    .hover:hover {
      & > .highlight {
        background-color: #f2e5db !important;
      }
      background-color: #f2f2f2;
    }
  }

  .MuiTableCell-root {
    border: 1px solid ${themeColors.concrete};
    background-color: transparent;
    font-size: 0.75rem;
    padding: 10px 16px;
    &:first-of-type {
      border-left: none;
    }
    &:last-of-type {
      border-right: none;
    }
  }
  .MuiTableCell-head {
    border-top: none;
    color: ${themeColors.cyprus};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center;
  }

  .MuiTableCell-root {
    color: ${themeColors.cyprus};
    &.highlight {
      background-color: #fff2e7;
      border-right: none;
      border-left: none;
    }
    &[value='-'],
    &.hide {
      border-right-color: transparent;
      color: transparent !important;
    }
  }

  .MuiTablePagination-spacer {
    flex: unset;
  }
  .MuiPagination-root {
    width: 400px;
    padding: 25px;
    .Mui-selected {
      border: 1px solid ${themeColors.cyprus};
      background: none;
      color: #0b2735;
    }
  }
  .MuiPaginationItem-root {
    color: #0b2735;
  }
  .no-data {
    background: #f2f2f2;
    border-radius: 8px;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 14px;
    pointer-events: none;
    font-size: 13px;
    color: #0d2d3d;
  }
`;

export const Toolbar = styled.div`
  padding: 24px 0px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${themeColors.white};
  max-width: 1148px;
  margin: auto;
  .switch-wrapper {
    display: flex;
    padding-left: 20px;
    white-space: nowrap;
    flex-wrap: wrap;
  }
  .MuiButton-outlined {
    border-radius: 23px;
    border: 1px solid ${themeColors.cyprus};
    color: ${themeColors.cyprus};
    text-transform: none;
    font-size: 13px;
    font-weight: 700;
    padding: 9px 21px;
    text-align: left;
    font: normal normal bold 13px/16px Montserrat;
    letter-spacing: 0px;
    color: #043746;
    opacity: 1;
    height: 42px !important;
    width: 152px !important;
    min-width: 152px !important;
    white-space: nowrap;
  }
  .MuiFormControlLabel-root {
    margin-right: 22px;
    flex: 1;
  }
  .MuiSwitch-root {
    width: 52px;
    .MuiIconButton-root:hover {
      background-color: transparent;
    }
    .MuiSwitch-switchBase {
      padding: 12px;
    }
    .MuiSwitch-track {
      background-color: ${themeColors.concrete};
      opacity: 1;
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: ${themeColors.neonCarrot};
      opacity: 1;
    }
    .MuiSwitch-switchBase.Mui-checked {
      transform: translateX(14px);
      .MuiSwitch-thumb {
        color: #fff9f4;
        border-color: #f2d6c0;
      }
    }
    .MuiSwitch-thumb {
      width: 14px;
      height: 14px;
      box-shadow: 0px 1px 1px #00000029;
      color: ${themeColors.white};
      border: 1px solid ${themeColors.concrete};
    }
  }
  .MuiFormControlLabel-label {
    font-size: 13px;
    margin-left: -2px;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  button {
    margin-right: 24px;
  }
`;

export const Viewing = styled.div`
  font-size: 13px;
  background-color: #f2f2f2;
  border-radius: 4px;
  padding: 14px 19px;
`;

export const Popover = styled(DefaultPopover)`
  .MuiPaper-elevation8 {
    box-shadow: 0px 3px 16px ${themeColors.cyprus}29;
  }

  .MuiPopover-paper {
    .MuiTypography-body1 {
      font-size: 13px;
    }
    .MuiCheckbox-colorPrimary:hover {
      background-color: transparent;
    }
    .MuiIconButton-root {
      font-size: 13px;
      .MuiSvgIcon-root {
        font-size: 1.4em;
      }
    }
  }
`;

export const TableCell = styled(DefaultTableCell)`
  &.MuiTableCell-head {
    line-height: 1.43;
    position: relative;
    padding: 10px 36px;
    &.sort-enabled {
      cursor: pointer;
    }
  }
  svg.sort {
    fill: #02ba79;
    position: absolute;
    right: 9px;
    top: calc(50% - 12px);
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 16px;
    &.ascending {
      transform: rotate(180deg);
    }
  }
`;
