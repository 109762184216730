import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import MaterialTable, { MTableBody, MTableBodyRow } from 'material-table';
import styled from '@emotion/styled';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import {
  Button,
  Checkbox,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Switch,
  TableBody,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { Consumer } from '../../../common/types';
import LoadingIndicator from '../../LoadingIndicator';
import DefaultPage from '../../Page';
import { useHeader } from '../../header/useHeader';
import { ColumnFieldName } from './useAsinPagination';
import useAsinBreakdownData from './useAsinBreakdownData';
import {
  Left,
  Popover,
  TableCell,
  TableContainer,
  Toolbar,
  Viewing,
} from './styles';
import { WeekItem } from './transformers';
import { prettyPrintLongformCurrency } from './utils';
import NoData from '../../NoData';
import { useToast } from '../../toast/useToast';
const Page = styled(DefaultPage)`
  .body {
    overflow-y: auto;
  }
`;
interface ColumnData {
  title: string;
  field: ColumnFieldName | string;
  enableSort?: boolean;
}

export interface RowItem {
  asin: string;
  productName: string;
  asinId: string;
  category: string;
  orderedRevenueCurrent: string;
  orderedRevenuePrevious: string;
  orderedUnits: string;
  subcategory: string;
  [weekNumber: string]: string;
}

export interface CombinedRowItem {
  asin: string;
  productName: string;
  asinId: string;
  category: string;
  orderedRevenueCurrent: WeekItem[];
  orderedRevenuePrevious: string;
  orderedUnits: string;
  subcategory: string;
}

export interface Column {
  field: string;
  title: string;
}

const AsinsList: React.FC = () => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'asin',
    'category',
    'productName',
    'orderedRevenueCurrent',
    'orderedRevenuePrevious',
    'orderedUnits',
    'subcategory',
  ]);
  const [dataLoading, setDataLoading] = useState(true);
  const [weeks, setWeeks] = useState(false);
  const [asinsTotal, setAsinsTotal] = useState(false);
  const [totalsRow, setTotalsRow] = useState<RowItem>({
    asin: 'All ASINs Total',
    productName: '',
    asinId: '',
    category: '-',
    orderedRevenueCurrent: '',
    orderedRevenuePrevious: '',
    orderedUnits: '',
    subcategory: '',
  });
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const adjustColumnButtonRef = useRef<HTMLButtonElement>(null);
  const [pages, setPages] = useState(0);

  const {
    columnTotals,
    currentClient,
    currentPage,
    data,
    weeksData,
    loading,
    setCurrentPage,
    setSortOption,
    sortOption,
    totalCount,
  } = useAsinBreakdownData(weeks);
  const { setContents: setToastContents, setOpen: setToastOpen } = useToast();

  const createNotification = useCallback(
    (message: string) => {
      setToastContents(<p>{message}</p>);
      setToastOpen(true);
    },
    [setToastContents, setToastOpen],
  );

  const removeNotification = useCallback(() => {
    setToastContents(<></>);
    setToastOpen(false);
  }, [setToastContents, setToastOpen]);

  useEffect(() => {
    if (selectedColumns.length === 1) {
      createNotification(
        'A minimum of 1 column has to be applied to show data.',
      );
      return;
    }

    removeNotification();
  }, [selectedColumns, createNotification, removeNotification]);

  const combinedData = useMemo(() => {
    if (weeks && weeksData.length > 0) {
      return data.map(_datum => {
        const foundWeekData = weeksData.find(
          _wd => _wd.asinId === _datum.asinId,
        );
        const weekData = foundWeekData
          ? foundWeekData.weeks.map(_wk => ({
              [`w${_wk.week}`]: _wk.value,
            }))
          : [];
        const newDatum = { ..._datum };
        if (newDatum.asin.toLowerCase().indexOf('page total') > -1) {
          weeksData[0].weeks.forEach(_w => {
            const sum = weeksData.reduce((sum, datum) => {
              const match = datum.weeks.find(week => week.week === _w.week);
              if (!match) {
                return sum;
              }
              const value = parseFloat(match.value);
              if (!isNaN(value)) {
                return sum + value;
              }
              return sum;
            }, 0);
            newDatum[`w${_w.week}`] = sum.toString();
          });
        }
        weekData.forEach(weekDatum => {
          for (const property in weekDatum) {
            newDatum[property] = weekDatum[property];
            newDatum.YTD = _datum.orderedRevenueCurrent;
          }
        });
        return newDatum;
      });
    } else {
      return data;
    }
  }, [data, weeks, weeksData]);

  const { periodLength, lastValidDate, lastValidDateIsLoading } = useHeader();

  useEffect(() => {
    if (periodLength > 31) setWeeks(false);
  }, [periodLength, setWeeks]);

  useEffect(() => {
    if (currentClient !== null) {
      setCurrentPage(1);
    }
  }, [currentClient, setCurrentPage]);

  useEffect(() => {
    if (totalCount % 25 === 0) {
      setPages(totalCount / 25);
    } else {
      setPages(Math.ceil(totalCount / 25));
    }
  }, [totalCount]);

  const handleCheckboxChange: (arg: {
    field: string;
    checked: boolean;
  }) => void = ({ field, checked }) => {
    if (checked) {
      setSelectedColumns(selected => [...selected, field]);
    } else {
      setSelectedColumns(selected =>
        selected.filter(column => column !== field),
      );
    }
  };

  useEffect(() => {
    setTotalsRow(current => ({ ...current, ...columnTotals }));
  }, [columnTotals]);

  const handleHeaderCellClick: Consumer<ColumnData> = datum => {
    if (!datum.enableSort) {
      return;
    }
    setCurrentPage(1);
    if (sortOption.column === datum.field) {
      setSortOption({
        column: datum.field,
        type: sortOption.type === 'asc' ? 'desc' : 'asc',
      });
    } else {
      setSortOption({ column: datum.field as ColumnFieldName, type: 'desc' });
    }
  };

  const columns: ColumnData[] = [
    { title: 'ASIN', field: 'asin', enableSort: true },
    { title: 'Product Name', field: 'productName', enableSort: true },
    { title: 'Category', field: 'category', enableSort: true },
    { title: 'Subcategory', field: 'subcategory', enableSort: true },
    {
      title: 'Ordered Revenue Current',
      field: 'orderedRevenueCurrent',
      enableSort: true,
    },
    {
      title: 'Ordered Revenue Previous Year',
      field: 'orderedRevenuePrevious',
      enableSort: true,
    },
    { title: 'Units Sold', field: 'orderedUnits', enableSort: true },
  ].concat(
    weeksData.length > 0
      ? [
          ...weeksData[0].weeks.map(_w => {
            return {
              title: `W${_w.week}`,
              field: `w${_w.week}`,
              enableSort: false,
            };
          }),
          {
            title: 'YTD',
            field: 'yearToDateRevenue',
            enableSort: false,
          },
        ]
      : [],
  );

  useEffect(() => {
    if (combinedData.length > 0 || !loading) {
      setDataLoading(false);
    }
  }, [combinedData.length, loading]);
  if (currentClient.id && !lastValidDateIsLoading() && !lastValidDate)
    return <NoData page="Sales ASIN Breakdown" />;
  return (
    <Page title="Sales ASIN Breakdown" page="asinBreakdown">
      <div className="asin-section">
        <TableContainer>
          <Toolbar>
            <Left>
              <Button
                aria-describedby="adjust_columns"
                className="outlined-button"
                color="primary"
                disableElevation
                onClick={() => setPopoverOpen(!popoverOpen)}
                ref={adjustColumnButtonRef}
                variant="outlined"
              >
                Adjust Columns
              </Button>
              <Popover
                anchorEl={adjustColumnButtonRef.current}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                id="adjust_columns"
                onClose={() => setPopoverOpen(false)}
                open={popoverOpen}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {columns
                  .filter(
                    _col =>
                      !_col.field.startsWith('w') &&
                      !_col.title.startsWith('YTD'),
                  )
                  .map(column => (
                    <MenuItem
                      key={column.field}
                      value={column.field}
                      onClick={() =>
                        selectedColumns.length !== 1 ||
                        selectedColumns.indexOf(column.field) === -1
                          ? handleCheckboxChange({
                              field: column.field,
                              checked: !(
                                selectedColumns.indexOf(column.field) > -1
                              ),
                            })
                          : false
                      }
                    >
                      <Checkbox
                        checked={selectedColumns.indexOf(column.field) > -1}
                        color="primary"
                        disableRipple
                        disabled={selectedColumns.length === 1}
                        onChange={(_, checked) =>
                          handleCheckboxChange({
                            field: column.field,
                            checked,
                          })
                        }
                      />
                      <ListItemText primary={column.title} />
                    </MenuItem>
                  ))}
              </Popover>
              <Viewing>Currently Viewing {totalCount} ASINs</Viewing>
            </Left>
            <div className="switch-wrapper">
              <FormControlLabel
                control={
                  <Switch
                    checked={asinsTotal}
                    disableRipple
                    disableFocusRipple
                    onChange={(_, checked) => setAsinsTotal(checked)}
                  />
                }
                label="All ASINs Total"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={weeks}
                    disableRipple
                    disableFocusRipple
                    onChange={(_, checked) => setWeeks(checked)}
                  />
                }
                disabled={periodLength > 31}
                label="Weeks"
              />
            </div>
          </Toolbar>
          <MaterialTable
            columns={columns.filter(
              col => selectedColumns.indexOf(col.field) > -1,
            )}
            localization={{
              body: {
                emptyDataSourceMessage:
                  dataLoading || loading ? (
                    <></>
                  ) : (
                    <div className="no-data">
                      There is no data for your selected date range or filters.
                      Please try again.
                    </div>
                  ),
              },
            }}
            components={{
              Row: function RowOverride(props) {
                return (
                  <MTableBodyRow {...props} className="hover" colSpan={7} />
                );
              },
              Body: function BodyOverride(props: {
                columns: ColumnData[];
                renderData: RowItem[];
              }) {
                if (loading) {
                  return (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={props.columns.length}>
                          <LoadingIndicator />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                } else if (
                  weeks &&
                  weeksData.length > 0 &&
                  props.renderData?.length > 0
                ) {
                  return (
                    <>
                      <TableBody>
                        {props.renderData.map((datum, i) => (
                          <TableRow className="hover" key={`row-${i}`}>
                            {props.columns.find(
                              column => column.field === 'asin',
                            ) && <TableCell>{datum.asin}</TableCell>}
                            {props.columns.find(
                              column => column.field === 'productName',
                            ) && <TableCell>{datum.productName}</TableCell>}
                            {props.columns.find(
                              column => column.field === 'category',
                            ) && (
                              <TableCell
                                className={
                                  datum.asin.toLowerCase().indexOf('total') > -1
                                    ? 'hide'
                                    : ''
                                }
                              >
                                {datum.category}
                              </TableCell>
                            )}
                            {props.columns.find(
                              column => column.field === 'subcategory',
                            ) && <TableCell>{datum.subcategory}</TableCell>}
                            {props.columns.find(
                              column =>
                                column.field === 'orderedRevenueCurrent',
                            ) &&
                              weeksData[0].weeks
                                .map(_wk => (
                                  <TableCell
                                    key={_wk.week}
                                    className="highlight"
                                  >
                                    {!datum[`w${_wk.week}`]
                                      ? 'Data Not Available'
                                      : prettyPrintLongformCurrency(
                                          datum[`w${_wk.week}`],
                                        )}
                                  </TableCell>
                                ))
                                .concat(
                                  <TableCell
                                    key="ytd-header"
                                    className="highlight"
                                  >
                                    {datum.orderedRevenueCurrent}
                                  </TableCell>,
                                )}
                            {props.columns.find(
                              column =>
                                column.field === 'orderedRevenuePrevious',
                            ) && (
                              <TableCell>
                                {datum.orderedRevenuePrevious}
                              </TableCell>
                            )}
                            {props.columns.find(
                              column => column.field === 'orderedUnits',
                            ) && <TableCell>{datum.orderedUnits}</TableCell>}
                          </TableRow>
                        ))}
                      </TableBody>
                      {props.renderData?.length > 0 && (
                        <TableRow>
                          <TableCell colSpan={7}>
                            <Pagination
                              showFirstButton={currentPage > 4}
                              showLastButton={pages > 4}
                              size="small"
                              hidePrevButton={currentPage < 5}
                              boundaryCount={1}
                              count={pages}
                              page={currentPage || 1}
                              onChange={(event, pageNumber: number) =>
                                setCurrentPage(pageNumber)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                } else {
                  return (
                    <>
                      <MTableBody {...props} />
                      {props.renderData?.length > 0 && (
                        <TableRow>
                          <TableCell colSpan={7}>
                            <Pagination
                              color="primary"
                              showFirstButton={currentPage > 4}
                              showLastButton={pages > 4}
                              size="small"
                              hidePrevButton={currentPage < 5}
                              boundaryCount={1}
                              count={pages}
                              page={currentPage || 1}
                              onChange={(event, pageNumber: number) =>
                                setCurrentPage(pageNumber)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                }
              },
              Header: function HeaderOverride(props: {
                columns: ColumnData[];
                dataCount: number;
              }) {
                return (
                  <TableHead>
                    <TableRow>
                      {props.columns
                        .filter(
                          _col =>
                            !_col.field.startsWith('w') &&
                            !_col.title.startsWith('YTD'),
                        )
                        .map(column => (
                          <TableCell
                            className={column.enableSort ? 'sort-enabled' : ''}
                            colSpan={
                              props.dataCount > 0 &&
                              weeks &&
                              column.field === 'orderedRevenueCurrent'
                                ? weeksData.length > 0
                                  ? weeksData[0].weeks.length + 1
                                  : 1
                                : 1
                            }
                            onClick={() => handleHeaderCellClick(column)}
                            key={column.title}
                            width={300}
                          >
                            {column.title}
                            {sortOption.column === column.field && (
                              <ArrowDownward
                                className={`sort ${
                                  sortOption.type === 'asc' ? 'ascending' : ''
                                }`}
                              />
                            )}
                          </TableCell>
                        ))}
                    </TableRow>
                    {weeks && weeksData.length > 0 && props.dataCount > 0 && (
                      <TableRow>
                        {props.columns.map(column => {
                          if (column.field === 'orderedRevenueCurrent') {
                            return weeksData[0].weeks
                              .sort((a, b) => {
                                if (a.week < b.week) {
                                  return -1;
                                }
                                if (a.week > b.week) {
                                  return 1;
                                }
                                return 0;
                              })
                              .map(_wk => (
                                <TableCell
                                  key={_wk.week}
                                  className="highlight"
                                >{`w${_wk.week}`}</TableCell>
                              ))
                              .concat(
                                <TableCell
                                  className="highlight"
                                  key="ytd-header'"
                                >
                                  YTD
                                </TableCell>,
                              );
                          } else {
                            return <TableCell />;
                          }
                        })}
                      </TableRow>
                    )}
                  </TableHead>
                );
              },
            }}
            data={asinsTotal ? [...combinedData, totalsRow] : combinedData}
            options={{
              draggable: false,
              toolbar: false,
              paging: false,
              sorting: false,
            }}
          />
        </TableContainer>
      </div>
    </Page>
  );
};

export default AsinsList;
