import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'urql';
import { useAuth } from '../auth/useAuth';
import {
  FieldConfiguration,
  GetFieldConfigurationsResponse,
  getFieldConfigurations,
} from '../api/fieldConfiguration';
import { Factory } from '../common/types';

export interface Context {
  fieldConfigurations: FieldConfiguration[];
}

export const FieldConfigurationsContext = createContext<Context>({
  fieldConfigurations: [],
});

export const FieldConfigurationsProvider: React.FC = ({ children }) => {
  const { validating, validToken } = useAuth();

  const [fieldConfigurations, setFieldConfigurations] = useState<
    FieldConfiguration[]
  >([]);

  const [getFieldConfigurationsResponse] = useQuery<
    GetFieldConfigurationsResponse
  >({
    query: getFieldConfigurations,
    pause: validating || !validToken,
  });

  useEffect(() => {
    if (
      !getFieldConfigurationsResponse.fetching &&
      !getFieldConfigurationsResponse.error &&
      getFieldConfigurationsResponse.data
    ) {
      setFieldConfigurations(
        getFieldConfigurationsResponse.data.fieldConfigurations.edges.map(
          edge => edge.node,
        ),
      );
    }
  }, [getFieldConfigurationsResponse]);

  return (
    <FieldConfigurationsContext.Provider value={{ fieldConfigurations }}>
      {children}
    </FieldConfigurationsContext.Provider>
  );
};

export const useFieldConfigurations: Factory<Context> = () =>
  useContext(FieldConfigurationsContext);
