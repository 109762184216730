import React, { createContext, useContext, useState, useEffect } from 'react';
import { Consumer, Factory } from '../../common/types';
import { useHeader } from '../header/useHeader';
export interface Context {
  setCategoryTitle: Consumer<string>;
  categoryTitle: string;
}

export const CategoryTitleContext = createContext<Context>({
  setCategoryTitle: () => null,
  categoryTitle: '',
});

export const CategoryTitleProvider: React.FC = ({ children }) => {
  const [categoryTitle, setCategoryTitle] = useState('');
  const { allFilters, selectedFilters } = useHeader();
  useEffect(() => {
    if (allFilters || !selectedFilters.length)
      return setCategoryTitle('Categories');
    if (selectedFilters[0].type === 'category')
      return setCategoryTitle('Subcategories');
    return setCategoryTitle('ASINs');
  }, [allFilters, selectedFilters]);
  return (
    <CategoryTitleContext.Provider
      value={{
        setCategoryTitle,
        categoryTitle,
      }}
    >
      {children}
    </CategoryTitleContext.Provider>
  );
};

export const useCategoryTitle: Factory<Context> = () =>
  useContext(CategoryTitleContext);
