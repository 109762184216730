import gql from 'graphql-tag';

export const getClients = gql`
  query GetClients {
    clients {
      edges {
        node {
          id
          name
          enabled
          accountManagers {
            edges {
              node {
                email
              }
            }
          }
          sellerCentralEnabled
          vendorCentralEnabled
        }
      }
    }
  }
`;

export interface AccountManagersResponse {
  edges: {
    node: {
      email: string;
    };
  }[];
}

export interface Client {
  id: string;
  name: string;
  accountManagers: AccountManagersResponse;
  sellerCentralEnabled: boolean;
  vendorCentralEnabled: boolean;
  enabled: boolean;
}

export interface GetClientsResponse {
  clients: {
    edges: {
      node: Client;
    }[];
  };
}
