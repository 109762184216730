import React from 'react';

const SearchIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 17.602 17.602"
  >
    <defs>
      <style>
        {`
        .a {
        fill: none;
          stroke: #0d2d3d;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 2px;
        }
        `}
      </style>
    </defs>
    <g transform="translate(-3.5 -3.5)">
      <path
        className="a"
        d="M18,11.25A6.75,6.75,0,1,1,11.25,4.5,6.75,6.75,0,0,1,18,11.25Z"
        transform="translate(0 0)"
      />
      <path
        className="a"
        d="M28.645,28.645l-3.67-3.67"
        transform="translate(-8.958 -8.958)"
      />
    </g>
  </svg>
);

export default SearchIcon;
