import React from 'react';
import styled from '@emotion/styled';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DefaultAccordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withAccordionOverrides } from '../../breakdowns/styles';
import { BreakDownDatapoint } from '../transformers';
import { useCategoryTitle } from '../../breakdowns/useCategoryTitle';
const Accordion = styled(DefaultAccordion)`
  ${withAccordionOverrides(false)}
`;

interface Props {
  breakdownDataPoints: BreakDownDatapoint[];
  expand: boolean;
  onShowAllClick: () => void;
}

const WaterfallBreakdownTable: React.FC<Props> = ({
  breakdownDataPoints,
  expand,
  onShowAllClick,
}) => {
  const { categoryTitle } = useCategoryTitle();
  return breakdownDataPoints.length === 0 ? null : (
    <Accordion elevation={0} expanded={expand} onChange={onShowAllClick}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <span className="performance-breakdown-title">BREAKDOWN</span>
        <span className="accordian-button">
          {expand ? 'Collapse' : 'Expand'}
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer className="performance-breakdown-table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="category-column waterfall-column">
                  {categoryTitle}
                </TableCell>

                <TableCell align="center" colSpan={2} className="border-left">
                  Ordered Revenue
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {breakdownDataPoints.map((breakdownDataPoint, index) => (
                <TableRow key={breakdownDataPoint.category}>
                  <TableCell className="waterfall-column">
                    {breakdownDataPoint.category}
                  </TableCell>
                  <TableCell
                    align="left"
                    key={`${breakdownDataPoint.category} - ${index} - c`}
                    className={`border-left detail-columns ${
                      breakdownDataPoint.change === 'positive'
                        ? 'positive_delta'
                        : 'negative_delta'
                    }`}
                  >
                    {breakdownDataPoint.percentage}
                  </TableCell>
                  <TableCell className="detail-columns" align="right">
                    {breakdownDataPoint.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default WaterfallBreakdownTable;
