import React from 'react';
import { Change, DataType } from '../../common/types';
import LoadingIndicator from '../LoadingIndicator';
import {
  prettyPrintNumber,
  prefixPositiveOrNegative,
} from '../../common/utils';
import CustomTooltip from '../Tooltip';
export interface FilterListItem {
  filter: string;
  filterId?: string;
  delta: { change: Change; percentage: string };
  total: string;
  prev?: string;
  curr?: string;
}

export interface Props {
  data: FilterListItem[];
  error?: boolean;
  loading?: boolean;
  deltaValueType: DataType;
  reverse?: boolean;
}

const FilterList: React.FC<Props> = ({
  data,
  error = false,
  loading = true,
  deltaValueType,
  reverse,
}) => {
  const FilterListName = ({ filterName }: { filterName: string }) => {
    if (filterName.length > 25)
      return (
        <CustomTooltip title={filterName}>
          <span className="category-name">
            {filterName.slice(0, 25).trim() + '...'}
          </span>
        </CustomTooltip>
      );
    return <span className="category-name">{filterName}</span>;
  };

  return loading ? (
    <LoadingIndicator />
  ) : error ? (
    <p>No data available</p>
  ) : (
    <ul className="top-categories-list">
      {data
        .slice(0, 5)
        .sort((a, b) => parseInt(b.total) - parseInt(a.total))
        .map(datum => (
          <li key={datum.filter} className="top-category-item">
            <FilterListName filterName={datum.filter} />
            <span
              className={`${
                reverse
                  ? datum.delta.change === 'positive'
                    ? 'negative'
                    : 'positive'
                  : datum.delta.change
              }_delta category-delta`}
            >
              {datum.delta.percentage === '-100%' ||
              datum.delta.percentage.trim() === '--'
                ? ''
                : prefixPositiveOrNegative(datum.delta.change)}
              {datum.delta.percentage.trim()}
            </span>
            <span className="category-total">
              {prettyPrintNumber(parseFloat(datum.total), deltaValueType)}
            </span>
          </li>
        ))}
    </ul>
  );
};

export default FilterList;
