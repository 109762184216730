import { useMemo } from 'react';
import { useQuery } from 'urql';
import moment from 'moment';
import {
  getAsins,
  GetAsinsResponse,
  GetDataPointsAggregateResponse,
  getDataPointsAsinGroupedWithCategories,
  getDataPointsAsinGroupedWithCategoriesWeekly,
} from '../../../api/data';
import { Client } from '../../../api/client';
import { Consumer } from '../../../common/types';
import { useClients } from '../../../clients/useClients';
import { DateRange } from '../../graphs/types';
import { useSidebar } from '../../sidebar/useSidebar';
import usePagination, { SortOption } from './useAsinPagination';
import { RowItem } from './AsinBreakdown';
import {
  ColumnTotals,
  getColumnTotalsFromDataResponses,
  transformDataTable,
  transformWeeksDataTable,
  WeeksRowItem,
} from './transformers';
import { useHeader } from '../../header/useHeader';
import { dateFormat } from '../../header/utils';

interface AsinBreakdownData {
  columnTotals: ColumnTotals;
  currentClient: Client;
  currentList: string[];
  currentPage: number;
  data: RowItem[];
  weeksData: WeeksRowItem[];
  error: boolean;
  loading: boolean;
  paginationLoading: boolean;
  setCurrentPage: Consumer<number>;
  setSortOption: Consumer<SortOption>;
  sortOption: SortOption;
  totalCount: number;
}

const useAsinBreakdownData: (weeks: boolean) => AsinBreakdownData = (
  weeks: boolean,
) => {
  const yearDates: DateRange = useMemo<DateRange>(() => {
    return {
      startDate: moment().startOf('year'),
      endDate: moment(),
    };
  }, []);

  const previousPeriodDates: DateRange = useMemo<DateRange>(() => {
    return {
      startDate: moment().startOf('year').subtract(1, 'year'),
      endDate: moment().startOf('year').subtract(1, 'year').endOf('year'),
    };
  }, []);

  const { currentClient } = useClients();
  const { selectedDates } = useHeader();

  const { fieldKeys } = useSidebar();

  const {
    currentList,
    currentPage,
    loading: paginationLoading,
    setCurrentPage,
    setSortOption,
    sortOption,
    totalCount,
  } = usePagination();

  const pauseQueries: boolean = useMemo(
    () =>
      currentClient.id === '' ||
      yearDates.startDate === null ||
      yearDates.endDate === null ||
      paginationLoading ||
      currentList.length === 0,
    [
      currentClient.id,
      currentList.length,
      paginationLoading,
      yearDates.endDate,
      yearDates.startDate,
    ],
  );

  const sharedVariables = useMemo(
    () => ({
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format('YYYYMMDD'),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format('YYYYMMDD'),
      client_id: currentClient.id,
      asin_id_list: currentList,
      order: sortOption.type,
    }),
    [currentClient, currentList, selectedDates, sortOption],
  );
  const [getCurrentDataResponse] = useQuery<GetDataPointsAggregateResponse>({
    query: getDataPointsAsinGroupedWithCategories,
    variables: {
      ...sharedVariables,
      fieldConfiguration_fieldKey: fieldKeys.orderedRevenue,
      weeklyStartDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      weeklyEndDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
    },
    pause: pauseQueries,
  });
  const [getOrderedUnitsResponse] = useQuery<GetDataPointsAggregateResponse>({
    query: getDataPointsAsinGroupedWithCategories,
    variables: {
      ...sharedVariables,
      fieldConfiguration_fieldKey: fieldKeys.unitsSold,
    },
    pause: pauseQueries,
  });

  const [getPreviousDataResponse] = useQuery<GetDataPointsAggregateResponse>({
    query: getDataPointsAsinGroupedWithCategories,
    variables: {
      ...sharedVariables,
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format(dateFormat),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format(dateFormat),
      fieldConfiguration_fieldKey: fieldKeys.orderedRevenue,
    },
    pause: pauseQueries,
  });

  const [getAsinsResponse] = useQuery<GetAsinsResponse>({
    query: getAsins,
    variables: {
      asin_id_list: currentList,
    },
    pause: pauseQueries || currentList.length < 1,
  });

  const data = useMemo(() => {
    if (!paginationLoading && currentList.length === 0) {
      return [];
    } else {
      return transformDataTable(
        getPreviousDataResponse,
        getCurrentDataResponse,
        getAsinsResponse,
        getOrderedUnitsResponse,
        sortOption,
      );
    }
  }, [
    paginationLoading,
    currentList.length,
    getPreviousDataResponse,
    getCurrentDataResponse,
    getAsinsResponse,
    getOrderedUnitsResponse,
    sortOption,
  ]);
  const [getCurrentWeeksDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getDataPointsAsinGroupedWithCategoriesWeekly,
    variables: {
      ...sharedVariables,
      fieldConfiguration_fieldKey: fieldKeys.orderedRevenue,
      asin_id_list: data.map(datum => datum.asinId).filter(asin => asin !== ''),
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format(dateFormat),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format(dateFormat),
    },
    pause: pauseQueries || !weeks || getCurrentDataResponse.fetching,
  });

  const weeksData = useMemo(
    () => transformWeeksDataTable(getCurrentWeeksDataResponse),
    [getCurrentWeeksDataResponse],
  );

  const error = useMemo(
    () =>
      getAsinsResponse.error ||
      getCurrentDataResponse.error ||
      getOrderedUnitsResponse.error ||
      getPreviousDataResponse.error,
    [
      getAsinsResponse,
      getCurrentDataResponse,
      getOrderedUnitsResponse,
      getPreviousDataResponse,
    ],
  );

  const loading = useMemo(
    () =>
      getAsinsResponse.fetching ||
      getCurrentDataResponse.fetching ||
      getCurrentWeeksDataResponse.fetching ||
      getOrderedUnitsResponse.fetching ||
      getPreviousDataResponse.fetching ||
      paginationLoading ||
      (pauseQueries && currentList.length !== 0),
    [
      currentList.length,
      getAsinsResponse.fetching,
      getCurrentDataResponse.fetching,
      getCurrentWeeksDataResponse.fetching,
      getOrderedUnitsResponse.fetching,
      getPreviousDataResponse.fetching,
      paginationLoading,
      pauseQueries,
    ],
  );

  const columnTotals = useMemo(
    () =>
      getColumnTotalsFromDataResponses({
        orderedRevenueResponse: getCurrentDataResponse,
        orderedUnitsResponse: getOrderedUnitsResponse,
        orderedRevenuePreviousResponse: getPreviousDataResponse,
      }),
    [getCurrentDataResponse, getOrderedUnitsResponse, getPreviousDataResponse],
  );

  return {
    columnTotals,
    currentClient,
    currentList,
    currentPage,
    data,
    weeksData,
    error: error !== undefined,
    loading,
    paginationLoading,
    setCurrentPage,
    setSortOption,
    sortOption,
    totalCount,
  };
};

export default useAsinBreakdownData;
