import { UseQueryState } from 'urql';
import { Asin, GetAsinsByClientIdResponse } from '../../api/asin';
import { extractUuidFromFullPath } from '../../common/utils';

export const transformAsinsByClientData: (
  response: UseQueryState<GetAsinsByClientIdResponse>,
) => Asin[] = response => {
  if (response.fetching || response.error) {
    return [];
  }
  if (response.data) {
    return response.data.asins.edges.map(edge => ({
      id: extractUuidFromFullPath(edge.node.id),
      asin: edge.node.asin,
      productName: edge.node.productName,
    }));
  }
  return [];
};
