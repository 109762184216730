import apiRoot from './apiRoot';

export const post: (
  url: string,
  body: string,
  token?: string,
) => Promise<Response> = (url, body, token = '') =>
  fetch(`${apiRoot}${url}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      ...(token && { authorization: `Bearer ${token}` }),
    },
    body,
  });

export const get: (url: string, token?: string) => Promise<Response> = (
  url,
  token = '',
) =>
  fetch(`${apiRoot}${url}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      ...(token && { authorization: `Bearer ${token}` }),
    },
  });
