import React from 'react';
import styled from '@emotion/styled';

const GradientSvg = styled.svg`
  opacity: 0;
  position: fixed;
`;

interface GradientDefinition {
  id: string;
  start: string;
  stop: string;
}

const GradientDefinition: React.FC<GradientDefinition> = ({
  id,
  start,
  stop,
}) => (
  <GradientSvg>
    <defs>
      <linearGradient id={`${id}_gradient`} gradientTransform="rotate(90)">
        <stop offset="0%" stopColor={start} />
        <stop offset="100%" stopColor={stop} />
      </linearGradient>
    </defs>
  </GradientSvg>
);

export default GradientDefinition;
