import React from 'react';
import styled from '@emotion/styled';
import { routes } from '../../common/routes';
import DefaultPage from '../Page';
import PrimaryBreakdown from '../breakdowns/PrimaryBreakdown';
import DualYAxisGraph from '../graphs/DualYAxisGraph';
import WaterfallGraph from '../graphs/waterfall/WaterfallGraph';
import { withDashboardStyles } from './styles';
import DonutChartBreakdown from '../breakdowns/donut/DonutChartBreakdown';
import BuyBoxSales from '../breakdowns/BuyBoxSales';
import { useSidebar } from '../sidebar/useSidebar';
import ASPSecondaryBreakdown from '../breakdowns/ASPSecondaryBreakdown';
import SecondaryBreakdown from '../breakdowns/SecondaryBreakdown';
import { useHeader } from '../header/useHeader';
import NoData from '../NoData';
import { useClients } from '../../clients/useClients';
const Page = styled(DefaultPage)`
  ${withDashboardStyles()}
`;

const SalesDashboard: React.FC = () => {
  const { fieldKeys } = useSidebar();
  const {
    lastValidDate,
    lastValidDateIsLoading,
    selectedFilters,
    periodLength,
    isReady,
  } = useHeader();
  const singleAsinSelected = () => {
    return selectedFilters?.length === 1 && selectedFilters[0]?.type === 'asin';
  };
  const { currentClient, changingClient } = useClients();
  if (
    currentClient.id &&
    !lastValidDateIsLoading() &&
    lastValidDate === false &&
    !periodLength &&
    !changingClient &&
    !isReady
  ) {
    return <NoData clientName={currentClient.name} />;
  }

  return (
    <Page title="Sales">
      <section className="breakdown-section ">
        <div className="primary-breakdown-container">
          <PrimaryBreakdown
            fieldKey={fieldKeys.orderedRevenue}
            link={routes.dashboard.asins}
            title="Ordered Revenue"
            deltaValueType="dollar amount"
          />
          <PrimaryBreakdown
            fieldKey={fieldKeys.primaryBreakdown2.key}
            link={routes.dashboard.asins}
            title={fieldKeys.primaryBreakdown2.title}
            deltaValueType={fieldKeys.primaryBreakdown2.type}
          />
          <PrimaryBreakdown
            fieldKey={fieldKeys.unitsSold}
            link={routes.dashboard.asins}
            title="Units Sold"
            deltaValueType="count"
          />
        </div>
      </section>
      <section className="breakdown-section">
        <div className="secondary-breakdown-container">
          {/* Type 'count' is only supported by the SecondaryBreakdown component, so we have to check here */}
          {fieldKeys.secondaryBreakdown1.type === 'count' ? (
            <SecondaryBreakdown
              fieldKey={fieldKeys.secondaryBreakdown1.key}
              link={routes.dashboard.asins}
              title={fieldKeys.secondaryBreakdown1.title}
              valueType={fieldKeys.secondaryBreakdown1.type}
            />
          ) : (
            <ASPSecondaryBreakdown
              fieldKey={fieldKeys.secondaryBreakdown1.key}
              secondaryFieldKey={fieldKeys.secondaryBreakdown1.secondKey}
              link={routes.dashboard.asins}
              title={fieldKeys.secondaryBreakdown1.title}
              valueType={fieldKeys.secondaryBreakdown1.type}
            />
          )}
          <ASPSecondaryBreakdown
            fieldKey={fieldKeys.secondaryBreakdown2.key}
            secondaryFieldKey={fieldKeys.secondaryBreakdown2.secondKey}
            link={routes.dashboard.asins}
            title={fieldKeys.secondaryBreakdown2.title}
            valueType={fieldKeys.secondaryBreakdown2.type}
          />
          <ASPSecondaryBreakdown
            fieldKey={fieldKeys.secondaryBreakdown3.key}
            secondaryFieldKey={fieldKeys.secondaryBreakdown3.secondKey}
            link={routes.dashboard.asins}
            title={fieldKeys.secondaryBreakdown3.title}
            valueType={fieldKeys.secondaryBreakdown3.type}
          />
        </div>
      </section>
      {!singleAsinSelected() && (
        <>
          <section className="breakdown-section donut-section">
            <DonutChartBreakdown />
          </section>
          <section className="breakdown-section buybox-sales-section">
            <BuyBoxSales />
          </section>
        </>
      )}
      <DualYAxisGraph title="Performance" />
      <WaterfallGraph title="Waterfall" />
    </Page>
  );
};

export default SalesDashboard;
