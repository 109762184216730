import React, { useMemo, useEffect, useState } from 'react';

import { DataPoint, DataType, FilterItem } from '../../common/types';
import { LineWithClickHandler } from './types';
import {
  transformDataPointAggregateToGraphData,
  transformPointsToGraphablePoints,
} from '../dashboard/transformers';
import { useQuery } from 'urql';
import {
  GetDataPointsAggregateResponse,
  getFilteredDataPointsGraph,
} from '../../api/data';
import { FieldConfiguration } from '../../api/fieldConfiguration';
import { XAxisResolutionType } from './DualYAxisGraph';
import { useHeader } from '../header/useHeader';
import { useClients } from '../../clients/useClients';
import { fieldConfigurationFormula, useSidebar } from '../sidebar/useSidebar';

interface Props {
  fieldKey: string;
  variable: FieldConfiguration;
  callBack: (line: LineWithClickHandler) => void;
  lines: LineWithClickHandler[];
  removeVariable: (displayName: string) => void;
  xAxisResolution: XAxisResolutionType;
}

const GraphLine: React.FC<Props> = ({
  fieldKey,
  variable,
  callBack,
  lines,
  removeVariable,
  xAxisResolution,
}) => {
  const [dataPoints, setDataPoints] = useState<DataPoint[]>([]);
  const [line, setLine] = useState<LineWithClickHandler | null>(null);
  const [usableFC, setUsableFC] = useState({ firstKey: '', secondKey: '' });
  const [currentFilters, setCurrentFilters] = useState<FilterItem[]>([]);
  const {
    selectedDates,
    previousPeriodDates,
    selectedFilters,
    checkedFilters,
    rowFilters,
  } = useHeader();
  const { currentClient } = useClients();

  const pauseQueries: boolean = useMemo(
    () =>
      currentClient.id === '' ||
      selectedDates.startDate === null ||
      selectedDates.endDate === null,
    [currentClient, selectedDates],
  );

  const { fieldKeys } = useSidebar();

  const convertFieldKey: (fieldKey: string, isFirst: boolean) => string = (
    fieldKey,
    isFirst,
  ) => {
    switch (fieldKey) {
      case 'ordered_revenue':
      case 'ordered_revenue_sc':
        return fieldKeys.orderedRevenue;
      case 'ordered_units':
      case 'ordered_units_sc':
        return fieldKeys.unitsSold;
      case 'average_sales_price':
        return isFirst ? fieldKeys.orderedRevenue : fieldKeys.unitsSold;
      case 'buy_box_percentage':
        return isFirst ? fieldKeys.buyBoxPerformance : fieldKeys.pageViews;
      case 'conversion_rate_old':
        return isFirst ? fieldKeys.orderId : fieldKeys.sessions;
      default:
        return fieldKey;
    }
  };

  useEffect(() => {
    setUsableFC(fieldConfigurationFormula(fieldKey));
    return () => {
      setUsableFC({ firstKey: '', secondKey: '' });
    };
  }, [fieldKey]);
  const [getDatPointsForGraph] = useQuery<GetDataPointsAggregateResponse>({
    query: getFilteredDataPointsGraph,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format('YYYYMMDD'),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format('YYYYMMDD'),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: convertFieldKey(usableFC.firstKey, true),
      asin_id_list: currentFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id),
      asin_category_list: currentFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
      asin_subcategory_list: currentFilters
        .filter(filter => filter.type === 'subcategory')
        .map(filter => filter.id),
      resolutionPeriod: xAxisResolution.toLowerCase(),
    },
    pause: pauseQueries,
  });
  const [getDatPointsForGraphSecondary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsGraph,
    variables: {
      startDate:
        selectedDates.startDate === null
          ? null
          : selectedDates.startDate.format('YYYYMMDD'),
      endDate:
        selectedDates.endDate === null
          ? null
          : selectedDates.endDate.format('YYYYMMDD'),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: convertFieldKey(usableFC.secondKey, true),
      asin_id_list: currentFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id),
      asin_category_list: currentFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
      asin_subcategory_list: currentFilters
        .filter(filter => filter.type === 'subcategory')
        .map(filter => filter.id),
      resolutionPeriod: xAxisResolution.toLowerCase(),
    },
    pause: pauseQueries || usableFC.secondKey === '',
  });
  const [getPreviousDatPointsForGraph] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsGraph,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format('YYYYMMDD'),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format('YYYYMMDD'),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: convertFieldKey(usableFC.firstKey, true),
      asin_id_list: currentFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id),
      asin_category_list: currentFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
      asin_subcategory_list: currentFilters
        .filter(filter => filter.type === 'subcategory')
        .map(filter => filter.id),
      resolutionPeriod: xAxisResolution.toLowerCase(),
    },
    pause: pauseQueries || variable.previous === false,
  });
  const [getPreviousDatPointsForGraphSecondary] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsGraph,
    variables: {
      startDate:
        previousPeriodDates.startDate === null
          ? null
          : previousPeriodDates.startDate.format('YYYYMMDD'),
      endDate:
        previousPeriodDates.endDate === null
          ? null
          : previousPeriodDates.endDate.format('YYYYMMDD'),
      client_id: currentClient.id,
      fieldConfiguration_fieldKey: convertFieldKey(usableFC.secondKey, true),
      asin_id_list: currentFilters
        .filter(filter => filter.type === 'asin')
        .map(filter => filter.id),
      asin_category_list: currentFilters
        .filter(filter => filter.type === 'category')
        .map(filter => filter.id),
      asin_subcategory_list: currentFilters
        .filter(filter => filter.type === 'subcategory')
        .map(filter => filter.id),
      resolutionPeriod: xAxisResolution.toLowerCase(),
    },
    pause:
      pauseQueries || usableFC.secondKey === '' || variable.previous === false,
  });

  useEffect(() => {
    const primaryPoints = transformDataPointAggregateToGraphData(
      variable.fieldType as DataType,
      xAxisResolution,
      getDatPointsForGraph,
      undefined,
      selectedDates,
      undefined,
      variable.displayName,
    );
    const primaryPreviousPoints = transformDataPointAggregateToGraphData(
      variable.fieldType as DataType,
      xAxisResolution,
      getDatPointsForGraph,
      getPreviousDatPointsForGraph,
      selectedDates,
      previousPeriodDates,
      variable.displayName,
    );
    const secondaryPoints = transformDataPointAggregateToGraphData(
      variable.fieldType as DataType,
      xAxisResolution,
      getDatPointsForGraphSecondary,
      undefined,
      selectedDates,
      undefined,
      variable.displayName,
    );
    const secondaryPreviousPoints = transformDataPointAggregateToGraphData(
      variable.fieldType as DataType,
      xAxisResolution,
      getDatPointsForGraphSecondary,
      getPreviousDatPointsForGraphSecondary,
      selectedDates,
      previousPeriodDates,
      variable.displayName,
    );
    if (variable.previous === true) {
      setDataPoints(
        transformPointsToGraphablePoints(
          primaryPreviousPoints,
          usableFC.secondKey === '' ? [] : secondaryPreviousPoints,
          variable.fieldType as DataType,
        ),
      );
    } else {
      setDataPoints(
        transformPointsToGraphablePoints(
          primaryPoints,
          usableFC.secondKey === '' ? [] : secondaryPoints,
          variable.fieldType as DataType,
        ),
      );
    }
  }, [
    getDatPointsForGraph,
    getPreviousDatPointsForGraph,
    previousPeriodDates,
    selectedDates,
    variable.fieldType,
    variable.previous,
    variable.displayName,
    xAxisResolution,
    getDatPointsForGraphSecondary,
    getPreviousDatPointsForGraphSecondary,
    usableFC.secondKey,
  ]);

  useEffect(() => {
    if (dataPoints.length > 0) {
      setLine({
        onRemoveClick: () => removeVariable(variable.displayName),
        data: dataPoints,
        dataSetName: variable.displayName,
        dataType: variable.fieldType as DataType,
        previous: variable.previous === undefined ? false : variable.previous,
        fieldKey: variable.fieldKey,
      });
    }
  }, [dataPoints, variable, removeVariable, currentFilters]);
  useEffect(() => {
    if (line !== null) {
      const exists = lines.find(
        _line => _line.dataSetName === line.dataSetName,
      );
      if (!exists) {
        callBack(line);
      } else {
        let counter = 0;

        if (exists.data.length === line.data.length) {
          exists.data.forEach((dp, i) => {
            if (dp.label !== line.data[i].label) {
              counter++;
            }
          });
        } else {
          counter = 1;
        }
        if (counter > 0) {
          callBack(line);
        }
      }
    }
  }, [callBack, line, lines, variable]);
  useEffect(() => {
    if (checkedFilters.length) return setCurrentFilters(checkedFilters);
    if (rowFilters.length) return setCurrentFilters(rowFilters);
    setCurrentFilters(selectedFilters);
  }, [selectedFilters, checkedFilters, rowFilters]);
  return <></>;
};

export default GraphLine;
