import React, { useState, useEffect, useCallback } from 'react';
import { useMutation } from 'urql';

import styled from '@emotion/styled';
import { TextField, Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Color as AlertColor } from '@material-ui/lab/Alert';

import { useAuth } from '../../auth/useAuth';
import { updatePasswordMutation } from '../../api/user';
import Page from '../Page';
import PasswordField from '../PasswordField';
import { withAccountPageStyles } from './styles';

const Container = styled.section`
  ${withAccountPageStyles()};
`;

interface AccountFormValuesType<TValue> {
  [id: string]: TValue;
}

const Account: React.FC = () => {
  const { username, userId, firstName, lastName } = useAuth();
  const defaultFormValues = {
    email: username || '',
    firstName: firstName || '',
    lastName: lastName || '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
  const AlertSeverity = {
    error: 'error',
    warning: 'warning',
    info: 'info',
    success: 'success',
  };
  const [formValues, setFormValues] = useState<AccountFormValuesType<string>>(
    defaultFormValues,
  );
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState(AlertSeverity.info);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, updatePassword] = useMutation(updatePasswordMutation);

  const updateFormValues = useCallback(
    (key: string, value: string) => {
      const newValues = { ...formValues };
      newValues[key] = value;
      setFormValues(newValues);
    },
    [formValues],
  );

  const handleUpdatePasswordClick = () => {
    if (isUpdatingPassword) {
      if (validateNewPassword()) {
        performPasswordUpdate();
      }
    } else {
      setIsUpdatingPassword(true);
    }
  };

  const validateNewPassword = () => {
    if (formValues.currentPassword.length === 0) {
      createNotification('Please enter current password', AlertSeverity.error);
      return false;
    }
    // TODO: Validate current password is correct.
    if (formValues.newPassword.length === 0) {
      createNotification('Please enter new password', AlertSeverity.error);
      return false;
    }
    if (formValues.confirmPassword.length === 0) {
      createNotification(
        'Please enter password confirmation',
        AlertSeverity.error,
      );
      return false;
    }
    if (formValues.newPassword !== formValues.confirmPassword) {
      createNotification('Passwords do not match', AlertSeverity.error);
      return false;
    }
    return true;
  };

  const performPasswordUpdate = async () => {
    const result = await updatePassword({
      userId,
      newPassword: formValues.newPassword,
      oldPassword: formValues.currentPassword,
    });
    if (result.error) {
      result.error.graphQLErrors.forEach(err => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let errorMsg = err.originalError?.debugMessage;
        if (!errorMsg || errorMsg === '') {
          errorMsg =
            'There has been an unexpected error. Please check your entries and try again.';
        }
        createNotification(errorMsg, AlertSeverity.error);
      });
      return;
    }

    createNotification('Password updated', AlertSeverity.success);
    setIsUpdatingPassword(false);
    setFormValues({
      ...formValues,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  };

  const createNotification = (message: string, severity: string) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setShowAlertMessage(true);
  };

  const removeNotification = () => {
    setAlertMessage('');
    setAlertSeverity(AlertSeverity.info);
    setShowAlertMessage(false);
  };
  useEffect(() => {
    if (firstName !== formValues.firstName)
      updateFormValues('firstName', firstName || '');
    if (lastName !== formValues.lastName)
      updateFormValues('lastName', lastName || '');
  }, [
    formValues.firstName,
    formValues.lastName,
    firstName,
    lastName,
    updateFormValues,
  ]);
  return (
    <Page title={firstName || username} page="account">
      <Container>
        <section>
          <form className="account-form" autoComplete="off">
            <TextField
              margin="normal"
              size="small"
              fullWidth
              disabled
              id="user-first-name"
              label="First Name"
              variant="outlined"
              value={formValues.firstName}
              className="input form-control"
            />
            <TextField
              margin="normal"
              size="small"
              fullWidth
              disabled
              id="user-last-name"
              label="Last Name"
              variant="outlined"
              value={formValues.lastName}
              className="input form-control"
            />
            <TextField
              margin="normal"
              size="small"
              fullWidth
              disabled
              id="email"
              label="Email"
              variant="outlined"
              value={formValues.email}
              className="input form-control"
            />
            {isUpdatingPassword && (
              <>
                <PasswordField
                  fieldKey="currentPassword"
                  id="current-password"
                  label="Current Password"
                  value={formValues.currentPassword}
                  onChange={(key, value) => updateFormValues(key, value)}
                />
                <PasswordField
                  fieldKey="newPassword"
                  id="new-password"
                  label="New Password"
                  value={formValues.newPassword}
                  onChange={(key, value) => updateFormValues(key, value)}
                />
                <PasswordField
                  fieldKey="confirmPassword"
                  id="confirm-password"
                  label="Confirm Password"
                  value={formValues.confirmPassword}
                  onChange={(key, value) => updateFormValues(key, value)}
                />
              </>
            )}
            <Button
              className="form-control password-button"
              variant="outlined"
              size="medium"
              color="primary"
              onClick={handleUpdatePasswordClick}
            >
              Change password
            </Button>
          </form>
        </section>
        <Snackbar
          open={showAlertMessage}
          autoHideDuration={6000}
          onClose={removeNotification}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={removeNotification}
            severity={alertSeverity as AlertColor}
          >
            {alertMessage}
          </MuiAlert>
        </Snackbar>
      </Container>
    </Page>
  );
};

export default Account;
