import { useMemo } from 'react';
import { useQuery, UseQueryState } from 'urql';
import {
  getAsins,
  GetAsinsResponse,
  GetDataPointsAggregateResponse,
  getFilteredDataPointsForDonutChart,
  getFilteredDataPointsGroupedByAsin,
  getFilteredDataPointsGroupedBySubCategory,
} from '../../../api/data';
import { useClients } from '../../../clients/useClients';
import { useSidebar } from '../../sidebar/useSidebar';
import { useHeader } from '../../header/useHeader';
import { dateFormat } from '../../header/utils';

export interface DataFilters {
  asins: string[];
  categories: string[];
  subCategories: string[];
}

interface DonutChartData {
  asinsResponse: UseQueryState<GetAsinsResponse>;
  dataFilters: DataFilters;
  loading: boolean;
  orderedProductsDataByAsinResponse: UseQueryState<
    GetDataPointsAggregateResponse
  >;
  orderedProductsDataBySubcategoryResponse: UseQueryState<
    GetDataPointsAggregateResponse
  >;
  orderedProductsDataResponse: UseQueryState<GetDataPointsAggregateResponse>;
  orderedRevenueDataByAsinResponse: UseQueryState<
    GetDataPointsAggregateResponse
  >;
  orderedRevenueDataBySubcategoryResponse: UseQueryState<
    GetDataPointsAggregateResponse
  >;
  orderedRevenueDataResponse: UseQueryState<GetDataPointsAggregateResponse>;
}

const useDonutChartData: () => DonutChartData = () => {
  const { selectedDates, selectedFilters, isReady } = useHeader();
  const { currentClient } = useClients();

  const { fieldKeys } = useSidebar();

  const dataFilters = useMemo(
    () => ({
      asins: selectedFilters
        .filter(item => item.type === 'asin')
        .map(item => item.id),
      categories: selectedFilters
        .filter(item => item.type === 'category')
        .map(item => item.id),
      subCategories: selectedFilters
        .filter(item => item.type === 'subcategory')
        .map(item => item.id),
    }),
    [selectedFilters],
  );

  const otherFilters = useMemo(
    () => ({
      clientId: currentClient.id,
      endDate: selectedDates.endDate?.format(dateFormat),
      startDate: selectedDates.startDate?.format(dateFormat),
    }),
    [currentClient, selectedDates],
  );

  const pauseQueries: boolean = useMemo(
    () =>
      currentClient.id === '' ||
      selectedDates.startDate === null ||
      selectedDates.endDate === null,
    [currentClient, selectedDates],
  );

  const [orderedRevenueDataResponse] = useQuery<GetDataPointsAggregateResponse>(
    {
      query: getFilteredDataPointsForDonutChart,
      variables: {
        fieldKey: fieldKeys.orderedRevenue,
        ...otherFilters,
      },
      pause:
        pauseQueries ||
        dataFilters.categories.length > 0 ||
        dataFilters.subCategories.length > 0 ||
        dataFilters.asins.length > 0,
    },
  );

  const [orderedProductsDataResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsForDonutChart,
    variables: {
      fieldKey: fieldKeys.unitsSold,
      ...otherFilters,
    },
    pause:
      pauseQueries ||
      dataFilters.categories.length > 0 ||
      dataFilters.subCategories.length > 0 ||
      dataFilters.asins.length > 0,
  });

  const [orderedRevenueDataBySubcategoryResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsGroupedBySubCategory,
    variables: {
      categories: dataFilters.categories,
      fieldKey: fieldKeys.orderedRevenue,
      ...otherFilters,
    },
    pause: pauseQueries || dataFilters.categories.length < 1,
  });

  const [orderedProductsDataBySubcategoryResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsGroupedBySubCategory,
    variables: {
      categories: dataFilters.categories,
      fieldKey: fieldKeys.unitsSold,
      ...otherFilters,
    },
    pause: pauseQueries || dataFilters.categories.length < 1,
  });

  const [orderedRevenueDataByAsinResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsGroupedByAsin,
    variables: {
      subcategories: dataFilters.subCategories,
      asins: dataFilters.asins,
      fieldKey: fieldKeys.orderedRevenue,
      ...otherFilters,
    },
    pause:
      pauseQueries ||
      (dataFilters.subCategories.length < 1 && dataFilters.asins.length < 1),
  });
  const [orderedProductsDataByAsinResponse] = useQuery<
    GetDataPointsAggregateResponse
  >({
    query: getFilteredDataPointsGroupedByAsin,
    variables: {
      subcategories: dataFilters.subCategories,
      asins: dataFilters.asins,
      fieldKey: fieldKeys.unitsSold,
      ...otherFilters,
    },
    pause:
      pauseQueries ||
      (dataFilters.subCategories.length < 1 && dataFilters.asins.length < 1),
  });

  const asinIdsForSelectedSubCategories: string[] = useMemo(() => {
    if (
      !orderedRevenueDataByAsinResponse.fetching &&
      !orderedRevenueDataByAsinResponse.error &&
      orderedRevenueDataByAsinResponse.data
    ) {
      return orderedRevenueDataByAsinResponse.data?.dataPointAggregates.edges.map(
        edge => edge.node.asinId || '',
      );
    }
    return [];
  }, [orderedRevenueDataByAsinResponse]);

  const [asinsResponse] = useQuery<GetAsinsResponse>({
    query: getAsins,
    variables: {
      asin_id_list: asinIdsForSelectedSubCategories,
    },
    pause: pauseQueries || asinIdsForSelectedSubCategories.length < 1,
  });
  const loading: boolean = useMemo(
    () =>
      !isReady ||
      asinsResponse.fetching ||
      orderedProductsDataByAsinResponse.fetching ||
      orderedProductsDataBySubcategoryResponse.fetching ||
      orderedProductsDataResponse.fetching ||
      orderedRevenueDataByAsinResponse.fetching ||
      orderedRevenueDataBySubcategoryResponse.fetching ||
      orderedRevenueDataResponse.fetching,
    [
      isReady,
      asinsResponse,
      orderedProductsDataByAsinResponse,
      orderedProductsDataBySubcategoryResponse,
      orderedProductsDataResponse,
      orderedRevenueDataByAsinResponse,
      orderedRevenueDataBySubcategoryResponse,
      orderedRevenueDataResponse,
    ],
  );

  return {
    asinsResponse,
    dataFilters,
    loading,
    orderedProductsDataByAsinResponse,
    orderedProductsDataBySubcategoryResponse,
    orderedProductsDataResponse,
    orderedRevenueDataByAsinResponse,
    orderedRevenueDataBySubcategoryResponse,
    orderedRevenueDataResponse,
  };
};

export default useDonutChartData;
