import React from 'react';
import styled from '@emotion/styled';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DefaultAccordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CombinedDonutGraphData } from '../../../common/types';
import {
  prefixPositiveOrNegative,
  prettyPrintNumber,
} from '../../../common/utils';
import { withAccordionOverrides } from '../styles';
import { useCategoryTitle } from '../useCategoryTitle';
const Accordion = styled(DefaultAccordion)`
  ${withAccordionOverrides(true)}
  .slice-breakdown-table {
    max-height: 480px;
    overflow: auto;
  }
`;

interface SliceBreakdownBlockProps {
  data: Array<CombinedDonutGraphData>;
  expand: boolean;
  onShowAllClick: () => void;
  selectedDataSlice: CombinedDonutGraphData;
}

const SliceBreakdownTable: React.FC<SliceBreakdownBlockProps> = ({
  data,
  expand,
  onShowAllClick,
  selectedDataSlice,
}) => {
  const { categoryTitle } = useCategoryTitle();
  return data.length === 0 ? null : (
    <Accordion elevation={0} expanded={expand} onChange={onShowAllClick}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <span className="slice-breakdown-title">Breakdown</span>
        <span className="accordian-button">
          {expand ? 'Collapse' : 'Expand'}
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer className="slice-breakdown-table">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{categoryTitle}</TableCell>
                <TableCell
                  colSpan={2}
                  className="border-left data-column-header"
                  align="center"
                >
                  Ordered Revenue
                </TableCell>

                <TableCell
                  colSpan={2}
                  className="border-left data-column-header"
                  align="center"
                >
                  Ordered Products
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(item => {
                if (item.id === 'all-others') {
                  return null;
                }

                const values = {
                  revenueDelta: item.revenuePercentage || 0,
                  revenueValue: item.revenueValue || 0,
                  productDelta: item.productsPercentage || 0,
                  productValue: item.productsValue || 0,
                };

                return (
                  <TableRow
                    key={`sl-${item.id}`}
                    className="slice-breakdown-item"
                  >
                    {/* <TableCell className="color-column"></TableCell> */}
                    <TableCell>
                      <span className="color-swatch-column">
                        <div className="color-swatch">
                          <div
                            className={`data-slice-color${
                              item.color === '#FBFBFB'
                                ? ' data-slice-border'
                                : ''
                            }${
                              selectedDataSlice.id === item.id
                                ? ' selected'
                                : ''
                            }`}
                            style={{ backgroundColor: item.color }}
                          />
                        </div>
                        <div>{item.label}</div>
                      </span>
                    </TableCell>
                    <TableCell
                      className={`column-data border-left ${
                        values.revenueDelta >= 0
                          ? 'positive_delta'
                          : 'negative_delta'
                      }`}
                    >
                      {values.revenueDelta > 0 &&
                        prefixPositiveOrNegative('positive')}
                      {prettyPrintNumber(values.revenueDelta, 'percent')}
                    </TableCell>
                    <TableCell className="column-data" align="right">
                      {prettyPrintNumber(
                        values.revenueValue,
                        'dollar amount',
                        2,
                      )}
                    </TableCell>
                    <TableCell
                      className={`column-data border-left ${
                        values.productDelta >= 0
                          ? 'positive_delta'
                          : 'negative_delta'
                      }`}
                    >
                      {values.productDelta > 0 &&
                        prefixPositiveOrNegative('positive')}
                      {prettyPrintNumber(values.productDelta, 'percent')}
                    </TableCell>
                    <TableCell className="column-data" align="right">
                      {prettyPrintNumber(values.productValue, 'count')}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default SliceBreakdownTable;
