import { UseQueryState } from 'urql';
import {
  GetAsinsResponse,
  GetDataPointsAggregateResponse,
} from '../../api/data';
import { Change, DataPoint, FilterType } from '../../common/types';
import { extractUuidFromFullPath, prettyPrintNumber } from '../../common/utils';
import { GetSubCategoriesByClientId } from '../../api/categories';

export const transformDataToWaterfallGraph: (
  previousRevenue: UseQueryState<GetDataPointsAggregateResponse>,
  currentRevenue: UseQueryState<GetDataPointsAggregateResponse>,
  previousSubItemRevenue: UseQueryState<GetDataPointsAggregateResponse>,
  currentSubItemRevenue: UseQueryState<GetDataPointsAggregateResponse>,
  subItemType: FilterType,
  asinsResponse: UseQueryState<GetAsinsResponse>,
  subcategoriesResponse: UseQueryState<GetSubCategoriesByClientId>,
  asinsResponsePrevious: UseQueryState<GetAsinsResponse>,
) => {
  dataset: DataPoint[][];
  labels: string[];
  max: number;
  changes: string[];
} = (
  previousRevenue,
  currentRevenue,
  previousSubItemRevenue,
  currentSubItemRevenue,
  subItemType,
  asinsResponse,
  subcategoriesResponse,
  asinsResponsePrevious,
) => {
  if (
    previousRevenue.data &&
    previousRevenue.data.dataPointAggregates &&
    currentRevenue.data &&
    currentRevenue.data.dataPointAggregates &&
    previousSubItemRevenue.data &&
    previousSubItemRevenue.data.dataPointAggregates &&
    currentSubItemRevenue.data &&
    currentSubItemRevenue.data.dataPointAggregates &&
    !(subItemType === 'asin' && (asinsResponse.fetching || !asinsResponse.data))
  ) {
    let previousDataPoints = previousSubItemRevenue.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const currentDataPoints = currentSubItemRevenue.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const previousRevenueValue =
      previousRevenue.data.dataPointAggregates.edges.length > 0
        ? previousRevenue.data.dataPointAggregates.edges[0].node.value
        : '0';
    const currentRevenueValue =
      currentRevenue.data.dataPointAggregates.edges.length > 0
        ? currentRevenue.data.dataPointAggregates.edges[0].node.value
        : '0';

    const compareTop = (a: DataPoint, b: DataPoint) => {
      return a.y - b.y;
    };
    const compareBottom = (a: DataPoint, b: DataPoint) => {
      return -1 * (a.y - b.y);
    };
    const calcDeltaDataPoints: () => DataPoint[] = () => {
      const deltas: DataPoint[] = [];
      currentDataPoints.forEach(currentDataPoint => {
        const foundDataPoint = previousDataPoints.find(previousDataPoint => {
          if (subItemType === 'category') {
            return previousDataPoint.categoryId === currentDataPoint.categoryId;
          }
          if (subItemType === 'subcategory') {
            return (
              previousDataPoint.subcategoryId === currentDataPoint.subcategoryId
            );
          }
          if (subItemType === 'asin') {
            return previousDataPoint.asinId === currentDataPoint.asinId;
          }
          return undefined;
        });
        if (foundDataPoint !== undefined) {
          const getX: () => string = () => {
            if (subItemType === 'category') {
              return currentDataPoint.category
                ? (currentDataPoint.category as string)
                : 'Uncategorized';
            }
            if (subItemType === 'subcategory') {
              const categories: string[] = [];
              subcategoriesResponse.data?.subcategories.edges.forEach(_item => {
                if (!categories.includes(_item.node.category.name)) {
                  categories.push(_item.node.category.name);
                }
              });
              const subcategory = subcategoriesResponse.data?.subcategories.edges.find(
                edge =>
                  extractUuidFromFullPath(edge.node.id) ===
                  currentDataPoint.subcategoryId,
              );
              return subcategory
                ? `${subcategory.node.name} ${
                    categories.length > 1
                      ? `(${subcategory.node.category.name})`
                      : ''
                  }`
                : 'Uncategorized';
            }
            if (subItemType === 'asin') {
              const asin = asinsResponse.data?.asins.edges.find(
                edge =>
                  extractUuidFromFullPath(edge.node.id) ===
                  currentDataPoint.asinId,
              );
              return asin
                ? `${asin.node.productName ?? ''} (${asin.node.asin})`
                : '';
            }
            return '';
          };
          if (getX() !== '') {
            deltas.push({
              x: getX(),
              y:
                parseFloat(currentDataPoint.value) -
                parseFloat(foundDataPoint.value),
            });
          }
          previousDataPoints = previousDataPoints.filter(dataPoint => {
            if (subItemType === 'category') {
              return dataPoint.categoryId !== foundDataPoint.categoryId;
            }
            if (subItemType === 'subcategory') {
              return dataPoint.subcategoryId !== foundDataPoint.subcategoryId;
            } else {
              return dataPoint.asinId !== foundDataPoint.asinId;
            }
          });
        } else {
          const getX: () => string = () => {
            if (subItemType === 'category') {
              return currentDataPoint.category as string;
            }
            if (subItemType === 'subcategory') {
              return currentDataPoint.subcategory as string;
            }
            if (subItemType === 'asin') {
              const asin = asinsResponse.data?.asins.edges.find(
                edge =>
                  extractUuidFromFullPath(edge.node.id) ===
                  currentDataPoint.asinId,
              );
              return asin ? asin.node.asin : '';
            }
            return '';
          };
          if (getX() !== '') {
            deltas.push({
              x: getX(),
              y: parseFloat(currentDataPoint.value),
            });
          } else {
            deltas.push({
              x: 'Uncategorized',
              y: parseFloat(currentDataPoint.value),
            });
          }
        }
      });
      if (previousDataPoints.length > 0) {
        previousDataPoints.forEach(previousDataPoint => {
          const getX: () => string = () => {
            if (subItemType === 'category') {
              return previousDataPoint.category as string;
            }
            if (subItemType === 'subcategory') {
              return previousDataPoint.subcategory as string;
            }
            if (subItemType === 'asin') {
              const asin = asinsResponsePrevious.data?.asins.edges.find(
                edge =>
                  extractUuidFromFullPath(edge.node.id) ===
                  previousDataPoint.asinId,
              );
              return asin ? asin.node.asin : '';
            }
            return '';
          };
          if (getX() !== '') {
            deltas.push({
              x: getX(),
              y: parseFloat(previousDataPoint.value) * -1,
            });
          } else {
            deltas.push({
              x: 'Uncategorized',
              y: parseFloat(previousDataPoint.value) * -1,
            });
          }
        });
      }
      return deltas;
    };
    const deltaDataPoints = calcDeltaDataPoints();
    const sumPartial = (array: DataPoint[], stopIndex: number) => {
      let sum = 0;
      for (let i = 0; i < stopIndex; i++) {
        sum += array[i].y;
      }
      return sum;
    };
    let ordered = deltaDataPoints.sort(compareBottom);
    if (deltaDataPoints.length > 9) {
      const topFive = ordered.slice(0, 4);
      ordered = ordered.slice(4).sort(compareTop);
      const bottomFive = ordered.slice(0, 4).sort(compareBottom);
      const other = ordered.slice(4).reduce(
        (a, b) => {
          return {
            x: `All Other ${
              subItemType === 'category'
                ? 'Categories'
                : subItemType === 'subcategory'
                ? 'Subcategories'
                : 'Asins'
            }`,
            y: a.y + b.y,
          };
        },
        { x: 'All Other Categories', y: 0 },
      );
      ordered = topFive.concat(bottomFive).concat(other);
    }
    const labels: string[] = [];
    const changes: string[] = [''];
    const previousTotal = parseFloat(previousRevenueValue);
    const currentTotal = parseFloat(currentRevenueValue);
    labels.push(prettyPrintNumber(previousTotal, 'dollar amount'));
    for (let i = 0; i < ordered.length; i++) {
      labels.push(
        `${prettyPrintNumber(ordered[i].y, 'dollar amount')}\n${
          ordered[i].y > 0 ? '+' : ''
        }${
          previousTotal > 0
            ? prettyPrintNumber(
                (100.0 / previousTotal) * ordered[i].y,
                'percent',
              )
            : prettyPrintNumber(
                (100.0 / currentTotal) * ordered[i].y,
                'percent',
              )
        }`,
      );
      changes.push(
        previousTotal > 0
          ? prettyPrintNumber((100.0 / previousTotal) * ordered[i].y, 'percent')
          : prettyPrintNumber((100.0 / currentTotal) * ordered[i].y, 'percent'),
      );
    }
    labels.push(
      `${prettyPrintNumber(currentTotal, 'dollar amount')}\n${
        currentTotal - previousTotal > 0 ? '+' : ''
      }${
        previousTotal > 0
          ? prettyPrintNumber(
              (100.0 / previousTotal) * (currentTotal - previousTotal),
              'percent',
            )
          : prettyPrintNumber(100, 'percent')
      }`,
    );
    changes.push(
      previousTotal > 0
        ? prettyPrintNumber(
            (100.0 / previousTotal) * (currentTotal - previousTotal),
            'percent',
          )
        : prettyPrintNumber(100, 'percent'),
    );
    const dataset = [
      [
        {
          x: 'Previous Period',
          y: 0,
        },
      ]
        .concat(
          ordered.map((value, index) => {
            const nameIndex = ordered.findIndex(_name => value.x === _name.x);
            return {
              x: value.x
                ? `${
                    nameIndex === index
                      ? value.x.toString() === ''
                        ? 'Uncategorized'
                        : value.x.toString()
                      : (value.x.toString() === ''
                          ? 'Uncategorized'
                          : value.x.toString()) + value.y
                  }`
                : 'Uncategorized',
              y:
                parseFloat(previousRevenueValue) +
                (value.y < 0 ? value.y : 0) +
                (index > 0 ? sumPartial(ordered, index) : 0),
            };
          }),
        )
        .concat([
          {
            x: 'Current Period',
            y: 0,
          },
        ]),
      [
        {
          x: 'Previous Period',
          y: parseFloat(previousRevenueValue),
        },
      ]
        .concat(
          ordered.map((value, index) => {
            const nameIndex = ordered.findIndex(_name => value.x === _name.x);
            return {
              x: value.x
                ? `${
                    nameIndex === index
                      ? value.x.toString()
                      : value.x.toString() + value.y
                  }`
                : 'Uncategorized',
              y: Math.abs(value.y),
              isNegative: value.y < 0,
            };
          }),
        )
        .concat([
          {
            x: 'Current Period',
            y: parseFloat(currentRevenueValue),
          },
        ]),
    ];
    const negativeSpace = dataset[0];
    const partials = dataset[1];

    const max = Math.round(
      Math.max(
        ...negativeSpace.map(datum => {
          const match = partials.find(partial => partial.x === datum.x);
          if (match) {
            return match.y + datum.y;
          }
          return datum.y;
        }),
      ) * 1.2,
    );
    //TODO REPEATED
    return {
      dataset,
      labels,
      max,
      changes,
    };
  }
  return { dataset: [], labels: [], max: 0, changes: [] };
};

export interface BreakDownDatapoint {
  category: string;
  value: string;
  percentage: string;
  change: Change;
}

export const transformDataToWaterfallBreakdown: (
  previousRevenue: UseQueryState<GetDataPointsAggregateResponse>,
  currentRevenue: UseQueryState<GetDataPointsAggregateResponse>,
  previousSubItemRevenue: UseQueryState<GetDataPointsAggregateResponse>,
  currentSubItemRevenue: UseQueryState<GetDataPointsAggregateResponse>,
  subItemType: FilterType,
  asinsResponse: UseQueryState<GetAsinsResponse>,
  subcategoriesResponse: UseQueryState<GetSubCategoriesByClientId>,
) => BreakDownDatapoint[] = (
  previousRevenue,
  currentRevenue,
  previousSubItemRevenue,
  currentSubItemRevenue,
  subItemType,
  asinsResponse,
  subcategoriesResponse,
) => {
  if (
    previousRevenue.data &&
    previousRevenue.data.dataPointAggregates &&
    currentRevenue.data &&
    currentRevenue.data.dataPointAggregates &&
    previousSubItemRevenue.data &&
    previousSubItemRevenue.data.dataPointAggregates &&
    currentSubItemRevenue.data &&
    currentSubItemRevenue.data.dataPointAggregates &&
    !(subItemType === 'asin' && (asinsResponse.fetching || !asinsResponse.data))
  ) {
    let previousDataPoints = previousSubItemRevenue.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const currentDataPoints = currentSubItemRevenue.data.dataPointAggregates.edges.map(
      edge => edge.node,
    );
    const previousRevenueValue =
      previousRevenue.data.dataPointAggregates.edges.length > 0
        ? previousRevenue.data.dataPointAggregates.edges[0].node.value
        : '0';
    const currentRevenueValue =
      currentRevenue.data.dataPointAggregates.edges.length > 0
        ? currentRevenue.data.dataPointAggregates.edges[0].node.value
        : '0';

    const previousTotal = parseFloat(previousRevenueValue);
    const currentTotal = parseFloat(currentRevenueValue);
    const compare = (a: BreakDownDatapoint, b: BreakDownDatapoint) => {
      return parseFloat(a.value) - parseFloat(b.value);
    };
    const calcDeltaDataPoints: () => BreakDownDatapoint[] = () => {
      const deltas: BreakDownDatapoint[] = [];
      currentDataPoints.forEach(currentDataPoint => {
        const foundDataPoint = previousDataPoints.find(previousDataPoint => {
          if (subItemType === 'category') {
            return previousDataPoint.categoryId === currentDataPoint.categoryId;
          }
          if (subItemType === 'subcategory') {
            return (
              previousDataPoint.subcategoryId === currentDataPoint.subcategoryId
            );
          }
          if (subItemType === 'asin') {
            return previousDataPoint.asinId === currentDataPoint.asinId;
          }
          return undefined;
        });
        if (foundDataPoint !== undefined) {
          const val =
            parseFloat(currentDataPoint.value) -
            parseFloat(foundDataPoint.value);
          const getCategory: () => string = () => {
            if (subItemType === 'category') {
              return currentDataPoint.category
                ? (currentDataPoint.category as string)
                : 'Uncategorized';
            }
            if (subItemType === 'subcategory') {
              const subcategory = subcategoriesResponse.data?.subcategories.edges.find(
                edge =>
                  extractUuidFromFullPath(edge.node.id) ===
                  currentDataPoint.subcategoryId,
              );
              return subcategory
                ? `${subcategory.node.name} (${subcategory.node.category.name})`
                : 'Uncategorized';
            }
            if (subItemType === 'asin') {
              const asin = asinsResponse.data?.asins.edges.find(
                edge =>
                  extractUuidFromFullPath(edge.node.id) ===
                  currentDataPoint.asinId,
              );
              return asin
                ? `${asin.node.productName ?? ''} (${asin.node.asin})`
                : '';
            }
            return '';
          };
          if (getCategory() !== '') {
            deltas.push({
              category: getCategory(),
              value: prettyPrintNumber(val, 'dollar amount', 2),
              percentage: `${val > 0 ? '+' : ''}${
                previousTotal > 0
                  ? prettyPrintNumber((100.0 / previousTotal) * val, 'percent')
                  : prettyPrintNumber((100.0 / currentTotal) * val, 'percent')
              }`,
              change: val > 0 ? 'positive' : 'negative',
            });
          }
          previousDataPoints = previousDataPoints.filter(dataPoint => {
            if (subItemType === 'category') {
              return dataPoint.categoryId !== foundDataPoint.categoryId;
            }
            if (subItemType === 'subcategory') {
              return dataPoint.subcategoryId !== foundDataPoint.subcategoryId;
            }
            if (subItemType === 'asin') {
              return dataPoint.asinId !== foundDataPoint.asinId;
            }
            return false;
          });
        } else {
          const getCategory: () => string = () => {
            if (subItemType === 'category') {
              return currentDataPoint.category as string;
            }
            if (subItemType === 'subcategory') {
              return currentDataPoint.subcategory as string;
            }
            if (subItemType === 'asin') {
              const asin = asinsResponse.data?.asins.edges.find(
                edge =>
                  extractUuidFromFullPath(edge.node.id) ===
                  currentDataPoint.asinId,
              );
              return asin ? asin.node.asin : '';
            }
            return '';
          };
          if (getCategory() !== '') {
            deltas.push({
              category: getCategory(),
              value: prettyPrintNumber(
                parseFloat(currentDataPoint.value),
                'dollar amount',
                2,
              ),
              percentage: `${
                parseFloat(currentDataPoint.value) > 0 ? '+' : ''
              }${
                previousTotal > 0
                  ? prettyPrintNumber(
                      (100.0 / previousTotal) *
                        parseFloat(currentDataPoint.value),
                      'percent',
                    )
                  : prettyPrintNumber(
                      (100.0 / currentTotal) *
                        parseFloat(currentDataPoint.value),
                      'percent',
                    )
              }`,
              change:
                parseFloat(currentDataPoint.value) > 0
                  ? 'positive'
                  : 'negative',
            });
          }
        }
      });
      if (previousDataPoints.length > 0) {
        previousDataPoints.forEach(previousDataPoint => {
          const val = parseFloat(previousDataPoint.value) * -1;
          const getCategory: () => string = () => {
            if (subItemType === 'category') {
              return previousDataPoint.category as string;
            }
            if (subItemType === 'subcategory') {
              return previousDataPoint.subcategory as string;
            }
            if (subItemType === 'asin') {
              const asin = asinsResponse.data?.asins.edges.find(
                edge =>
                  extractUuidFromFullPath(edge.node.id) ===
                  previousDataPoint.asinId,
              );
              return asin ? asin.node.asin : '';
            }
            return '';
          };
          if (getCategory() !== '') {
            deltas.push({
              category: getCategory(),
              value: prettyPrintNumber(val, 'dollar amount', 2),
              percentage: `${val > 0 ? '+' : ''}${
                previousTotal > 0
                  ? prettyPrintNumber((100.0 / previousTotal) * val, 'percent')
                  : prettyPrintNumber((100.0 / currentTotal) * val, 'percent')
              }`,
              change: val > 0 ? 'positive' : 'negative',
            });
          }
        });
      }
      return deltas;
    };
    const deltaDataPoints = calcDeltaDataPoints();

    return deltaDataPoints.sort(compare);
  }
  return [];
};
