import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Grid from '@material-ui/core/Grid';
import { combineDonutGraphData } from '../../dashboard/transformers';
import { CombinedDonutGraphData } from '../../../common/types';
import { donutChartBreakdownColors } from '../../../common/theme';
import LoadingIndicator from '../../LoadingIndicator';
import { transformResponseDataToDonutGraphData } from './transformers';
import { withDonutChartBreakdownStyles } from './styles';
import useDonutChartData from './useDonutChartData';
import ChartBlock from './ChartBlock';
import SliceBreakdownBlock from './SliceBreakdownBlock';
import SliceBreakdownTable from './SliceBreakdownTable';

const Container = styled.section`
  ${withDonutChartBreakdownStyles()}
`;

const maxGroupsCount = 7;

const DonutChartBreakdown: React.FC = () => {
  const [selectedDataSlice, setSelectedDataSlice] = useState<
    CombinedDonutGraphData
  >({} as CombinedDonutGraphData);
  const [showAllCategories, setShowAllCategories] = useState(false);

  const {
    asinsResponse,
    dataFilters,
    loading,
    orderedProductsDataByAsinResponse,
    orderedProductsDataBySubcategoryResponse,
    orderedProductsDataResponse,
    orderedRevenueDataByAsinResponse,
    orderedRevenueDataBySubcategoryResponse,
    orderedRevenueDataResponse,
  } = useDonutChartData();

  const {
    colors: orderedRevenueColors,
    data: orderedRevenueData,
    extraGroup: extraOrderedRevenueData,
    total: orderedRevenueTotal,
  } = transformResponseDataToDonutGraphData({
    filters: dataFilters,
    loading,
    maxGroupsCount,
    responses: {
      asins: asinsResponse,
      groupedByAsin: orderedRevenueDataByAsinResponse,
      groupedByCategory: orderedRevenueDataResponse,
      groupedBySubcategory: orderedRevenueDataBySubcategoryResponse,
    },
  });

  const {
    colors: orderedProductsColors,
    data: orderedProductsData,
    extraGroup: extraOrderedProductsData,
    total: orderedProductsTotal,
  } = transformResponseDataToDonutGraphData({
    filters: dataFilters,
    loading,
    maxGroupsCount,
    responses: {
      asins: asinsResponse,
      groupedByAsin: orderedProductsDataByAsinResponse,
      groupedByCategory: orderedProductsDataResponse,
      groupedBySubcategory: orderedProductsDataBySubcategoryResponse,
    },
    baseGroupIds: orderedRevenueData.map(item => item.id || ''),
  });
  const combinedData = combineDonutGraphData(
    orderedRevenueData,
    orderedRevenueTotal,
    orderedProductsData,
    orderedProductsTotal,
    donutChartBreakdownColors,
  );
  const combinedExtraData = showAllCategories
    ? combineDonutGraphData(
        extraOrderedRevenueData,
        orderedRevenueTotal,
        extraOrderedProductsData,
        orderedProductsTotal,
        donutChartBreakdownColors,
      )
    : [];

  const handleSelectDataSlice = (id: string) => {
    setSelectedDataSlice(
      combinedData.find(item => item.id === id) ||
        ({} as CombinedDonutGraphData),
    );
  };

  useEffect(() => {
    if (
      selectedDataSlice.id !== undefined &&
      combinedData.find(item => item.id === selectedDataSlice.id) === undefined
    ) {
      setSelectedDataSlice({} as CombinedDonutGraphData);
    }
  }, [combinedData, selectedDataSlice]);
  return dataFilters.asins.length === 1 ? null : (
    <Container>
      <div className="charts-wrapper">
        <ChartBlock
          colors={orderedRevenueColors}
          data={orderedRevenueData}
          graphType="revenue"
          loading={loading}
          onDataSliceSelect={handleSelectDataSlice}
          selectedDataSlice={selectedDataSlice}
          title="Ordered Revenue"
          total={orderedRevenueTotal}
        />
        <ChartBlock
          colors={orderedProductsColors}
          data={orderedProductsData}
          graphType="products"
          loading={loading}
          onDataSliceSelect={handleSelectDataSlice}
          selectedDataSlice={selectedDataSlice}
          title="Ordered Products"
          total={orderedProductsTotal}
        />
      </div>

      <div className="donut-slice-breakdown donut-chart-section">
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Grid container>
              <SliceBreakdownBlock
                data={combinedData}
                selectedDataSlice={selectedDataSlice}
              />
            </Grid>
            <SliceBreakdownTable
              data={[
                ...combinedData,
                ...combinedExtraData.map(item => ({
                  ...item,
                  color: '#FBFBFB',
                })),
              ]}
              expand={showAllCategories}
              onShowAllClick={() => setShowAllCategories(!showAllCategories)}
              selectedDataSlice={selectedDataSlice}
            />
          </>
        )}
      </div>
    </Container>
  );
};

export default DonutChartBreakdown;
