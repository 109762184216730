import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { routes } from '../common/routes';
import { useAuth } from '../auth/useAuth';

const PrivateRoute: React.FC<RouteProps> = props => {
  const { validating, validToken } = useAuth();
  const { search, pathname } = useLocation();
  if (validToken && pathname === '/')
    return <Redirect to={`/sales${search}`} />;
  return validating ? (
    <></>
  ) : (
    <>
      {validToken ? (
        <Route {...props} />
      ) : (
        <Redirect to={`${routes.login}${search}`} />
      )}
    </>
  );
};

export default PrivateRoute;
