import { css, SerializedStyles } from '@emotion/core';
import { Factory } from '../../common/types';
import { themeColors } from '../../common/theme';
import { maxContentWidth } from '../../common/constants';

export const withDashboardStyles: Factory<SerializedStyles> = () => css`
  .body {
    overflow: auto !important;
  }
  section.body {
    background: transparent !important;
    .history-table {
      max-width: 1148px;
      margin: auto;
    }
    .breakdown-section {
      display: flex;
      max-width: ${maxContentWidth}px;
      min-width: 895px;
      margin: 0 auto 16.5px auto;
      &:nth-child(2) {
        margin-bottom: 24px;
      }
      .primary-breakdown-container,
      .secondary-breakdown-container {
        width: 100%;
        height: 100%;
        max-width: 1148px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        > div {
          width: 32.5%;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: start;
          background: ${themeColors.white};
          border: 1px solid ${themeColors.gainsboro};
          border-radius: 8px;
          color: ${themeColors.cyprus};

          .title {
            text-transform: uppercase;
            font: normal normal bold 13px/20px Montserrat;
            letter-spacing: 0.52px;
            opacity: 0.8;
            height: 16px;
            display flex;
            align-items: center;
          }

          .data {
            display: flex;
            flex-direction: row;
            align-items: space-between;
            justify-content: center;
            width: 100%;

            .empty_response {
              margin: 24px 0;
              font-size: 13px;
              color: #0d2d3d;
              justify-content: center;
            }

            

            .delta {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              padding: 0 15px 0 4.5%;

              .delta_title {
                opacity: 0.8;
              }

              .delta_data {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                font-weight: bold;
                font-size: 13px;
                .delta_value {
                  margin-left: 8%;
                }
                .positive_delta,
                .none_delta {
                  color: ${themeColors.successGreen};
                }
                .negative_delta {
                  color: ${themeColors.errorRed};
                }
              }
            }
            .more-vert-icon {
              padding: 6px;
              right: 1px !important;
              top: 7px !important;

              .MuiSvgIcon-root {
                height: 1.45rem;
                width: 1.45rem;
              }
            }
          }

          .breakdown_summary_container {
            padding: 0 12px 0 12px !important;
            .breakdown_summary {
              opacity: 0.8;
              text-transform: none;
              font-size: 13px;
              color: ${themeColors.cyprus};
              position: relative;
            }
            .MuiIconButton-root {
              padding: 8px;
              color: ${themeColors.cyprus};
              svg {
                width: 18px;
                height: 18px;
              }
              &.MuiIconButton-edgeEnd {
                margin-right: -12px !important;
              }
            }
          }
        }
      }
      &.donut-section {
        border-radius: 16px;
        max-width: ${maxContentWidth}px;
        min-width: 895px;
      }
    }
    > section {
      margin: 0 auto;
      margin-bottom: 24px;
      border-radius: 16px;

      &.graph-breakdown {
        &.performance-breakdown {
          .title {
            padding-bottom: 8px;
          }
          .graph_container {
            padding-bottom: 0;
          }
        }
        max-width: 1148px;
        min-width: 895px;
        padding: 0;
        border: 1px solid ${themeColors.gainsboro};
        .title {
          padding: 40px 40px 4px 40px;
          &.performance-title {
            padding-top: 28px !important;
            padding-bottom: 16px !important;
          }
          text-transform: uppercase;
          font-size: 13px;
          font-weight: 700;
        }
        .graph_container {
          padding-bottom: 40px;
        }
      }
    }
  }
`;
