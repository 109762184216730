import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { Consumer, DataType, Factory } from '../../common/types';
import { useHeader } from '../header/useHeader';

interface VariableFieldKey {
  key: string;
  secondKey: string;
  title: string;
  type: DataType;
}

interface FieldKeys {
  orderedRevenue: string;
  unitsSold: string;
  buyBoxPerformance: string;
  pageViews: string;
  orderId: string;
  sessions: string;
  primaryBreakdown2: VariableFieldKey;
  secondaryBreakdown1: VariableFieldKey;
  secondaryBreakdown2: VariableFieldKey;
  secondaryBreakdown3: VariableFieldKey;
  buyBox: VariableFieldKey;
  buyBoxSales: VariableFieldKey;
}

export const fieldKeyMap: { vendor: FieldKeys; seller: FieldKeys } = {
  vendor: {
    orderedRevenue: 'ordered_revenue',
    unitsSold: 'ordered_units',
    buyBoxPerformance: 'buy_box_percentage',
    pageViews: 'page_views',
    orderId: 'order_id',
    sessions: 'sessions',
    primaryBreakdown2: {
      key: 'shipped_cogs',
      secondKey: '',
      title: 'Shipped COGS',
      type: 'dollar amount',
    },
    secondaryBreakdown1: {
      key: 'glance_views',
      secondKey: '',
      title: 'Glance Views',
      type: 'count',
    },
    secondaryBreakdown2: {
      key: 'ordered_units',
      secondKey: 'glance_views',
      title: 'Conversion Rate',
      type: 'percent',
    },
    secondaryBreakdown3: {
      key: 'ordered_revenue',
      secondKey: 'ordered_units',
      title: 'ASP',
      type: 'dollar amount',
    },
    buyBox: {
      key: 'lost_buy_box',
      title: 'TOP LOST BUY BOX % CHANGE',
      secondKey: 'glance_views',
      type: 'percent',
    },
    buyBoxSales: {
      key: 'ordered_revenue',
      secondKey: '',
      title: 'Top Ordered Revenue % Change',
      type: 'dollar amount',
    },
  },
  seller: {
    orderedRevenue: 'ordered_revenue_sc',
    unitsSold: 'ordered_units_sc',
    buyBoxPerformance: 'buy_box_percentage',
    pageViews: 'page_views',
    orderId: 'order_id',
    sessions: 'sessions',
    primaryBreakdown2: {
      key: 'sessions',
      secondKey: '',
      title: 'Sessions',
      type: 'count',
    },
    secondaryBreakdown1: {
      key: 'buy_box_percentage',
      title: 'BUY BOX %',
      secondKey: 'page_views',
      type: 'percent',
    },
    secondaryBreakdown2: {
      key: 'order_id',
      secondKey: 'sessions',
      title: 'Conversion Rate',
      type: 'percent',
    },
    secondaryBreakdown3: {
      key: 'ordered_revenue_sc',
      secondKey: 'ordered_units_sc',
      title: 'ASP',
      type: 'dollar amount',
    },
    buyBox: {
      key: 'buy_box_percentage',
      secondKey: 'page_views',
      title: 'TOP BUY BOX % CHANGE',
      type: 'percent',
    },
    buyBoxSales: {
      key: 'ordered_revenue_sc',
      secondKey: '',
      title: 'Top Ordered Revenue % Change',
      type: 'dollar amount',
    },
  },
};

export const fieldConfigurationFormula: (
  fc: string,
) => { firstKey: string; secondKey: string } = (fc: string) => {
  switch (fc) {
    case 'lost_buy_box':
      return { firstKey: 'lost_buy_box', secondKey: 'glance_views' };
    case 'rep_oos':
      return { firstKey: 'rep_oos', secondKey: 'glance_views' };
    case 'average_sales_price':
      return { firstKey: 'ordered_revenue', secondKey: 'ordered_units' };
    case 'buy_box_percentage':
      return { firstKey: 'buy_box_percentage', secondKey: 'page_views' };
    case 'conversion_rate_old':
      return { firstKey: 'order_id', secondKey: 'sessions' };
    case 'average_sales_price_sc':
      return { firstKey: 'ordered_revenue_sc', secondKey: 'ordered_units_sc' };
    default:
      return { firstKey: fc, secondKey: '' };
  }
};

export enum DataSetType {
  vendor,
  seller,
}

export interface Context {
  fieldKeys: FieldKeys;
  dataType: DataSetType;
  open: boolean;
  setDataType: Consumer<DataSetType>;
  setOpen: Consumer<boolean>;
}

export const SidebarContext = createContext<Context>({
  fieldKeys: { ...fieldKeyMap.vendor },
  dataType: DataSetType.vendor,
  open: false,
  setDataType: () => null,
  setOpen: () => null,
});

export const SidebarProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(true);
  const [dataType, localSetDataType] = useState(DataSetType.vendor);

  const { setSidebarDataType, setSidebarDataTypeChanged } = useHeader();

  const fieldKeys = useMemo(() => {
    if (dataType === DataSetType.vendor) {
      return { ...fieldKeyMap.vendor };
    }
    if (dataType === DataSetType.seller) {
      return { ...fieldKeyMap.seller };
    }
    /* default to vendor central */
    return { ...fieldKeyMap.vendor };
  }, [dataType]);

  const setDataType: Consumer<DataSetType> = newType => {
    if (newType !== dataType) {
      setSidebarDataTypeChanged(true);
      localSetDataType(newType);
    }
  };

  useEffect(() => {
    setSidebarDataType(dataType);
  }, [dataType, setSidebarDataType]);

  return (
    <SidebarContext.Provider
      value={{ dataType, fieldKeys, open, setDataType, setOpen }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar: Factory<Context> = () => useContext(SidebarContext);
