import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import FlagIcon from '../../icons/FlagWithCircle';
import { useToast } from './useToast';
import { AlertTitle, toastTheme } from './styles';

const Toast: React.FC = () => {
  const { open, setOpen, contents, title } = useToast();

  const handleClose = () => {
    setOpen(false);
  };

  const Alert: (props: AlertProps) => JSX.Element = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  return (
    <ThemeProvider theme={toastTheme}>
      <Snackbar
        className="main-toast"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={15000}
        onClose={handleClose}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon className="modal-icon" />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert severity="info" onClose={handleClose} icon={<FlagIcon />}>
          <AlertTitle>{title}</AlertTitle>
          {contents}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default Toast;
