import React, { createContext, useContext, useState } from 'react';
import { useQuery } from 'urql';
import { extractUuidFromFullPath } from '../common/utils';
import { useAuth } from '../auth/useAuth';
import { Client, getClients, GetClientsResponse } from '../api/client';
import { Factory } from '../common/types';

export interface Context {
  clients: Client[];
  currentClient: Client;
  changingClient: boolean;
  setCurrentClient: (id: string, changingClient?: boolean) => void;
  setChangingClient: (changing: boolean) => void;
}

export const ClientsContext = createContext<Context>({
  clients: [],
  currentClient: {
    id: '',
    name: '',
    accountManagers: { edges: [] },
    vendorCentralEnabled: false,
    sellerCentralEnabled: false,
    enabled: false,
  },
  changingClient: false,
  setCurrentClient: () => null,
  setChangingClient: () => false,
});

export const ClientsProvider: React.FC = ({ children }) => {
  const { validating, validToken } = useAuth();
  const clients = useProvidedClients(!validating && validToken);

  return (
    <ClientsContext.Provider value={clients}>
      {children}
    </ClientsContext.Provider>
  );
};

export const useClients: Factory<Context> = () => useContext(ClientsContext);

const useProvidedClients: (loggedIn: boolean) => Context = loggedIn => {
  const [currentClient, setActiveClient] = useState<Client>({
    id: '',
    name: '',
    accountManagers: { edges: [] },
    vendorCentralEnabled: false,
    sellerCentralEnabled: false,
    enabled: false,
  });
  const [currentClients, setCurrentClients] = useState<Client[]>([]);
  const [changingClient, setChangingClient] = useState<boolean>(false);
  const [getClientsResponse] = useQuery<GetClientsResponse>({
    query: getClients,
    pause: !loggedIn,
  });

  const setCurrentClient: (
    id: string,
    clientChanging?: boolean,
  ) => void = async (id, clientChanging = false) => {
    const client = await currentClients.find(client => client.id === id);
    if (clientChanging) await setChangingClient(true);
    if (client) {
      setActiveClient(client);
    }
  };
  if (
    getClientsResponse.data &&
    getClientsResponse.data.clients &&
    currentClients.length === 0
  ) {
    const clients = getClientsResponse.data.clients.edges.map(edge => ({
      name: edge.node.name,
      id: extractUuidFromFullPath(edge.node.id),
      accountManagers: edge.node.accountManagers,
      vendorCentralEnabled: edge.node.vendorCentralEnabled,
      sellerCentralEnabled: edge.node.sellerCentralEnabled,
      enabled: edge.node.enabled,
    }));
    setCurrentClients(clients);
    return {
      clients,
      currentClient,
      changingClient,
      setCurrentClient,
      setChangingClient,
    };
  } else {
    return {
      clients: currentClients,
      currentClient,
      changingClient,
      setCurrentClient,
      setChangingClient,
    };
  }
};
