import { mergeMap, pipe, fromPromise, map, fromValue } from 'wonka';
import { updateToken } from '../api/user';
import { authTokenKey, refreshTokenKey } from './useAuth';
import jwt_decode from 'jwt-decode';
import { Exchange } from '@urql/core/dist/types/types';

const isTokenExpired = () => {
  const token = window.localStorage.getItem(authTokenKey);
  if (!token) return true;
  const decoded = jwt_decode<{
    exp: number;
    iat: number;
    username: string;
    roles: string[];
    uid: string;
    fullName: string;
  }>(token);
  const expiration = decoded.exp * 1000;
  const now = new Date();
  const current = now.getTime();
  return expiration <= current;
};

export const refreshTokenExchange: Exchange = ({ forward }) => ops$ =>
  pipe(
    ops$,
    mergeMap(op => {
      if (isTokenExpired()) {
        const refreshToken = window.localStorage.getItem(refreshTokenKey);
        return pipe(
          fromPromise(updateToken(refreshToken ? refreshToken : '')),
          map(response => {
            if (response.token && response.refresh_token) {
              window.localStorage.setItem(authTokenKey, response.token);
              window.localStorage.setItem(
                refreshTokenKey,
                response.refresh_token,
              );
            }
            return {
              ...op,
              context: {
                ...op.context,
                token: window.localStorage.getItem(authTokenKey),
              },
            };
          }),
        );
      } else {
        return fromValue({
          ...op,
          context: {
            ...op.context,
            token: window.localStorage.getItem(authTokenKey),
          },
        });
      }
    }),
    forward,
  );
