import { History } from 'history';
import moment, { Moment } from 'moment';
import { Client } from '../../api/client';
import { Consumer, FilterItem, NullableMoment } from '../../common/types';
import { DateRange } from '../graphs/types';
import { CompareToPreviousTypeOptions } from './useHeader';
import { urlFilterParams } from './useUrlSync';

export const dateFormat = 'YYYYMMDD';

interface UrlSyncProps {
  history: History;
  params: URLSearchParams;
}

interface SyncSelectedDatesWithUrlArgumentObject extends UrlSyncProps {
  lastValidDate: NullableMoment | boolean;
  selectedDates: DateRange;
  updateSelectedDates: Consumer<DateRange>;
}

export const syncSelectedDatesWithUrl: Consumer<SyncSelectedDatesWithUrlArgumentObject> = ({
  history,
  lastValidDate,
  params,
  selectedDates,
  updateSelectedDates,
}) => {
  const startDateUrlParam = params.get(urlFilterParams.startDate);
  const endDateUrlParam = params.get(urlFilterParams.endDate);
  if (startDateUrlParam && endDateUrlParam) {
    if (selectedDates.startDate && selectedDates.endDate && lastValidDate) {
      if (
        moment(selectedDates.endDate).isAfter(moment(lastValidDate as Moment))
      ) {
        const endDate: moment.Moment = (lastValidDate as Moment).endOf('day');
        const startDate: moment.Moment = moment(lastValidDate as Moment)
          .subtract(7, 'days')
          .startOf('day');
        updateSelectedDates({
          startDate,
          endDate,
        });
      } else {
        const startDateString = selectedDates.startDate.format(dateFormat);
        const endDateString = selectedDates.endDate.format(dateFormat);
        if (
          startDateUrlParam !== startDateString ||
          endDateUrlParam !== endDateString
        ) {
          params.set(urlFilterParams.startDate, startDateString);
          params.set(urlFilterParams.endDate, endDateString);
          history.replace({
            search: params.toString(),
          });
        }
      }
    } else {
      const startDate = moment(startDateUrlParam);
      const endDate = moment(endDateUrlParam);
      if (startDate.isValid() && endDate.isValid() && lastValidDate) {
        updateSelectedDates({
          startDate,
          endDate,
        });
      } else if (lastValidDate) {
        const endDate: moment.Moment = (lastValidDate as Moment).endOf('day');
        const startDate: moment.Moment = moment(lastValidDate as Moment)
          .subtract(7, 'days')
          .startOf('day');
        updateSelectedDates({
          startDate,
          endDate,
        });
      }
    }
  } else {
    if (selectedDates.startDate && selectedDates.endDate) {
      const startDateString = selectedDates.startDate.format(dateFormat);
      const endDateString = selectedDates?.endDate.format(dateFormat);
      params.set(urlFilterParams.startDate, startDateString);
      params.set(urlFilterParams.endDate, endDateString);
      history.replace({
        search: params.toString(),
      });
    } else if (lastValidDate) {
      const endDate: moment.Moment = (lastValidDate as Moment).endOf('day');
      const startDate: moment.Moment = moment(lastValidDate as Moment)
        .subtract(7, 'days')
        .startOf('day');
      updateSelectedDates({
        startDate,
        endDate,
      });
    }
  }
};

interface SyncCompareToPreviousTypeWithUrlArgumentObject extends UrlSyncProps {
  compareToPreviousType: CompareToPreviousTypeOptions;
  setCompareToPreviousType: Consumer<CompareToPreviousTypeOptions>;
}

export const syncCompareToPreviousTypeWithUrl: Consumer<SyncCompareToPreviousTypeWithUrlArgumentObject> = ({
  compareToPreviousType,
  history,
  params,
  setCompareToPreviousType,
}) => {
  const compareToPreviousTypeUrlParam = params.get(
    urlFilterParams.compareToPrevious,
  );
  if (compareToPreviousType === CompareToPreviousTypeOptions.notSet) {
    switch (compareToPreviousTypeUrlParam) {
      case 'y':
        setCompareToPreviousType(CompareToPreviousTypeOptions.year);
        break;
      case 'p':
        setCompareToPreviousType(CompareToPreviousTypeOptions.period);
        break;
      default:
        setCompareToPreviousType(CompareToPreviousTypeOptions.period);
        params.set(urlFilterParams.compareToPrevious, 'p');
        history.replace({
          search: params.toString(),
        });
    }
  } else {
    if (compareToPreviousTypeUrlParam !== null) {
      if (
        compareToPreviousTypeUrlParam === 'y' &&
        compareToPreviousType === CompareToPreviousTypeOptions.period
      ) {
        params.set(urlFilterParams.compareToPrevious, 'p');
        history.replace({
          search: params.toString(),
        });
      } else if (
        compareToPreviousTypeUrlParam === 'p' &&
        compareToPreviousType === CompareToPreviousTypeOptions.year
      ) {
        params.set(urlFilterParams.compareToPrevious, 'y');
        history.replace({
          search: params.toString(),
        });
      }
    }
  }
};

interface SyncCurrentClientWithUrlArgumentObject extends UrlSyncProps {
  clients: Client[];
  currentClient: Client;
  setCurrentClient: Consumer<string>;
}

export const syncCurrentClientWithUrl: Consumer<SyncCurrentClientWithUrlArgumentObject> = ({
  clients,
  currentClient,
  history,
  params,
  setCurrentClient,
}) => {
  if (clients.length > 0) {
    const clientIdUrlParam = params.get(urlFilterParams.client);
    if (currentClient.id === '') {
      const selectedClient = clients.find(
        client => client.id === clientIdUrlParam,
      );
      if (selectedClient) {
        setCurrentClient(selectedClient.id);
      } else {
        setCurrentClient(clients[0].id);
      }
    } else if (clientIdUrlParam !== currentClient.id) {
      removeDatesFromUrl({ history, params });
      params.set(urlFilterParams.client, currentClient.id);
      history.replace({
        search: params.toString(),
      });
    }
  }
};

interface SyncSelectedFiltersWithUrlArgumentObject extends UrlSyncProps {
  clearFilters: boolean;
  selectedFilters: FilterItem[];
  setSelectedFilters: Consumer<FilterItem[]>;
}

export const syncSelectedFiltersWithUrl: Consumer<SyncSelectedFiltersWithUrlArgumentObject> = ({
  clearFilters,
  selectedFilters,
  setSelectedFilters,
  params,
  history,
}) => {
  const filtersUrlParam = params.get(urlFilterParams.filters);
  const currentFiltersAsString = JSON.stringify({ selected: selectedFilters });
  if (filtersUrlParam !== currentFiltersAsString) {
    if (selectedFilters.length === 0 && filtersUrlParam) {
      if (clearFilters) {
        params.set(urlFilterParams.filters, currentFiltersAsString);
        history.replace({
          search: params.toString(),
        });
      } else {
        const filtersFromUrl = JSON.parse(filtersUrlParam).selected;
        setSelectedFilters(filtersFromUrl);
      }
    } else if (selectedFilters.length > 0) {
      params.set(urlFilterParams.filters, currentFiltersAsString);
      history.replace({
        search: params.toString(),
      });
    }
  }
};

export const removeDatesFromUrl: Consumer<UrlSyncProps> = ({
  history,
  params,
}) => {
  params.delete(urlFilterParams.startDate);
  params.delete(urlFilterParams.endDate);
  history.replace({
    search: params.toString(),
  });
};
